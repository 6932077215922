export const TEAMS = 'Launch community in Teams';
export const TEAMS_DISABLED = 'Your access to Teams is not ready yet!';
export const SHAREPOINT = 'Launch community in SharePoint';
export const LEAVE = 'Leave community';
export const LEAVE_MESSAGE = 'Are you sure you want to leave this community?';
export const INVITE = 'Invite members to community';
export const EXPORT = 'Export member list (.csv)';
export const EXPORT_INVITES = 'Export invitation list (.csv)';
export const EDIT = 'Edit community';
export const CLOSE = 'Close community';
export const JOIN = 'Join community';
export const REQUEST = 'Request access';
export const VIEWMANAGE = 'View community details and membership';
export const PROCESSING =
  "We are in the process of creating your community now and it's going to take about an hour. We'll send you an email when it's ready to use.";
export const POPUP_COMMUNITY_CLOSE =
  "Are you sure you want to delete the community '%DISPLAYNAME%'? All channels, chats, files and the Office 365 Group for this community will be deleted. You should backup/export all content before you delete the community.";
