import actions from './InsiderFeed.actions';
import { InsiderFeedItem } from './InsiderFeed.types';
import { getAuthFromState } from '../../auth';
import { httpServiceSecureGet } from '../../common/Http';

export const insiderFeedActionCreators = {
  requestInsiderFeed: () => async (dispatch, getState) => {
    if (getState().insiderFeed.isLoading) {
      return;
    }

    dispatch({
      type: actions.FETCH_INSIDER_FEED_REQUEST,
    });

    const feed: InsiderFeedItem[] = [];
    let seeMoreLink: string = null;

    try {
      const { gtToken } = await getAuthFromState(getState, dispatch);
      const json = await httpServiceSecureGet('/Feed', gtToken);

      // Double parse JSON because API is returning JSON of JSON
      const data = JSON.parse(json);

      // Error checking
      if ( !data || data.error ) {
        throw data;
      }

      // Build see more link
      seeMoreLink = data?.SeeMoreLinkUrl;
      if ( !seeMoreLink ) {
        seeMoreLink = data?.['odata.metadata']?.substring(0, data?.['odata.metadata']?.indexOf('_api'));
      }

      // Build slide array
      data?.value?.forEach( item => {
        feed.push({
          id: item['odata.id'],
          title: item.Title,
          description: item.Description,
          imageUrl: item.BannerImageUrl?.Url,
          imageContent: item.BannerImageUrl?.ImageContent,
          imageContentType: item.BannerImageUrl?.ImageContentType,
          link: item.File?.ServerRelativeUrl,
        });
      });
    }
    catch (error) {
      console.log('Error occurred while fetching carousel slides', error);
    }
    finally {
      dispatch({
        type: actions.FETCH_INSIDER_FEED_RESPONSE,
        feed,
        seeMoreLink,
      });
    }
  }
};

export default insiderFeedActionCreators;
