import * as React from 'react';
import { Link } from '@fluentui/react/lib/Link';

/**
 * Constants and utilities
 */
import { BRANDNAME } from '../common/Tooltips';
import ContactButton from "../components/ContactButton";

export class UntrustedNetwork extends React.Component<any, any> {
  public render() {
    return (
       <div className='govTeams-errorContent'>
          <h2 className="heading-error">
            It looks like you’re trying to access {BRANDNAME} from an untrusted network.
          </h2>
          <hr />
          <p>
            Direct access to {BRANDNAME} is restricted to trusted networks. Trusted networks include Commonwealth Government networks that are rated PROTECTED.
          </p>
          <p>
            You can use an iOS device (version 12 or above) to access {BRANDNAME} from an untrusted network.  <br />
            Please refer to{' '}
            <Link href='https://www.govteams.gov.au/wvd-decommission' target='_blank'>
              this page
            </Link>
            {' '}for more information.
          </p>
          <p>
            If you think the network you are working from should be trusted, please <ContactButton buttonLabel={'contact us'} />
          </p>
       </div>
    );
  }
}

export default UntrustedNetwork;
