/**
 * Commmunity list view
 *
 * Displays list of communities organised by
 * -favourites
 * -owner
 * -member
 * -suggested
 *
 * Each community item is clickable and launches the community panel
 */

/**
 * React Redux
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {communityActionCreators, MemberType} from '../../../../controllers/CommunityController';

/**
 * UI Fabric
 */
import { ActionButton, IconButton } from '@fluentui/react/lib/Button';
import {
  ConstrainMode,
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode
} from '@fluentui/react/lib/DetailsList';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { ResponsiveMode } from '@fluentui/react';
import { Icon } from '@fluentui/react/lib/Icon';
import { Link } from '@fluentui/react/lib/Link';
import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';

/**
 * GovTEAMS components
 */
import Tag from '../../../../components/ReduxFormFields/Tag';

/**
 * Utilities
 */
import { mediaQuery, screenSize } from '../../../../common/ResponsiveUtils';
import { sortItems, sortItemsByInt } from '../../../../common/Utils';
import { isEqual } from 'lodash';

/**
 * Constants
 */
import * as tooltips from '../../../../common/Tooltips';

export class CommunityList extends React.Component<any, any> {
  // public static getDerivedStateFromProps(props, state) {
  //   let items = [];

  //   switch (state.selectedKey) {
  //     case 'favouriteCommunities':
  //       items =
  //         props.favouriteCommunities !== null &&
  //         props.favouriteCommunities !== undefined
  //           ? props.favouriteCommunities
  //           : [];
  //       break;
  //     case 'ownerCommunities':
  //       items =
  //         props.ownerCommunities !== null &&
  //         props.ownerCommunities !== undefined
  //           ? props.ownerCommunities
  //           : [];
  //       break;
  //     case 'memberCommunities':
  //       items =
  //         props.memberCommunities !== null &&
  //         props.memberCommunities !== undefined
  //           ? props.memberCommunities
  //           : [];
  //       break;
  //     case 'suggestedCommunities':
  //       items =
  //         props.suggestedCommunities !== null &&
  //         props.suggestedCommunities !== undefined
  //           ? props.suggestedCommunities
  //           : [];
  //       break;
  //     default:
  //       items =
  //         props.ownerCommunities !== null &&
  //         props.ownerCommunities !== undefined
  //           ? props.ownerCommunities
  //           : [];
  //       break;
  //   }

  //   if (!isEqual(items, state.items)) {
  //     return {
  //       currPage: 0,
  //       items
  //     };
  //   } else {
  //     return {};
  //   }
  // }

  constructor(props) {
    super(props);

    const columns = [
      {
        ariaLabel: 'Quick Actions',
        fieldName: 'actions',
        isResizable: false,
        key: 'actions',
        maxWidth: 20,
        minWidth: 20,
        name: <Icon iconName={'FavoriteStarFill'} className='columnIcon' />
      },
      {
        ariaLabel: 'Operations for community name',
        fieldName: 'displayName',
        isResizable: true,
        key: 'displayName',
        isMultiline: true,
        maxWidth: 200,
        minWidth: 150,
        name: 'Community Name',
        onColumnClick: this.onColumnClick
      },
      {
        fieldName: 'description',
        isResizable: true,
        key: 'description',
        isMultiline: true,
        maxWidth: 300,
        minWidth: 250,
        name: 'Description',
        onColumnClick: this.onColumnClick
      },
      {
        ariaLabel: 'Operations for community type',
        fieldName: 'communityType',
        isResizable: true,
        key: 'communityType',
        maxWidth: 150,
        minWidth: 100,
        name: 'Community type',
        onColumnClick: this.onColumnClick
      },
      {
        fieldName: 'tags',
        isResizable: true,
        key: 'tags',
        isMultiline: true,
        maxWidth: 200,
        minWidth: 150,
        name: 'Tags'
      },
      {
        fieldName: 'members',
        isResizable: true,
        key: 'members',
        maxWidth: 20,
        minWidth: 20,
        name: 'Members',
        onColumnClick: this.onColumnClick
      }
    ];

    this.state = {
      columns,
      currPage: 0,
      filter: '',
      items: [],
      page: [],
      pageItems: [],
      screenWidth: window.innerWidth,
      selectedKey: 'favouriteCommunities'
    };
  }

  public render() {
    const { columns } = this.state;
    const { memberType } = this.props;

    return (
      <React.Fragment>
        {!this.props.ownerCommunities &&
        !this.props.memberCommunities &&
        !this.props.favouriteCommunities ? (
          <div className='communityList'>
            <Spinner size={SpinnerSize.large} label={'Loading Communities'} />
          </div>
        ) : (
          <div className='communityList'>
            {mediaQuery.minWidth(
              this.state.screenWidth,
              screenSize.SCREEN_MD
            ) ? (
              <div className='pivotLinks'>
                {(this.props.isGuest == null || !this.props.isGuest) &&
                this.props.memberType !== 'Sponsored' ? (
                  <Pivot
                    selectedKey={this.state.selectedKey}
                    onLinkClick={this.handleLinkClick}
                  >
                    <PivotItem
                      linkText={
                        'Favourites ' +
                        (this.props.favouriteCommunities
                          ? '(' + this.props.favouriteCommunities.length + ')'
                          : '(0)')
                      }
                      itemKey='favouriteCommunities'
                    />
                    <PivotItem
                      linkText={
                        'Owner ' +
                        (this.props.ownerCommunities
                          ? '(' + this.props.ownerCommunities.length + ')'
                          : '(0)')
                      }
                      itemKey='ownerCommunities'
                    />
                    <PivotItem
                      linkText={
                        'Member ' +
                        (this.props.memberCommunities
                          ? '(' + this.props.memberCommunities.length + ')'
                          : '(0)')
                      }
                      itemKey='memberCommunities'
                    />
                    {(memberType === MemberType.FAM ||
                      memberType === MemberType.OrganisationAdmin ||
                      memberType === MemberType.StateLicensedAccount ||
                      memberType === MemberType.Partner
                    ) && (
                    <PivotItem
                      linkText={
                        'Open Communities ' +
                        (this.props.openCommunities
                          ? '(' + this.props.openCommunities.length + ')'
                          : '(0)')
                      }
                      itemKey='openCommunities'
                    />
                    )}
                  </Pivot>
                ) : (
                  <Pivot
                    selectedKey={this.state.selectedKey}
                    onLinkClick={this.handleLinkClick}
                  >
                    <PivotItem
                      linkText={
                        'Favourites ' +
                        (this.props.favouriteCommunities
                          ? '(' + this.props.favouriteCommunities.length + ')'
                          : '(0)')
                      }
                      itemKey='favouriteCommunities'
                    />
                    <PivotItem
                      linkText={
                        'Member ' +
                        (this.props.memberCommunities
                          ? '(' + this.props.memberCommunities.length + ')'
                          : '(0)')
                      }
                      itemKey='memberCommunities'
                    />
                  </Pivot>
                )}
              </div>
            ) : (
              <div className='communityToggle'>
                <Dropdown
                  responsiveMode={ResponsiveMode.large}
                  selectedKey={this.state.selectedKey}
                  onChange={this.handleDropdownChange}
                  options={this.getCommunityTypeOptions()}
                />
              </div>
            )}

            {this.state.items && this.state.items.length > 0 && (
              <DetailsList
                columns={columns}
                constrainMode={ConstrainMode.horizontalConstrained}
                items={this.state.page}
                layoutMode={DetailsListLayoutMode.justified}
                onRenderItemColumn={this.onRenderItem}
                selectionMode={SelectionMode.none}
              />
            )}

            {this.state.items && this.state.items.length > 0 && (
              <div className='govTeams-searchPager'>
                {this.state.currPage > 0 && this.state.pageItems.length > 1 && (
                  <ActionButton
                    className='govTeams-searchPager--prev'
                    onClick={this.onPrevious}
                  >
                    <Icon iconName='ChevronLeft' />
                    Previous
                  </ActionButton>
                )}
                {this.state.pageItems.length - 1 > this.state.currPage && (
                  <ActionButton
                    className='govTeams-searchPager--next'
                    onClick={this.onNext}
                  >
                    Next
                    <Icon iconName='ChevronRight' />
                  </ActionButton>
                )}
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }

  public componentDidUpdate(prevProps, prevState) {
    // if (!isEqual(prevState.items, this.state.items)) {
    //   const pageItems = this.setPages(this.state.items);
    //   console.dir(pageItems);
    //   this.setState({
    //     page: [...pageItems[0]],
    //     pageItems: [...pageItems]
    //   });
    // }

    if (!isEqual(prevProps, this.props)) {
      this.refreshList();
    }
  }

  public async componentDidMount() {
    this.refreshList();

    window.addEventListener('resize', this.updateDimensions);
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  private updateDimensions = (event) => {
    this.setState({ screenWidth: window.innerWidth });
  };

  private refreshList() {
    let items = [];
    const {
      ownerCommunities,
      favouriteCommunities,
      memberCommunities,
      isDirty,
      isGuest
    } = Object.assign({}, this.props);
    if (
      (ownerCommunities || favouriteCommunities || memberCommunities) &&
      !isDirty
    ) {
      if (isGuest !== null && isGuest) {
        items = memberCommunities;
      }

      if (isGuest && memberCommunities) {
        items = memberCommunities ? memberCommunities : [];
      } else {
        items = favouriteCommunities ? favouriteCommunities : [];
      }

      const pageItems = this.setPages(items);

      this.setState({
        items,
        page: pageItems[0],
        pageItems
      });
    }
  }

  private onRenderItem = (item, index, column) => {
    const fieldContent = item[column.fieldName];
    const key = column.key;

    switch (key) {
      case 'actions':
        return this.renderActions(item);
      case 'displayName':
        return this.renderDisplayname(item);
      case 'tags':
        return this.renderTags(item);
      case 'members':
        return this.renderMembers(item);
      case 'communityType':
        return this.renderCommunityType(item);
      default:
        return fieldContent;
    }
  };

  private toggleFavourite = (item) => {
    this.props.communityActionCreators.toggleFavourite(item);
  };

  private getCommunityTypeOptions() {
    const options =
      this.props.memberType !== 'Sponsored'
        ? [
            {
              key: 'openCommunities',
              text:
                'Open Communities ' +
                (this.props.openCommunities
                  ? '(' + this.props.openCommunities.length + ')'
                  : '(0)')
            },
            {
              key: 'favouriteCommunities',
              text:
                'Favourites ' +
                (this.props.favouriteCommunities
                  ? '(' + this.props.favouriteCommunities.length + ')'
                  : '(0)')
            },
            {
              key: 'ownerCommunities',
              text:
                'Owner ' +
                (this.props.ownerCommunities
                  ? '(' + this.props.ownerCommunities.length + ')'
                  : '(0)')
            },
            {
              key: 'memberCommunities',
              text:
                'Member ' +
                (this.props.memberCommunities
                  ? '(' + this.props.memberCommunities.length + ')'
                  : '(0)')
            },
            {
              key: 'suggestedCommunities',
              text:
                'Suggested ' +
                (this.props.suggestedCommunities
                  ? '(' + this.props.suggestedCommunities.length + ')'
                  : '(0)')
            }
          ]
        : [
            {
              key: 'favouriteCommunities',
              text:
                'Favourites ' +
                (this.props.favouriteCommunities
                  ? '(' + this.props.favouriteCommunities.length + ')'
                  : '(0)')
            },
            {
              key: 'memberCommunities',
              text:
                'Member ' +
                (this.props.memberCommunities
                  ? '(' + this.props.memberCommunities.length + ')'
                  : '(0)')
            }
          ];

    return options;
  }

  private renderActions = (item) => {
    return (
      <div>
        {item.communityId > 0 && (item.isOwner || item.isMember) && (
          <TooltipHost
            content={
              item.isFavourite ? 'Remove Favourite' : 'Favourite Community'
            }
            calloutProps={{ gapSpace: 0 }}
          >
            <IconButton
              iconProps={{
                iconName: item.isFavourite ? 'FavoriteStarFill' : 'FavoriteStar'
              }}
              onClick={(ev) => {
                this.toggleFavourite(item);
                ev.preventDefault();
                ev.stopPropagation();
              }}
            />
          </TooltipHost>
        )}
        <TooltipHost content='Open Teams' calloutProps={{ gapSpace: 0 }}>
          {false && item.communityAzureId && (
            <IconButton
              iconProps={{
                iconName: 'TeamsLogo'
              }}
              onClick={(ev) => {
                window.open(item.url, '_blank');
                ev.preventDefault();
                ev.stopPropagation();
              }}
            />
          )}
        </TooltipHost>
        <TooltipHost content='Open SharePoint' calloutProps={{ gapSpace: 0 }}>
          {false && item.sharePointUrl && (
            <IconButton
              iconProps={{
                iconName: 'SharePointLogo'
              }}
              onClick={(ev) => {
                window.open(item.sharePointUrl, '_blank');
                ev.preventDefault();
                ev.stopPropagation();
              }}
            />
          )}
        </TooltipHost>
      </div>
    );
  };

  private renderDisplayname = (item) => {
    return (
      <strong>
        <Link
          key={item}
          onClick={(ev) => {
            if (item.communityId < 0) {
              window.open(item.sharePointUrl, 'blank');
              ev.preventDefault();
              ev.stopPropagation();
            } else {
              this.props.communityActionCreators.viewCommunityPanel(item);
              ev.preventDefault();
              ev.stopPropagation();
            }
          }}
        >
          {item.displayName}
        </Link>
      </strong>
    );
  };

  private renderCommunityType = (item) => {
    return (
      <div>
        <span>
          <Icon iconName={this.getCommunityTypeIcon(item)} />{' '}
          {item.communityType}
        </span>{' '}
        {item.sponsored && (
          <React.Fragment>
            <TooltipHost content={tooltips.SPONSORED_BADGE}>
              <span className='sponsoredBadge'>Sponsored</span>
            </TooltipHost>
          </React.Fragment>
        )}
      </div>
    );
  };

  private getCommunityTypeIcon(item) {
    switch (item.communityType) {
      case 'Open':
        return 'Unlock';
      case 'Private':
        return 'Lock';
      case 'Hidden':
        return 'Hide';
      default:
        return '';
    }
  }

  private setPages(items) {
    const pageItems: any = [];
    let count = 0;
    let page = 0;

    items.forEach((item) => {
      if (!pageItems[page]) {
        pageItems[page] = [];
      }

      if (count < 12) {
        pageItems[page].push(item);
        count++;
      }

      if (count === 12) {
        page++;
        count = 0;
      }
    });

    return pageItems;
  }

  private onNext = (event) => {
    const nextPage = this.state.currPage + 1;

    this.setState({
      currPage: nextPage,
      page: this.state.pageItems[nextPage]
    });
  };

  private onPrevious = (event) => {
    const prevPage = this.state.currPage - 1;

    this.setState({
      currPage: prevPage,
      page: this.state.pageItems[prevPage]
    });
  };

  private renderTags = (item) => {
    const tags = item.tags;

    return (
      <div className='tags'>
        {tags.map((tag, idx) => {
          return <Tag tag={tag} key={idx} />;
        })}
      </div>
    );
  };

  private renderMembers = (item) => {
    return <span>{item.totalMembers}</span>;
  };

  private onColumnClick = (ev, column) => {
    const { columns, page } = this.state;

    let newPage = page.slice();
    const newColumns = columns.slice();

    const currColumn = newColumns.filter((currCol, idx) => {
      return column.key === currCol.key;
    })[0];

    newColumns.forEach((newCol) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    if (currColumn.fieldName !== 'members') {
      newPage = sortItems(
        newPage,
        currColumn.fieldName,
        currColumn.isSortedDescending
      );
    } else {
      newPage = sortItemsByInt(
        newPage,
        'totalMembers',
        currColumn.isSortedDescending
      );
    }

    this.setState({
      columns: newColumns,
      page: newPage
    });
  };

  private handleLinkClick = (item) => {
    this.changeItems(item.props.itemKey);
  };

  private handleDropdownChange = (event, item) => {
    this.changeItems(item.key);
  };

  private changeItems(key) {
    let items = [];

    switch (key) {
      case 'openCommunities':
        items =
          this.props.openCommunities !== null &&
          this.props.openCommunities !== undefined
            ? this.props.openCommunities
            : [];
        break;
      case 'favouriteCommunities':
        items =
          this.props.favouriteCommunities !== null &&
          this.props.favouriteCommunities !== undefined
            ? this.props.favouriteCommunities
            : [];
        break;
      case 'ownerCommunities':
        items =
          this.props.ownerCommunities !== null &&
          this.props.ownerCommunities !== undefined
            ? this.props.ownerCommunities
            : [];
        break;
      case 'memberCommunities':
        items =
          this.props.memberCommunities !== null &&
          this.props.memberCommunities !== undefined
            ? this.props.memberCommunities
            : [];
        break;
      case 'suggestedCommunities':
        items =
          this.props.suggestedCommunities !== null &&
          this.props.suggestedCommunities !== undefined
            ? this.props.suggestedCommunities
            : [];
        break;
      default:
        items =
          this.props.ownerCommunities !== null &&
          this.props.ownerCommunities !== undefined
            ? this.props.ownerCommunities
            : [];
        break;
    }

    const pageItems = this.setPages(items);

    this.setState({
      currPage: 0,
      items,
      page: pageItems[0],
      pageItems,
      selectedKey: key
    });
  }
}

const mapStateToProps = (state) => ({
  isDirty: state.community.isDirty,
  isGuest: state.profile.isGuest,
  memberType: state.profile.memberType,
  favouriteCommunities: state.community.favouriteCommunities,
  memberCommunities: state.community.memberCommunities,
  ownerCommunities: state.community.ownerCommunities,
  openCommunities: state.community.openCommunities,
});

const mapDispatchToProps = (dispatch) => ({
  communityActionCreators: bindActionCreators(communityActionCreators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CommunityList);
