import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CarouselComponent from './CarouselComponent';
import carouselActionCreators from '../../../../controllers/CarouselController/Carousel.operations';

const Carousel = props => {
    const { slides } = props;
    useEffect(() => {
        props.carouselActionCreators.requestSlides();
    }, [props.carouselActionCreators]);

    return (
        <div>
            <CarouselComponent
                slides={slides}
            />
        </div>
    )
}

const mapStateToProps = (state: any) => ({
  slides: state.carousel.slides,
  isLoading: state.carousel.isLoading
});

const mapDispatchToProps = (dispatch) => ({
  carouselActionCreators: bindActionCreators(carouselActionCreators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Carousel);
