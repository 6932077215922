/**
 * Reactivation screen
 *
 * Allows member to:
 * -Reactivate account
 * -Change email address
 * -TO DO: Remove membership from all communities
 *
 */
import * as React from 'react';

/**
 * Redux
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { profileActionCreators } from '../../controllers/ProfileController';

/**
 * UI Fabric
 */
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { Icon } from '@fluentui/react/lib/Icon';
import { MessageBar } from '@fluentui/react/lib/MessageBar';
import { push } from 'react-router-redux';

/**
 * GovTEAMS components
 */
import Deactivation from './Deactivation';

/**
 * UI Fabric customized
 */
import * as constants from '../../common/Constants';
import { BRANDNAME } from '../../common/Tooltips';

/**
 * Typings
 */

export interface IUserAccessProps {
  memberStatus: string;
  externalEmailAddress: string;
  daysLeftToVerify: number;
  profile: any;
  noContent: boolean;
}

export class Reactivated extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      showSection1: false,
      showDeactivate: false
    };
  }

  public componentDidMount() {
    // if we hit the Verified page and our status is Active or Pending Licence
    // then verification is good so display notification
    // if not then something has gone wrong, so throw an error message
    if (
      this.props.profile.memberStatus === 'Active' ||
      this.props.profile.memberStatus === 'Pending Licence'
    ) {
      this.props.profileActions.notifyVerifiedEmail();
    } else {
      this.props.profileActions.notifyVerifiedEmailFail();
    }
    this.props.dispatch(push(constants.SCREEN_DASHBOARD));
  }

  public render() {
    return (
      <div className='govTeams-account govTeams-reactivate'>
        <Deactivation />
        <div className='govTeams-account-row'>
          <div className='govTeams-account-col col-wide'>
            {!this.accountUnderReview() && !this.accountInactive() && (
              <div className='govTeams-userAccess'>
                <h1>You have successfully verified your email address</h1>
                <hr />
                <p>
                  Click the button below or wait 15 seconds to be redirected to
                  your {BRANDNAME} Dashboard.
                </p>
                <PrimaryButton
                  onClick={this.onDashboard}
                  text='GovTEAMS Dashboard'
                />{' '}
                <p />
                <hr />
                <p>
                  <a
                    href='https://www.govteams.gov.au/reconfirm-your-email/'
                    target='_blank'
                    rel={'noreferrer'}
                  >
                    Why we ask you to verify your email address
                  </a>
                  .
                </p>
              </div>
            )}

            <div className='govTeams-collapsibleSection'>
              <div className='collapsibleHeader' onClick={this.onClickSection2}>
                <Icon
                  iconName={
                    !this.state.showSection1 ? 'ChevronRight' : 'ChevronDown'
                  }
                />
                <h2>Don’t want to use {BRANDNAME} anymore?</h2>
              </div>
              {this.state.showSection1 && (
                <div className='collapsibleContent'>
                  {this.accountUnderReview() && !this.accountInactive() && (
                    <div>
                      <p>
                        You can deactivate your {BRANDNAME} account at any time.
                        Your community memberships will remain in the system,
                        however you have the option to be removed from these
                        communities.
                      </p>
                      <p>
                        <PrimaryButton onClick={this.onDeactivate}>
                          Deactivate my account
                        </PrimaryButton>
                      </p>
                    </div>
                  )}

                  {this.accountInactive() && !this.accountUnderReview() && (
                    <div>
                      <p>{constants.TEXT_REACTIVATE_REMOVE}</p>
                      <p>
                        <PrimaryButton onClick={this.onRemoveCommunities}>
                          Remove me from communities
                        </PrimaryButton>
                      </p>
                    </div>
                  )}

                  <MessageBar className='govTeams-messageBar'>
                    You can reactivate your account at any time by logging in
                    with your {BRANDNAME} username (ending in{' '}
                    <strong>@govteams.gov.au</strong>) and current password.
                  </MessageBar>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  private onDashboard = (event) => {
    this.props.dispatch(push(constants.SCREEN_DASHBOARD));
  };

  private onClickSection2 = (event) => {
    this.setState({
      showSection1: !this.state.showSection1
    });
  };

  private onDeactivate = (event) => {
    this.props.profileActions.startDeactivationProfile();
  };

  private onRemoveCommunities = (event) => {
    this.props.profileActions.endDeactivationProfile(true, true);
  };

  private accountUnderReview = () => {
    return this.props.profile.memberStatus === 'Under Review';
  };

  private accountInactive = () => {
    return this.props.profile.memberStatus === 'Inactive';
  };
}

const mapStateToProps = (state) => ({
  profile: state.profile,
  initialValues: state.profile.initialValues
});

const mapDispatchToProps = (dispatch) => ({
  profileActions: bindActionCreators(profileActionCreators, dispatch),
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Reactivated);
