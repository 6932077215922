import { progressActionCreators } from '../../components/Progress';
import {communityActionCreators} from '../CommunityController';
import { membersActionCreators } from '../MembersController';
import { notificationActionCreators } from '../../components/Notification';

import actions from './Requests.actions';

import * as constants from '../../common/Constants';
import { getAuthFromState } from '../../auth';
import { httpServiceSecureGet, httpServiceSecurePut, httpServiceSecurePost } from '../../common/Http';
import {saveInteractionWithCommunityEvent} from '../../common/EventTracking';

export const requestsActionCreators = {
  requestFetchRequests: (communityId) => async (dispatch, getState) => {
    if (getState().requests.isLoading) {
      return;
    }

    dispatch({ type: actions.REQUESTS_FETCH_REQUEST });
    dispatch(
        progressActionCreators.startShowProgress(
            'Fetching community requests...'
        )
    );

    try {
      const { gtToken } = await getAuthFromState(getState, dispatch);
      const response = await httpServiceSecureGet(`/requests/${communityId}`, gtToken);

      const values: any[] = [];
      response.forEach(item => {
        values.push(item);
      });

      dispatch({ type: actions.REQUESTS_FETCH_RESPONSE, values });
    } catch (error) {
      dispatch(
          notificationActionCreators.startShowNotification(
              constants.ERROR_REQUESTS_FETCH
          )
      );

      dispatch({
        type: actions.REQUESTS_FETCH_RESPONSE,
        error
      });
    }
    finally {
      dispatch(progressActionCreators.endShowProgress());
    }
  },

  requestChangeRequest: (requestId, isAccepted) => async (
    dispatch,
    getState
  ) => {
    if (getState().requests.isLoading) {
      return;
    }

    dispatch({
      type: actions.REQUESTS_CHANGE_REQUEST
    });

    if (requestId) {
      dispatch(
          progressActionCreators.startShowProgress('Saving change request...')
      );

      try {
        const { gtToken } = await getAuthFromState(getState, dispatch);
        await httpServiceSecurePut(`/requests/${requestId}`, gtToken, !!isAccepted);

        dispatch({ type: actions.REQUESTS_CHANGE_RESPONSE });
        dispatch(progressActionCreators.endShowProgress());
        dispatch(
            requestsActionCreators.requestFetchRequests(
                getState().community.communityId
            )
        );
        dispatch(
            membersActionCreators.requestFetchMembers(
                getState().community.communityId
            )
        );
      } catch (error) {
        alert(error);
        dispatch({
          type: actions.REQUESTS_CHANGE_RESPONSE,
          error
        });
        dispatch(progressActionCreators.endShowProgress());
      }
    }
  },

  startJoinMember: (communityId, communityType, isManagePage, communityName = '') => async (
    dispatch,
    getState
  ) => {

    saveInteractionWithCommunityEvent( 'request_to_join', communityName);

    if (communityType === 'Private') {
      dispatch({
        type: actions.REQUESTS_JOIN_START,
        communityId,
        communityType,
        isManagePage
      });
    } else {
      dispatch(
        requestsActionCreators.requestAddMember(
          communityId,
          communityType,
          isManagePage,
          ''
        )
      );
    }
  },

  endJoinMember: (submit, requestMessage) => async (dispatch, getState) => {
    dispatch({
      type: actions.REQUESTS_JOIN_END
    });

    if (submit) {
      dispatch(
        requestsActionCreators.requestAddMember(
          getState().requests.communityId,
          getState().requests.communityType,
          getState().requests.isManagePage,
          requestMessage
        )
      );
    }
  },

  requestAddMember: (
    communityId,
    communityType,
    isManagePage,
    requestMessage
  ) => async (dispatch, getState) => {
    if (getState().requests.isLoading) {
      return;
    }

    dispatch({
      type: actions.REQUESTS_ADD_REQUEST,
      communityType
    });

    if (communityId) {
      dispatch(
          progressActionCreators.startShowProgress('Joining the Community...')
      );

      try {
        const { gtToken } = await getAuthFromState(getState, dispatch);
        const json = await httpServiceSecurePost(`/requests/${communityId}`, gtToken, {requestMessage});

        dispatch(notificationActionCreators.startShowNotification(json));

        dispatch({ type: actions.REQUESTS_ADD_RESPONSE });
        if (communityType === 'Open') {
          dispatch(
              communityActionCreators.updateMemberCommunities(communityId)
          );
          dispatch(
              communityActionCreators.requestFetchCommunity(communityId)
          );
          if (isManagePage) {
            dispatch(
                membersActionCreators.requestFetchMembers(communityId)
            );
          }
        }
      } catch (error) {
        dispatch({
          type: actions.REQUESTS_ADD_RESPONSE,
          error
        });
      }
      finally {
        dispatch(progressActionCreators.endShowProgress());
      }
    }
  }
};

export default requestsActionCreators;
