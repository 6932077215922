import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { profileActionCreators } from '../../controllers/ProfileController';
import * as constants from '../../common/Constants';
import {useMsal} from '@azure/msal-react';

const LoggingOutComponent = (props: { auth: any, profileActions: any }) => {
    const { profileActions } = props;
    const [doRedirect, setDoRedirect] = useState<boolean>(false);
    const { instance, accounts } = useMsal();

    /**
     * Monitor changes to dispatch and profileActions
     */
    useEffect(() => {
        if ( profileActions ) {
            profileActions.startLogoutProfile(instance, accounts);
            setDoRedirect(true);
        }
    }, [profileActions, instance, accounts]);

    /**
     * Render
     */
    return (
        <div className='govTeams-errorContent'>
            <br/>
            <p>Logging out. Please wait...!</p>
            {doRedirect &&
                <Redirect to={constants.SCREEN_LOGOUT} />
            }
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.profile,
});

const mapDispatchToProps = (dispatch) => ({
    profileActions: bindActionCreators(profileActionCreators, dispatch)
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LoggingOutComponent);
