import * as React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

/**
 * Fabric UI
 */
import { IconButton } from '@fluentui/react/lib/Button';
import { SearchBox } from '@fluentui/react/lib/SearchBox';

/**
 * Constants and utilities
 */
import * as constants from '../../common/Constants';
import { saveSearchEvent } from '../../common/EventTracking';

export class SearchBoxAlt extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      filter: '',
      showSearchButton: false
    };
  }

  public render() {
    return (
      <div className="govTeams-searchBoxAlt">
        <SearchBox
          placeholder={`Search GovTEAMS Communities, Members and Documents`}
          underlined={false}
          onChange={(filter: any) => {
            if (filter !== '') {
              this.setState({ filter, showSearchButton: true });
            } else {
              this.setState({ filter, showSearchButton: false });
            }
          }}
          onSearch={(filter: any) => {
              saveSearchEvent(filter);
              this.props.dispatch(push(constants.SCREEN_SEARCH + '/' + filter));
            }
          }
        />
        {this.state.showSearchButton && (
          <IconButton
            iconProps={{ iconName: 'Forward' }}
            onClick={this.onSearchClick}
          />
        )}
      </div>
    );
  }

  private onSearchClick = event => {
    const _search = this.state.filter.target?.value;
    saveSearchEvent(_search);
    this.props.dispatch(
      push(constants.SCREEN_SEARCH + '/' + _search)
    );
  };
}

const mapDispatchToProps = (dispatch: any) => ({
  dispatch
});

export default connect(mapDispatchToProps)(SearchBoxAlt);
