import actions from './Invites.actions';
import { IInvitesProps } from './Invites.types';

const initialState: IInvitesProps = {
  pendingInvitations: [],
  expiredInvitations: [],
  values: [],
  isLoading: false,
  error: null
};

export const reducer = (state = initialState, action) => {
  //API
  if (action.type === actions.INVITES_FETCH_REQUEST) {
    return {
      ...state,
      pendingInvitations: null,
      expiredInvitations: null,
      values: null,
      isLoading: true
    };
  }

  if (action.type === actions.INVITES_FETCH_RESPONSE) {
    return {
      ...state,
      values: action.values,
      pendingInvitations: action.pendingInvitations,
      expiredInvitations: action.expiredInvitations,
      isLoading: false,
      error: action.error
    };
  }

  if (action.type === actions.INVITES_DELETE_REQUEST) {
    return {
      ...state,
      isLoading: true
    };
  }

  if (action.type === actions.INVITES_DELETE_RESPONSE) {
    return {
      ...state,
      pendingInvitations:
        action.values != null
          ? action.pendingInvitations
          : state.pendingInvitations,
      expiredInvitations:
        action.values != null
          ? action.expiredInvitations
          : state.expiredInvitations,
      values: action.values != null ? action.values : state.values,
      isLoading: false,
      error: action.error
    };
  }

  if (action.type === actions.INVITES_RESEND_REQUEST) {
    return {
      ...state,
      isLoading: true
    };
  }

  if (action.type === actions.INVITES_RESEND_RESPONSE) {
    return {
      ...state,
      isLoading: false,
      error: action.error
    };
  }

  return state;
};

export default reducer;
