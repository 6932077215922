/**
 * React
 */
import * as React from 'react';

/**
 * Fabric UI
 */
import { Label } from '@fluentui/react/lib/Label';
import { Icon } from '@fluentui/react/lib/Icon';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { DirectionalHint } from '@fluentui/react/lib/Callout';

interface ITooltipLabelProps {
  ariaLabel: string;
  required: boolean;
  label: string;
}

export class TooltipLabel extends React.Component<ITooltipLabelProps, any> {
  public render() {
    return renderLabel({
      ariaLabel: this.props.ariaLabel,
      label: this.props.label,
      required: this.props.required
    });
  }
}

export function renderLabel(label: any) {
  return (
    <TooltipHost
      content={label.ariaLabel}
      directionalHint={DirectionalHint.rightTopEdge}
    >
      <Label className="formLabel" required={label.required}>
        {label.label}
      </Label>
      <Icon iconName="Info" />
    </TooltipHost>
  );
}
