import * as React from 'react';

export interface IUserAccessContentProps {
  externalEmailAddress: string;
  daysLeftToVerify: number;
}

export default class UserAccessContent extends React.Component<
  IUserAccessContentProps,
  any
> {
  public render() {
    return (
      <div>
        <p>
          You still have access to your government email, we’ve sent a
          verification link to{' '}
          <strong>{this.props.externalEmailAddress}</strong>. Click this link to
          verify your account. You have{' '}
          <strong>{this.props.daysLeftToVerify} days</strong> to do this before
          your account is deactivated.
        </p>
      </div>
    );
  }
}
