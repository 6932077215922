import React from 'react';
import ReactDOM from 'react-dom/client';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import ReactGA from 'react-ga4';

import reportWebVitals from './reportWebVitals';
import { msalConfig } from './auth/authConfig';

import './index.scss';
import App from './publicApp';

if ( !process.env.REACT_APP_TENANTID ) console.error('Error: Tenant ID is empty. Cannot continue!');
if ( !process.env.REACT_APP_CLIENTID ) console.error('Error: Client ID is empty. Cannot continue!');
if ( !process.env.REACT_APP_TENANTDOMAIN ) console.error('Error: Tenant Domain is empty. Cannot continue!');
if ( !process.env.REACT_APP_APPLICATION_INSIGHTS_KEY ) console.error('Warning: AzureInsights key is empty.');

/**
 * Load GA tag
 */
try {
  ReactGA.initialize(process.env.REACT_APP_GA_ID);
  console.log('GA4 initialized', process.env.REACT_APP_GA_ID);
} catch (err) {
  console.error('GA4 initialization failed: ', err);
}

const msalInstance = new PublicClientApplication(msalConfig);
initializeIcons(undefined, { disableWarnings: true });

const root = ReactDOM.createRoot(
    document.getElementById('root')
);

/**
 * We recommend wrapping most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.
 */
root.render(
    <React.Fragment>
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>
    </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
