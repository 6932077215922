/**
 * User preferenes panel
 *
 */

/**
 * React
 */
import * as React from 'react';

/**
 * Redux
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { memberPreferencesActionCreators } from '../../controllers/MemberPreferencesController';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import {
  IMemberPreferencesProps,
  IMemberPreferencesState
} from '../../controllers/MemberPreferencesController/MemberPreferences.types';
import MemberPreferencesForm from './MemberPreferencesForm';

interface IMemberPreferencesComponentProps
  extends IMemberPreferencesProps,
    IMemberPreferencesState {}
class MemberPreferences extends React.Component<
  IMemberPreferencesComponentProps,
  {}
> {
  public render() {
    return (
      <Panel
        isOpen={this.props.showPreferences}
        hasCloseButton={true}
        isBlocking={true}
        isLightDismiss={true}
        type={PanelType.custom}
        customWidth={'400px'}
        onDismiss={this.onPanelDismiss}
      >
        <h2>User preferences</h2>
        <MemberPreferencesForm />
      </Panel>
    );
  }

  private onPanelDismiss = () => {
    this.props.memberPreferencesActionCreators.hideMemberPreferences();
  };
}

const mapStateToProps = (state) => ({
  userId: state.memberPreferences.userId,
  preferences: state.memberPreferences.preferences,
  showPreferences: state.memberPreferences.showPreferences,
  isMemberPreferencesLoading: state.memberPreferences.isMemberPreferencesLoading,
  isSubmitted: state.memberPreferences.isSubmitted,
  error: state.memberPreferences.error
});

const mapDispatchToProps = (dispatch) => ({
  memberPreferencesActionCreators: bindActionCreators(
    memberPreferencesActionCreators,
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberPreferences);
