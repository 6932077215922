import * as React from 'react';

/**
 * Redux
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { profileActionCreators } from '../../controllers/ProfileController';

/**
 * Fabric UI
 */
import { PrimaryButton } from '@fluentui/react/lib/Button';

/**
 * GovTEAMS components
 */
import UserAccessContext from './UserAccessContent';

export interface IUserAccessProps {
  memberStatus: string;
  externalEmailAddress: string;
  daysLeftToVerify: number;
  profile: any;
  noContent: boolean;
}

export interface IUserAccessState {
  hidden: boolean;
  verificationCode: string;
  errorMessage: string | undefined;
}

export class UserAccess extends React.Component<
  IUserAccessProps,
  IUserAccessState
> {
  constructor(props: IUserAccessProps) {
    super(props);

    this.state = {
      errorMessage: undefined,
      hidden: true,
      verificationCode: ''
    };
  }

  public componentDidUpdate(nextProps) {
    if (
      this.state.errorMessage === undefined &&
      this.state.errorMessage !== nextProps.verificationError
    ) {
      this.setState({
        errorMessage: nextProps.verificationError.substring(
          0,
          nextProps.verificationError.indexOf('CorrelationId')
        )
      });
    }
  }

  public render() {
    return (
      <div className='govTeams-userAccess'>
        {!this.props.noContent && (
          <UserAccessContext
            daysLeftToVerify={this.props.daysLeftToVerify}
            externalEmailAddress={this.props.externalEmailAddress}
          />
        )}

        {/* <MaskedTextField
          className="govTeams-fieldVerify"
          placeholder="Enter 6-digit verification code"
          onKeyPress={this.onKeyPress}
          onChange={this.onChange}
          errorMessage={this.state.errorMessage}
          mask="999999"
        /> */}

        <p>
          <PrimaryButton onClick={this.onResend} text='Resend link' />{' '}
          {/* <PrimaryButton onClick={this.onVerify} text="Verify" /> */}
        </p>
      </div>
    );
  }

  private onResend = (event) => {
    this.props.profile.requestResendVerify();
  };

  // private onVerify = (): void => {
  //   this.verify();
  // };

  // private onChange = (event, newValue) => {
  //   this.setState({ verificationCode: newValue });
  // };

  // private onKeyPress = event => {
  //   if (event.key === 'Enter') {
  //     event.preventDefault();
  //     this.verify();
  //   }
  // };

  // private verify() {
  //   const code = parseInt(this.state.verificationCode, 10);
  //   if (isNaN(code)) {
  //     this.setState({ errorMessage: 'please enter verification code.' });
  //   } else if ((this.state.verificationCode).indexOf('_') !== -1) {
  //     this.setState({
  //       errorMessage: 'please enter correct 6-digit verification code.'
  //     });
  //   } else {
  //     this.setState({
  //       errorMessage: undefined
  //     });
  //     this.props.profile.requestVerifyProfile(code);
  //   }
  // }
}

const mapStateToProps = (state: any) => ({
  daysLeftToVerify: state.profile.daysLeftToVerify,
  externalEmailAddress: state.profile.mail,
  memberStatus: state.profile.memberStatus,
  verificationError: state.profile.verificationError
});

const mapDispatchToProps = (dispatch: any) => ({
  profile: bindActionCreators(profileActionCreators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(UserAccess);
