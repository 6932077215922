/**
 * List of included domains
 */

/**
 * React
 */
import * as React from 'react';

export class OrganisationDomains extends React.Component<any, any> {
  public render() {
    const domains: string[] = this.props.domains;

    return (
      <React.Fragment>
        {domains && domains.length > 0 && (
          <table className="orgDomains">
            <thead>
              <tr>
                <th>Included domains</th>
              </tr>
            </thead>
            <tbody>
              {domains.map((domain: string, index: number) => {
                return (
                  <tr key={index}>
                    <td>{domain}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </React.Fragment>
    );
  }
}

export default OrganisationDomains;
