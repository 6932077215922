import { progressActionCreators } from '../../components/Progress';
import { notificationActionCreators } from '../../components/Notification';

import { getAuthFromState, msGraphSecureGet } from '../../auth';
import { httpServiceSecureGet } from '../../common/Http';
import * as constants from '../../common/Constants';
import actions from './Search.actions';

/**
 * Old queries
 */
// export const RECENT_DOCUMENTS_FILTER = "ResourceVisualization/Type eq 'PowerPoint' and ResourceVisualization/Type eq 'Word' and ResourceVisualization/Type eq 'Excel' and ResourceVisualization/Type eq 'Pdf' and ResourceVisualization/Type eq 'OneNote' and ResourceVisualization/Type eq 'OneNotePage' and ResourceVisualization/Type eq 'InfoPath' and ResourceVisualization/Type eq 'Visio' and ResourceVisualization/Type eq 'Publisher' and ResourceVisualization/Type eq 'Project' and ResourceVisualization/Type eq 'Access' and ResourceVisualization/Type eq 'Csv' and ResourceVisualization/Type eq 'Text'";
// export const RECENT_DOCUMENTS_FILTER =
//   "ResourceVisualization/Type ne 'Mail' and ResourceVisualization/Type ne 'Archive' and ResourceVisualization/Type ne 'Xps' and ResourceVisualization/Type ne 'Audio' and ResourceVisualization/Type ne 'Video' and ResourceVisualization/Type ne 'Image' and ResourceVisualization/Type ne 'Web' and ResourceVisualization/Type ne 'Xml' and ResourceVisualization/Type ne 'Story' and ResourceVisualization/Type ne 'ExternalContent' and ResourceVisualization/Type ne 'Folder' and ResourceVisualization/Type ne 'Other' and ResourceVisualization/Type ne 'spsite'";


const _searchMembers = async ({dispatch, getState, skipToken, filter, searchMembers, currentPage}) => {

    /**
     * Build filters and page token
     */
    const pageParam = skipToken !== '' ? `$skiptoken=${skipToken}&` : '';
    const filterParam = filter ? `startswith(displayName,'${filter}') or startswith(givenName,'${filter}') or startswith(surname,'${filter}') or startswith(mail,'"${filter}') or startswith(userPrincipalName,'${filter}')` : '';

    try {
        const { instance: msalInstance, msToken } = await getAuthFromState(getState, dispatch);
        const response = await msGraphSecureGet(`/users?${pageParam}$filter=${filterParam}&$select=id,department,jobTitle,mail,displayName,userPrincipalName,userType,accountEnabled`, msToken, msalInstance);

        /**
         * Add new results as a new array, this is used to paginate the results
         */
        const newPage: any[] = [];

        response.value.forEach((member: any) => {
            if (member.userPrincipalName.indexOf('#EXT#') > 0) {
                member.isGuest = true;
            } else {
                member.isGuest = false;
            }
            if (member.accountEnabled !== false) {
                newPage.push(member);
            }
        });

        if (newPage.length > 0) {
            searchMembers.push(newPage);
        }

        /**
         * Get the next page token from the @odata.nextLink property from the response
         * -pagination is currently not wrapped in microsoft-graph-client
         */
        const nextSkipToken = '';

        dispatch({
            type: actions.SEARCHMEMBERS_FETCH_RESPONSE,
            filter,
            searchMembers,
            skipToken: nextSkipToken
        });
    }
    catch (error) {
        dispatch(
            notificationActionCreators.startShowNotification(
                constants.ERROR_MEMBERS_FETCH
            )
        );
        dispatch({
            type: actions.SEARCHMEMBERS_FETCH_RESPONSE,
            filter,
            searchMembers,
            error
        });
    }
    finally {
        dispatch(progressActionCreators.endShowProgress());
    }
};

export const searchActionCreators = {
    clearSearch: () => (dispatch) => {
        dispatch({ type: actions.SEARCH_CLEAR });
    },

    requestFetchSearchCommunity: (filter) => async (dispatch, getState) => {
        if (getState().community.isLoading) {
            return;
        }

        const encodedFilter = encodeURIComponent(filter);

        dispatch({ type: actions.SEARCHCOMMUNITY_FETCH_REQUEST });
        dispatch(
            progressActionCreators.startShowProgress('Fetching search communities...')
        );

        const searchCommunities: any[] = [];

        try {
            const { gtToken } = await getAuthFromState(getState, dispatch);
            const communities = await httpServiceSecureGet(`/communities/Search?filter=${encodedFilter}`, gtToken);

            communities.forEach((community: any) => {
                searchCommunities.push(community);
            });

            dispatch({
                type: actions.SEARCHCOMMUNITY_FETCH_RESPONSE,
                filter,
                searchCommunities
            });

        } catch (error) {
            dispatch(
                notificationActionCreators.startShowNotification(
                    constants.ERROR_SEARCHCOMMUNITIES_FETCH
                )
            );
            dispatch({
                type: actions.SEARCHCOMMUNITY_FETCH_RESPONSE,
                error
            });
        }
        finally {
            dispatch(progressActionCreators.endShowProgress());
        }
    },

    requestFetchSearchMembers: (
        filter,
        skipToken,
        newSearch,
        pageNumber
    ) => async (dispatch, getState) => {
        if (getState().members.isLoading) {
            return;
        }

        let searchMembers: any[] = getState().search.searchMembers;
        let currentPage = 0;

        if (newSearch) {
            searchMembers = [];
        } else {
            if (pageNumber && pageNumber !== '') {
                currentPage = pageNumber;
            }
        }

        dispatch({
            type: actions.SEARCHMEMBERS_FETCH_REQUEST,
            currentPage
        });
        dispatch(progressActionCreators.startShowProgress('Fetching members...'));

        await _searchMembers({dispatch, getState, skipToken, filter, searchMembers, currentPage});
    },

    /**
     * Microsoft Graph API document search
     *
     */
    requestFetchRecentDocuments: (filter) => async (dispatch, getState) => {
        if (getState().search.isLoading) {
            return;
        }

        dispatch({
            type: actions.RECENTDOCUMENTS_FETCH_REQUEST
        });

        /**
         * Clear all pages in the search results
         */
        let endpoint;

        switch (filter) {
            case constants.RECENT_DOCUMENTS_MY:
                endpoint = '/me/insights/used';
                break;
            case constants.RECENT_DOCUMENTS_POPULAR:
                endpoint = '/me/insights/trending';
                break;
            default:
                endpoint = '';
                break;
        }

        /**
         * Build filters and page token
         */
        const recentDocuments: any[] = [];

        try {
            const { instance: msalInstance, msToken } = await getAuthFromState(getState, dispatch);
            const url = `${endpoint}?$top=10&$filter=ResourceVisualization/Type ne 'Mail' and ResourceVisualization/Type ne 'Archive' and ResourceVisualization/Type ne 'Xps' and ResourceVisualization/Type ne 'Audio' and ResourceVisualization/Type ne 'Video' and ResourceVisualization/Type ne 'Image' and ResourceVisualization/Type ne 'Web' and ResourceVisualization/Type ne 'Xml' and ResourceVisualization/Type ne 'Story' and ResourceVisualization/Type ne 'ExternalContent' and ResourceVisualization/Type ne 'Folder' and ResourceVisualization/Type ne 'Other' and ResourceVisualization/Type ne 'spsite'`;
            const response = await msGraphSecureGet(url, msToken, msalInstance);

            response.value.forEach((result) => {
                const lastAccessed = result.lastUsed
                    ? new Date(result.lastUsed.lastAccessedDateTime)
                    : '';
                const lastModified = result.lastUsed
                    ? new Date(result.lastUsed.lastModifiedDateTime)
                    : '';
                const containerType = result.resourceVisualization.containerType;
                const document = {
                    id: result.id,
                    community:
                        containerType !== 'OneDriveBusiness'
                            ? result.resourceVisualization.containerDisplayName
                            : 'Your personal OneDrive',
                    title: result.resourceVisualization.title,
                    type: result.resourceVisualization.type,
                    previewText: result.resourceVisualization.previewText,
                    url: result.resourceReference.webUrl,
                    lastAccessed: lastAccessed.toLocaleString(),
                    lastModified: lastModified.toLocaleString()
                };

                recentDocuments.push(document);
            });

            dispatch({
                type: actions.RECENTDOCUMENTS_FETCH_RESPONSE,
                recentDocuments
            });
        } catch (error) {
            dispatch({
                type: actions.RECENTDOCUMENTS_FETCH_RESPONSE,
                filter,
                recentDocuments,
                error
            });
        }
    },

    requestFetchSearchDocuments: (filter, pageNumber, newSearch) => async (
        dispatch,
        getState
    ) => {
        if (getState().search.isLoading) {
            return;
        }
        /**
         * Clone array or it won't fire ComponentWillUpdate as an addition ot the array isn't considered a change
         */

        let searchDocuments = getState().search.searchDocuments
            ? getState().search.searchDocuments.slice()
            : getState().search.searchDocuments;
        let currentPage = 0;

        /**
         * Clear array if new search
         */
        if (newSearch) {
            searchDocuments = [];
        } else {
            if (pageNumber && pageNumber !== '') {
                currentPage = pageNumber;
            }
        }

        const pageSize = 10;
        const startRow = pageSize * pageNumber;

        dispatch({
            type: actions.SEARCHDOCUMENTS_FETCH_REQUEST,
            currentPage
        });
        dispatch(progressActionCreators.startShowProgress('Fetching documents...'));

        /**
         * Clear all pages in the search results
         */
        try {
            /**
             * Search all when there are no search terms but this is currently now allowed in the custom API
             */
            if (!filter || filter === '') {
                filter = '*';
            }
            const { gtToken } = await getAuthFromState(getState, dispatch);
            const data = await httpServiceSecureGet(`/DocumentSearch?filter=${encodeURIComponent(filter)}&rowLimit=${pageSize}&startRow=${startRow}`, gtToken);

            const dataJson = JSON.parse(data);
            const results =
                dataJson.PrimaryQueryResult.RelevantResults.Table.Rows;

            /**
             * Quirk: The total rows is always one more than its real count
             */
            const totalDocuments =
                dataJson.PrimaryQueryResult.RelevantResults.TotalRows > 0
                    ? dataJson.PrimaryQueryResult.RelevantResults.TotalRows - 1
                    : 0;
            const newPage: any[] = [];

            /**
             * Create document objects with relevant metadata
             */
            results.forEach((row) => {
                const cells = row.Cells;
                const title = cells[2].Value;
                const author = cells[3].Value;
                const fileSize = cells[4].Value * 0.001;
                let path = cells[5].Value;
                // const isFile = false;
                const fileType = cells[30].Value;

                /**
                 * Check if the document is a Microsoft document and set the URL to open in its respective O365 app
                 */
                switch (fileType) {
                    case 'docx':
                    case 'doc':
                    case 'one':
                    case 'xlsx':
                    case 'xls':
                    case 'csv':
                    case 'ppt':
                    case 'pptx':
                        path = cells[5].Value;
                        break;
                    default:
                        break;
                }

                const dateModified = cells[7].Value;
                const document = {
                    title,
                    author,
                    fileSize,
                    path,
                    dateModified,
                    fileType
                };

                newPage.push(document);
            });

            if (newPage.length > 0) {
                searchDocuments.push(newPage);
            }

            dispatch({
                type: actions.SEARCHDOCUMENTS_FETCH_RESPONSE,
                filter,
                searchDocuments,
                totalDocuments
            });
        } catch (error) {

            dispatch({
                type: actions.SEARCHDOCUMENTS_FETCH_RESPONSE,
                error
            });
        }
        finally {
            dispatch(progressActionCreators.endShowProgress());
        }
    },

    setCurrentPage: (pageNumber) => async (dispatch, getState) => {
        dispatch({
            type: actions.SET_CURRENT_PAGE,
            currentPage: pageNumber
        });
    }
};

export default searchActionCreators;
