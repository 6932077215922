import {
    IDepartment,
    IGovTEAMSResponse,
} from './Department.types';
import { actions } from './Department.actions';
import { constants } from './Department.constants';
import { progressActionCreators } from '../../components/Progress';
import { getAuthFromState } from '../../auth/authHandler';
import { httpServiceSecureGet } from '../../common/Http';

export const departmentActionCreators = {
    requestFetchDepartment: (departmentId: number) => async (
        dispatch,
        getState
    ) => {
        if (getState().isLoading) {
            return;
        }

        dispatch({ type: actions.DEP_FETCH_REQUEST });
        dispatch(
            progressActionCreators.startShowProgress(constants.PROGRESS_DEP_FETCH)
        );

        try {
            const { gtToken } = await getAuthFromState(getState, dispatch);
            const department: IGovTEAMSResponse<IDepartment> = await httpServiceSecureGet(`/Departments/${departmentId}`, gtToken);

            dispatch({
                type: actions.DEP_FETCH_RESPONSE,
                department: department.result
                    ? (department.result as IDepartment)
                    : undefined
            });
        } catch (error) {
            dispatch({
                type: actions.DEP_FETCH_RESPONSE,
                departmentError: error.result || error.toString(),
            });
        }
        finally {
            dispatch(progressActionCreators.endShowProgress());
        }
    },

    requestFetchAllDepartments: () => async (dispatch, getState) => {
        if (getState().isLoadingAll) {
            return;
        }

        dispatch({ type: actions.DEP_FETCH_ALL_REQUEST });

        try {
            const { gtToken } = await getAuthFromState(getState, dispatch);
            const departmentResponse: IGovTEAMSResponse<IDepartment[]> = await httpServiceSecureGet(`/Departments`, gtToken);

            dispatch({
                type: actions.DEP_FETCH_ALL_RESPONSE,
                departments: departmentResponse.result
                    ? departmentResponse.result
                    : undefined
            });
        } catch (error) {
            dispatch({ type: actions.DEP_FETCH_ALL_RESPONSE, error: error.toString() });
        }
    },

    requestFetchAllMemberDepartments: (memberTypeId:number, organisationDepartmentId:any) => async (dispatch, getState) => {
        if (getState().isLoadingAll) {
            return;
        }

        dispatch({ type: actions.DEP_FETCH_ALL_FOR_MEMBERTYPE_REQUEST });

        try {
            const { gtToken } = await getAuthFromState(getState, dispatch);
            const departmentResponse: IGovTEAMSResponse<IDepartment[]> = await httpServiceSecureGet(`/Departments/member/${memberTypeId}?organisationDepartmentId=${organisationDepartmentId}`, gtToken);

            dispatch({
                type: actions.DEP_FETCH_ALL_FOR_MEMBERTYPE_RESPONSE,
                departments: departmentResponse.result
                    ? departmentResponse.result
                    : undefined
            });
        } catch (error) {
            dispatch({
                type: actions.DEP_FETCH_ALL_FOR_MEMBERTYPE_RESPONSE,
                error
            });
        }
    },
};

export default departmentActionCreators;