export const actions = {
  REQUESTS_JOIN_START: 'REQUESTS_JOIN_START',
  REQUESTS_JOIN_END: 'REQUESTS_JOIN_END',
  REQUESTS_FETCH_REQUEST: 'REQUESTS_FETCH_REQUEST',
  REQUESTS_FETCH_RESPONSE: 'REQUESTS_FETCH_RESPONSE',
  REQUESTS_CHANGE_REQUEST: 'REQUESTS_CHANGE_REQUEST',
  REQUESTS_CHANGE_RESPONSE: 'REQUESTS_CHANGE_RESPONSE',
  REQUESTS_ADD_REQUEST: 'REQUESTS_ADD_REQUEST',
  REQUESTS_ADD_RESPONSE: 'REQUESTS_ADD_RESPONSE'
};

export default actions;
