import * as React from 'react';
import { connect } from 'react-redux';
import { BRANDNAME } from '../../common/Tooltips';

const Error_InvalidCode = props => (
  <div>
    <h2 className="heading-error">Invalid Code</h2>
    <p>
      It looks like something is wrong with the link you've tried to use. This
      could occur if you've copied and pasted the link and you've missed part of
      the URL.{' '}
    </p>
    <p>
      Try the link again, and if you are still having issues, you can contact
      the <a href={props.mailTo}>{BRANDNAME} support</a> helpdesk and quote the
      following error ID: <strong> {props.correlationId} </strong>
    </p>
  </div>
);

export default connect()(Error_InvalidCode);
