import { progressActionCreators } from '../../components/Progress';
import { invitesActionCreators } from '../../screens/CommunityManagement/components/Invites/Invites.operations';
import actions from './InviteMembers.actions';
import {getAuthFromState} from '../../auth/authHandler';
import {httpServiceSecurePut} from '../../common/Http';
import {saveCommunityManageEvent} from '../../common/EventTracking';

export const inviteMembersActionCreators = {
  startEditInviteMembers: (communityId) => async (dispatch, getState) => {
    dispatch({
      type: actions.INVITE_MODAL_OPEN,
      communityId
    });

    saveCommunityManageEvent('invite_members', '');
  },

  endEditInviteMembers: (communityId) => async (dispatch, getState) => {
    dispatch({ type: actions.INVITE_MODAL_CLOSE });
  },

  toggleBulkInvite: () => async (dispatch, getState) => {
    dispatch({
      type: actions.INVITE_TOGGLE_BULK_INVITE,
      isBulkInvite: !getState().inviteMembers.isBulkInvite
    });
  },

  requestSaveMembers: (communityId, isBulk) => async (dispatch, getState) => {
    if (getState().inviteMembers.isLoading) {
      return;
    }

    dispatch({ type: actions.INVITE_MEMBERS_SAVE_REQUEST });
    const inviteMembersForm = getState().form.InviteMembersForm.values;

    dispatch(
      progressActionCreators.startShowProgress('Saving new members...', true)
    );

    try {
      const { gtToken } = await getAuthFromState(getState, dispatch);

      const members = { members: inviteMembersForm.members };
      const endpoint = isBulk ? 'invitemembersbulk' : 'invitemembers';
      const json = await httpServiceSecurePut(`/${endpoint}/${communityId}`, gtToken, members);
      const results = json.notifications;
      const isLimitOverdue = false;

      dispatch({
        type: actions.INVITE_MEMBERS_SAVE_RESPONSE,
        isSubmitted: true,
        results,
        isLimitOverdue
      });
      dispatch(
        invitesActionCreators.requestFetchInvites(
          getState().community.communityId
        )
      );
      dispatch(progressActionCreators.endShowProgress());
    } catch (error) {
      const json = await error.json();

      dispatch({
        type: actions.INVITE_MEMBERS_SAVE_RESPONSE,
        isSubmitted: true,
        error: json,
        isLimitOverdue: false
      });
      dispatch(
        invitesActionCreators.requestFetchInvites(
          getState().community.communityId
        )
      );
      dispatch(progressActionCreators.endShowProgress());
    }
  }
};

export default inviteMembersActionCreators;
