/**
 * Community creation form
 *
 */

/**
 * React Redux
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { memberPreferencesActionCreators } from '../../controllers/MemberPreferencesController';
import { Field, reduxForm } from 'redux-form';

/**
 * Fabric UI
 */
import {
  PrimaryButton,
  DefaultButton
} from '@fluentui/react/lib/Button';
import { ResponsiveMode } from '@fluentui/react';

/*
 * Fabric UI customised
 */
import Toggle from '../../components/ReduxFormFields/Toggle';
import Dropdown from '../../components/ReduxFormFields/Dropdown';
import {
  TooltipLabel
  // renderLabel
} from '../../components/ReduxFormFields/FormFieldUtils';
import { CommunityGridSortOrder } from './../../screens/Dashboard/components/Communities/CommunityGridSorter';

/**
 * GovTEAMS components
 */

const validate = (values) => {
  const errors: any = {};

  return errors;
};

class MemberPreferencesFormComponent extends React.Component<any, any> {
  public render() {
    const { handleSubmit, pristine, submitting } = this.props;

    return (
      <form onSubmit={handleSubmit} onKeyPress={this.onKeyPress}>
        <div className='formFields'>
          <div className='formField'>
            <TooltipLabel
              ariaLabel={'Default community sort order'}
              required={true}
              label='Default community sort order'
            />
            <Field
              name='gridSortOrder'
              responsiveMode={ResponsiveMode.large}
              component={Dropdown}
              placeholder='Community sort order'
              required={true}
              options={[
                {
                  key: CommunityGridSortOrder.Default,
                  text: 'Default'
                },
                {
                  key: CommunityGridSortOrder.AlphabeticalAsc,
                  text: 'A-Z'
                },
                {
                  key: CommunityGridSortOrder.AlphabeticalDesc,
                  text: 'Z-A'
                  },
                  {
                      key: CommunityGridSortOrder.OldToNew,
                      text: 'Oldest to Newest'
                  },
                  {
                      key: CommunityGridSortOrder.NewToOld,
                      text: 'Newest to Oldest'
                  }
              ]}
            />
          </div>
          <div className='formField'>
            <TooltipLabel
              ariaLabel={'Display communities as list by default'}
              required={true}
              label='Dashboard list display'
            />
            <Field
              name='dashboardIsListMode'
              component={Toggle}
              defaultChecked={this.props.preferences.dashboardIsListMode}
              parse={(value) => value === 'true' || value === true}
            />
          </div>
        </div>

        <div className='formFooter'>
          <div className='formFooter-left'>
            <DefaultButton text='Cancel' onClick={this.onClose} />
            <PrimaryButton
              text='Submit'
              type='submit'
              onClick={handleSubmit(this.onSubmit)}
              disabled={ pristine || submitting}
            />
          </div>
        </div>
      </form>
    );
  }

  public async componentDidMount() {
    const {
      dashboardIsListMode,
      gridSortOrder
    } = this.props.preferences;

    this.props.initialize({
      dashboardIsListMode,
      gridSortOrder
    });
  }

  // public async componentDidUpdate(prevProps, prevState) {
  // }

  private onClose = (event) => {
    this.props.memberPreferencesActionCreators.hideMemberPreferences();
  };

  private onSubmit = (form) => {
    this.props.memberPreferencesActionCreators.updateMemberPreferences();
  };

  private onKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };
}

const mapStateToProps = (state) => ({
  preferences: state.memberPreferences.preferences
});

const mapDispatchToProps = (dispatch) => ({
  memberPreferencesActionCreators: bindActionCreators(
    memberPreferencesActionCreators,
    dispatch
  )
});

const MemberPreferencesForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberPreferencesFormComponent);

export default reduxForm({
  form: 'MemberPreferencesForm',
  validate
})(MemberPreferencesForm);
