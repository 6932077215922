import actions from './InsiderFeed.actions';
import { IInsiderFeedState } from './InsiderFeed.types';

const initialState: IInsiderFeedState = {
  feed: [],
  seeMoreLink: null,
  isLoading: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_INSIDER_FEED_REQUEST:
      return {
        ...state,
        feed: [],
        seeMoreLink: null,
        isLoading: true,
      };

    case actions.FETCH_INSIDER_FEED_RESPONSE:
      return {
        ...state,
        feed: action.feed,
        seeMoreLink: action.seeMoreLink,
        isLoading: false,
      };
    default:
      return { ...state };
  }
};

export default reducer;
