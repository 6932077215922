/**
 * Dashboard wrapper
 *
 */

/**
 * React
 */
import * as React from 'react';

/**
 * GovTEAMS components
 */
import DashboardContent from './DashboardContent';
import Onboarding from './components/Onboarding/Onboarding';

/**
 * GovTeams dependencies
 */
export class Dashboard extends React.Component<any, any> {
  public render() {
    return (
      <div className='govTeams-dashboard'>
        <DashboardContent />
        <Onboarding />
      </div>
    );
  }
}

export default Dashboard;
