import * as React from 'react';
import { BRANDNAME } from '../../common/Tooltips';

export class ApprovalPending extends React.Component<any, any> {
  public render() {
    return (
      <div className='govTeams-textLayout'>
        <h1>We're re-confirming you are eligible to use {BRANDNAME}</h1>
        <hr />
        <p>
          We've received your request to reactivate your {BRANDNAME}
          account, however your security clearance expiry date we have on file
          has passed. Before we provide you access again we first need to
          confirm that you have an active security clearance that allows you to
          view PROTECTED information. We will process this as soon as possible,
          and once we have completed the review you will receive an email
          advising the outcome and next steps.
        </p>
        <br />
      </div>
    );
  }
}

export default ApprovalPending;
