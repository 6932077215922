/**
 * Notification item displayed in the notification panel.
 */

/**
 * React Redux
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { notificationActionCreators } from './Notification.operations';

/**
 * Fabric UI
 */
import { Callout } from '@fluentui/react/lib/Callout';
import { IconButton } from '@fluentui/react/lib/Button';

export interface INotificationProps {
  timestamp: any;
  message: string;
  show: boolean;
  notification: any;
}

export class NotificationComponent extends React.Component<
  INotificationProps,
  any
> {
  public componentDidUpdate() {
    if (this.props.show) {
      setTimeout(() => {
        this.onHide();
      }, 3000);
    }
  }

  public render() {
    return (
      this.props.show && (
        <Callout
          className='govTeams-notification'
          isBeakVisible={false}
          onDismiss={this.onDismiss}
          preventDismissOnScroll={true}
        >
          <div className='govTeams-Callout'>
            <div className='govTeams-Callout--header'>
              <IconButton
                iconProps={{ iconName: 'Clear' }}
                onClick={this.onDismiss}
              />
            </div>
            <div className='govTeams-Callout--body'>
              <div className='notification-message'>{this.props.message}</div>
              <div className='notification-time'>{this.props.timestamp}</div>
            </div>
          </div>
        </Callout>
      )
    );
  }

  private onHide = (): void => {
    this.props.notification.endShowNotification();
  };

  private onDismiss = (): void => {
    this.props.notification.dismissNotification(this.props.timestamp);
  };
}

const mapStateToProps = (state: any) => ({
  message: state.notification.message,
  show: state.notification.show,
  timestamp: state.notification.timestamp
});

const mapDispatchToProps = (dispatch: any) => ({
  notification: bindActionCreators(notificationActionCreators, dispatch)
});

export const Notification = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationComponent);

export default Notification;
