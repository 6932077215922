import * as React from 'react';
import {PrimaryButton} from '@fluentui/react/lib/Button';
import {getMemberEmailDomain} from '../../common/Utils';

export class Goodbye extends React.Component<any, any> {
    private getMemberTypeFromHash() {
        if (window.location.hash) {
            try {
                return window.atob(window.location.hash.substring(1));
            } catch (e) {
                return '';
            }
        }
        return '';
    }

    public render() {
        const memberType = this.getMemberTypeFromHash();

        return (
            <div className='govTeams-errorContent'>
                <br/>
                <h1>Sorry to see you go</h1>
                <hr/>
                <p>
                    You can reactivate your account at any time by logging in with
                    your username (ending in {getMemberEmailDomain(memberType)}) and password.
                </p>
                <br/>
                <h1>We'd love your feedback</h1>
                <p>
                    Your ideas and feedback help us improve. Take a minute to tell us
                    what you think.
                </p>
                <PrimaryButton
                    text='Provide feedback'
                    href='https://www.govteams.gov.au/provide-feedback/'
                />
            </div>
        );
    }
}

export default Goodbye;
