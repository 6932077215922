import React, { useCallback } from 'react';
import useEmblaCarousel, {EmblaOptionsType, EmblaCarouselType} from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';

import { getSharepointUrl } from '../../../../common/Utils';
import { DotButton, useDotButton } from './CarouselButton';
import { saveDashboardInteractionEvent } from '../../../../common/EventTracking';

export const CarouselComponent = ({slides}) => {
    const onButtonClick = useCallback((emblaApi: EmblaCarouselType) => {
        const { autoplay } = emblaApi.plugins();
        if (!autoplay)
            return;
        if (autoplay.options.stopOnInteraction !== false)
            autoplay.stop();
    }, []);

    const options: EmblaOptionsType = { loop: true }
    const [emblaRef, emblaApi] = useEmblaCarousel(options, [Autoplay()])
    const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(
        emblaApi,
        onButtonClick
    );

    /**
     * Log event in GA
     * @param name
     * @param uri
     */
    const _trackEvent = (name: string, uri: string) => {
        saveDashboardInteractionEvent( 'carousel_clicked', name);
    };

    /**
     * Get HTML tag based on URL link
     * @param title
     * @param uri
     * @param children
     */
    const _renderCarousel = (title: string, uri: string, children: React.ReactNode) => {
        if ( uri )
            return <a
              className='carousel__overlay'
              target='_blank'
              rel='noreferrer'
              href={getSharepointUrl(uri)}
              onClick={() => _trackEvent(title, uri)}
            >{children}</a>;
        return <div className='carousel__overlay'>{children}</div>;
    };

    /**
     * Get valid data array
     * @param arr
     */
    const _getValidData = (arr: any) =>
        arr.filter((item: any) =>
            item &&
            item.title &&
            item.imageContentType &&
            item.imageContent &&
            item.imageContentType !== 'text/plain' &&
            item.imageContentType !== 'application/xml'
        );

    /**
     * Render
     */
    return (
        <>
            {slides && slides.length > 0 && (
                <div className='govTeams-carousel'>
                    <div className='carousel'>
                        <div className='carousel__viewport' ref={emblaRef}>
                            <div className='carousel__container'>
                                {_getValidData(slides).map( slide => (
                                    <div className='carousel__slide' key={`slide_${slide.id}`}>
                                        {_renderCarousel(slide.title, slide.link,
                                            <>
                                                <img
                                                    className='carousel__slide__img'
                                                    src={`data:${slide.imageContentType};base64, ${slide.imageContent}`}
                                                    title={slide.title}
                                                    alt={slide.title}
                                                />
                                                {(slide.title || slide.description) && (
                                                    <div className='carousel__slide__title'>
                                                        <h2>{slide.title}</h2>
                                                        <p>{slide.description}</p>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                ))}
                            </div>

                            <div className='carousel__dots'>
                                {scrollSnaps.map((snap, index) => (
                                    <DotButton
                                        key={`dot_${snap}_${index}`}
                                        onClick={() => onDotButtonClick(index)}
                                        className={`carousel__dot${index === selectedIndex ? ' carousel__dot--selected' : ''}`}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default CarouselComponent;