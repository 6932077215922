import React from 'react';
import { replace } from 'react-router-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ActionButton } from '@fluentui/react/lib/Button';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { Icon } from '@fluentui/react/lib/Icon';

/**
 * Constants and utilities
 */
import * as constants from '../../common/Constants';
import { IOrganisation, organisationActionCreators } from '../../controllers/OrganisationController';
import { organisationSummaryActionCreators } from './../../screens/OrganisationSummary';

export const OrganisationLandingComponent = (props) => {
  const { organisations, isLoadingAll, dispatch, organisationSummaryActionCreators } = props;

  return (
    <div className='organisation-landing'>
      <h1>Organisations</h1>
      {isLoadingAll && (
          <Spinner size={SpinnerSize.small} label={'Loading organisations'} ariaLive='assertive' />
      )}
      {organisations && organisations.length > 0 && (
        <div className='organisationList'>
          {organisations.map(
            (org: IOrganisation, index: number) => {
              return (
                <div className='organisationList-item' key={index}>
                  <ActionButton
                      className='organisationList-itemButton'
                      iconProps={{ iconName: 'EMI' }}
                      onClick={(event: any) => {
                        organisationSummaryActionCreators.startOrganisationManage(
                            event.currentTarget.getAttribute('data-orgid')
                        );
                        dispatch(
                            replace(
                                `${
                                    constants.SCREEN_ORGANISATION
                                }/${event.currentTarget.getAttribute('data-orgid')}`
                            )
                        );
                        event.preventDefault();
                        event.stopPropagation();
                      }}
                      data-orgid={org.id}
                  >
                    {org.name} <Icon iconName='ChevronRight' />
                  </ActionButton>
                </div>
              );
            }
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  initialValues: state.profile.initialValues,
  organisations: state.organisation.organisations,
  isLoadingAll: state.organisation.isLoadingAll,
});

const mapDispatchToProps = (dispatch: any) => ({
  organisationSummaryActionCreators: bindActionCreators(
    organisationSummaryActionCreators,
    dispatch
  ),
  organisationActionCreators: bindActionCreators(
    organisationActionCreators,
    dispatch
  ),
  dispatch
});

export const OrganisationLanding = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganisationLandingComponent);

export default OrganisationLanding;
