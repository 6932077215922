import actions from './Announcements.actions';

const initialState = {
  announcementToHide: {},
  announcementList: [],
  hasLoaded: false,
  isLoading: false
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === actions.ANNOUNCEMENT_FETCH_REQUEST) {
    return {
      ...state,
      isLoading: true
    };
  }

  if (action.type === actions.ANNOUNCEMENT_FETCH_RESPONSE) {
    return {
      ...state,
      announcementToHide: {},
      announcementList: action.announcementList,
      //announcementList: [
      //        { "ID": 1, "Date": "13\/7\/2018", "Headline": "Test 1", "Priority": "Alert", "Summary": "This to be alerted about!", "ReferenceLink": "http://www.google.com" },
      //        { "ID": 2, "Date": "13\/7\/2018", "Headline": "Test 2", "Priority": "Information", "Summary": "This to be alerted about and is really very very long and needs to be truncated at somepoint but I am not sure where this is going to happen so I will keep typing", "ReferenceLink": "http://www.google.com" },
      //        { "ID": 3, "Date": "13\/7\/2018", "Headline": "Test 3", "Priority": "Warning", "Summary": "This to be alerted about!3", "ReferenceLink": "http://www.google.com" },
      //        { "ID": 4, "Date": "13\/7\/2018", "Headline": "Test 3", "Priority": "Warning", "Summary": "This to be alerted about!3", "ReferenceLink": "http://www.google.com" }
      //    ],
      hasLoaded: true,
      isLoading: false
    };
  }

  if (action.type === actions.ANNOUNCEMENT_HIDE) {
    return {
      ...state,
      announcementToHide: action.announcementToHide,
      announcementList: action.announcementList
    };
  }

  return state;
};

export default reducer;
