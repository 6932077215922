import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

// Cant include this function from Util then get errors becz of circular dependency
const consoleLog = (...args) => {
  if ( process.env.REACT_APP_ENV === "development" )
    console.log(...args);
};

class AzureInsights {
  private static instance: AzureInsights | null = null;
  private _appInsights: ApplicationInsights;
  private _reactPlugin: ReactPlugin;

  private constructor(history: any) {
    if (process.env.NODE_ENV === 'test') {
      consoleLog('AzureInsights initialization skipped in test mode');
      return; // Skip initialization in test mode
    }

    this._reactPlugin = new ReactPlugin();
    this._appInsights = new ApplicationInsights({
      config: {
        connectionString: process.env.REACT_APP_APPLICATION_INSIGHTS_KEY || '',
        extensions: [this._reactPlugin],
        extensionConfig: {
          [this._reactPlugin.identifier]: { history }
        },
      },
    });
    this._appInsights.loadAppInsights();
  }

  public static getInstance(history?: any): AzureInsights {
    if (!AzureInsights.instance) {
      if (!history) {
        throw new Error('AzureInsights requires a history object for initialization!');
      }
      AzureInsights.instance = new AzureInsights(history);
      consoleLog('AzureInsights initiated');
    }
    return AzureInsights.instance;
  }

  public trackEvent(eventName: string, actionName: string, category: string, label: string, params: any = {}) {
    if (!this._appInsights) {
      consoleLog('Attempted to track event in test mode or when azure insights was not initialised');
      return;
    }

    if (!eventName) {
      consoleLog('AI event not sent. Event name is empty', eventName);
      return;
    }
    consoleLog('AI event logged', eventName, ' | ', actionName, ' | ', category, ' | ', label, ' | ', params);
    this._appInsights.trackEvent({ name: eventName, properties: {
        ...params,
        event_platform: 'Portal',
        event_action: actionName,
        event_category: category,
        event_label: label,
      }});
  }

  public trackUser(validatedId: string, accountId: string) {
    if (!this._appInsights) {
      consoleLog('Attempted to track user in test mode or when azure insights was not initialised');
      return;
    }

    if (!validatedId) {
      consoleLog('AI user not sent. User name is empty', validatedId);
      return;
    }

    const noSpaceAccountId = accountId?.replaceAll(' ', '_');
    consoleLog('AI user logged', validatedId, ' | ', noSpaceAccountId);
    this._appInsights.setAuthenticatedUserContext(validatedId, noSpaceAccountId, true);
  }

  get reactPlugin(): ReactPlugin {
    return this._reactPlugin;
  }
}

export default AzureInsights;
