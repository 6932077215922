/**
 * Organisation management screen
 *
 */

/**
 * React
 */
import * as React from 'react';

/**
 * Redux
 */
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { RouteComponentProps } from 'react-router';
import { bindActionCreators } from 'redux';
import {
  organisationActionCreators,
  IOrganisationState,
  IOrganisationProps,
  IOrganisationMember,
  IOrganisationMemberSearchParams
} from '../../controllers/OrganisationController';
import { MemberType } from '../../controllers/CommunityController';

/**
 * Fabric UI
 */
import { AnimationClassNames } from '@uifabric/styling';
import {
  ActionButton,
  PrimaryButton,
  DefaultButton,
  CommandBarButton
} from '@fluentui/react/lib/Button';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { DirectionalHint } from '@fluentui/react/lib/Callout';
import {
  ConstrainMode,
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  IColumn
} from '@fluentui/react/lib/DetailsList';
import { Dialog, DialogFooter } from '@fluentui/react/lib/Dialog';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { SearchBox } from '@fluentui/react/lib/SearchBox';
import { Selection } from '@fluentui/react/lib/Selection';
import { MarqueeSelection } from '@fluentui/react/lib/MarqueeSelection';
import {
  MessageBar,
  MessageBarType
} from '@fluentui/react/lib/MessageBar';

/**
 * Utilities
 */
import { sortItems, getFormattedDateTime, isProtected } from '../../common/Utils';

/**
 * Components
 */
import { MembersActionResults } from './components/MembersActionResults';

/**
 * Constants and utilities
 */
import * as constants from '../../common/Constants';
import { IOrganisationMembersState } from './OrganisationMembers.types';
import { ListPager } from './components/ListPager';
import {
  selectionActions,
  constants as componentConstants,
  tooltips
} from './OrganisationMembers.constants';

type IMergedProps = IOrganisationState &
  IOrganisationProps &
  RouteComponentProps;

const tooltipCommandBarButton: any = (props: any) => {
  const { defaultRender: DefaultRender = CommandBarButton } = props;

  return (
    <TooltipHost
      content={props.ariaLabel}
      directionalHint={DirectionalHint.topAutoEdge}
    >
      <DefaultRender {...props} />
    </TooltipHost>
  );
};

export class OrganisationMembersComponent extends React.Component<
  IMergedProps,
  IOrganisationMembersState
> {
  private selection: Selection;

  constructor(props: IMergedProps) {
    super(props);

    const columns: IColumn[] = this.getColumns();

    this.selection = new Selection({
      onSelectionChanged: () => {
        this.getSelectedMembers();
      }
    });

    this.state = {
      columns,
      items: [],
      searchWord: '',
      searchCleared: false,
      selectedMembers: [],
      selectedDomains: [],
      selectedStatuses: [],
      selectedMemberTypes: [],
      isFilterOpen: false,
      filtersCleared: false,
      isLoaded: false,
      hideAlertDialog: true,
      hideResultsDialog: true,
      hideInfoMessage: false,
      rowsPerPage: 20
    };
  }

  public render() {
    const { membersResult } = this.props;

    return (
      <div className='orgMembersManagement-page'>
        <ActionButton
          className='govTeams-actionButton'
          onClick={(event: any) => {
            this.props.dispatch(
              replace(
                `${constants.SCREEN_ORGANISATION}/${this.props.match.params['organisationId']}`
              )
            );
          }}
          iconProps={{ iconName: 'ChevronLeft' }}
        >
          {componentConstants.LINK_BACK}
        </ActionButton>
        {this.state.items && !this.props.error ? (
          <React.Fragment>
            <div className='topSection'>
              <h1>Organisation user list</h1>
              <hr />
              <p>{componentConstants.MESSAGE_SELECTION_LIMIT}</p>
            </div>
            <div className='searchRow'>
              <div className='searchBox-col'>
                <div className='searchBox'>
                  <SearchBox
                    placeholder='Search list'
                    value={this.state.searchWord}
                    onSearch={(newSearchWord: string) => {
                      this.onSearch();
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>, newSearchWord: string) => {
                      this.setState({
                        searchWord: newSearchWord
                      });
                    }}
                    onClear={(event: React.MouseEvent<any>) => {
                      this.setState({
                        searchWord: '',
                        searchCleared: true
                      });
                    }}
                  />
                  <PrimaryButton text='Search' onClick={this.onSearch} />
                </div>
              </div>
              <div className='rowsDropdown-col'>
                <Dropdown
                  placeholder='Items per page'
                  options={[
                    {
                      key: 10,
                      text: `Items per page: 10`
                    },
                    {
                      key: 20,
                      text: `Items per page: 20`
                    },
                    {
                      key: 30,
                      text: `Items per page: 30`
                    },
                    {
                      key: 40,
                      text: `Items per page: 40`
                    },
                    {
                      key: 50,
                      text: `Items per page: 50`
                    }
                  ]}
                  onChange={(
                    event: React.FormEvent<HTMLDivElement>,
                    item: IDropdownOption
                  ) => {
                    this.setState({
                      rowsPerPage: item.key as number
                    });
                  }}
                  selectedKey={this.state.rowsPerPage}
                />
              </div>
            </div>

            {!this.state.hideInfoMessage &&
              this.props.match.params['licenceStatus'] && (
                <MessageBar
                  className='infoMessage'
                  messageBarType={MessageBarType.warning}
                  onDismiss={(event) => {
                    this.setState({
                      hideInfoMessage: true
                    });
                  }}
                  dismissButtonAriaLabel='Close'
                  isMultiline={true}
                  overflowButtonAriaLabel='Overflow'
                >
                  {this.props.match.params['licenceStatus'] && (
                    <p>{componentConstants.MESSAGE_PREFILTER_PENDING}</p>
                  )}
                </MessageBar>
              )}

            <CommandBar
              className='govTeams-CommandBar'
              items={this.getCommandBarItems()}
              farItems={this.getCommandBarFarItems()}
            />
            {this.state.isFilterOpen && (
              <div
                className={`filterList ${AnimationClassNames.slideDownIn10}`}
              >
                <div className='filterList-row'>
                  <div className='filterList-heading'>
                    <h2>Filter list</h2>
                  </div>

                  <div className='filterList-dropdowns'>
                    <div className='filterList-dropdowns-row'>
                      <div className='filterList-dropdown'>
                        <Dropdown
                          placeholder='Select email domains'
                          label='Email domains'
                          multiSelect={true}
                          options={this.getDomainOptions()}
                          onChange={this.onDomainChange}
                          selectedKeys={this.state.selectedDomains}
                        />
                      </div>
                      <div className='filterList-dropdown'>
                        <Dropdown
                          placeholder='Select member status'
                          label='Member status'
                          multiSelect={true}
                          onChange={this.onLicenceStatusChange}
                          options={[
                            {
                              key: 'Active',
                              text: 'Active'
                            },
                            {
                              key: 'Under Review',
                              text: 'Under Review'
                            },
                            {
                              key: 'Inactive',
                              text: 'Inactive'
                            },
                       
                            {
                              key: 'Blocked',
                              text: 'Blocked'
                            },
                            {
                              key: 'Pending Licence',
                              text: 'Pending Licence'
                            },
                           
                       
                          ]}
                          selectedKeys={this.state.selectedStatuses}
                        />
                      </div>
                      {/* Currently unused in Unclass */}
                      {isProtected() && (<div className='filterList-dropdown'>
                        <Dropdown
                          placeholder='Select member type'
                          label='Member type'
                          multiSelect={true}
                          onChange={this.onMemberTypeChange}
                          options={this.getMemberTypeOptions()}
                          selectedKeys={this.state.selectedMemberTypes}
                        />
                      </div>)}
                    </div>
                  </div>

                  <div className='filterList-footer'>
                    <DefaultButton onClick={this.onClear}>Clear</DefaultButton>
                    <PrimaryButton onClick={this.onSearch}>
                      Filter
                    </PrimaryButton>
                  </div>
                </div>
              </div>
            )}
            <Dialog
              hidden={this.state.hideAlertDialog}
              onDismiss={this.closeConfirmationDialog}
              dialogContentProps={{
                title: componentConstants.ALERT_DIALOG_TITLE
              }}
              modalProps={{
                isBlocking: true,
                isDarkOverlay: true,
                className: 'govTeams-dialog'
              }}
            >
              <p>{componentConstants.ALERT_DIALOG_MESSAGE}</p>
              <DialogFooter>
                <DefaultButton
                  onClick={this.closeConfirmationDialog}
                  text='Ok'
                />
                {/* <PrimaryButton
                  onClick={this.doSelectionAction}
                  text="Continue"
                /> */}
              </DialogFooter>
            </Dialog>

            <Dialog
              className='govTeams-dialog-results'
              minWidth='600px'
              hidden={this.state.hideResultsDialog}
              onDismiss={this.closeResultsDialog}
              dialogContentProps={{}}
              modalProps={{
                isBlocking: true,
                isDarkOverlay: true
              }}
            >
              {this.props.membersActionResult && (
                <React.Fragment>
                  <h2
                    className={`h1 heading-${
                      !this.props.membersActionResult.code
                        ? 'error'
                        : this.props.membersActionResult.code.toLowerCase()
                    }`}
                  >
                    {this.props.membersActionResult.code}
                  </h2>
                  <p>{this.props.membersActionResult.message}</p>
                  <div className='spacing-bottom' />
                  <MembersActionResults
                    results={this.props.membersActionResult}
                  />
                </React.Fragment>
              )}
              <DialogFooter>
                <PrimaryButton onClick={this.closeResultsDialog} text='Ok' />
              </DialogFooter>
            </Dialog>

            <div className='membersList'>
              <MarqueeSelection selection={this.selection}>
                <DetailsList
                  columns={this.state.columns}
                  constrainMode={ConstrainMode.unconstrained}
                  items={this.state.items}
                  layoutMode={DetailsListLayoutMode.justified}
                  onRenderItemColumn={this.onRenderItem}
                  selectionMode={SelectionMode.multiple}
                  selection={this.selection}
                />
              </MarqueeSelection>
            </div>

            {membersResult && membersResult.totalPages > 0 && (
              <ListPager
                currentPage={membersResult.pageNumber}
                pageSize={5}
                maxPages={membersResult.totalPages}
                onPage={this.onPage}
                onNext={this.onNext}
                onPrevious={this.onPrevious}
              />
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {this.state.isLoaded && this.props.error && (
              <React.Fragment>
                <h1>Error</h1>
                <table className='table-requestError'>
                  <tbody>
                    <tr>
                      <th>Type</th>
                      <td>{this.props.error.code}</td>
                    </tr>
                    <tr>
                      <th>Message</th>
                      <td>{this.props.error.message}</td>
                    </tr>
                    <tr>
                      <th>CorrelationId</th>
                      <td>{this.props.error.innerError.requestId}</td>
                    </tr>
                  </tbody>
                </table>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }

  public componentDidMount() {
    const organisationId =
      this.props.organisation && this.props.organisation.id !== undefined
        ? this.props.organisation.id
        : this.props.match.params['organisationId'];

    this.init(organisationId);
  }

  public async componentDidUpdate(
    prevProps: IMergedProps,
    prevState: IOrganisationMembersState
  ) {
    if (prevProps.membersResult !== this.props.membersResult) {
      if (this.props.membersResult) {
        this.setState({
          columns: this.getColumns(),
          items: this.props.membersResult.members as IOrganisationMember[],
          selectedMembers: []
        });
      }
    }

    if (prevProps.membersActionResult !== this.props.membersActionResult) {
      if (this.props.membersActionResult) {
        this.setState({
          hideResultsDialog: false
        });
      }
    }

    if (prevState.rowsPerPage !== this.state.rowsPerPage) {
      this.onSearch();
    }

    if (this.state.searchWord === '' && this.state.searchCleared) {
      await this.onSearch();

      this.setState({
        searchCleared: false
      });
    }

    if (
      this.state.selectedDomains.length === 0 &&
      this.state.selectedStatuses.length === 0 &&
      this.state.filtersCleared
    ) {
      await this.onSearch();

      this.setState({
        filtersCleared: false
      });
    }
  }

  private async init(organisationId: string) {
    const licenceStatus: string = this.props.match.params['licenceStatus'];
    const preselectedStatuses: string[] = this.state.selectedStatuses;

    if (licenceStatus) {
      preselectedStatuses.push(licenceStatus);
    }

    await this.props.organisationActionCreators.requestFetchOrganisationDomains(
      organisationId
    );

    if (preselectedStatuses.length === 0) {
      await this.props.organisationActionCreators.requestFetchOrganisationMembers(
        organisationId
      );
    } else {
      await this.onSearch();
    }

    this.setState({
      selectedStatuses: preselectedStatuses,
      isLoaded: true
    });
  }

  private getCommandBarItems = () => {
    return [
      {
        key: 'allocate',
        name: 'Allocate licence',
        ariaLabel: tooltips.ALLOCATE_LICENCE,
        disabled: this.state.selectedMembers.length === 0,
        iconProps: {
          iconName: 'UserFollowed'
        },
        commandBarButtonAs: tooltipCommandBarButton,
        onClick: () => {
          if (this.state.selectedMembers.length < 10) {
            this.props.organisationActionCreators.allocateLicences(
              this.props.match.params['organisationId'],
              this.state.selectedMembers
            );
          } else {
            this.setState({
              selectionAction: selectionActions.allocateLicence,
              hideAlertDialog: false
            });
          }
        }
      },
      {
        key: 'deactivate',
        name: 'Deactivate account',
        ariaLabel: tooltips.DEACTIVATE_LICENCE,
        disabled: this.state.selectedMembers.length === 0,
        iconProps: {
          iconName: 'UserPause'
        },
        commandBarButtonAs: tooltipCommandBarButton,
        onClick: () => {
          if (this.state.selectedMembers.length <= 10) {
            this.props.organisationActionCreators.deactivateLicences(
              this.props.match.params['organisationId'],
              this.state.selectedMembers
            );
          } else {
            this.setState({
              selectionAction: selectionActions.deactivateLicence,
              hideAlertDialog: false
            });
          }
        }
      },
      {
        key: 'move',
        name: 'Remove licence',
        ariaLabel: tooltips.MOVE_TO_WAITLIST,
        disabled: this.state.selectedMembers.length === 0,
        iconProps: {
          iconName: 'UserRemove'
        },
        commandBarButtonAs: tooltipCommandBarButton,
        onClick: () => {
          if (this.state.selectedMembers.length <= 10) {
            this.props.organisationActionCreators.moveToWaitlist(
              this.props.match.params['organisationId'],
              this.state.selectedMembers
            );
          } else {
            this.setState({
              selectionAction: selectionActions.moveToWaitlist,
              hideAlertDialog: false
            });
          }
        }
      }
    ];
  };

  private getCommandBarFarItems = () => {
    return [
      {
        key: 'filter',
        name: 'Filter list',
        ariaLabel: tooltips.FILTER_LIST,
        iconProps: {
          iconName: 'Filter'
        },
        commandBarButtonAs: tooltipCommandBarButton,
        onClick: () => {
          this.setState({
            isFilterOpen: !this.state.isFilterOpen
          });
        }
      },
      {
        key: 'export',
        name: 'Export .csv',
        ariaLabel: tooltips.EXPORT_CSV,
        className: 'commandBar-primaryButton',
        disabled: this.props.isExporting || (!this.props.match.params['organisationId'] ? true : false),
        iconProps: {
          iconName: 'Download'
        },
        commandBarButtonAs: tooltipCommandBarButton,
        onClick: () => {
          const params: IOrganisationMemberSearchParams = {
            searchWord: this.state.searchWord,
            domains: this.state.selectedDomains,
            statuses: this.state.selectedStatuses,
            pageNumber: 1,
            rowsPerPage: this.state.rowsPerPage
          };

          this.props.organisationActionCreators.exportFilteredOrganisationMemberCsv(
            this.props.match.params['organisationId'],
            params
          );
        }
      }
    ];
  };

  // private doSelectionAction = async () => {
  //   this.setState({
  //     hideAlertDialog: true
  //   });

  //   switch (this.state.selectionAction) {
  //     case selectionActions.allocateLicence:
  //       await this.props.organisationActionCreators.allocateLicences(
  //         this.props.match.params['organisationId'],
  //         this.state.selectedMembers
  //       );
  //       break;
  //     case selectionActions.deactivateLicence:
  //       await this.props.organisationActionCreators.deactivateLicences(
  //         this.props.match.params['organisationId'],
  //         this.state.selectedMembers
  //       );
  //       break;
  //     case selectionActions.moveToWaitlist:
  //       await this.props.organisationActionCreators.moveToWaitlist(
  //         this.props.match.params['organisationId'],
  //         this.state.selectedMembers
  //       );
  //       break;
  //   }

  //   /**
  //    * Refresh results
  //    */
  //   this.onSearch();
  // };

  private closeConfirmationDialog = () => {
    this.setState({
      hideAlertDialog: true
    });
  };

  private closeResultsDialog = () => {
    this.selection.setAllSelected(false);

    this.setState({
      hideResultsDialog: true,
      selectedMembers: []
    });

    /**
     * Refresh results
     */
    this.onSearch();
  };

  private getDomainOptions() {
    const options = this.props.domains
      ? this.props.domains.map((domain) => {
          return {
            key: domain,
            text: domain
          };
        })
      : [];

    return options;
  }

   private getMemberTypeOptions() {
     // const memberTypeOptions: IDropdownOption[] = [];

     // for (const value in MemberType) {
     //   if (typeof MemberType[value] === 'string') {
     //     memberTypeOptions.push({
     //       key: MemberType[value],
     //       text: MemberType[value]
     //     });
     //   }
     // }
     const memberTypeOptions: IDropdownOption[] = [{
       key: MemberType.FAM,
       text: MemberType.FAM
     },{
       key: MemberType.LAM,
       text: 'External Member'
     }];

     return memberTypeOptions;
   }

  private onDomainChange = (
    event: React.FormEvent<HTMLDivElement>,
    item: IDropdownOption
  ): void => {
    const newSelectedDomains = this.state.selectedDomains;
    if (item.selected) {
      newSelectedDomains.push(item.key as string);
    } else {
      const currIndex = newSelectedDomains.indexOf(item.key as string);
      if (currIndex > -1) {
        newSelectedDomains.splice(currIndex, 1);
      }
    }
    this.setState({
      selectedDomains: newSelectedDomains
    });
  };

  private onLicenceStatusChange = (
    event: React.FormEvent<HTMLDivElement>,
    item: IDropdownOption
  ): void => {
    const newLicenceStatus = this.state.selectedStatuses;
    if (item.selected) {
      newLicenceStatus.push(item.key as string);
    } else {
      const currIndex = newLicenceStatus.indexOf(item.key as string);
      if (currIndex > -1) {
        newLicenceStatus.splice(currIndex, 1);
      }
    }
    this.setState({
      selectedStatuses: newLicenceStatus
    });
  };

   private onMemberTypeChange = (
     event: React.FormEvent<HTMLDivElement>,
     item: IDropdownOption
   ): void => {
     const newMemberTypes = this.state.selectedMemberTypes;
     if (item.selected) {
       newMemberTypes.push(item.key as string);
     } else {
       const currIndex = newMemberTypes.indexOf(item.key as string);
       if (currIndex > -1) {
         newMemberTypes.splice(currIndex, 1);
       }
     }

     this.setState({
       selectedMemberTypes: newMemberTypes
     });
   };

  private onSearch = () => {
    const params: IOrganisationMemberSearchParams = {
      searchWord: this.state.searchWord,
      domains: this.state.selectedDomains,
      statuses: this.state.selectedStatuses,
      memberTypes: this.state.selectedMemberTypes,
      pageNumber: 1,
      rowsPerPage: this.state.rowsPerPage
    };

    this.props.organisationActionCreators.requestFetchOrganisationMembers(
      this.props.match.params['organisationId'],
      params
    );
  };

  private onClear = (event: React.MouseEvent<any>) => {
    this.setState({
      selectedDomains: [],
      selectedStatuses: [],
      selectedMemberTypes: [],
      filtersCleared: true
    });
  };

  private onPage = (event: React.MouseEvent<any>) => {
    this.props.organisationActionCreators.requestFetchOrganisationMembers(
      this.props.match.params['organisationId'],
      {
        searchWord: this.state.searchWord,
        domains: this.state.selectedDomains,
        statuses: this.state.selectedStatuses,
        memberTypes: this.state.selectedMemberTypes,
        pageNumber: event.currentTarget.dataset.page,
        rowsPerPage: this.state.rowsPerPage
      }
    );
  };

  private onPrevious = (event: React.MouseEvent<any>) => {
    this.props.organisationActionCreators.requestFetchOrganisationMembers(
      this.props.match.params['organisationId'],
      {
        searchWord: this.state.searchWord,
        domains: this.state.selectedDomains,
        statuses: this.state.selectedStatuses,
        memberTypes: this.state.selectedMemberTypes,
        pageNumber:
          this.props.membersResult && this.props.membersResult.pageNumber - 1,
        rowsPerPage: this.state.rowsPerPage
      }
    );
  };

  private onNext = (event: React.MouseEvent<any>) => {
    this.props.organisationActionCreators.requestFetchOrganisationMembers(
      this.props.match.params['organisationId'],
      {
        searchWord: this.state.searchWord,
        domains: this.state.selectedDomains,
        statuses: this.state.selectedStatuses,
        memberTypes: this.state.selectedMemberTypes,
        pageNumber:
          this.props.membersResult && this.props.membersResult.pageNumber + 1,
        rowsPerPage: this.state.rowsPerPage
      }
    );
  };

  private onRenderItem = (item, index, column) => {
    const fieldContent = item[column.fieldName];
    const key = column.key;

    switch (key) {
      case 'nextVerificationDate':
      case 'licenceRequestDate':
      case 'clearanceExpiryDate':
     case 'lastLoginDate':

        return getFormattedDateTime(fieldContent);
      case 'memberType':
        return item.memberType !== MemberType.LAM
          ? item.memberType
          : 'External Member';
      // case 'department':
      default:
        return fieldContent;
    }
  };

  private onColumnClick = (
    event: React.MouseEvent<HTMLElement>,
    column: IColumn
  ) => {
    const { columns, items } = this.state;

    let newItems = items.slice();
    const newColumns: IColumn[] = columns.slice();

    const currColumn = newColumns.filter((currCol, idx) => {
      return column.key === currCol.key;
    })[0];

    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = false;
      }
    });

    newItems = sortItems(
      newItems,
      currColumn.fieldName,
      currColumn.isSortedDescending
    );

    this.setState({
      columns: newColumns,
      items: newItems
    });
  };

  private getColumns(): IColumn[] {
    return (isProtected()) ? [
      {
        key: 'fullName',
        name: 'Name',
        fieldName: 'fullName',
        isResizable: true,
        isMultiline: true,
        minWidth: 200,
        maxWidth: 250,
        onColumnClick: this.onColumnClick
      },
      {
        key: 'emailAddress',
        name: 'Email',
        fieldName: 'emailAddress',
        isResizable: true,
        minWidth: 250,
        maxWidth: 300,
        onColumnClick: this.onColumnClick
      },
      {
        key: 'azureUPN',
        name: 'User name',
        fieldName: 'azureUPN',
        isResizable: true,
        minWidth: 250,
        maxWidth: 300,
        onColumnClick: this.onColumnClick
      },
      {
        key: 'memberStatus',
        name: 'Status',
        fieldName: 'memberStatus',
        isResizable: true,
        isMultiline: true,
        minWidth: 150,
        maxWidth: 200,
        onColumnClick: this.onColumnClick
      },
      {
         key: 'memberType',
         name: 'Member type',
         fieldName: 'memberType',
         isResizable: true,
         minWidth: 150,
         maxWidth: 200,
         onColumnClick: this.onColumnClick
      },
      {
        key: 'domain',
        name: 'Email domain',
        fieldName: 'domain',
        isResizable: true,
        minWidth: 150,
        maxWidth: 250,
        onColumnClick: this.onColumnClick
      },
      {
        key: 'department',
        name: 'Department',
        fieldName: 'department',
        isResizable: true,
        isMultiline: true,
        minWidth: 220,
        onColumnClick: this.onColumnClick
      },
      {
        key: 'occupation',
        name: 'Occupation',
        fieldName: 'occupation',
        isResizable: true,
        isMultiline: true,
        minWidth: 120,
        maxWidth: 120,
        onColumnClick: this.onColumnClick
      },
      {
        key: 'level',
        name: 'Level',
        fieldName: 'level',
        isResizable: true,
        isMultiline: true,
        minWidth: 60,
        maxWidth: 60,
        onColumnClick: this.onColumnClick
      },
      {
        key: 'nextVerificationDate',
        name: 'Next verification date',
        fieldName: 'nextVerificationDate',
        isResizable: true,
        isMultiline: true,
        minWidth: 150,
        maxWidth: 150,
        onColumnClick: this.onColumnClick
      },
      {
        key: 'licenceRequestDate',
        name: 'Licence requested',
        fieldName: 'licenceRequestDate',
        isResizable: true,
        isMultiline: true,
        minWidth: 150,
        maxWidth: 150,
        onColumnClick: this.onColumnClick
      },
      {
        key: 'clearanceExpiryDate',
        name: 'Clearance expiry date',
        fieldName: 'clearanceExpiryDate',
        isResizable: true,
        minWidth: 150,
        maxWidth: 150,
        onColumnClick: this.onColumnClick
        },
        {
            key: 'lastLoginDate',
            name: 'Last Login Date',
            fieldName: 'lastLoginDate',
            isResizable: true,
            isMultiline: true,
            minWidth: 150,
            maxWidth: 150,
            onColumnClick: this.onColumnClick
        }
    ] : [
        {
          key: 'fullName',
          name: 'Name',
          fieldName: 'fullName',
          isResizable: true,
          isMultiline: true,
          minWidth: 200,
          maxWidth: 250,
          onColumnClick: this.onColumnClick
        },
        {
          key: 'emailAddress',
          name: 'Email',
          fieldName: 'emailAddress',
          isResizable: true,
          minWidth: 250,
          maxWidth: 300,
          onColumnClick: this.onColumnClick
        },
        {
          key: 'azureUPN',
          name: 'User name',
          fieldName: 'azureUPN',
          isResizable: true,
          minWidth: 250,
          maxWidth: 300,
          onColumnClick: this.onColumnClick
        },
        {
          key: 'memberStatus',
          name: 'Status',
          fieldName: 'memberStatus',
          isResizable: true,
          isMultiline: true,
          minWidth: 150,
          maxWidth: 200,
          onColumnClick: this.onColumnClick
        },
        {
          key: 'domain',
          name: 'Email domain',
          fieldName: 'domain',
          isResizable: true,
          minWidth: 150,
          maxWidth: 250,
          onColumnClick: this.onColumnClick
        },
        {
          key: 'department',
          name: 'Department',
          fieldName: 'department',
          isResizable: true,
          isMultiline: true,
          minWidth: 220,
          onColumnClick: this.onColumnClick
        },
        {
          key: 'occupation',
          name: 'Occupation',
          fieldName: 'occupation',
          isResizable: true,
          isMultiline: true,
          minWidth: 120,
          maxWidth: 120,
          onColumnClick: this.onColumnClick
        },
        {
          key: 'level',
          name: 'Level',
          fieldName: 'level',
          isResizable: true,
          isMultiline: true,
          minWidth: 60,
          maxWidth: 60,
          onColumnClick: this.onColumnClick
        },
        {
          key: 'nextVerificationDate',
          name: 'Next verification date',
          fieldName: 'nextVerificationDate',
          isResizable: true,
          isMultiline: true,
          minWidth: 150,
          maxWidth: 150,
          onColumnClick: this.onColumnClick
        },
        {
          key: 'licenceRequestDate',
          name: 'Licence requested',
          fieldName: 'licenceRequestDate',
          isResizable: true,
          isMultiline: true,
          minWidth: 150,
          maxWidth: 150,
          onColumnClick: this.onColumnClick
        },
        {
            key: 'lastLoginDate',
            name: 'Last Login Date',
            fieldName: 'lastLoginDate',
            isResizable: true,
            isMultiline: true,
            minWidth: 150,
            maxWidth: 150,
            onColumnClick: this.onColumnClick
        }
    ];
  }

  private getSelectedMembers = () => {
    const selectedMembers = this.selection.getSelection() as IOrganisationMember[];

    this.setState({
      selectedMembers
    });
  };
}

const mapStateToProps = (state) => ({
  isExporting: state.organisation.isExporting,
  organisation: state.organisation.organisation,
  membersResult: state.organisation.membersResult,
  membersActionResult: state.organisation.membersActionResult,
  domains: state.organisation.domains,
  licence: state.organisation.licence,
  membersCsv: state.organisation.membersCsv,
  error: state.organisation.error
});

const mapDispatchToProps = (dispatch) => ({
  organisationActionCreators: bindActionCreators(
    organisationActionCreators,
    dispatch
  ),
  dispatch
});

export const OrganisationMembers = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganisationMembersComponent);

export default OrganisationMembers;
