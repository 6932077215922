import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'

/**
 * Component reducers
 */
import { reducer as announcements } from '../components/Announcements/Announcements.reducers';
import { reducer as community } from '../controllers/CommunityController/Community.reducers';
import { reducer as members } from '../controllers/MembersController/Members.reducers';
import { reducer as notification } from '../components/Notification/Notification.reducers';
import { reducer as profile } from '../controllers/ProfileController/Profile.reducers';
import { reducer as references } from '../controllers/ProfileController/References';
import { reducer as progress } from '../components/Progress/Progress.reducers';
import { reducer as reduxFormReducer } from 'redux-form';
import { reducer as requests } from '../controllers/RequestsController/Requests.reducers';
import { reducer as organisation } from '../controllers/OrganisationController/Organisation.reducers';
import { reducer as inviteMembers } from '../controllers/InviteMembersController/InviteMembers.reducers';
import { reducer as memberPreferences } from '../controllers/MemberPreferencesController/MemberPreferences.reducers';
import { reducer as department } from '../controllers/DepartmentController/Department.reducers';
import { reducer as insiderFeed } from '../controllers/InsiderFeedController/InsiderFeed.reducers';
import { reducer as carousel } from '../controllers/CarouselController/Carousel.reducers';
import { reducer as auth } from '../controllers/AuthController/Auth.reducers';

/**
 * Screen reducers
 */
import { reducer as invites } from '../screens/CommunityManagement/components/Invites/Invites.reducers';
import { reducer as quickLinks } from '../screens/Dashboard/components/QuickLinks/QuickLinks.reducers';
import { reducer as statistics } from '../screens/Dashboard/components/Statistics/Statistics.reducers';
import { reducer as reports } from '../screens/Reports/Reports.reducers';
import { reducer as search } from '../screens/Search/Search.reducers';
import { reducer as organisationSummary } from '../screens/OrganisationSummary/OrganisationSummary.reducers';

const reducers = {
  announcements,
  community,
  form: reduxFormReducer,
  invites,
  inviteMembers,
  // manage,
  members,
  notification,
  profile,
  progress,
  quickLinks,
  references,
  reports,
  requests,
  search,
  statistics,
  carousel,
  organisation,
  organisationSummary,
  memberPreferences,
  department,
  insiderFeed,
  auth,
};

const _reducers = (history) => combineReducers({
  router: connectRouter(history),
  ...reducers,
});

export default _reducers;

