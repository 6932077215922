import * as React from 'react';
import { connect } from 'react-redux';
import { ActionButton } from '@fluentui/react/lib/Button';
import { BRANDNAME } from '../../common/Tooltips';

export class BlockedComponent extends React.Component<any, any> {
  public render() {
    return (
      <div className='govTeams-account'>
        <div className='govTeams-row'>
          <div className='govTeams-account-col'>
            <h1>Licence allocation blocked</h1>
            <hr />
            <p>
              Your organisation administrator is responsible for allocating
              licences and has blocked your licence allocation.
            </p>
            <p>
              For further information, please contact your {BRANDNAME} organisation
              administrator.
            </p>
            <div className='spacing-bottom' />
            {this.props.initialValues &&
              this.props.initialValues.organisationContact && (
                <React.Fragment>
                  <h2 className='h3'>Your organisation contact</h2>
                  <ActionButton
                    className='meta-contactInfo'
                    href={`mailto:${this.props.initialValues.organisationContact}`}
                    iconProps={{ iconName: 'mail' }}
                  >
                    {this.props.initialValues.organisationContact}
                  </ActionButton>
                </React.Fragment>
              )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  initialValues: state.profile.initialValues
});

const mapDispatchToProps = (dispatch: any) => ({});

export const Blocked = connect(
  mapStateToProps,
  mapDispatchToProps
)(BlockedComponent);

export default Blocked;
