import { actions } from './Department.actions';
import { IDepartmentState } from './Department.types';

export const initialState: IDepartmentState = {
    department: undefined,
    departments: undefined,
    departmentError: undefined,
    message: undefined,
    error: undefined,
    isLoading: false,
    isLoadingAll: false
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.DEP_FETCH_ALL_REQUEST: {
            return {
                ...state,
                isLoadingAll: true
            };
        }

        case actions.DEP_FETCH_ALL_RESPONSE: {
            return {
                ...state,
                departments: action.departments,
                error: action.error,
                isLoadingAll: false
            };
        }
        case actions.DEP_FETCH_ALL_FOR_MEMBERTYPE_REQUEST: {
            return {
                ...state,
                isLoadingAll: true
            };
        }

        case actions.DEP_FETCH_ALL_FOR_MEMBERTYPE_RESPONSE: {
            return {
                ...state,
                departments: action.departments,
                error: action.error,
                isLoadingAll: false
            };
        }

        case actions.DEP_FETCH_REQUEST: {
            return {
                ...state,
                department: undefined,
                domains: undefined,
                licence: undefined,
                admins: undefined,
                departmentError: undefined,
                isLoading: true
            };
        }

        case actions.DEP_FETCH_RESPONSE: {
            return {
                ...state,
                department: action.department,
                departmentError: action.departmentError,
                isLoading: false
            };
        }

        default: {
            return state;
        }
    }
};

export default reducer;