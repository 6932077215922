export const actions = {
  MEMBERS_FETCH_REQUEST: 'MEMBERS_FETCH_REQUEST',
  MEMBERS_FETCH_RESPONSE: 'MEMBERS_FETCH_RESPONSE',
  MEMBERS_VIEW_REQUEST: 'MEMBERS_VIEW_REQUEST',
  MEMBERS_VIEW_RESPONSE: 'MEMBERS_VIEW_RESPONSE',
  MEMBERS_FETCH_BY_ROLE_REQUEST: 'MEMBERS_FETCH_BY_ROLE_REQUEST',
  MEMBERS_FETCH_BY_ROLE_RESPONSE: 'MEMBERS_FETCH_BY_ROLE_RESPONSE',
  MEMBERS_DELETE_REQUEST: 'MEMBERS_DELETE_REQUEST',
  MEMBERS_DELETE_RESPONSE: 'MEMBERS_DELETE_RESPONSE',
  MEMBERS_CHANGE_REQUEST: 'MEMBERS_CHANGE_REQUEST',
  MEMBERS_CHANGE_RESPONSE: 'MEMBERS_CHANGE_RESPONSE',
  MEMBERS_LEAVE_REQUEST: 'MEMBERS_LEAVE_REQUEST',
  MEMBERS_LEAVE_RESPONSE: 'MEMBERS_LEAVE_RESPONSE',
};

export default actions;
