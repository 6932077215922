import actions from './Reports.actions';

const initialState = {
  allMembers: null,
  isLoading: false,
  error: null
};

export const reducer = (state, action) => {
  state = state || initialState;

  //API
  if (action.type === actions.COMMUNITYACTIVITIES_REPORT_REQUEST) {
    return {
      ...state,
      communityId: action.communityId,
      isLoading: true
    };
  }

  if (action.type === actions.COMMUNITYACTIVITIES_REPORT_RESPONSE) {
    return {
      ...state,
      communityActivities: action.communityActivities,
      error: action.error,
      isLoading: false
    };
  }

  if (action.type === actions.ALLMEMBERS_REPORT_REQUEST) {
    return {
      ...state,
      isLoading: true
    };
  }

  if (action.type === actions.ALLMEMBERS_REPORT_RESPONSE) {
    return {
      ...state,
      allMembers: action.allMembers,
      error: action.error,
      isLoading: false
    };
  }

  return state;
};

export default reducer;
