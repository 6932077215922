/**
 * Onboarding cards for first logins
 *
 */

/**
 * React Redux
 */
import * as React from 'react';
import { connect } from 'react-redux';

/**
 * Fabric UI
 */
import { Dialog } from '@fluentui/react/lib/Dialog';
import OnboardingGuestContent from './OnboardingGuestContent';
import OnboardingFAMContent from './OnboardingFAMContent';
import OnboardingProtectedContent from './OnboardingProtectedContent';
import { isProtected } from '../../../../common/Utils';

class Onboarding extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      selectedKey: '1',
      show: true
    };
  }

  public render() {
    const pathname = window.location.pathname.toLocaleLowerCase();
    const isFirstLogin = pathname === '/firstlogin' ? true : false;

    return (
      <div>
        {isFirstLogin && (
          <Dialog
            dialogContentProps={{ showCloseButton: true }}
            modalProps={{
              isBlocking: true,
              isDarkOverlay: true,
              className: 'govTeams-dialog govTeams-onboarding'
            }}
            hidden={!this.state.show}
            onDismiss={this.onDismiss}
          >
            <div className='m-2'>
              {isProtected() ?
                (
                  <OnboardingProtectedContent
                    onComplete={() => {
                      this.setState({
                        show: false
                      });
                    }}
                  />
                ) : (
                  !this.props.isGuest ? (
                    <OnboardingFAMContent
                      onComplete={() => {
                        this.setState({
                          show: false
                        });
                      }}
                    />
                  ) : (
                    <OnboardingGuestContent
                      onComplete={() => {
                        this.setState({
                          show: false
                        });
                      }}
                    />
                  )
                )}
            </div>
          </Dialog>
        )}
      </div>
    );
  }

  private onDismiss = (event) => {
    this.setState({
      show: false
    });
  };
}

const mapStateToProps = (state: any) => ({
  profile: state.profile,
  isGuest: state.profile.isGuest
});

export default connect(mapStateToProps, {})(Onboarding);
