/**
 * Dashboard wrapper
 *
 */

/**
 * React
 */
import * as React from 'react';

/**
 * Dashboard components
 */
import CommunityGrid from './CommunityGrid';
import CommunityList from './CommunityList';

/**
 * GovTeams dependencies
 */
const DashboardCommunities = (props: { isListView: boolean; }) => {
  const { isListView } = props;

  return (
    <React.Fragment>
      {!!isListView ? <CommunityGrid /> : <CommunityList />}
    </React.Fragment>
  );
};

export default DashboardCommunities;

