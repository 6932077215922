import actions from './Statistics.actions';

const initialState = {
  totalMembers: null,
  totalCommunities: null,
  totalAgencies: null,
  topAgencies: null,
  isLoading: false,
  error: null
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case actions.STATISTICSTOTAL_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case actions.STATISTICSTOTAL_FETCH_RESPONSE:
      return {
        ...state,
        totalMembers: action.totalMembers,
        totalCommunities: action.totalCommunities,
        totalAgencies: action.totalAgencies,
        isLoading: false,
        error: action.error
      };
  default:
      return state;
  }
};

export default reducer;
