export const actions = {
  SEARCH_CLEAR: 'SEARCH_CLEAR',
  SEARCHCOMMUNITY_FETCH_REQUEST: 'SEARCHCOMMUNITY_FETCH_REQUEST',
  SEARCHCOMMUNITY_FETCH_RESPONSE: 'SEARCHCOMMUNITY_FETCH_RESPONSE',
  SEARCHDOCUMENTS_FETCH_REQUEST: 'SEARCHDOCUMENTS_FETCH_REQUEST',
  SEARCHDOCUMENTS_FETCH_RESPONSE: 'SEARCHDOCUMENTS_FETCH_RESPONSE',
  RECENTDOCUMENTS_FETCH_REQUEST: 'RECENTDOCUMENTS_FETCH_REQUEST',
  RECENTDOCUMENTS_FETCH_RESPONSE: 'RECENTDOCUMENTS_FETCH_RESPONSE',
  SEARCHMEMBERS_FETCH_REQUEST: 'SEARCHMEMBERS_FETCH_REQUEST',
  SEARCHMEMBERS_FETCH_RESPONSE: 'SEARCHMEMBERS_FETCH_RESPONSE',
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE'
};

export default actions;
