import * as React from 'react';
import { connect } from 'react-redux';
import { BRANDNAME } from '../../common/Tooltips';

const Error_Revoked = props => (
  <div>
    <h2 className="heading-alert">
      Your invitation to {BRANDNAME} is no longer valid
    </h2>
    <p>It looks like your invitation to {BRANDNAME} is no longer valid. </p>
    <p>
      This can happen if the person who invited you has since removed your
      invitation.{' '}
    </p>
    <p>
      If you think this has occured in error, please contact the person who
      invited you to {BRANDNAME} and ask them to re-invite you.
    </p>
    <p>
      {' '}
      If you have contacted the person who invited you and they advise your
      invitation is still showing in the system, you can contact the{' '}
      <a href={props.mailTo}>{BRANDNAME} support</a> helpdesk and quote the
      following error ID: <strong> {props.correlationId} </strong>
    </p>
  </div>
);

export default connect()(Error_Revoked);
