import * as React from 'react';
import { connect } from 'react-redux';
import { BRANDNAME } from '../../common/Tooltips';

const Error_VerifyInvalidCode = props => (
  <div>
    <h2 className="heading-error">Invalid Verification Code</h2>
    <p>
      It looks like something is wrong with the link you've tried to use. This
      could occur if you've copied and pasted the link and you've missed part of
      the URL or you are using a link from an old email.{' '}
    </p>
    <p>
      Try the link again, and if you are still having issues, you can resend a new link to yourself by
      going to the <a href="dashboard">{BRANDNAME} dashboard</a>, log in and click 'resend' of your verification email.
    </p>
    <p>
      If you are unable to log in or continue to experience issues, please raise a support request with 
      the <a href={props.mailTo}>{BRANDNAME} support</a> helpdesk and quote the
      following error ID: <strong> {props.correlationId} </strong>
    </p>
  </div>
);

export default connect()(Error_VerifyInvalidCode);
