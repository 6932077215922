/**
 * Community tools
 *
 * Provides functionality to manage and access community features.
 * Used on community panel and community management screen.
 *
 */

/**
 * React
 */
import * as React from 'react';

/**
 * Redux
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { inviteMembersActionCreators } from './../../../../controllers/InviteMembersController';
import { requestsActionCreators } from '../../../../controllers/RequestsController';
import { communityActionCreators } from './../../../../controllers/CommunityController';
import { membersActionCreators } from './../../../../controllers/MembersController';
import CommunityActionsCommonComponent from '@govteams/library/components/ManageCommunity/CommunityActionsComponent';

/**
 * Constants
 */
import * as constants from './CommunityActions.constants';

const CommunityActionsComponent = props => {
  const {
    community,
    showManagementTools,
    panelDismiss,
    isExporting,
    profile,
    communityActionCreators,
    inviteMembersActionCreators,
    membersActionCreators,
    requestsActionCreators,
  } = props;

  React.useEffect(() => {
    if (!community && panelDismiss) {
      panelDismiss();
    }
  }, [community, panelDismiss]);

  return (
    <div className='govTeams-communityActions'>
      <CommunityActionsCommonComponent
        community={community}
        profile={profile}
        constants={constants}
        showManagementTools={showManagementTools}
        isExporting={isExporting}

        closeCommunityPanel={communityActionCreators.closeCommunityPanel}
        requestCloseCommunity={communityActionCreators.requestCloseCommunity}
        requestLeaveCommunity={membersActionCreators.requestLeaveMember}
        startEditInviteMembers={inviteMembersActionCreators.startEditInviteMembers}
        requestViewCommunityMembers={membersActionCreators.viewCommunityMembers}
        requestDeleteMember={membersActionCreators.requestDeleteMember}
        startEditCommunity={communityActionCreators.startEditCommunity}
        exportCommunityMemberCsv={communityActionCreators.exportCommunityMemberCsv}
        exportCommunityInvitesCsv={communityActionCreators.exportCommunityInvitesCsv}
        startJoinMember={requestsActionCreators.startJoinMember}
        startEditManage={communityActionCreators.startEditManage}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  profile: state.profile,
  community: state.community.initialValues,
  isExporting: state.community.isExporting,
  favouriteCommunities: state.community.favouriteCommunities
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  inviteMembersActionCreators: bindActionCreators(inviteMembersActionCreators, dispatch),
  requestsActionCreators: bindActionCreators(requestsActionCreators, dispatch),
  membersActionCreators: bindActionCreators(membersActionCreators, dispatch),
  communityActionCreators: bindActionCreators(communityActionCreators, dispatch)
});

const CommunityActions = connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunityActionsComponent);

export default CommunityActions;
