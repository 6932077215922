import * as React from 'react';
import { connect } from 'react-redux';
import { BRANDNAME } from '../../common/Tooltips';

const Error_InviteExistingAzureUser = props => (
  <div>
    <h2 className="heading-error">You are already registered for {BRANDNAME} using a different email address</h2>
    <p>
      We've found an existing {BRANDNAME} account for you that uses a different email address.
        Instead of registering a second account for you, we've given your existing {BRANDNAME} account access to the community you were invited to. <a href="https://users.govteams.gov.au/">Log in to {BRANDNAME}</a> with your existing account to get started.
    </p>    
    <p>
      In future, if a {BRANDNAME} user invites you to a community using a different email address, you should contact them and ask them to add you to their community using the email address you have registered in {BRANDNAME}.
    </p>
    <p>
      If you don't remember the email address of your existing account, or you want to update your existing account's email address to your new email address, please <a href="https://www.govteams.gov.au/contact-us">contact {BRANDNAME} Support</a>.
    </p>            
  </div>
);

export default connect()(Error_InviteExistingAzureUser);
