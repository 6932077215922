import * as React from 'react';
import { Fabric } from '@fluentui/react/lib/Fabric';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import { isProtected } from './../common/Utils';

const Layout = props => (
  <Fabric>
    <div
      className={`govTeams-App-wrapper${
        isProtected() ? ' govTeams-protected' : ''
        }`}
    >
      <div className="govTeams-header">
        <Header />
      </div>
      <div className="govTeams-pageContainer">
        <div className="govTeams-container govTeams-content">
          {props.children}
        </div>
      </div>
      <Footer />
    </div>
  </Fabric>
);

export default Layout;