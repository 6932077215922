/**
 * React
 */
import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import insiderFeedActionCreators from '../../../../controllers/InsiderFeedController/InsiderFeed.operations';

import InsiderFeedComponent from './InsiderFeedComponent';

const InsiderFeed = props => {
    const { isLoading, feed, seeMoreLink } = props;

    useEffect(() => {
        props.insiderFeedActionCreators.requestInsiderFeed();
    }, [props.insiderFeedActionCreators]);

    return (
        <InsiderFeedComponent
            isLoading={isLoading}
            feed={feed}
            seeMoreLink={seeMoreLink}
        />
    );
}

const mapStateToProps = (state) => ({
    feed: state.insiderFeed.feed,
    seeMoreLink: state.insiderFeed.seeMoreLink,
    isLoading: state.insiderFeed.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    insiderFeedActionCreators: bindActionCreators(insiderFeedActionCreators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(InsiderFeed);
