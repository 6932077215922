/**
 * Members view of search
 *
 */

/**
 * React Redux
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { searchActionCreators } from '../Search.operations';

/**
 * Fabric UI
 */
import { ActionButton } from '@fluentui/react/lib/Button';
import { Icon } from '@fluentui/react/lib/Icon';

import {
  MessageBar,
  MessageBarType
} from '@fluentui/react/lib/MessageBar';

import {
  ConstrainMode,
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode
} from '@fluentui/react/lib/DetailsList';

/**
 * GovTEAMS dependencies
 */
import MemberPersona from './MemberPersona';

/**
 * Utilities
 */
import { sortItems, isProtected } from '../../../common/Utils';
import { isEqual } from 'lodash';

export class SearchMembers extends React.Component<any, any> {
  constructor(props) {
    super(props);

    const columns = (isProtected()) ?
      [
        {
          key: 'displayName',
          name: 'Member name',
          fieldName: 'displayName',
          isResizable: true,
          minWidth: 250,
          maxWidth: 300,
          onColumnClick: this.onColumnClick
        },
        {
          key: 'jobTitle',
          name: 'Job role',
          fieldName: 'jobTitle',
          isResizable: true,
          minWidth: 250,
          maxWidth: 300,
          onColumnClick: this.onColumnClick
        },
        {
          key: 'department',
          name: 'Department',
          fieldName: 'department',
          isResizable: true,
          isMultiline: true,
          minWidth: 250,
          maxWidth: 300,
          onColumnClick: this.onColumnClick
        }
      ]
      :
      [
        {
          key: 'displayName',
          name: 'Member name',
          fieldName: 'displayName',
          isResizable: true,
          minWidth: 250,
          maxWidth: 300,
          onColumnClick: this.onColumnClick
        },
        {
          key: 'isGuest',
          name: 'Member type',
          fieldName: 'isGuest',
          isResizable: true,
          minWidth: 250,
          maxWidth: 300,
          onColumnClick: this.onColumnClick
        },
        {
          key: 'jobTitle',
          name: 'Job role',
          fieldName: 'jobTitle',
          isResizable: true,
          minWidth: 250,
          maxWidth: 300,
          onColumnClick: this.onColumnClick
        },
        {
          key: 'department',
          name: 'Department',
          fieldName: 'department',
          isResizable: true,
          isMultiline: true,
          minWidth: 250,
          maxWidth: 300,
          onColumnClick: this.onColumnClick
        }
      ];
    
    this.state = {
      items: [],
      columns,
      currPage: 0
    };
  }

  public render() {
    return (
      <React.Fragment>
        {this.state.items && this.state.items.length > 0 && (
          <React.Fragment>
            <h2>Member search results: {this.props.filter}</h2>
            <DetailsList
              columns={this.state.columns}
              constrainMode={ConstrainMode.horizontalConstrained}
              items={this.state.items}
              layoutMode={DetailsListLayoutMode.justified}
              onRenderItemColumn={this.onRenderItem}
              selectionMode={SelectionMode.none}
            />
          </React.Fragment>
        )}
        {this.props.searchMembers && this.props.searchMembers.length === 0 && (
          <MessageBar messageBarType={MessageBarType.info} isMultiline={true}>
            There are no results for {this.props.filter} in members
          </MessageBar>
        )}

        {this.props.searchMembers && this.props.searchMembers.length > 0 && (
          <div className='govTeams-searchPager'>
            {this.state.currPage > 0 && this.props.searchMembers.length > 1 && (
              <ActionButton
                className='govTeams-searchPager--prev'
                onClick={this.onPrevious}
              >
                <Icon iconName='ChevronLeft' />
                Previous
              </ActionButton>
            )}

            {(this.props.skipToken !== '' ||
              this.props.searchMembers.length - 1 > this.state.currPage) && (
              <ActionButton
                className='govTeams-searchPager--next'
                onClick={this.onNext}
              >
                Next
                <Icon iconName='ChevronRight' />
              </ActionButton>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }

  public componentDidMount() {
    if (this.props.searchMembers) {
      const items = this.props.searchMembers[this.props.currentPage];

      this.setState({
        items,
        currPage: 0
      });
    }
  }

  public componentDidUpdate(prevProps, prevState) {
    if (!isEqual(this.props.searchMembers, prevProps.searchMembers)) {
      if (this.props.searchMembers) {
        const items = this.props.searchMembers[this.props.currentPage];
        this.setState({
          items
        });
      }
    }
  }

  private onColumnClick = (ev, column) => {
    const { columns, items } = this.state;

    let newItems = items.slice();
    const newColumns = columns.slice();

    const currColumn = newColumns.filter((currCol, idx) => {
      return column.key === currCol.key;
    })[0];

    newColumns.forEach((newCol) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = false;
      }
    });

    newItems = sortItems(
      newItems,
      currColumn.fieldName,
      currColumn.isSortedDescending,
      currColumn.fieldName==='isGuest'
    );

    this.setState({
      columns: newColumns,
      items: newItems
    });
  };

  private onRenderItem = (item, index, column) => {
    const fieldContent = item[column.fieldName];
    const key = column.key;

    switch (key) {
      case 'displayName':
        return this.onRenderName(item);
      case 'isGuest':
        return this.getMemberTypeLabel(item);
      default:
        return fieldContent;
    }
  };

  private getMemberTypeLabel = (item) => {
    if ( item.isGuest ) {
      return 'Guest';
    }
    if ( item.userPrincipalName && typeof item.userPrincipalName === "string" && item.userPrincipalName.toLowerCase().search('@partner.') > 0 ) {
      return 'Partner';
    }
    return 'Government';
  };

  private onRenderName = (item) => {
    return <MemberPersona item={item} />;
  };

  // private onRenderMessage = item => {
  //   return (
  //     <IconButton
  //       id={item.email}
  //       iconProps={{ iconName: 'Message' }}
  //       onClick={this.onMessage}
  //     />
  //   );
  // };

  private onPrevious = (event) => {
    const page = this.state.currPage - 1;
    this.props.search.setCurrentPage(page);
    const items = this.props.searchMembers[page];

    this.setState({
      items,
      currPage: page
    });
  };

  private onNext = (event) => {
    const page = this.state.currPage + 1;

    if (this.state.currPage + 1 === this.props.searchMembers.length) {
      this.props.search.requestFetchSearchMembers(
        this.props.filter,
        this.props.skipToken,
        false,
        page
      );
    } else {
      this.props.search.setCurrentPage(page);
      const items = this.props.searchMembers[page];

      this.setState({
        items
      });
    }

    this.setState({
      currPage: page
    });
  };
}

const mapStateToProps = (state) => ({
  filter: state.search.filter,
  searchMembers: state.search.searchMembers,
  skipToken: state.search.skipToken,
  currentPage: state.search.currentPage
});

const mapDispatchToProps = (dispatch) => ({
  search: bindActionCreators(searchActionCreators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchMembers);
