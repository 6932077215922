import actions from './Statistics.actions';
import * as commonConstants from '../../../../common/Constants';
import {getAuthFromState} from '../../../../auth';
import {httpServiceSecureGet} from '../../../../common/Http';

export const statisticsActionCreators = {
  requestFetchStatistics: () => async (dispatch, getState) => {
    dispatch(statisticsActionCreators.requestFetchStatisticsTotals());
  },

  requestFetchStatisticsTotals: () => async (dispatch, getState) => {
    if (getState().statistics.isLoading) {
      return;
    }

    dispatch({
      type: actions.STATISTICSTOTAL_FETCH_REQUEST
    });

    try {
      const { gtToken } = await getAuthFromState(getState, dispatch);
      const values: any = await httpServiceSecureGet('/statistics/totals', gtToken);

      dispatch({
        type: actions.STATISTICSTOTAL_FETCH_RESPONSE,
        totalMembers: values.totalMembers,
        totalCommunities: values.totalCommunities,
          totalAgencies: values.totalAgencies,
        error: null
      });
    } catch (error) {
      dispatch({
        type: actions.STATISTICSTOTAL_FETCH_RESPONSE,
        error : commonConstants.ERROR_STATISTICSTOTAL_FETCH
      });
    }
  },
};

export default statisticsActionCreators;
