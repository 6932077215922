/**
 * List of included domains
 */

/**
 * React
 */
import * as React from 'react';

/**
 * Fabric UI
 */
import {
  ConstrainMode,
  DetailsList,
  SelectionMode,
  IColumn
} from '@fluentui/react/lib/DetailsList';

interface IMembersActionResultsProps {
  results: any;
}

export class MembersActionResults extends React.Component<
  IMembersActionResultsProps,
  any
> {
  constructor(props) {
    super(props);

    const columns: IColumn[] = [
      {
        key: 'emailAddress',
        name: 'Email',
        fieldName: 'emailAddress',
        isResizable: true,
        minWidth: 150,
        maxWidth: 250
      },
      // {
      //   key: 'code',
      //   name: 'Code',
      //   fieldName: 'code',
      //   isResizable: true,
      //   isMultiline: true,
      //   minWidth: 30,
      //   maxWidth: 30
      // },
      {
        key: 'message',
        name: 'Status',
        fieldName: 'message',
        isResizable: true,
        isMultiline: true,
        minWidth: 300
      }
    ] as IColumn[];

    this.state = {
      columns,
      items: []
    };
  }

  public render() {
    return (
      <DetailsList
        columns={this.state.columns}
        constrainMode={ConstrainMode.horizontalConstrained}
        items={this.state.items}
        selectionMode={SelectionMode.none}
        onRenderItemColumn={this.onRenderItem}
      />
    );
  }

  public componentDidMount() {
    const items =
      this.props.results &&
      this.props.results.result &&
      Array.isArray(this.props.results.result) &&
      this.props.results.result.map((memberResult: any) => {
        return {
          emailAddress: memberResult.id,
          code: memberResult.code,
          message: memberResult.message
        };
      });

    this.setState({
      items
    });
  }

  // private getEmail(id: string, members: IOrganisationMember[]) {
  //   const foundMember = members.filter((member: IOrganisationMember) => {
  //     return member.id === id;
  //   })[0];

  //   return foundMember && foundMember.emailAddress;
  // }

  private onRenderItem = (item, index, column) => {
    const fieldContent = item[column.fieldName];
    const key = column.key;

    switch (key) {
      case 'message':
        return this.renderStatus(item);
      default:
        return fieldContent;
    }
  };

  private renderStatus = item => {
    let className = 'status-success';

    if (item.code !== 'Success') {
      className = 'status-error';
    }

    return (
      <table>
        <tbody>
          <tr>
            <td className="actionStatus">
              <span className={className} />
            </td>
            <td>{item.message}</td>
          </tr>
        </tbody>
      </table>
    );
  };
}

export default MembersActionResults;
