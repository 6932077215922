import { AccountInfo, BrowserAuthError, IPublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser';

import { IAuthToken } from '../controllers/AuthController/Auth.types';
import AUTH_ACTIONS from '../controllers/AuthController/Auth.actions';
import { MemberStatus } from '../controllers/CommunityController';
import {consoleLog, getTimeInFuture} from '../common/Utils';
import * as constants from '../common/Constants';

import { loginRequest, tokenRequest, LOGOUT_REDIRECT } from './authConfig';

function getParameterByName(name, url = window.location.href) {
    // eslint-disable-next-line
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) { return null };
    if (!results[2]) { return '' };
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function getLoginHint() {
    const loginHint = getParameterByName('login_hint');
    if ( loginHint ) {
        return loginHint;
    }
    return '';
}

/**
 * This function logout a user
 * @param instance
 * @param account
 */
export function logoutRedirect(instance, account) {

    // Get account
    let currentAccount = account?.[0];
    if ( !currentAccount ) {
        const currentAccounts = instance.getAllAccounts();
        currentAccount = currentAccounts?.[0];
    }

    // Build logout config
    const logoutRequest = {
        account: currentAccount,
        postLogoutRedirectUri: LOGOUT_REDIRECT,
    };

    // Logout via instance
    instance?.logoutRedirect(logoutRequest)
        .then(res => consoleLog('Redirecting to logout successful', res))
        .catch(err => console.error('Failed to redirect to logout', err));
}

/**
 * This function silently gets token from MSAL
 * @param msalInstance
 * @param msalAccounts
 * @param request
 */
export function getLoginToken(
    msalInstance: IPublicClientApplication,
    msalAccounts: AccountInfo,
    request: any,
): Promise<IAuthToken> {
    return new Promise((resolve, reject) => {
        const _request = request ? request:loginRequest;
        const _tokenRequest = {
            ..._request,
            account: msalAccounts[0],
        };

        // Append login hint
        const _loginHint = getLoginHint();
        if ( _loginHint ) {
            _tokenRequest.loginHint = _loginHint;
        }

        // Silently acquires an access token which is then attached to a request for MS Graph data
        msalInstance
            .acquireTokenSilent(_tokenRequest)
            .then((response : any) => {
                resolve(response);
            })
            .catch(error => {
                consoleLog('Acquire Token Failed: ', error);
                const e = error.error || error;

                // Acquire token silent failure, and send an interactive request
                if ( error instanceof InteractionRequiredAuthError  ||
                    error instanceof BrowserAuthError ||
                    e === "invalid_grant" ||
                    e?.code === 'InvalidAuthenticationToken' ||
                    e?.code?.search(/unauthorized/i) >= 0 ||
                    e?.code?.search(/authentication/i) >= 0
                ) {
                    consoleLog('Redirecting to get new token');
                    msalInstance.acquireTokenRedirect(_tokenRequest)
                        .then(res => consoleLog('New token acquired after redirect', res))
                        .catch(err => {
                            const errStr = err.toString();
                            consoleLog('Failed to get new token after redirect.', err);

                            // Don't do anything if another interaction is in progress
                            if ( errStr.search(/interaction_in_progress/i) >= 0 )
                                return;
                            consoleLog('Redirecting to logout screen. Left with no choice.');
                            goToLoggingOutScreen();
                        });
                    // Both lines are working, so I will stick with acquireTokenRedirect
                    // goToLoginScreen(msalInstance)
                    //     .then(null);
                }

                reject({message: error.toString()});
            });
    });
}

export function getMsLoginToken(
    msalInstance: IPublicClientApplication,
    msalAccounts: AccountInfo,
): Promise<IAuthToken> {
    return getLoginToken(msalInstance, msalAccounts, loginRequest);
}

export function getApiLoginToken(
    msalInstance: IPublicClientApplication,
    msalAccounts: AccountInfo,
): Promise<IAuthToken> {
    return getLoginToken(msalInstance, msalAccounts, tokenRequest);
}

export async function getAuthFromState( getState, dispatch? ) : Promise<any> {
    const auth = getState().auth;
    if ( auth ) {
        let { instance, accounts, msToken, gtToken } = auth;

        // has msToken expired
        const timeInFuture = getTimeInFuture({ minute: 10 });

        // Store already has tokens & it is expired
        if ( msToken?.expiresOn <= timeInFuture ) {
            msToken = await getMsLoginToken(instance, accounts);
            dispatch({type: AUTH_ACTIONS.SAVE_TOKEN_MS, msToken: msToken});
        }
        if ( gtToken?.expiresOn <= timeInFuture ) {
            gtToken = await getApiLoginToken(instance, accounts);
            dispatch({type: AUTH_ACTIONS.SAVE_TOKEN_GT, gtToken: gtToken});
        }

        return { instance, accounts, msToken, gtToken };
    }
    return null;
}

export function isUserDashboardDisabled(profile) : boolean {
    if ( !profile || profile.memberStatus === MemberStatus.Inactive || profile.memberStatus === MemberStatus.Blocked  )
        return true;

    return false;
}

export function goToLoginScreen(msalInstance: IPublicClientApplication, loginHint?: string | null): Promise<void> {
    consoleLog('Redirecting to Login Screen');

    const _loginRequest: any = { ...loginRequest };
    if ( loginHint != null && loginHint !== 'undefined' && loginHint !== '' )
        _loginRequest.loginHint = loginHint;

    return msalInstance?.loginRedirect(_loginRequest);
}

export function goToLoggingOutScreen() {
    consoleLog('Redirecting to LoggingOut Screen');
    window.location.href = constants.SCREEN_LOGGING_OUT;
}

