/**
 * Document view of search
 *
 */

/**
 * React
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { searchActionCreators } from '../Search.operations';

/**
 * Fabric UI
 */
import { getFileTypeIconProps } from '@uifabric/file-type-icons';
import { ActionButton } from '@fluentui/react/lib/Button';
import { Icon } from '@fluentui/react/lib/Icon';
import { Link } from '@fluentui/react/lib/Link';
import {
  MessageBar,
  MessageBarType
} from '@fluentui/react/lib/MessageBar';
import {
  ConstrainMode,
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode
} from '@fluentui/react/lib/DetailsList';

/**
 * Constants
 */
import * as constants from '../../../common/Constants';

/**
 * Utilities
 */
import { sortItems } from '../../../common/Utils';
import { isEqual } from 'lodash';

export class SearchDocuments extends React.Component<any, any> {
  constructor(props) {
    super(props);

    const columns = [
      {
        key: 'fileType',
        name: 'File type',
        fieldName: 'fileType',
        isResizable: true,
        minWidth: 80,
        maxWidth: 80,
        onColumnClick: this.onColumnClick
      },
      {
        key: 'title',
        name: 'Title',
        fieldName: 'title',
        isMultiline: true,
        isResizable: true,
        minWidth: 300,
        onColumnClick: this.onColumnClick
      },
      {
        key: 'dateModified',
        name: 'Date modified',
        fieldName: 'dateModified',
        isResizable: true,
        minWidth: 250,
        maxWidth: 300,
        onColumnClick: this.onColumnClick
      },
      {
        key: 'fileSize',
        name: 'File size',
        fieldName: 'fileSize',
        isResizable: true,
        minWidth: 250,
        maxWidth: 300,
        onColumnClick: this.onColumnClick
      }
    ];

    this.state = {
      items: [],
      columns,
      currPage: 0
    };
  }

  public render() {
    return (
      <div className='govTeams-search'>
        {this.state.items && this.state.items.length > 0 && !this.props.error && (
          <React.Fragment>
            <h2>
              Document search results: {this.props.filter}{' '}
              <span>({this.props.totalDocuments})</span>
            </h2>
            <DetailsList
              columns={this.state.columns}
              constrainMode={ConstrainMode.horizontalConstrained}
              items={this.state.items}
              layoutMode={DetailsListLayoutMode.justified}
              onRenderItemColumn={this.onRenderItem}
              selectionMode={SelectionMode.none}
            />
          </React.Fragment>
        )}

        {this.props.searchDocuments && this.props.searchDocuments.length === 0 && (
          <MessageBar messageBarType={MessageBarType.info} isMultiline={true}>
            There are no results for {this.props.filter} in documents
          </MessageBar>
        )}

        {this.props.error && (
          <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
            A <strong>{this.props.error.message}</strong> error was encountered
            due to <strong>{constants.ERROR_DOCUMENTS_FETCH}</strong>.
          </MessageBar>
        )}

        {this.props.searchDocuments && this.props.searchDocuments.length > 0 && (
          <div className='govTeams-searchPager'>
            {this.state.currPage > 0 && this.props.searchDocuments.length > 1 && (
              <ActionButton
                className='govTeams-searchPager--prev'
                onClick={this.onPrevious}
              >
                <Icon iconName='ChevronLeft' />
                Previous
              </ActionButton>
            )}

            {(this.props.totalDocuments > this.getCurrentTotal() ||
              this.props.searchDocuments.length - 1 > this.state.currPage) && (
              <ActionButton
                className='govTeams-searchPager--next'
                onClick={this.onNext}
              >
                Next
                <Icon iconName='ChevronRight' />
              </ActionButton>
            )}
          </div>
        )}
      </div>
    );
  }

  public componentDidMount() {
    if (this.props.searchDocuments) {
      const items = this.props.searchDocuments[this.props.currentPage];

      this.setState({
        items,
        currPage: 0
      });
    }
  }

  public componentDidUpdate(prevProps, prevState) {
    if (!isEqual(this.props.searchDocuments, prevProps.searchDocuments)) {
      if (this.props.searchDocuments) {
        const items = this.props.searchDocuments[this.props.currentPage];
        this.setState({
          items
        });
      }
    }
  }

  private onPrevious = (event) => {
    const page = this.state.currPage - 1;
    this.props.search.setCurrentPage(page);

    const items = this.props.searchDocuments[page];

    this.setState({
      items,
      currPage: page
    });
  };

  private onNext = (event) => {
    const page = this.state.currPage + 1;

    this.getCurrentTotal();

    if (this.props.currentPage + 1 === this.props.searchDocuments.length) {
      this.props.search.requestFetchSearchDocuments(
        this.props.filter,
        page,
        false
      );
    } else {
      this.props.search.setCurrentPage(page);
      const items = this.props.searchDocuments[page];

      this.setState({
        items
      });
    }

    this.setState({
      currPage: page
    });
  };

  private onRenderItem = (item, index, column) => {
    const fieldContent = item[column.fieldName];
    const key = column.key;

    switch (key) {
      case 'title':
        return (
          <strong>
            <Link href={item.path} target='_blank'>
              {fieldContent}
            </Link>
          </strong>
        );
      case 'fileSize':
        return item.fileSize.toFixed(2) + ' KB';
      case 'fileType':
        return this.onRenderFileType(fieldContent);
      case 'dateModified':
        const date = new Date(fieldContent);
        return date.toLocaleString('en-US');
      default:
        return fieldContent;
    }
  };

  private onColumnClick = (ev, column) => {
    const { columns, items } = this.state;

    let newItems = items.slice();
    const newColumns = columns.slice();

    const currColumn = newColumns.filter((currCol, idx) => {
      return column.key === currCol.key;
    })[0];

    newColumns.forEach((newCol) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = false;
      }
    });

    newItems = sortItems(
      newItems,
      currColumn.fieldName,
      currColumn.isSortedDescending
    );

    this.setState({
      columns: newColumns,
      items: newItems
    });
  };

  private onRenderFileType = (extension) => {
    return <Icon {...getFileTypeIconProps({ extension, size: 32 })} />;
  };

  private getCurrentTotal() {
    const pages = this.props.searchDocuments;
    let total = 0;

    pages.forEach((page) => {
      total += page.length;
    });

    return total;
  }
}

const mapStateToProps = (state) => ({
  filter: state.search.filter,
  searchDocuments: state.search.searchDocuments,
  error: state.search.error,
  totalDocuments: state.search.totalDocuments,
  currentPage: state.search.currentPage
});

const mapDispatchToProps = (dispatch) => ({
  search: bindActionCreators(searchActionCreators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchDocuments);
