import * as React from 'react';

/**
 * React Redux
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { profileActionCreators } from '../../controllers/ProfileController';

/**
 * Redux-form
 */
import {
    Field,
    reduxForm
} from 'redux-form';

/**
 * UI Fabric
 */
import {
    ActionButton,
    PrimaryButton,
    DefaultButton
} from '@fluentui/react/lib/Button';
import { Icon } from '@fluentui/react/lib/Icon';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { DirectionalHint } from '@fluentui/react/lib/Callout';

/**
 * UI Fabric customized
 */
import TextField from '../../components/ReduxFormFields/TextField';
import DepartmentPicker from '../../components/ReduxFormFields/DepartmentPicker';
import * as constants from '../../common/Constants';
import * as tooltips from '../../common/Tooltips';
import {
    renderLabel,
    TooltipLabel
} from '../../components/ReduxFormFields/FormFieldUtils';

/**
 * Typings
 */
import { MemberType } from '../../controllers/CommunityController';

const validate = (values) => {
    const errors: any = {};
    const regExName = /^(?=.*\S)[A-Za-z 0-9]([a-zA-Z'\-_ 0-9]{0,62})+[A-Za-z 0-9]+$/;
    const regExOccupation = /^(?=.*\S)[A-Za-z ]([a-zA-Z'\-_ 0-9]{0,39})+[A-Za-z 0-9]+$/;
    const regExLevel = /^(?=.*\S)[A-Za-z ]([a-zA-Z'\-_ 0-9]{0,18})+[A-Za-z 0-9]+$/;
    const regExEmployer = /^(?=.*\S)[A-Za-z ]([a-zA-Z'\-_ 0-9]{0,62})+[A-Za-z 0-9]+$/;
    const regexPhone = /^(?=(?:\D*\d\D*){8,14}$)[- \d()+]*/;

    if (values.firstName === '') {
        errors.firstName = 'First Name is required.';
    } else if (
        values.firstName !== undefined &&
        values.firstName &&
        values.firstName.length > 64
    ) {
        errors.firstName = 'First name cannot be more than 64 characters.';
    } else if (!regExName.test(values.firstName)) {
        errors.firstName =
            "First name must be a minimum of 2 characters long and can only have the following characters: A-Z a-z - _ ' 0-9 and spaces.  They must start and end with a letter or number.";
    }

    if (values.lastName === '') {
        errors.lastName = 'Last Name is required.';
    } else if (
        values.lastName !== undefined &&
        values.lastName &&
        values.lastName.length > 64
    ) {
        errors.lastName = 'Last name cannot be more than 64 characters.';
    }

    if (!regExName.test(values.lastName)) {
        errors.lastName =
            "Last name must be a minimum of 2 characters long and can only have the following characters: A-Z a-z - _ ' 0-9 and spaces.  They must start and end with a letter or number.";
    }

    if (
        values.employer !== undefined &&
        values.employer &&
        values.employer.length > 41
    ) {
        errors.employer = 'employer/JobTitle cannot be more than 41 characters.';
    } else if (values.employer === '' || !regExEmployer.test(values.employer)) {
        errors.employer =
            "Employer must be a minimum of 2 characters long and can only have the following characters: A-Z a-z - _ ' 0-9 and spaces.  Employer must start a with a letter.";
    }

    if (
        values.occupation !== undefined &&
        values.occupation &&
        values.occupation.length > 41
    ) {
        errors.occupation =
            'Occupation/JobTitle cannot be more than 41 characters.';
    } else if (
        values.occupation === '' ||
        !regExOccupation.test(values.occupation)
    ) {
        errors.occupation =
            "Occupation must be a minimum of 2 characters long and can only have the following characters: A-Z a-z - _ ' 0-9 and spaces.  Occupation must start with a letter.";
    }

    if (
        values.positionLevel !== undefined &&
        values.positionLevel &&
        values.positionLevel.length > 20
    ) {
        errors.positionLevel =
            'Level/classification cannot be more than 20 characters.';
    } else if (
        values.positionLevel !== '' &&
        !regExLevel.test(values.positionLevel)
    ) {
        errors.positionLevel =
            "Level must be a minimum of 2 characters long and can only have the following characters: A-Z a-z - _ ' 0-9 and spaces.  Level must start with a letter.";
    }

    if (!values.employer && !regExEmployer.test(values.employer)) {
        errors.employer =
            "Employer must be a minimum of 2 characters long and can only have the following characters: A-Z a-z - _ ' 0-9 and spaces.  Employer must start with a letter.";
    }

    if (values.phone !== '' && !regexPhone.test(values.phone)) {
        errors.phone = 'This does not appear to be a valid phone number.';
    }

    if (values.mobile !== '' && !regexPhone.test(values.mobile)) {
        errors.mobile = 'This does not appear to be a valid mobile number.';
    }

    if (
        (values.department && values.department.length === 0) ||
        !values.department ||
        (values.department[0] &&
            !values.department[0].id &&
            !values.department[0].name)
    ) {
        errors.department =
            'Australian Government department or agency is required';
    }

    if (values.memberType === 'Guest' &&
        (values.mobile === '' || values.mobile === null) && (values.phone === '' || values.phone === null))
    {
        errors.mobile = 'At least one phone number must be specified';
        errors.phone = 'At least one phone number must be specified';
    }

    return errors;
};

export class ProfileFormComponent extends React.Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            departmentOptions: []
        };
    }

    public render() {
        const { handleSubmit, pristine, submitting, invalid } = this.props;

        return (
            <div>
                <ActionButton
                    className='govTeams-actionButton'
                    onClick={this.onClose}
                    iconProps={{ iconName: 'ChevronLeft' }}
                >
                    {constants.LINK_BACKTODASHBOARD}
                </ActionButton>

                <h1>My {tooltips.BRANDNAME} account</h1>
                <hr />
                {this.props.profile.memberType === MemberType.StateLicensedAccount ||
                    this.props.profile.memberType === MemberType.LinkedGuestUser ? (
                    <div>
                        {constants.LINKEDACCOUNT_PROFILEMESSAGE}
                        <hr />
                        <TooltipHost
                            content={tooltips.PROFILE_PASSWORD}
                            directionalHint={DirectionalHint.rightTopEdge}
                        >
                            <ActionButton
                                className='govTeams-actionButton govTeams-actionButton-pw'
                                href='https://account.activedirectory.windowsazure.com/ChangePassword.aspx?BrandContextID=O365&ruO365='
                                target='_blank'
                                iconProps={{ iconName: 'Lock' }}
                            >
                                Change password <Icon iconName='Info' />
                            </ActionButton>
                        </TooltipHost>
                        <span> | </span>)
                        <TooltipHost
                            content={tooltips.PROFILE_DEACTIVATION}
                            directionalHint={DirectionalHint.rightTopEdge}
                        >
                            <ActionButton
                                className='govTeams-actionButton govTeams-actionButton-pw'
                                onClick={this.onDeactivateAccount}
                            >
                                Deactivate my account
                                <Icon iconName='Info' />
                            </ActionButton>
                        </TooltipHost>
                    </div>
                ) : (
                    <form onSubmit={handleSubmit} onKeyPress={this.onKeyPress}>
                        <div className='formFields'>
                            <h2>My details</h2>
                            {!this.props.profile.isGuest && (
                                <div className='formField'>
                                    <TooltipLabel
                                        ariaLabel={tooltips.PROFILE_USERNAME}
                                        required={false}
                                        label='Username'
                                    />
                                    <div className='formField-text readOnly'>
                                        {this.props.profile
                                            ? this.props.profile.userPrincipalName
                                            : ''}
                                    </div>
                                </div>
                            )}
                            <div className='formFields-col2'>
                                <div className='formField'>
                                    <Field
                                        name='firstName'
                                        component={TextField}
                                        label='First name'
                                        placeholder='Enter first name'
                                        maxLength={64}
                                        required={true}
                                    />
                                </div>
                                <div className='formField'>
                                    <Field
                                        name='lastName'
                                        component={TextField}
                                        label='Last name'
                                        placeholder='Enter last name'
                                        maxLength={64}
                                        required={true}
                                    />
                                </div>
                            </div>
                            {this.props.departments &&
                                !this.props.profile.isGuest &&
                                this.props.profile.memberType !== MemberType.Partner  &&
                                this.props.profile.memberType !== MemberType.LAM && (
                                    <div className='formField'>
                                        <Field
                                            name='department'
                                            component={DepartmentPicker}
                                            label='Australian Government departments or agency'
                                            required={true}
                                        />
                                    </div>
                                )}{' '}
                            {(this.props.profile.isGuest ||
                                this.props.profile.memberType === MemberType.Partner ||
                                this.props.profile.memberType === MemberType.LAM) && (
                                    <div className='formField'>
                                        <Field
                                            name='employer'
                                            component={TextField}
                                            label='Employer'
                                            maxLength={41}
                                            disabled={this.props.profile.memberType === MemberType.Partner}
                                            required={this.props.profile.memberType !== MemberType.Partner}
                                        />
                                    </div>
                            )}
                            <div className='formField'>
                                <Field
                                    name='occupation'
                                    component={TextField}
                                    label='Occupation/job title'
                                    maxLength={41}
                                    required={true}
                                />
                            </div>
                            {(this.props.profile.isGuest == null ||
                                !this.props.profile.isGuest) &&
                                this.props.profile.memberType !== MemberType.Partner &&
                                this.props.profile.memberType !== MemberType.LAM && (
                                    <div className='formField'>
                                        <Field
                                            name='positionLevel'
                                            component={TextField}
                                            label='Level/Classification'
                                            maxLength={20}
                                            disabled={this.props.profile.isGuest}
                                        />
                                    </div>
                                )}
                            {this.props.profile.isGuest && (
                                    <div>
                                        <h2>Contact details</h2>
                                        <div className='formFields-col2'>
                                            <div className='formField'>
                                                <Field
                                                    name='mobile'
                                                    component={TextField}
                                                    label='Mobile Phone'
                                                    placeholder='Enter mobile phone'
                                                    disabled={!this.props.profile.isGuest}
                                                    ariaLabel={tooltips.PROFILE_MOBILE}
                                                    onRenderLabel={renderLabel}
                                                    // eslint-disable-next-line no-restricted-globals
                                                    onBlur={onblur}
                                                />
                                            </div>
                                            <div className='formField'>
                                                <Field
                                                    name='phone'
                                                    component={TextField}
                                                    label='Office Phone'
                                                    placeholder='Enter office phone'
                                                    disabled={!this.props.profile.isGuest}
                                                    ariaLabel={tooltips.PROFILE_PHONE}
                                                    onRenderLabel={renderLabel}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                        </div>
                        <div className='formFooter'>
                            <div className='formFooter-left'>
                                <DefaultButton text='Cancel' onClick={this.onClose} />
                                <PrimaryButton
                                    text='Save'
                                    type='submit'
                                    disabled={pristine || submitting || invalid}
                                    onClick={handleSubmit(this.onSubmit)}
                                />
                            </div>
                            <div className='formFooter-right'>
                                <div className='icon-row mt-1'>
                                    <TooltipHost
                                        content={tooltips.PROFILE_PASSWORD}
                                        directionalHint={DirectionalHint.rightTopEdge}
                                    >
                                        <ActionButton
                                            className='govTeams-actionButton govTeams-actionButton-pw'
                                            href='https://account.activedirectory.windowsazure.com/ChangePassword.aspx?BrandContextID=O365&ruO365='
                                            target='_blank'
                                            iconProps={{ iconName: 'Lock' }}
                                        >
                                            Change password <Icon iconName='Info' />
                                        </ActionButton>
                                    </TooltipHost>
                                    {!this.props.profile.isGuest && <span> | </span>}

                                    {!this.props.profile.isGuest && (
                                        <TooltipHost
                                            content={tooltips.PROFILE_DEACTIVATION}
                                            directionalHint={DirectionalHint.rightTopEdge}
                                        >
                                            <ActionButton
                                                className='govTeams-actionButton govTeams-actionButton-pw'
                                                onClick={this.onDeactivateAccount}
                                            >
                                                Deactivate my account
                                                <Icon iconName='Info' />
                                            </ActionButton>
                                        </TooltipHost>
                                    )}
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </div>
        );
    }

    public componentDidMount() {
        this.props.profileActions.startEditProfile();
    }

    public componentDidUpdate(prevProps) {
        if (
            prevProps.profile.govTeamsProfileInfo !==
            this.props.profile.govTeamsProfileInfo
        ) {
            if ((this.props.profile.govTeamsProfileInfo.mobile === '' || this.props.profile.govTeamsProfileInfo.mobile === null) 
                && (this.props.profile.govTeamsProfileInfo.phone === '' || this.props.profile.govTeamsProfileInfo.phone === null)) 
            { 
                //We need to set these values for guests that don't have any phone numbers
                //otherwise the page validation will not work
                //NULLs seem to cause the validation to break
                //TODO: Fix the validation on this form properly!
                this.props.profile.govTeamsProfileInfo.mobile = '0400000000';
                this.props.profile.govTeamsProfileInfo.phone = '';
            };
            this.props.initialize(this.props.profile.govTeamsProfileInfo);
        }
    }

    private onKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    private onClose = (event) => {
        this.props.profileActions.endEditProfile();
        event.preventDefault();
        event.stopPropagation();
    };

    private onSubmit = (form) => {
        this.props.profileActions.requestSaveProfile();
    };

    private onDeactivateAccount = (event) => {
        this.props.profileActions.startDeactivationProfile();
    };
}

const mapStateToProps = (state) => ({
    profile: state.profile,
    departments: state.department.departments
});

const mapDispatchToProps = (dispatch) => ({
    profileActions: bindActionCreators(profileActionCreators, dispatch)
});

const ProfileForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileFormComponent);

export default reduxForm({
    form: 'ProfileForm',
    validate
})(ProfileForm);
