/**
 * Community creation form
 *
 */

/**
 * React Redux
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import {
    communityActionCreators
} from '../../controllers/CommunityController';

/**
 * Fabric UI
 */
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { Link } from '@fluentui/react/lib/Link';

/**
 * Govteams Library
 */
import CreateCommunityComponent from '@govteams/library/components/Community/CreateCommunityComponent/';

import CommunityPanel from '../../components/CommunityPanel/CommunityPanel';

/**
 * GovTEAMS components
 */
import { BRANDNAME } from '../../common/Tooltips';
import { isProtected } from '../../common/Utils';
import * as constantsForCommunityAction from '../../screens/CommunityManagement/components/CommunityActions/CommunityActions.constants';
import * as tooltips from '../../common/Tooltips';
import { GET_API_URL } from '../../auth/authConfig';
import {requestsActionCreators} from '../../controllers/RequestsController';
import {membersActionCreators} from '../../controllers/MembersController';
import {inviteMembersActionCreators} from '../../controllers/InviteMembersController';

class CommunityCreateComponent extends React.Component<any, any> {

    public handleSimilarCommunityClick = (c: any) => {
    };

    public render() {
        return (
            <div>
                <CreateCommunityComponent
                    brandName={BRANDNAME}
                    onClose={this.onClose}
                    handleLinkClick={this.handleLinkClick}
                    handleTnCAccepted={this.onCheckboxChange}
                    showSummary={this.showSummary()}
                    showTnCGTO={this.showTnCGTO()}
                    showTnCGTP={this.showTnCGTP()}
                    isProtected={isProtected()}
                    communityId={this.props.communityId}
                    isSubmitted={this.props.isSubmitted}

                    profile={this.props.profile}
                    isFormDirty={this.props.isFormDirty}
                    createError={this.props.createError}
                    similarCommunities={this.props.similarCommunities}
                    allMembers={this.props.allMembers}

                    findSimilarCommunities={this.props.communityActionCreators.requestSimilarCommunities}
                    setFormIsDirty={this.props.communityActionCreators.setFormIsDirty}
                    requestFetchCommunity={this.props.communityActionCreators.requestFetchCommunity}
                    requestSaveCommunity={this.props.communityActionCreators.requestSaveCommunity}
                    endEditCommunity={this.props.communityActionCreators.endEditCommunity}

                    onSimilarCommunityClick={this.handleSimilarCommunityClick}
                    tooltipSponsoredBadge={tooltips.SPONSORED_BADGE}
                    tagPickerResolveUrl={`${GET_API_URL()}/tags/`}
                    authToken={this.props.auth}

                    constants={constantsForCommunityAction}
                    communityActionCreators={this.props.communityActionCreators}
                    membersActionCreators={this.props.membersActionCreators}
                    inviteMembersActionCreators={this.props.inviteMembersActionCreators}
                    requestsActionCreators={this.props.requestsActionCreators}
                />
            </div>
        );
    }

    public async componentDidMount() {
        const communityId = this.props.match.params.communityId;

        if (this.props.isTnCAccepted || communityId) {
        }

        if (communityId) {
            await this.props.communityActionCreators.requestFetchCommunity(communityId, true);
        }
    }

    private onClose = (event) => {
        this.props.communityActionCreators.endEditCommunity(this.props.communityId);
        event.preventDefault();
        event.stopPropagation();
    };

    private onCheckboxChange = (ev, isChecked) => {
        this.props.communityActionCreators.acceptedTnCCommunity();
    };

    private handleLinkClick = (item) => {
    };

    private showTnCGTO = () => {
        return (
            <React.Fragment>
                <h1 id='title-TnC'>Terms and Conditions</h1>
                <p>
                    To create a community you must read and accept the responsibilities of
                    being a community owner.
                </p>
                <div className='form-textArea-container'>
                    <div className='form-textArea'>
                        <p>
                            By using {BRANDNAME} as a Community Owner, you agree to these Terms
                            and Conditions.
                        </p>
                        <p>
                            These Community Owner Terms and Conditions are in addition to the {BRANDNAME} Acceptable Use Conditions which apply to all {BRANDNAME} users, including Community Owners.
                        </p>
                        <p>Please read all these Terms and Conditions carefully.</p>

                        <h2>Introduction</h2>
                        <p>
                            {BRANDNAME} is developed and managed by the Department of Finance
                            (Finance).
                        </p>
                        <p>
                            You will become a Community Owner and become subject to these
                            Terms and Conditions if:
                        </p>

                        <ul>
                            <li>
                                you request the creation of a  {BRANDNAME} community and your
                                request is accepted by Finance; or
                            </li>
                            <li>you become the Community Owner of an existing community.</li>
                        </ul>

                        <h2>Responsibilities</h2>
                        <p>You are responsible for, and agree that you will:</p>
                        <ul>
                            <li>
                                <strong>create the appropriate type of community.</strong>{' '}
                                Familiarise yourself with the three community types (Open,
                                Private, Hidden) and ensure you select the appropriate community
                                for your needs.
                            </li>
                            <li>
                                <strong>assist new Community Members.</strong> Be prepared to
                                provide support to new members that you invite to your
                                community.{' '}
                            </li>
                            <li>
                                <strong>
                                    carefully consider who you invite to your community.
                                </strong>{' '}
                                Consider whether the members or guests you are inviting to your
                                community need to know the information in your community.{' '}
                            </li>
                            <li>
                                <strong>
                                    create valuable communities to collaborate with others.
                                </strong>{' '}
                                Think about whether you need to create a new community or you
                                can use an existing community.
                            </li>
                            <li>
                                <strong>build your community to its full potential.</strong>{' '}
                                Take the time to work on your community so you and the Community
                                Members get the most value out of collaborating.
                            </li>
                            <li>
                                <strong>keep adequate records</strong> and ensure that documents and relevant content are maintained
                                in an appropriate records management system. GovTEAMS OFFICIAL is not a records management system.
                                Records should be removed from GovTEAMS OFFICIAL and stored
                                in line with your agency's records management policy.
                            </li>
                            <li>
                                <strong>
                                    jointly, with other Community Owners, manage, amend, monitor, review and archive your community.
                                </strong>{' '}
                                This includes decommissioning your community where it is no longer active.
                                Communities which are inactive for extended periods of time may be closed and eventually deleted by GovTEAMS.
                            </li>
                            <li>
                                <strong>inform and assist members of your community.</strong>{' '}
                                You are responsible for authorising and assisting members with
                                accessing, disabling or changing existing members’ details for
                                your community.
                            </li>
                            <li>
                                <strong>
                                    ensure that members in your community act in accordance with
                                    the {BRANDNAME} Acceptable Use Conditions
                                </strong>
                            </li>
                            <li>
                                <strong>
                                    monitor Community Member behaviour and report breaches
                                </strong>{' '}
                                of APS Code of Conduct, inappropriate behaviour, and any event
                                where information above the OFFICIAL:Sensitive protective
                                marker has been uploaded to {BRANDNAME}.
                            </li>
                            <li>
                                <strong>report or remove content from your community</strong>{' '}
                                which in your reasonable opinion contravenes the Acceptable Use
                                conditions of {BRANDNAME}, and advising the Community Member of
                                reasons for removal. Finance is not responsible for proper
                                management of the content within your community.
                            </li>
                            <li>
                                <strong>report and remove Members from your community</strong>{' '}
                                who, in your reasonable opinion, are behaving inappropriately.
                            </li>
                            <li>
                                <strong>
                                    respond to and resolve FOI requests relevant to your agency
                                </strong>
                            </li>
                            <li>
                                <strong>use Community Member details</strong> (name, email
                                address, telephone numbers or other information) gained from
                                applications to join your community, or otherwise in connection
                                with your use of {BRANDNAME}, in accordance with Australian Privacy
                                Principles.
                            </li>
                            <li>
                                <strong>
                                    ensure that information published on your community
                                </strong>{' '}
                                is labelled and handled in accordance with the Australian
                                Government’s Protective Security Policy Framework (PSPF) and
                                does not exceed the security rating of OFFICIAL:Sensitive.
                            </li>
                            <li>
                                <strong>maintain an up-to-date backup of your site</strong> for
                                recovery from accidental deletion by you or your Community
                                Members.
                            </li>
                            <li>
                                <strong>
                                    ensure your community meets the following criteria to operate:
                                </strong>
                                <ul>
                                    <li>minimum of 2 active Community Owners; and</li>
                                    <li>at least one visit every 3 months.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>
                                    ensure Community Members can only access content and be
                                    involved in discussions on a need to know basis.
                                </strong>{' '}
                                This includes removing members that no longer require access to
                                the information held in the community.
                            </li>
                            <li>
                                <strong>
                                    review Community Member access when notified that member
                                    details have changed
                                </strong>
                                , such as, moved agency or left the APS.
                            </li>
                        </ul>

                        <p>
                            Nothing in this document removes or affects Finance's right to
                            suspend or remove Community Members or Community Owners from your
                            community, including you, or otherwise deal with, alter or amend {BRANDNAME} or its content.
                        </p>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    private showTnCGTP = () => {
        return (
            <React.Fragment>
                <h1 id='title-TnC'>Terms and Conditions</h1>
                <p>
                    To create a community you must read and accept the responsibilities of
                    being a community owner.
                </p>
                <div className='form-textArea-container'>
                    <div className='form-textArea'>
                        <p>
                            By using {BRANDNAME} as a Community Owner, you agree to these Terms
                            and Conditions.
                        </p>
                        <p>
                            These Community Owner Terms and Conditions are in addition to the {BRANDNAME} Acceptable Use Conditions which apply to all {BRANDNAME} users, including Community Owners.
                        </p>
                        <p>Please read all these Terms and Conditions carefully.</p>

                        <h2>Introduction</h2>
                        <p>
                            {BRANDNAME} is developed and managed by the Department of Finance
                            (Finance).
                        </p>
                        <p>
                            You will become a Community Owner and become subject to these
                            Terms and Conditions if:
                        </p>

                        <ul>
                            <li>
                                you request the creation of a  {BRANDNAME} community and your
                                request is accepted by Finance; or
                            </li>
                            <li>you become the Community Owner of an existing community.</li>
                        </ul>

                        <h2>Responsibilities</h2>
                        <p>You are responsible for, and agree that you will:</p>
                        <ul>
                            <li>
                                <strong>create the appropriate type of community.</strong>{' '}
                                Familiarise yourself with the two community types (Private and Hidden)  and ensure you select the appropriate community
                                for your needs.
                            </li>
                            <li>
                                <strong>assist new Community Members.</strong> Be prepared to
                                provide support to new members that you invite to your
                                community.{' '}
                            </li>
                            <li>
                                <strong>
                                    carefully consider who you invite to your community.
                                </strong>{' '}
                                Consider whether the members or guests you are inviting to your
                                community need to know the information in your community.{' '}
                            </li>
                            <li>
                                <strong>
                                    create valuable communities to collaborate with others.
                                </strong>{' '}
                                Think about whether you need to create a new community or you
                                can use an existing community.
                            </li>
                            <li>
                                <strong>build your community to its full potential.</strong>{' '}
                                Take the time to work on your community so you and the Community
                                Members get the most value out of collaborating.
                            </li>
                            <li>
                                <strong>
                                    jointly, with other Community Owners, manage, amend, monitor,
                                    review and archive your community.
                                </strong>{' '}
                                This includes decommissioning your community where it is no
                                longer active. Finance is not responsible for the operation or
                                decommissioning of your community.
                            </li>
                            <li>
                                <strong>inform and assist members of your community.</strong>{' '}
                                You are responsible for authorising and assisting members with
                                accessing, disabling or changing existing members’ details for
                                your community.
                            </li>
                            <li>
                                <strong>
                                    ensure that members in your community act in accordance with
                                    the {BRANDNAME} Acceptable Use Conditions
                                </strong>
                            </li>
                            <li>
                                <strong>
                                    monitor Community Member behaviour and report breaches
                                </strong>{' '}
                                of APS Code of Conduct, inappropriate behaviour, and any event
                                where information above the PROTECTED classification has
                                been uploaded to {BRANDNAME}.
                            </li>
                            <li>
                                <strong>report or remove content from your community</strong>{' '}
                                which in your reasonable opinion contravenes the Acceptable Use
                                conditions of {BRANDNAME}, and advising the Community Member of
                                reasons for removal. Finance is not responsible for proper
                                management of the content within your community.
                            </li>
                            <li>
                                <strong>report and remove Members from your community</strong>{' '}
                                who, in your reasonable opinion, are behaving inappropriately.
                            </li>
                            <li>
                                <strong>
                                    respond to and resolve FOI requests relevant to your agency
                                </strong>
                            </li>
                            <li>
                                <strong>keep adequate records</strong> and ensure that documents
                                and relevant content are maintained in an appropriate records
                                management system.
                            </li>
                            <li>
                                <strong>use Community Member details</strong> (name, email
                                address, telephone numbers or other information) gained from
                                applications to join your community, or otherwise in connection
                                with your use of {BRANDNAME}, in accordance with Australian Privacy
                                Principles.
                            </li>
                            <li>
                                <strong>
                                    ensure that information published on your community
                                </strong>{' '}
                                is labelled and handled in accordance with the Australian
                                Government’s Protective Security Policy Framework (PSPF) and
                                does not exceed the PROTECTED security classification.
                            </li>
                            <li>
                                <strong>maintain an up-to-date backup of your site</strong> for
                                recovery from accidental deletion by you or your Community
                                Members.
                            </li>
                            <li>
                                <strong>
                                    ensure your community meets the following criteria to operate:
                                </strong>
                                <ul>
                                    <li>minimum of 2 active Community Owners; and</li>
                                    <li>at least one visit every 3 months.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>
                                    ensure Community Members can only access content and be
                                    involved in discussions on a need to know basis.
                                </strong>{' '}
                                This includes removing members that no longer require access to
                                the information held in the community.
                            </li>
                            <li>
                                <strong>
                                    review Community Member access when notified that member
                                    details have changed
                                </strong>
                                , such as, moved agency or left the APS.
                            </li>
                        </ul>

                        <p>
                            Nothing in this document removes or affects Finance's right to
                            suspend or remove Community Members or Community Owners from your
                            community, including you, or otherwise deal with, alter or amend {BRANDNAME} or its content.
                        </p>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    private showSummary = () => {
        return (
            <React.Fragment>
                <div className='communitySummary'>
                    <h1>You're done!</h1>
                    <p>
                        We’re in the process of creating your community – this will take
                        about an hour. You'll receive an email when it's ready to use.
                    </p>

                    <h3>Learn about communities</h3>
                    <p>
                        Not sure where to start? We're here to help! We have guides for
                        getting started, tips, tricks and more at{' '}
                        <Link href='https://www.govteams.gov.au/' target='_blank'>
                            govteams.gov.au
                        </Link>
                    </p>

                    <PrimaryButton text='Ok, got it!' onClick={this.onClose} />
                </div>
            </React.Fragment>
        );
    };
}

const mapStateToProps = (state) => ({
    createError: state.community.error,
    communityId: state.community.communityId,
    isTnCAccepted: state.community.isTnCAccepted,
    initialValues: state.community.initialValues,
    isSubmitted: state.community.isSubmitted,
    isFormDirty: state.community.isFormDirty,
    communityFormState: state.form.CommunityForm,
    searchCommunities: state.search.searchCommunities,
    similarCommunities: state.community.similarCommunities,
    allMembers: state.members.allMembers,
    profile: state.profile,
    auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
    communityActionCreators: bindActionCreators(communityActionCreators, dispatch),
    requestsActionCreators: bindActionCreators(requestsActionCreators, dispatch),
    inviteMembersActionCreators: bindActionCreators(inviteMembersActionCreators, dispatch),
    membersActionCreators: bindActionCreators(membersActionCreators, dispatch),
    dispatch
});

export const CommunityCreate = connect(
    mapStateToProps,
    mapDispatchToProps
)(CommunityCreateComponent);

export default CommunityCreate;
