/**
 *  Waffle panel which emulates the Office 365 waffle
 */

/**
 * React Redux
 */
import * as React from 'react';
import { connect } from 'react-redux';

/**
 * Fabric UI
 */
import { DefaultButton, IconButton } from '@fluentui/react/lib/Button';
import { Icon } from '@fluentui/react/lib/Icon';
import { Link } from '@fluentui/react/lib/Link';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';

/**
 * Constants and utilities
 */
import * as constants from '../../common/Constants';
import { saveDashboardInteractionEvent } from '../../common/EventTracking';

const Waffle = (props: any) => {
  const [isWaffleMenuVisible, setIsWaffleMenuVisible] = React.useState<boolean>(false);
  const { profile } = props;

  const showWaffleMenu = (): void => {
    if (profile.memberStatus !== 'Inactive') {
      setIsWaffleMenuVisible(true);
    }
  };

  const panelDismiss = (): void => {
    setIsWaffleMenuVisible(false);
  };

  const getOneNoteLink = (): string => {
    if (profile.userPrincipalName) {
      return `https://www.onenote.com/notebooks?auth=2&auth_upn=${profile.userPrincipalName}&wdorigin=applauncher`;
    } else {
      return 'https://www.onenote.com/';
    }
  };

  /**
   * Log event in GA
   * @param name
   */
  const _trackEvent = (name: string) => {
    saveDashboardInteractionEvent( 'header_waffle', name);
  };

  return (
    <div className='govTeams-waffle'>
      <IconButton
        iconProps={{ iconName: 'Waffle' }}
        title='Open the app launcher to access Office 365 apps'
        ariaLabel='App launcher'
        onClick={showWaffleMenu}
        className='waffle-button'
      />
      <Panel
        className='govTeams-waffle-panel'
        isOpen={isWaffleMenuVisible}
        onDismiss={panelDismiss}
        hasCloseButton={false}
        isBlocking={true}
        isLightDismiss={true}
        type={PanelType.customNear}
        customWidth={'320px'}
      >
        <div className='panel-waffle'>
          <div className='panel-header'>
            <IconButton
              iconProps={{ iconName: 'Waffle' }}
              title='Open the app launcher to access Office 365 apps'
              ariaLabel='App launcher'
              onClick={panelDismiss}
              className='waffle-button'
            />
            {!profile.isGuest && (
              <Link
                href={constants.WAFFLE_O365}
                className='link-o365'
                target='_blank'
                onClick={() => _trackEvent('Office 365')}
              >
                Office 365 <Icon iconName='Forward' />
              </Link>
            )}
          </div>
          <div className='panel-content'>
            <h2>Apps</h2>
            <ul>
              <li>
                <DefaultButton
                  href={constants.WAFFLE_TEAMS}
                  target='_blank'
                  text='Teams'
                  iconProps={{ iconName: 'TeamsLogo' }}
                  title='Teams'
                  ariaLabel='Launch Teams'
                  className='app-button app-button-teams'
                  onClick={() => _trackEvent('Teams')}
                />
              </li>
              {/* {!profile.isGuest && (
                  <li>
                    <DefaultButton
                      href={constants.WAFFLE_O365}
                      target="_blank"
                      text="Office 365"
                      iconProps={{ iconName: "OfficeLogo" }}
                      title="Office 365"
                      ariaLabel="Launch Office 365"
                      className="app-button"
                    />
                  </li>
                )} */}
              {!profile.isGuest && (
                <li>
                  <DefaultButton
                    href={constants.WAFFLE_DELVE}
                    target='_blank'
                    text='Delve'
                    iconProps={{ iconName: 'DelveLogo' }}
                    title='Delve'
                    ariaLabel='Launch Delve'
                    className='app-button app-button-delve'
                    onClick={() => _trackEvent('Delve')}
                  />
                </li>
              )}
              <li>
                <DefaultButton
                  href={constants.WAFFLE_SHAREPOINT}
                  target='_blank'
                  text='Sharepoint'
                  iconProps={{ iconName: 'SharePointLogo' }}
                  title='SharePoint'
                  ariaLabel='Launch SharePoint'
                  className='app-button app-button-sharepoint'
                  onClick={() => _trackEvent('SharePoint')}
                />
              </li>
              <li>
                <DefaultButton
                  href={constants.WAFFLE_PLANNER}
                  target='_blank'
                  text='Planner'
                  iconProps={{ iconName: 'PlannerLogo' }}
                  title='Planner'
                  ariaLabel='Launch Planner'
                  className='app-button app-button-planner'
                  onClick={() => _trackEvent('Planner')}
                />
              </li>
              {!profile.isGuest && (
                <li>
                  <DefaultButton
                    href={constants.WAFFLE_OUTLOOK}
                    target='_blank'
                    text='Outlook'
                    iconProps={{ iconName: 'OutlookLogo' }}
                    title='Outlook'
                    ariaLabel='Launch Outlook'
                    className='app-button app-button-outlook'
                    onClick={() => _trackEvent('Outlook')}
                  />
                </li>
              )}
              {!profile.isGuest && (
                <li>
                  <DefaultButton
                    href={constants.WAFFLE_CALENDAR}
                    target='_blank'
                    text='Calendar'
                    iconProps={{ iconName: 'Calendar' }}
                    title='Calendar'
                    ariaLabel='Launch Calendar'
                    className='app-button app-button-calendar'
                    onClick={() => _trackEvent('Calendar')}
                  />
                </li>
              )}
              {!profile.isGuest && (
                <li>
                  <DefaultButton
                    href={constants.WAFFLE_ONEDRIVE}
                    target='_blank'
                    text='OneDrive'
                    iconProps={{ iconName: 'OneDrive' }}
                    title='OneDrive'
                    ariaLabel='Launch OneDrive'
                    className='app-button app-button-onedrive'
                    onClick={() => _trackEvent('OneDrive')}
                  />
                </li>
              )}
              <li>
                <DefaultButton
                  href={getOneNoteLink()}
                  target='_blank'
                  text='OneNote'
                  iconProps={{ iconName: 'OneNoteLogo' }}
                  title='OneNote'
                  ariaLabel='Launch OneNote'
                  className='app-button app-button-onenote'
                  onClick={() => _trackEvent('OneNote')}
                />
              </li>
            </ul>
            {!profile.isGuest && (
              <Link
                href={constants.WAFFLE_ALLAPPS}
                className='link-apps'
                target='_blank'
                onClick={() => _trackEvent('All Apps')}
              >
                All apps <Icon iconName='Forward' />
              </Link>
            )}
          </div>
        </div>
      </Panel>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  profile: state.profile
});

export default connect(mapStateToProps)(Waffle);
