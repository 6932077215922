import * as React from 'react';
import { connect } from 'react-redux';
import SupportButton from '../../components/SupportButton';

const ConfirmationError = () => (
  <div className='my-2'>
    <h2 className="heading-error">Error</h2>
    <p>
      Something has gone when confirming your account. This could be due to the
      following reasons:
    </p>
    <ul>
      <li>The confirmation code you entered is incorrect.</li>
      <li>You have already registered with this email address.</li>
      <li>There was a system error.</li>
    </ul>
    <p>
      If you believe that there has been a system error, please contact <SupportButton />
    </p>
  </div>
);

export default connect()(ConfirmationError);
