/**
 * Display member information from Delve
 *
 */

/**
 * React
 */
import * as React from 'react';
import renderHTML from 'react-render-html';

/**
 * Redux
 */
import { connect } from 'react-redux';

/**
 * UI Fabric
 */
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { Icon } from '@fluentui/react/lib/Icon';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { DirectionalHint } from '@fluentui/react/lib/Callout';

/**
 * Typings
 */
import { MemberType } from '../../controllers/CommunityController';

/**
 * Constants and utilities
 */
import * as constants from '../../common/Constants';
import * as tooltips from '../../common/Tooltips';

export interface IDelveProps {
  profile: any;
}

export class Delve extends React.Component<IDelveProps, any> {
  public render() {
    const { profile } = this.props;

    return (
      <div>
        {!profile.isGuest &&
          profile.memberType !== MemberType.StateLicensedAccount &&
          profile.memberType !== MemberType.LinkedGuestUser && (
            <div className='govTeams-delve'>
              <hr />
              <TooltipHost
                content={tooltips.PROFILE_DELVE}
                directionalHint={DirectionalHint.rightTopEdge}
              >
                <h2 className='heading-tooltip'>
                  My Delve profile
                  <Icon iconName='Info' className='heading-tooltip-icon' />
                </h2>
              </TooltipHost>
              <div>
                <div className='govTeams-delve-section'>
                  <h3>About me</h3>
                  <p>
                    {renderHTML(
                      profile.aboutMe !== ''
                        ? profile.aboutMe
                        : constants.ERROR_PROFILE_EMPTYFIELD_ABOUT
                    )}
                  </p>
                </div>

                <div className='govTeams-delve-section'>
                  <h3>Projects</h3>
                  <p>
                    {profile.projects !== ''
                      ? profile.projects
                      : constants.ERROR_PROFILE_EMPTYFIELD_PROJECTS}
                  </p>
                </div>

                <div className='govTeams-delve-section'>
                  <h3>Skills</h3>
                  <p>
                    {profile.skills !== ''
                      ? profile.skills
                      : constants.ERROR_PROFILE_EMPTYFIELD_SKILLS}
                  </p>
                </div>

                <div className='govTeams-delve-section'>
                  <h3>Expertise</h3>
                  <p>
                    {profile.topics !== ''
                      ? profile.topics
                      : constants.ERROR_PROFILE_EMPTYFIELD_TOPICS}
                  </p>
                </div>

                <div className='govTeams-delve-section'>
                  <h3>Schools and Education</h3>
                  <p>
                    {profile.schools !== ''
                      ? profile.schools
                      : constants.ERROR_PROFILE_EMPTYFIELD_SCHOOLS}
                  </p>
                </div>

                <div className='govTeams-delve-section'>
                  <h3>Interests and Hobbies</h3>
                  <p>
                    {profile.interests !== ''
                      ? profile.interests
                      : constants.ERROR_PROFILE_EMPTYFIELD_INTERESTS}
                  </p>
                </div>
              </div>

              <div className='formFooter'>
                <TooltipHost
                  content={tooltips.PROFILE_DELVE_UPDATE}
                  directionalHint={DirectionalHint.rightTopEdge}
                >
                  <PrimaryButton
                    className='govTeams-actionButtonAlt'
                    href={
                      'https://aus.delve.office.com/?u=' +
                      profile.id +
                      '&v=editprofile'
                    }
                    iconProps={{ iconName: 'ChevronRightSmall' }}
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    Update Delve profile
                  </PrimaryButton>
                </TooltipHost>
              </div>
            </div>
          )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.profile
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Delve);
