/**
 * App banner. Emulates Office 365 Suitebar
 *
 */

/**
 * React Redux
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
/**
 * Fabric UI
 */
import { IconButton } from '@fluentui/react/lib/Button';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';

/**
 * GovTEAMS components
 */
import DisplayUser from './ProfilePanel';
import HelpPanel from './HelpPanel';
import OrganisationPanel from './OrganisationPanel';
import NotificationPanel from '../Notification/NotificationPanel';
import SearchBoxAlt from '../ReduxFormFields/SearchBoxAlt';
import Waffle from './Waffle';

/**
 * Constants and utilities
 */
import * as constants from '../../common/Constants';
import MemberPreferences from '../MemberPreferences/MemberPreferences';
import {useEffect} from 'react';
import {saveUserLoggedInEvent} from '../../common/EventTracking';

const Header = (props: any) => {
  const [showNavRight, setShowNavRight] = React.useState<boolean>(false);
  const [showNavSearch, setShowNavSearch] = React.useState<boolean>(false);
  const { profile, location } = props;
  const pathname: string = location?.pathname;

  /**
   * Set user session
   */
  useEffect(() => {
    if ( profile?.azureMail )
      saveUserLoggedInEvent(profile.azureMail, profile);
  }, [profile?.azureMail]);

  const onClickNavRight = () => {
    setShowNavRight(prev => !prev);
  };

  const onClickNavSearch = () => {
    setShowNavSearch(prev => !prev);
  };

  const onDismissSearch = () => {
    setShowNavSearch(false);
  };

  const getTitle = (pathname) => {
    const paths = pathname.split('/');

    switch (paths[1]) {
      case 'Organisation':
        return 'Organisation management';
      case 'Community':
        return 'Community management';
      case 'Profile':
        return 'Profile';
      case 'Search':
        return 'Search';
      case 'Reactivate':
        return 'Reactivate';
      case 'LoggingOut':
        return 'Logging Out';
      case 'Logout':
        return 'Logout';
      default:
        return 'Dashboard';
    }
  }

  return (
    <div className='govTeams-suitebar'>
      <div className={`govTeams-suitebar-leftAlign`}>
        <div className={'navLeft'}>
          <Waffle />
          <a
              href={constants.SCREEN_DASHBOARD}
              className={`govTeams-iconfont-logo ${
                  showNavRight ? 'hideNav' : ''
              }`}
          >
            <div className={'govTeams-logo'} />
          </a>
          <span className='subtitle'>{getTitle(pathname)}</span>
        </div>
      </div>
      <div className='govTeams-suitebar-centerAlign'>
        <div className='govTeams-search'>
          {pathname.indexOf('/Search') === -1 &&
            pathname.indexOf('/ThankyouForRegistering') &&
            pathname.indexOf('/Reactivate') && <SearchBoxAlt />}
        </div>
      </div>
      <div className='govTeams-suitebar-rightAlign'>
        <span
          className={`navRight ${showNavRight ? 'showNav' : ''}`}
        >
          {pathname.indexOf('/Search') === -1 &&
            pathname.indexOf('/ThankyouForRegistering') && (
              <div className='navRight-search'>
                <IconButton
                  iconProps={{
                    iconName: 'Search'
                  }}
                  onClick={onClickNavSearch}
                />
                <Panel
                  className='govTeams-panel'
                  isOpen={showNavSearch}
                  onDismiss={onDismissSearch}
                  isBlocking={true}
                  isLightDismiss={true}
                  type={PanelType.custom}
                  customWidth='400px'
                >
                  <SearchBoxAlt />
                </Panel>
              </div>
            )}
          {profile && (profile.id || profile.mail) && (
            <React.Fragment>
              <HelpPanel />
              <NotificationPanel />
              <OrganisationPanel />
              <MemberPreferences />
            </React.Fragment>
          )}
          <DisplayUser />
        </span>
        <IconButton
          iconProps={{
            iconName: !showNavRight ? 'GlobalNavButton' : 'Clear'
          }}
          className={`navButton ${showNavRight ? 'navClose' : ''}`}
          onClick={onClickNavRight}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  profile: state.profile
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatch
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
