/**
 * Dialog to display message to user that has had their licence temporarily removed
 *
 */

/**
 * React Redux
 */
import * as React from 'react';
import { connect } from 'react-redux';

/**
 * Fabric UI
 */
import { Dialog, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { profileActionCreators } from '../../../../controllers/ProfileController';
import { bindActionCreators } from 'redux';
import { BRANDNAME } from '../../../../common/Tooltips';

const LicenceRestoredComponent = props => {
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const { profile, isGuest, profileActionCreators } = props;

  const _onDismiss = (_event) => {
    setShowModal(false);
  };

  /**
   * Monitor changes to the license temp flag
   */
  React.useEffect(() => {
    if (profile?.licenceTempRemovedDisplayModal) {
      console.log("LicenceTempRemovedDisplayModal modal is true");
      setShowModal(true);

      if (!isGuest) {
        profileActionCreators.requestProfileRestoreLicence();
      }
    }
  }, [profile, isGuest]);

  /**
   * Render
   */
  return (
    <div>
      {showModal && (
        <div>
          <div>
            <Dialog
                dialogContentProps={{ showCloseButton: true }}
                modalProps={{
                  isBlocking: true,
                  isDarkOverlay: true,
                  className: 'govTeams-dialog govTeams-onboarding'
                }}
                hidden={!showModal}
                onDismiss={_onDismiss}
            >
              <div className='dialogSection px-2'>
                <div className='dialogSection-text'>
                  <h2>Your account is ready to be used again.</h2>
                  <p>Your {BRANDNAME} account was dormant because you had not logged in for a while.</p>
                  <p>We have now made your account active and it is ready for use.</p>
                  <p>You don’t have to do anything else to continue using {BRANDNAME}.</p>
                </div>
              </div>
              <DialogFooter>
                <div className='dialogFooter-top'>
                  <PrimaryButton
                      onClick={_onDismiss}
                      className='my-2'
                      styles={{ root: { height: 42 } }}
                  >
                    OK
                  </PrimaryButton>
                </div>
              </DialogFooter>
            </Dialog>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  profile: state.profile,
  isGuest: state.profile.isGuest
});

const mapDispatchToProps = (dispatch: any) => ({
  profileActionCreators: bindActionCreators(profileActionCreators, dispatch)
});

const LicenceRestored = connect(mapStateToProps, mapDispatchToProps)(LicenceRestoredComponent);
export default LicenceRestored;
