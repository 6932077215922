import * as React from 'react';
import { Toggle as UIFabricToggle } from '@fluentui/react/lib/Toggle';

const Toggle = ({
  input,
  meta: { touched, error },
  ...rest
}: {
  input: any;
  meta: any;
}) => (
  <UIFabricToggle
    errorMessage={touched ? error : null}
    onChanged={(checked) => {
      input.onChange(checked);
    }}
    {...input}
    {...rest}
  />
);

export default Toggle;
