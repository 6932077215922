import * as React from 'react';
import { connect } from 'react-redux';
import { BRANDNAME } from '../../common/Tooltips';

const Error_Activation = props => (
  <div>
    <h2 className="heading-alert">
      You’ve already started the {BRANDNAME} registration process.
    </h2>
    <p>
      It looks like you have already started the process to register for a
      {BRANDNAME} account. You should have previously received an email with your
      username and a link to activate your account. Please click on the link in
      this email to complete your registration.
    </p>
    <p>
      If you are unable to find your activation email or are still having issues
      registering for an account, you can contact the{' '}
      <a href={props.mailTo}>{BRANDNAME} support</a> helpdesk and quote the
      following error ID:<strong> {props.correlationId} </strong>
    </p>
  </div>
);

export default connect()(Error_Activation);
