import actions from './Announcements.actions';
import {getAuthFromState} from '../../auth/authHandler';
import {httpServiceSecureGet} from '../../common/Http';

export const announcementsActionCreators = {
  requestAnnouncements: () => async (dispatch, getState) => {
    if (
      getState().announcements.isLoading ||
      getState().announcements.hasLoaded
    ) {
      return;
    }

    dispatch({ type: actions.ANNOUNCEMENT_FETCH_REQUEST });

    try {
      const { gtToken } = await getAuthFromState(getState, dispatch);
      const announcements: any = await httpServiceSecureGet('/Announcements', gtToken);
      const announcementList: any[] = [];

      if (announcements) {
        try {
          // Announcements come as strange JSON from backend.
          const data = JSON.parse(announcements);

          // If there are results in annoucements
          if (data.d !== null && data.d.results !== null) {
            data.d.results.forEach((announcement) => {
              announcementList.push(announcement);
            });
          }
        } catch (err) {
          return;
        } finally {
          dispatch({
            type: actions.ANNOUNCEMENT_FETCH_RESPONSE,
            announcementList
          });
        }
      }
    } catch (error) {
      dispatch({
        type: actions.ANNOUNCEMENT_FETCH_RESPONSE,
        announcementList: []
      });
    }
  },

  hideAnnouncement: (id) => (dispatch, getState) => {
    const newAnnouncementsToHide = JSON.parse(
      JSON.stringify(getState().announcements.announcementToHide)
    );
    newAnnouncementsToHide[id] = true;

    const newAnnouncementList = JSON.parse(
      JSON.stringify(getState().announcements.announcementList)
    );

    dispatch({
      type: actions.ANNOUNCEMENT_HIDE,
      announcementToHide: newAnnouncementsToHide,
      announcementList: newAnnouncementList
    });
  }
};

export default announcementsActionCreators;
