import * as React from 'react';
import { Dropdown as UIFabricDropdown } from '@fluentui/react/lib/Dropdown';

const Dropdown = ({
  input,
  meta: { touched, error },
  ...rest
}: {
  input: any;
  meta: any;
}) => (
  <UIFabricDropdown
    errorMessage={touched ? error : null}
    onChanged={item => {
      input.onChange(item.key);
    }}
    selectedKey={input.value}
    {...input}
    {...rest}
  />
);

export default Dropdown;
