import React, {useEffect, useState} from 'react';
import { useMsal } from '@azure/msal-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { authActionCreators } from '../controllers/AuthController';

const AuthLayoutComponent = props => {
    const [isLoading, setIsLoading] = useState(true);
    const { instance, accounts } = useMsal();

    /**
     * Monitor changes to instance, accounts and actions
     */
    useEffect(() => {
        console.log('Authentication module v1.0.1')
        // Handle the redirect flows
        instance
            .handleRedirectPromise()
            .then((tokenResponse) => {
                // Handle redirect response
                // GT token
                if ( tokenResponse?.scopes?.includes('user_impersonation')) {
                    props.authActionCreators.saveGtToken(tokenResponse);
                }
                else if ( tokenResponse?.scopes?.includes('profile')) {
                    props.authActionCreators.saveMsToken(tokenResponse);
                }
            })
            .catch((error) => {
                // Handle redirect error
                console.log('Error occurred after redirect: ', error);
            });

        // Save instance and account in store
        if ( instance && accounts ) {
            props.authActionCreators.saveAuthInstance(instance, accounts);
            setTimeout(() => setIsLoading(false), 250);
        }
    }, [instance, accounts, props.authActionCreators]);

    return (
        <div className='secure-app'>
            {isLoading &&
                <div className='ms-fontColor-gray100 mx-2'>Loading...</div>}
            {!isLoading &&
                <>{props.children}</>}
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
    authActionCreators: bindActionCreators(authActionCreators, dispatch),
});

export const AuthLayoutContent = connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthLayoutComponent);

export default AuthLayoutContent;
