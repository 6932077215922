import md5 from 'crypto-js/md5';
import {saveGACustomEvent, saveGAEvent, saveGAPageView, setGAUserId} from './GoogleAnalytics';
import AzureInsights from './AzureInsights';

const serverUrl = `${window.location.origin}`;

// Cant include this function from Util then get errors becz of circular dependency
const consoleLog = (...args) => {
  if ( process.env.REACT_APP_ENV === "development" )
    console.log(...args);
};

const saveAIEvent = (eventName: string, actionName: string, category: string, label: string, params: any = {}) => {
  const azureInsightsAgain = AzureInsights.getInstance();
  azureInsightsAgain.trackEvent(eventName, actionName, category, label, params);
};

const saveAIUser = (userName: string, user: any) => {
  const azureInsightsAgain = AzureInsights.getInstance();
  azureInsightsAgain.trackUser(userName, user.memberType || '');
};

export const saveUserLoggedInEvent = (userId: string, user: any) => {
  if ( userId ) {
    const hashedUser = md5(userId).toString();
    consoleLog('GA user set: ', hashedUser);
    setGAUserId(hashedUser, user);
    saveAIUser(hashedUser, user);
    saveAIEvent('login', user.memberType?.toLowerCase().replaceAll(' ', '_'), 'login', hashedUser);
  }
};

export const savePageViewEvent = (location: any) => {
  if ( location === undefined ) {
    consoleLog('Page view not logged. Location NA: ', location);
    return;
  }

  let uri = `${serverUrl}/`;
  if ( location !== {} ) {
    const path = location.search ? `/${location.search}`:'';
    uri = `${serverUrl}${location.pathname}${path}`;
  }
  saveGAPageView(uri);
};

export const saveCommunityManageEvent = (actionName: string, label: string, params: any = {}) => {
  if ( !actionName ) {
    consoleLog('Custom community action event not sent. Action name is empty', actionName);
    return;
  }
  saveGACustomEvent('community_manage', actionName, 'community', label, params);
  saveAIEvent('community_manage', actionName, 'community', label, params);
};

export const saveDashboardInteractionEvent = (actionName: string, label: string, params: any = {}) => {
  if ( !actionName ) {
    consoleLog('Custom dashboard event not sent. Action name is empty', actionName);
    return;
  }
  saveGACustomEvent('dashboard_interaction', actionName, 'interaction', label, params);
  saveAIEvent('dashboard_interaction', actionName, 'interaction', label, params);
};

export const saveSearchEvent = (search: string) => {
  saveGAEvent('search', {
    search_term: search,
  });
  saveGACustomEvent('dashboard_interaction', 'search', 'interaction', search);
  saveAIEvent('dashboard_interaction', 'search', 'interaction', search);
};

export const saveInteractionWithCommunityEvent = (actionName: string, label: string, params: any = {}) => {
  saveGACustomEvent('community_interaction', actionName, 'community', label, params);
  saveAIEvent('community_interaction', actionName, 'community', label, params);
};