import * as React from 'react';

/**
 * Redux
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { requestsActionCreators } from '../../controllers/RequestsController';

/**
 * Fabric UI
 */
import { Dialog } from '@fluentui/react/lib/Dialog';
import { TextField } from '@fluentui/react/lib/TextField';
import {
  PrimaryButton,
  DefaultButton,
} from '@fluentui/react/lib/Button';

export interface IJoinRequestProps {
  isOpen: boolean;
  requestsActions: any;
}

export interface IJoinRequestState {
  isSubmitDisabled: boolean;
}

export class JoinRequest extends React.Component<
  IJoinRequestProps,
  IJoinRequestState
> {
  private _textField;

  constructor(props) {
    super(props);

    this.state = {
      isSubmitDisabled: true,
    };
  }

  public render() {
    return (
      <Dialog
        hidden={!this.props.isOpen}
        onDismiss={this.onDismiss}
        dialogContentProps={{
          showCloseButton: true,
          title: 'Request to join community',
        }}
        modalProps={{
          isBlocking: true,
          isDarkOverlay: true,
          className: 'govTeams-dialog govTeams-UserAccess-modal',
        }}
        className=''
      >
        <p>Let the owners know why you'd like to join!</p>
        <br />
        <TextField
          componentRef={(ref) => (this._textField = ref)}
          placeholder='Why are you interested in this community? What can you contribute? etc...'
          multiline={true}
          rows={4}
          maxLength={1024}
          onChange={this.onChange}
          autoFocus={true}
        />
        <br />
        <DefaultButton text='Cancel' onClick={this.onDismiss} />
        <PrimaryButton
          text='Submit'
          onClick={this.onSubmit}
          disabled={this.state.isSubmitDisabled}
          className='buttonSpacing'
        />
      </Dialog>
    );
  }

  private onChange = () => {
    this.setState({
      isSubmitDisabled: this._textField.value === '' ? true : false,
    });
  };

  private onDismiss = () => {
    this.setState({
      isSubmitDisabled: true,
    });
    this.props.requestsActions.endJoinMember(false);
  };

  private onSubmit = () => {
    this.props.requestsActions.endJoinMember(true, this._textField.value);
  };
}

const mapStateToProps = (state: any) => ({
  isOpen: state.requests.isOpen,
});

const mapDispatchToProps = (dispatch: any) => ({
  requestsActions: bindActionCreators(requestsActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(JoinRequest);
