/**
 * external member review screen
 *
 */

/**
 * React
 */
import * as React from 'react';

/*
 * Redux
 */
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { RouteComponentProps } from 'react-router';
import { bindActionCreators } from 'redux';

/**
 * Fabric UI
 */
import {
  ActionButton,
} from '@fluentui/react/lib/Button';
 import {
   IColumn,
 } from '@fluentui/react/lib/DetailsList';
import { Label } from '@fluentui/react/lib/Label';

/**
 * Components
 */
import { Accordion } from '../../components/Accordion';

/**
 * GovTEAMS
 */
import {
//  IOrganisationMember,
//  IOrganisationMemberSearchParams,
  IOrganisationProps,
  IOrganisationState,
  organisationActionCreators
} from '../../controllers/OrganisationController';
import { IExternalMemberReviewState } from './ExternalMemberReview.types';
import { IOrganisationExternalMemberCommunities, IOrganisationExternalMemberReview } from '../../controllers/OrganisationController/Organisation.types';

/**
 * Constants and utilities
 */
import * as constants from '../../common/Constants';


type IMergedProps = IOrganisationState &
  IOrganisationProps &
  RouteComponentProps;

export class ExternalMemberReviewComponent extends React.Component<
  IMergedProps,
  IExternalMemberReviewState
  > {
  // private internalCommunityCount: number;
  // private externalCommunityCount: number;

  constructor(props: IMergedProps) {
    super(props);

    const columns: IColumn[] = this.getColumns();

    // this.internalCommunityCount = 0;
    // this.externalCommunityCount = 0;

    this.state = {
      member: undefined,
      isLoaded: false,
      membersActionResult: undefined,
      columns,
      items: [],
      collapsed: true
    };
  }

  public render() {
    //const { membersResult } = this.props;

    return (
      <div className='orgMembersManagement-page'>
        <ActionButton
          className='govTeams-actionButton'
          onClick={(event: any) => {
            this.props.dispatch(
              replace(
                `${constants.SCREEN_ORGANISATION}/${this.props.match.params['organisationId']}/ExternalMembers`
              )
            );
          }}
          iconProps={{ iconName: 'ChevronLeft' }}
        >
          {constants.LINK_BACKTOEXTERNALMEMBERSVIEW}
        </ActionButton>

        <React.Fragment>
          {/* Header */}
          <div className='topSection'>
            <h1>Review external member</h1>
            <hr />
          </div>
        </React.Fragment>

        {this.state.member && !this.props.error ? (
          <React.Fragment>
        
            {/* User details */}
            <div>
              <div>
                <table style={{width: '75%'}}>
                  <tbody>
                  <tr style={{borderBottom: '1px solid'}}>
                    <td style={{width: '50%'}}><Label style={{fontWeight: 'bold'}}>Name</Label></td>
                    <td>&nbsp;</td>
                    <td><Label style={{fontWeight: 'bold'}}>Status</Label></td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: '10px'}}><Label>{this.state.member.fullName}</Label></td>
                    <td>&nbsp;</td>
                    <td style={{paddingLeft: '10px'}}><Label>{this.state.member.memberStatus}</Label></td>
                  </tr>
                  <tr style={{borderBottom: '1px solid'}}>
                    <td style={{width: '50%'}}><Label style={{paddingTop: '20px', fontWeight: 'bold'}}>External email address</Label></td>
                    <td>&nbsp;</td>
                    <td><Label style={{paddingTop: '20px', fontWeight: 'bold'}}>User name</Label></td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: '10px'}}><Label>{this.state.member.emailAddress}</Label></td>
                    <td>&nbsp;</td>
                    <td style={{paddingLeft: '10px'}}><Label>{this.state.member.userName}</Label></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            
            <div className='filterList-footer'>
              &nbsp;
            </div>

            {/* Internal Communities */}
            <div>
              <h4>Communities managed by members in your organisation</h4>
              <div>
                {this.state.items.map((item: IOrganisationExternalMemberCommunities, index: number) => {
                    return this._onRenderCell(item, index);
                })}
              </div>
            </div>

            <div className='filterList-footer'>
              &nbsp;
            </div>

            {/* External Communities */}
            <div>
              <h4>Communities managed by members in other organisations</h4>
              <p>This External Member is collaborating in <strong>{this.state.member.externalCommunityCount}</strong> communities managed by members outside your organisation. </p>
              {this.state.member.externalCommunityCount !== 0 ? (
                <p>Because these communities don’t have any Owners from your organisation we are unable to provide their details.</p>
               ) : (<p>&nbsp;</p>)
              }
            </div>

          </React.Fragment>
          ) : (
            <React.Fragment>
            {this.state.isLoaded && this.props.error && (
              <React.Fragment>
                <h1>Error</h1>
                <table className='table-requestError'>
                  <tbody>
                    <tr>
                      <th>Type</th>
                      <td>{this.props.error.code}</td>
                    </tr>
                    <tr>
                      <th>Message</th>
                      <td>{this.props.error.message}</td>
                    </tr>
                    <tr>
                      <th>CorrelationId</th>
                      <td>{this.props.error.innerError.requestId}</td>
                    </tr>
                  </tbody>
                </table>
              </React.Fragment>
            )}
            </React.Fragment>
         )
        }
      </div>
    )
  }

  public componentDidMount() {
    const organisationId =
      this.props.organisation && this.props.organisation.id !== undefined
        ? this.props.organisation.id
        : this.props.match.params['organisationId'];
  
    const memberAzureId =
    this.props.memberForAction && this.props.memberForAction.azureId !== undefined
      ? this.props.memberForAction.azureId
      : this.props.match.params['memberAzureId'];

    this.init(organisationId, memberAzureId);
  }

  public async componentDidUpdate(
    prevProps: IMergedProps,
    prevState: IExternalMemberReviewState
  ) {
    if (prevProps.membersResult !== this.props.membersResult) {
      if (this.props.membersActionResult) {
        const details = this.props.membersActionResult.result as IOrganisationExternalMemberReview;

        this.setState({
          member: details,
          columns: this.getColumns(),
          items: details.internalCommunities as IOrganisationExternalMemberCommunities[]
        });
      }
    }
  }

  private async init(organisationId: string, memberAzureId: string) {
    await this.props.organisationActionCreators.reviewExternalMember(
      organisationId,
      memberAzureId
    );

    const details = this.props.membersActionResult.result as IOrganisationExternalMemberReview;

    this.setState({
      member: details,
      columns: this.getColumns(),
      items: details.internalCommunities as IOrganisationExternalMemberCommunities[]
    });
  }

  private _onRenderCell(item: IOrganisationExternalMemberCommunities, index: number | undefined): JSX.Element {
    const { collapsed } = this.state;
    return (
      <Accordion title={item.communityName}
        defaultCollapsed={ collapsed} className={'itemCell'} key={index}>
        <div className={'itemContent'}>
          <div className={'itemReponse'}><ul>{item.communityOwners.map(owner => <li key={index}>{owner}</li>)}</ul></div>
        </div>
      </Accordion>
    );
  }

  private getColumns(): IColumn[] {
    return ([
      {
        key: 'communityName',
        name: 'CommunityName',
        fieldName: 'communityName',
        isResizable: true,
        isMultiline: true,
        minWidth: 200,
        maxWidth: 250,
      },
      {
        key: 'owners',
        name: 'Community Owners',
        fieldName: 'communityOwners',
        isResizable: true,
        isMultiline: true,
        minWidth: 250,
        maxWidth: 300,
      }
    ]);
  }

  // private onRenderItem = (item, index, column) => {
  //   const fieldContent = item[column.fieldName];
  //   const key = column.key;

  //   switch (key) {
  //     case 'owners':
  //       return '* ' + fieldContent.join("\\r\\n * ");
  //       //return <List items={fieldContent} />
  //     default:
  //       return fieldContent;
  //   }
  // };
}

const mapStateToProps = (state) => ({
  organisation: state.organisation.organisation,
  membersResult: state.organisation.membersResult,
  membersActionResult: state.organisation.membersActionResult,
  error: state.organisation.error,
  member: state.member
});

const mapDispatchToProps = (dispatch) => ({
  organisationActionCreators: bindActionCreators(
    organisationActionCreators,
    dispatch
  ),
  dispatch
});

export const ExternalMemberReview = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExternalMemberReviewComponent);

export default ExternalMemberReview;