import * as React from 'react';
import { connect } from 'react-redux';
import { BRANDNAME } from '../../common/Tooltips';

const Error_VerifyAccount = props => (
  <div>
    <h2 className="heading-alert">Verification error</h2>
    <p>
      We were unable to verify your account. Please try copying and pasting the
      link from your verification email into your web browser. If you are still
      having issues, contact the{' '}
      <a href={props.mailTo}>{BRANDNAME} support helpdesk</a> .
    </p>
  </div>
);

export default connect()(Error_VerifyAccount);
