import actions from './Requests.actions';

const initialState = {
  values: [],
  isLoading: false,
  communityId: null,
  communityType: null,
  isManagePage: null,
  isOpen: false,
  error: null
};

export const reducer = (state, action) => {
  state = state || initialState;

  //UI
  if (action.type === actions.REQUESTS_JOIN_START) {
    return {
      ...state,
      communityId: action.communityId,
      communityType: action.communityType,
      isManagePage: action.isManagePage,
      isOpen: true
    };
  }

  if (action.type === actions.REQUESTS_JOIN_END) {
    return {
      ...state,
      isOpen: false
    };
  }

  //API
  if (action.type === actions.REQUESTS_FETCH_REQUEST) {
    return {
      ...state,
      values: null,
      isLoading: true
    };
  }

  if (action.type === actions.REQUESTS_FETCH_RESPONSE) {
    return {
      ...state,
      values: action.values,
      isLoading: false,
      error: action.error
    };
  }

  if (action.type === actions.REQUESTS_CHANGE_REQUEST) {
    return {
      ...state,
      isLoading: true
    };
  }

  if (action.type === actions.REQUESTS_CHANGE_RESPONSE) {
    return {
      ...state,
      isLoading: false,
      error: action.error
    };
  }

  if (action.type === actions.REQUESTS_ADD_REQUEST) {
    return {
      ...state,
      isLoading: true
    };
  }

  if (action.type === actions.REQUESTS_ADD_RESPONSE) {
    return {
      ...state,
      isLoading: false,
      error: action.error
    };
  }

  return state;
};

export default reducer;
