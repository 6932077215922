import * as React from 'react';
import { Link } from '@fluentui/react/lib/Link';

import { BRANDNAME } from '../../common/Tooltips';

interface IProps {
  prefix?: string | React.ReactNode
  buttonLabel?: string | React.ReactNode
}

const ContactButton = (props: IProps) => {
  const {
    prefix = '',
    buttonLabel = `${BRANDNAME} support`
  } = props;

  return (
    <>
      {prefix}
      <Link href={'https://www.govteams.gov.au/contact-us'} target='_blank' rel='noreferrer'>
        {buttonLabel}
      </Link>
    </>
  );
}

export default ContactButton;