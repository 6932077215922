import * as React from 'react';
import { TextField as UIFabricTextField } from '@fluentui/react/lib/TextField';

const TextField = ({
  input,
  meta: { touched, error },
  ...rest
}: {
  input: any;
  meta: any;
}) => (
  <UIFabricTextField
    errorMessage={touched ? error : null}
    onChanged={item => {
      input.onChange(item);
    }}
    {...input}
    {...rest}
  />
);

export default TextField;
