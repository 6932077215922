/**
 * Community tools
 *
 * Provides functionality to manage and access community features.
 * Used on community panel and community management screen.
 *
 */

/**
 * React
 */
import * as React from 'react';

/**
 * Fabric UI
 */
import { Link } from '@fluentui/react/lib/Link';
import {
  MessageBar,
  MessageBarType
} from '@fluentui/react/lib/MessageBar';

/**
 * Constants
 */
import { ILicence } from '../../../controllers/OrganisationController';

export class LicenceAllocationInfo extends React.Component<any, any> {
  public render() {
    const licence: ILicence = this.props.licence;

    return (
      <React.Fragment>
        {licence && (
          <React.Fragment>
            {Math.floor(licence.allocation) >= licence.threshold &&
              licence.dynamicUserCount + licence.reservedUserCount <
                licence.licenceCap && (
                <MessageBar
                  className='licenceAllocation-warning'
                  messageBarType={MessageBarType.warning}
                  isMultiline={true}
                >
                  You've reached or exceeded {Math.floor(licence.allocation)}%
                  of your total licence cap. You'll now be required to approve
                  each licence request for your organisation.
                  <Link
                    href='https://www.govteams.gov.au/licence-threshold'
                    target='_blank'
                  >
                    Learn more
                  </Link>
                </MessageBar>
              )}

            {licence.dynamicUserCount + licence.reservedUserCount >=
              licence.licenceCap && (
              <MessageBar
                className='licenceAllocation-warning'
                messageBarType={MessageBarType.severeWarning}
                isMultiline={true}
              >
                You've reached your nominated licence cap. You're currently
                unable to allocate licences for your organisation.
                <Link
                  href='https://www.govteams.gov.au/licence-cap'
                  target='_blank'
                >
                  Learn more about increasing your licence cap
                </Link>
              </MessageBar>
            )}

            <div className='licenceAllocation'>
              <div className='row'>
                <div className='licenceAllocation-chart'>
                  <div className={`c100 p${Math.floor(licence.allocation)}`}>
                    <span>
                      <span
                        className={`percentage${
                          Math.floor(licence.allocation) === 100
                            ? ' percentageMax'
                            : ''
                        }`}
                      >
                        {Math.floor(licence.allocation) <= 100
                          ? Math.floor(licence.allocation)
                          : 100}
                        %
                      </span>
                      <span className='percentageLabel'>Allocated</span>
                    </span>
                    <div className='slice'>
                      <div className='bar' />
                      <div className='fill' />
                    </div>
                  </div>
                </div>
                <div className='licenceAllocation-stats'>
                  <table>
                    <tbody>
                      <tr>
                        <td>Licence cap</td>
                        <td>
                          {licence.licenceCap < 2147483647
                            ? licence.licenceCap
                            : 'Unlimited'}
                        </td>
                      </tr>
                      <tr>
                        <td>Allocated</td>
                        <td>
                          {licence.dynamicUserCount + licence.reservedUserCount}
                        </td>
                      </tr>
                      <tr>
                        <td>Reserved</td>
                        <td>{licence.reservedUserCount}</td>
                      </tr>
                      <tr>
                        <td>Pending requests</td>
                        <td>{licence.pendingLicenceUserCount}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default LicenceAllocationInfo;
