/**
 * Displays statistics for total members, communities and agencies
 *
 */

/**
 * React Redux
 */
import * as React from 'react';
import { connect } from 'react-redux';

/**
 * Utilities
 */
import { isEmpty } from 'lodash';
import { BRANDNAME } from '../../../../common/Tooltips';

export interface IStatisticsProps {
    profile: any;
    statistics: any;

}

export class StatisticsComponent extends React.Component<
    IStatisticsProps,
    any
    > {

    public render() {
        return (

            <div className='dashboard-stats'>
                {!isEmpty(this.props.statistics) && isEmpty(this.props.statistics.error) ? (
                    <>
                        <div className='stats-item'>
                            <div className='stats-number'>
                                {new Intl.NumberFormat('en-AU', {
                                    useGrouping: true
                                }).format(this.props.statistics.totalMembers)}
                            </div>
                      Members
                    </div>
                        <div className='stats-item'>
                            <div className='stats-number'>
                                {new Intl.NumberFormat('en-AU', {
                                    useGrouping: true
                                }).format(this.props.statistics.totalCommunities)}
                            </div>
                      Communities
                    </div>
                        <div className='stats-item'>
                            <div className='stats-number'>
                                {new Intl.NumberFormat('en-AU', {
                                    useGrouping: true
                                }).format(this.props.statistics.totalAgencies)}
                            </div>
                        Organisations
                    </div>
                    </>
                ) : (
                        <p> We weren't able to retrieve the {BRANDNAME} Statitics. Please refresh your browser to try again</p>
                    )}
            </div>

        );
    }
}

const mapStateToProps = (state: any) => ({
    profile: state.profile,
    statistics: state.statistics
});

const mapDispatchToProps = (dispatch: any) => ({});

export const Statistics = connect(
    mapStateToProps,
    mapDispatchToProps
)(StatisticsComponent);

export default Statistics;
