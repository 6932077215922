import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Dialog } from '@fluentui/react/lib/Dialog';
import {
  PrimaryButton,
  DefaultButton
} from '@fluentui/react/lib/Button';
import { Checkbox } from '@fluentui/react/lib/Checkbox';

import { profileActionCreators } from '../../controllers/ProfileController';

export interface IDeactivationProps {
  isOpen: boolean;
  profileActions: any;
}

export class Deactivation extends React.Component<IDeactivationProps, any> {
  private _checkbox;

  public render() {
    return (
      <Dialog
        hidden={!this.props.isOpen}
        onDismiss={this.onDismiss}
        dialogContentProps={{
          showCloseButton: true,
          title: 'Is this goodbye?'
        }}
        modalProps={{
          isBlocking: true,
          isDarkOverlay: true,
          className: 'govTeams-dialog govTeams-UserAccess-modal'
        }}
      >
        <p>
          If you deactivate your account, we’ll leave your community memberships
          in place so you can pick up right where you left off.
        </p>
        <p>Want us to remove your community memberships?</p>
        <Checkbox
          label='Yes, remove my community memberships'
          componentRef={(ref) => (this._checkbox = ref)}
        />

        <DefaultButton text='Cancel' onClick={this.onDismiss} />
        <PrimaryButton
          text='Deactivate my account'
          onClick={this.onSubmit}
          className='buttonSpacing'
        />
      </Dialog>
    );
  }

  private onDismiss = () => {
    this.props.profileActions.endDeactivationProfile(false);
  };

  private onSubmit = () => {
    this.props.profileActions.endDeactivationProfile(
      true,
      this._checkbox.checked
    );
  };
}

const mapStateToProps = (state: any) => ({
  isOpen: state.profile.isDeactivating
});

const mapDispatchToProps = (dispatch: any) => ({
  profileActions: bindActionCreators(profileActionCreators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Deactivation);
