import * as React from 'react';
import { connect } from 'react-redux';
import { BRANDNAME } from '../../common/Tooltips';

const Error_LoginFAM = props => (
  <div>
    <h2 className="heading-alert">You've already registered for {BRANDNAME}</h2>
    <p>
      We've just checked and it looks like you've already registered for
      {BRANDNAME}. To get started, <a href={props.url}>login</a> to {BRANDNAME} using
      your new username (ending in @govteams.gov.au) that was sent to you in
      your {BRANDNAME} activation email. You will be asked to enter the password
      you set when you registered your account.
    </p>

    <p>
      If you are having trouble logging in, go to{' '}
      <a href="https://www.govteams.gov.au/support">{BRANDNAME} support site</a> to
      browse the user guides. Still having trouble? Contact the{' '}
      <a href={props.mailTo}>{BRANDNAME} support</a> helpdesk and quote the
      following error ID: <strong> {props.correlationId} </strong>
    </p>
  </div>
);

export default connect()(Error_LoginFAM);
