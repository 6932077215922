import * as React from 'react';
import { connect } from 'react-redux';
import { ActionButton } from '@fluentui/react/lib/Button';
import { BRANDNAME } from '../../common/Tooltips';

export class PendingComponent extends React.Component<any, any> {
  public render() {
    return (
      <div className='govTeams-account'>
        <div className='govTeams-row'>
          <div className='govTeams-account-col'>
            <h1>Pending licence allocation</h1>
            <hr />
            <p>
              Your organisation is unable to allocate you a {BRANDNAME} licence at
              this stage as they are approaching their licence cap. Your
              organisation administrator is responsible for allocating licences
              and has been notified of your licencing status.
            </p>
            <p>
              For further information, please contact your {BRANDNAME} organisation
              administrator.
            </p>
            <div className='spacing-bottom' />
            {this.props.profile &&
              this.props.profile.organisationContact && (
                <React.Fragment>
                  <h2 className='h3'>Your organisation contact</h2>
                  <ActionButton
                    className='meta-contactInfo'
                  href={`mailto:${this.props.profile.organisationContact}`}
                    iconProps={{ iconName: 'mail' }}
                  >
                  {this.props.profile.organisationContact}
                  </ActionButton>
                </React.Fragment>
              )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  profile: state.profile
});

const mapDispatchToProps = (dispatch: any) => ({});

export const Pending = connect(
  mapStateToProps,
  mapDispatchToProps
)(PendingComponent);

export default Pending;
