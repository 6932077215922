import { push } from 'react-router-redux';

import { progressActionCreators } from '../../components/Progress';
import {
  communityActionCreators,
  CommunityRole,
  MemberType,
  ICommunityMember
} from '../CommunityController';
import { notificationActionCreators } from '../../components/Notification';
import actions from './Members.actions';
import * as constants from '../../common/Constants';
import {getAuthFromState} from '../../auth/authHandler';
import {httpServiceSecureGet, httpServiceSecureDelete, httpServiceSecurePut} from '../../common/Http';
import {saveInteractionWithCommunityEvent} from '../../common/EventTracking';

export const membersActionCreators = {
  requestFetchMembers: (communityId: string) => async (dispatch, getState) => {
    if (getState().members.isLoading || communityId === null) {
      return;
    }

    dispatch({ type: actions.MEMBERS_FETCH_REQUEST });
    dispatch(
      progressActionCreators.startShowProgress(constants.PROGRESS_MEMBERS_FETCH)
    );

    const allMembers: ICommunityMember[] = [];
    const adminMembers: ICommunityMember[] = [];
    const govMembers: ICommunityMember[] = [];
    const guestMembers: ICommunityMember[] = [];
    const externalMembers: ICommunityMember[] = [];

    try {
      const { gtToken } = await getAuthFromState(getState, dispatch);
      const members: ICommunityMember[] = await httpServiceSecureGet(`/members/${communityId}`, gtToken);

      members.forEach((member: ICommunityMember) => {
        allMembers.push(member);

        switch (member.role) {
          case CommunityRole.Owner:
            adminMembers.push(member);
            break;
          case CommunityRole.Member:
            if (member.isGuest) {
              guestMembers.push(member);
            } else {
              if (member.memberType !== MemberType.LAM) {
                govMembers.push(member);
              } else {
                externalMembers.push(member);
              }
            }
            break;
        }
      });

      dispatch({
        type: actions.MEMBERS_FETCH_RESPONSE,
        allMembers,
        adminMembers,
        govMembers,
        guestMembers,
        externalMembers
      });
      dispatch(progressActionCreators.endShowProgress());
    } catch (error) {
      dispatch(
        notificationActionCreators.startShowNotification(
          constants.ERROR_MEMBERS_FETCH
        )
      );
      dispatch({
        type: actions.MEMBERS_FETCH_RESPONSE,
        error: error.toString(),
      });
      dispatch(progressActionCreators.endShowProgress());
    }
  },

  viewCommunityMembers: (communityId: string) => (dispatch) => {
    dispatch(push(`${constants.SCREEN_COMMUNITY}/${communityId}/Members`));
  },

  requestFetchMembersByRole: (communityId: string, role: string) => async (
    dispatch,
    getState
  ) => {
    if (getState().members.isLoading) {
      return;
    }

    dispatch({ type: actions.MEMBERS_FETCH_BY_ROLE_REQUEST });
    // dispatch(
    //   progressActionCreators.startShowProgress('Fetching community members...')
    // );

    try {
      const { gtToken } = await getAuthFromState(getState, dispatch);
      const members: ICommunityMember[] = await httpServiceSecureGet(`/members/${communityId}${role ? '/' + role : ''}`, gtToken);
      const allMembers: ICommunityMember[] = [];

      members.forEach((member: ICommunityMember) => {
        allMembers.push(member);
      });

      dispatch({
        type: actions.MEMBERS_FETCH_BY_ROLE_RESPONSE,
        allMembers
      });
      // dispatch(progressActionCreators.endShowProgress());
    } catch (error) {
      dispatch(
          notificationActionCreators.startShowNotification(
              constants.ERROR_MEMBERS_FETCH
          )
      );
      dispatch({
        type: actions.MEMBERS_FETCH_BY_ROLE_RESPONSE,
        error: error.toString(),
      });
      // dispatch(progressActionCreators.endShowProgress());
    }
  },

  requestLeaveMember: (
    communityId: string,
    azureId: string,
    isManagePage: boolean,
    communityName: string = '',
  ) => async (dispatch, getState) => {
    if (getState().members.isLoading) {
      return;
    }

    saveInteractionWithCommunityEvent('leave', communityName);
    dispatch({ type: actions.MEMBERS_LEAVE_REQUEST });
    dispatch(
      progressActionCreators.startShowProgress(
        constants.PROGRESS_MEMBERS_LEAVE
      )
    );

    try {
      const { gtToken } = await getAuthFromState(getState, dispatch);
      await httpServiceSecureDelete(`/members/${communityId}/${azureId}`, gtToken);

      dispatch(
        notificationActionCreators.startShowNotification(
          constants.MESSAGE_MEMBERS_LEAVE
        )
      );
      dispatch({ type: actions.MEMBERS_LEAVE_RESPONSE });
      dispatch(progressActionCreators.endShowProgress());

      if (isManagePage) {
        dispatch(membersActionCreators.requestFetchMembers(communityId));
      }
      return true;

    } catch (error) {
      alert(error.toString());
      dispatch({
        type: actions.MEMBERS_LEAVE_RESPONSE,
        error: error.toString(),
      });
      dispatch(progressActionCreators.endShowProgress());
      return false;
    }
  },

  requestDeleteMember: (
    communityId: string,
    azureId: string,
    isManagePage: boolean,
    communityName: string = '',
  ) => async (dispatch, getState) => {
    if (getState().members.isLoading) {
      return;
    }
    saveInteractionWithCommunityEvent('delete_member', communityName);

    dispatch({ type: actions.MEMBERS_DELETE_REQUEST });
    dispatch(
      progressActionCreators.startShowProgress(
        constants.PROGRESS_MEMBERS_DELETE
      )
    );

    try {
      const { gtToken } = await getAuthFromState(getState, dispatch);
      await httpServiceSecureDelete(`/members/${communityId}/${azureId}`, gtToken);

      dispatch(
          notificationActionCreators.startShowNotification(
              constants.MESSAGE_MEMBERS_DELETE
          )
      );
      dispatch({ type: actions.MEMBERS_DELETE_RESPONSE });
      dispatch(progressActionCreators.endShowProgress());

      if (isManagePage) {
        dispatch(membersActionCreators.requestFetchMembers(communityId));
      }

      dispatch(
          communityActionCreators.updateSuggestedCommunities(communityId)
      );
      return true;

    } catch (error) {
      alert(error.toString());
      dispatch({
        type: actions.MEMBERS_DELETE_RESPONSE,
        error: error.toString(),
      });
      dispatch(progressActionCreators.endShowProgress());
      return false;
    }
  },

  requestChangeMember: (communityId, azureId, isPromote) => async (
    dispatch,
    getState
  ) => {
    if (getState().members.isLoading) {
      return;
    }

    dispatch({ type: actions.MEMBERS_CHANGE_REQUEST });
    dispatch(
      progressActionCreators.startShowProgress(
        isPromote
          ? constants.PROGRESS_MEMBERS_PROMOTE
          : constants.PROGRESS_MEMBERS_DEMOTE
      )
    );

    try {

      const { gtToken } = await getAuthFromState(getState, dispatch);
      await httpServiceSecurePut(`/members/${communityId}/${azureId}/${isPromote}`, gtToken);

      dispatch({
        type: actions.MEMBERS_CHANGE_RESPONSE
      });
      dispatch(progressActionCreators.endShowProgress());
      dispatch(communityActionCreators.requestFetchCommunity(communityId));
      dispatch(membersActionCreators.requestFetchMembers(communityId));

      if (isPromote) {
        dispatch(
            notificationActionCreators.startShowNotification(
                constants.MESSAGE_MEMBERS_PROMOTE
            )
        );
      } else {
        dispatch(
            notificationActionCreators.startShowNotification(
                constants.MESSAGE_MEMBERS_DEMOTE
            )
        );
      }
    } catch (error) {
      dispatch({
        type: actions.MEMBERS_CHANGE_RESPONSE,
        error: error.toString(),
      });
      dispatch(progressActionCreators.endShowProgress());
      alert(error.toString());
    }
  }
};

export default membersActionCreators;
