import { store } from '../../publicApp';
import { actions } from './Progress.actions';
import { notificationActionCreators } from '../Notification';

let WatchDogVariable;
let timeoutStep = 0;

const TIMEOUT_MS = 15000;
const TIMEOUT_MESSAGES = [
  '',
  "Don't go anywhere... We are still working on it for you.",
  "This is taking longer than expected... let's try for a little longer.",
  "We've had some trouble inviting your users. Please review your community management page to see whether all of your users have been added or invited. Feel free to retry inviting any users that have not appeared on that screen"
];

export function startWatchDog() {
  if (timeoutStep === 1 || timeoutStep === 2) {
    store.dispatch({
      type: actions.WATCHDOG_TRIGGER,
      label: TIMEOUT_MESSAGES[timeoutStep]
    });
    timeoutStep++;
    WatchDogVariable = setTimeout(startWatchDog, TIMEOUT_MS);
  } else if (timeoutStep === 3) {
    store.dispatch({ type: actions.SHOW_END });
    store.dispatch(
      notificationActionCreators.startShowNotification(
        TIMEOUT_MESSAGES[timeoutStep]
      ) as any
    );
    stopWatchDog();
  }
}

export function stopWatchDog() {
  window.clearTimeout(WatchDogVariable);
  timeoutStep = 0;
}

export const progressActionCreators = {
  startShowProgress: (
    label: string = '',
    enableTimeout: boolean = false,
    isBlocking: boolean = true
  ) => (dispatch) => {
    dispatch({
      type: actions.SHOW_START,
      label,
      enableTimeout,
      isBlocking
    });

    if (enableTimeout) {
      timeoutStep = 1;
      WatchDogVariable = setTimeout(startWatchDog, TIMEOUT_MS);
    }
  },
  endShowProgress: () => (dispatch) => {
    dispatch({ type: actions.SHOW_END });
    stopWatchDog();
  }
};

export default progressActionCreators;
