/**
 * Community panel
 *
 * A summarised view of a community management screen that is accessible from community cards, list and search items
 *
 * Displays:
 * -Community information
 * -Owners
 * -Tools
 */

/**
 * React
 */
import * as React from 'react';

/**
 * Redux
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CommunityPanelCommonComponent from '@govteams/library/components/Community/CommunityPanelComponent';

import { communityActionCreators } from '../../controllers/CommunityController';
import { membersActionCreators } from '../../controllers/MembersController';
import { BRANDNAME } from '../../common/Tooltips';
import * as constants from '../../screens/CommunityManagement/components/CommunityActions/CommunityActions.constants';
import { inviteMembersActionCreators } from '../../controllers/InviteMembersController';
import { requestsActionCreators } from '../../controllers/RequestsController';

import CommunityEditModal from './../../components/CommunityEditModal';

const CommunityPanelComponent = (props: any) => {
  const { community, allMembers, profile } = props;
  return (
    <CommunityPanelCommonComponent
      isCommunityPanelOpen={community.isCommunityPanelOpen}
      initialValues={community.initialValues}
      community={community}
      allMembers={allMembers}
      profile={profile}
      brandName={BRANDNAME}
      constants={constants}

      CommunityEditModal={CommunityEditModal}

      communityActionCreators={props.communityActionCreators}
      membersActionCreators={props.membersActionCreators}
      inviteMembersActionCreators={props.inviteMembersActionCreators}
      requestsActionCreators={props.requestsActionCreators}
    />
  );
}

const mapStateToProps = (state) => ({
  community: state.community,
  profile: state.profile,
  allMembers: state.members.allMembers,
});

const mapDispatchToProps = (dispatch) => ({
  inviteMembersActionCreators: bindActionCreators(inviteMembersActionCreators, dispatch),
  requestsActionCreators: bindActionCreators(requestsActionCreators, dispatch),
  communityActionCreators: bindActionCreators(communityActionCreators, dispatch),
  membersActionCreators: bindActionCreators(membersActionCreators, dispatch),
});

const CommunityPanel = connect(mapStateToProps, mapDispatchToProps)(CommunityPanelComponent);

export default CommunityPanel;