import { actions } from './Carousel.actions';
import { getAuthFromState } from '../../auth';
import { httpServiceSecureGet } from '../../common/Http';
import { SlideCard } from './Carousel.types';

export const carouselActionCreators = {
  requestSlides: () => async (dispatch, getState) => {
    if (getState().carousel.isLoading) {
      return;
    }

    dispatch({
      type: actions.FETCH_CAROUSEL_SLIDES_REQUEST,
    });
    const slides: SlideCard[] = [];

    try {
      const { gtToken } = await getAuthFromState(getState, dispatch);
      const json = await httpServiceSecureGet('/News', gtToken);

      // Double parse JSON because API is returning JSON of JSON
      const data = JSON.parse(json);

      // Error checking
      if ( !data || data.error ) {
        throw data;
      }

      // Build slide array
      data?.value?.forEach( item => {
        slides.push({
          id: item['odata.id'],
          title: item.Title,
          description: item.Description,
          imageUrl: item.BannerImageUrl?.Url,
          imageContent: item.BannerImageUrl?.ImageContent,
          imageContentType: item.BannerImageUrl?.ImageContentType,
          link: item.File?.ServerRelativeUrl,
        });
      });
    }
    catch (error) {
      console.log('Error occurred while fetching carousel slides', error);
    }
    finally {
      dispatch({
        type: actions.FETCH_CAROUSEL_SLIDES_RESPONSE,
        slides,
      });
    }
  }
};

export default carouselActionCreators;
