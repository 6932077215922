/**
 * App footer
 *
 */
import * as React from 'react';
import { Link } from '@fluentui/react/lib/Link';
import { isProtected } from '../../common/Utils';

export default class Footer extends React.Component<any, any> {
  public render() {
    return (
      <div className='govTeams-Footer'>
        <div className='footerLeft'>
          <ul>
            <li>
              <Link
                href='https://www.govteams.gov.au/disclaimer/'
                target='_blank'
              >
                Disclaimer
              </Link>
            </li>
            <li>
              <Link href='https://www.govteams.gov.au/privacy/' target='_blank'>
                Privacy
              </Link>
            </li>
            <li>
              <Link
                href='https://www.govteams.gov.au/copyright/'
                target='_blank'
              >
                Copyright
              </Link>
            </li>
            <li>
              <Link
                href='https://www.govteams.gov.au/terms-of-use/'
                target='_blank'
              >
                Terms of Use
              </Link>
            </li>
          </ul>
        </div>
        <div className='footerRight'>{isProtected() ? 'PROTECTED' : 'OFFICIAL:Sensitive'}</div>
      </div>
    );
  }
}
