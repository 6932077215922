import { actions } from './OrganisationSummary.actions';
import { IOrganisationSummaryState } from './OrganisationSummary.types';

export const initialState: IOrganisationSummaryState = {
  organisationId: undefined,
  organisation: undefined,
  admins: undefined,
  licence: undefined,
  domains: undefined
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.ORG_MANAGE_START: {
      return {
        ...state,
        organisationId: action.organisationId
      };
    }

    case actions.ORG_EDIT_START: {
      return {
        ...state,
        organisationId: action.organisationId
      };
    }

    case actions.ORG_MANAGE_MEMBERS_START: {
      return {
        ...state,
        organisationId: action.organisationId
      };
    }

    case actions.ORG_MANAGE_MEMBERS_PENDING_START: {
      return {
        ...state,
        organisationId: action.organisationId
      };
    }

    case actions.ORG_RESERVE_LICENCES_START: {
      return {
        ...state,
        organisationId: action.organisationId
      };
    }

    case actions.ORG_FREEZE_LICENCES_START: {
      return {
        ...state,
        organisationId: action.organisationId
      };
    }

    case actions.ORG_MANAGE_EXTERNAL_MEMBERS_START: {
      return {
        ...state,
        organisationId: action.organisationId
      };
    }
    case actions.ORG_MANAGE_EXTERNAL_MEMBERS_END: {
      return {
        ...state,
        organisationId: action.organisationId
      };
    }

    case actions.ORG_MANAGE_AUTO_EXTEND_ID_RANGE_START: {
      return {
        ...state,
        organisationId: action.organisationId
      };
    }
    case actions.ORG_MANAGE_AUTO_EXTEND_ID_RANGE_END: {
      return {
        ...state,
        organisationId: action.organisationId
      };
      }

      case actions.ORG_MANAGE_ORG_DEFAULT_COMMUNITY_START: {
          return {
              ...state,
              organisationId: action.organisationId
          };
      }
      case actions.ORG_MANAGE_ORG_DEFAULT_COMMUNITY_END: {
          return {
              ...state,
              organisationId: action.organisationId
          };
      }

    default: {
      return state;
    }
  }
};

export default reducer;
