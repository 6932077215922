import * as React from 'react';
import { connect } from 'react-redux';
import { BRANDNAME } from '../../common/Tooltips';
import ContactButton from "../../components/ContactButton";

const Error_SovereignCloud = props => (
    <div>
        <h2 className="heading-error">You are unable to register for {BRANDNAME} using your work email address</h2>
        <p>
            Unfortunately, there are settings in your organisation's systems that prevent you from being able to register for {BRANDNAME} using your work email address.
            We will need to work with your organisations IT area to have them allow their system to proceed with your registration.
            To start this process, please go to the <ContactButton buttonLabel={'GovTEAMS Contact Us page'} /> and raise a ticket advising that you attempted to register and received an error during registration which indicates your account is in an 'Microsoft Azure Government cloud'.
            Please also provide an email address for your IT Support area so that we can begin working on the solution.
        </p>
        <p>
            If you are urgently required to use {BRANDNAME}, you can register using an email address that is not managed by your organisation e.g. a personal email address.
            Please contact the person who invited you to {BRANDNAME} to ask them to invite you again using that email address and follow the link in the new invitation email to register with your alternative email address.
        </p>
        <p>
            <br /><b>Here's the technical reason why you can't register:</b><br />
            Your organisation runs Microsoft systems e.g. (Microsoft Office 365 and/or Microsoft Azure Active Directory) in what is known as a <a href="https://learn.microsoft.com/en-us/azure/active-directory/external-identities/b2b-government-national-clouds">National Cloud</a> (e.g. Microsoft Azure Government cloud).
            Due to added security protection for systems in the National Cloud, your organisation's systems will not by default connect to systems outside the National Cloud (e.g. an Australian Commercial Microsoft Office 365 system like {BRANDNAME}).
            To enable connectivity between our system and your employers, both IT areas need to specifically allow the connection on either side.
            By default, GovTEAMS will allow all connections however it is up to your employers IT area as to whether they will allow the connection on your side.
        </p>
    </div>
);

export default connect()(Error_SovereignCloud);
