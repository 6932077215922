/**
 * Primary search screen
 *
 */

/**
 * React
 */
import * as React from 'react';

/**
 * Redux
 */
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { communityActionCreators } from '../../controllers/CommunityController';
import { searchActionCreators } from './Search.operations';

/**
 * Office Fabric UI components
 */
import { ActionButton, PrimaryButton } from '@fluentui/react/lib/Button';
import {
  MessageBar,
  MessageBarType
} from '@fluentui/react/lib/MessageBar';
import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import { TextField } from '@fluentui/react/lib/TextField';

/**
 * Search views
 */
import SearchCommunities from './components/Communities';
import SearchMembers from './components/Members';
import SearchDocuments from './components/Documents';

/**
 * Constants
 */
import * as constants from '../../common/Constants';
import { BRANDNAME } from '../../common/Tooltips';
import { isUserDashboardDisabled } from '../../auth/';
import { saveSearchEvent } from '../../common/EventTracking';

const CONTENT_TYPE_COMMUNITY = 'communities';
const CONTENT_TYPE_MEMBERS = 'members';
const CONTENT_TYPE_DOCUMENTS = 'documents';

export class SearchComponent extends React.Component<any, any> {
  private filterTextField: any;

  constructor(props) {
    super(props);

    this.state = {
      filter: '',
      isDashboardDisabled: false,
      searchIsActive: false,
      showWarning: false,
      selectedKey: CONTENT_TYPE_COMMUNITY
    };
  }

  public render() {

    return (
      <>
        {this.state.isDashboardDisabled && (
            <Redirect to={constants.SCREEN_REACTIVATE} />
        )}

        {!this.state.isDashboardDisabled && (
          <div className='govTeams-search'>
            <ActionButton
                className='govTeams-actionButton'
                onClick={this.onReturn}
                iconProps={{ iconName: 'ChevronLeft' }}
            >
              {constants.LINK_BACKTODASHBOARD}
            </ActionButton>

            <h1>{BRANDNAME} search</h1>
            <div className='govTeams-searchBox'>
              <div className='searchBox'>
                <TextField
                    componentRef={(ref) => (this.filterTextField = ref)}
                    placeholder={`Search GovTEAMS Communities, Members and Documents`}
                    value={this.state.filter}
                    onKeyDown={this.onKeyPress}
                    onChange={this.onSearchChange}
                />
                <PrimaryButton text='Search' onClick={this.onSearchClick} />
              </div>

              {this.state.showWarning && (
                  <MessageBar
                      className='govTeams-searchWarning'
                      messageBarType={MessageBarType.warning}
                      isMultiline={true}
                      onDismiss={this.onDismissWarning}
                  >
                    {constants.ERROR_SEARCH_WARNING}
                  </MessageBar>
              )}
            </div>
            {this.state.searchIsActive && !this.props.isGuest && (
                <React.Fragment>
                  <div className='pivotLinks'>
                    <Pivot
                        selectedKey={this.state.selectedKey}
                        onLinkClick={this.onPivotLinkClick}
                    >
                      <PivotItem
                          linkText='Communities'
                          itemKey={CONTENT_TYPE_COMMUNITY}
                      />

                      <PivotItem linkText='Members' itemKey={CONTENT_TYPE_MEMBERS} />

                      <PivotItem
                          linkText='Documents'
                          itemKey={CONTENT_TYPE_DOCUMENTS}
                      />
                    </Pivot>
                  </div>
                  <div className='govTeams-searchResults'>
                    {this.renderResults(this.state.selectedKey)}
                  </div>
                </React.Fragment>
            )}

            {this.state.searchIsActive && this.props.isGuest && (
                <React.Fragment>
                  <div className='pivotLinks'>
                    <Pivot
                        selectedKey={this.state.selectedKey}
                        onLinkClick={this.onPivotLinkClick}
                    >
                      <PivotItem
                          linkText='Communities'
                          itemKey={CONTENT_TYPE_COMMUNITY}
                      />
                    </Pivot>
                  </div>
                  <div className='govTeams-searchResults'>
                    {this.renderResults(this.state.selectedKey)}
                  </div>
                </React.Fragment>
            )}
            </div>
        )}
      </>
    );
  }

  public componentDidMount() {
    const isDashboardDisabled = isUserDashboardDisabled(this.props.profile);
    if ( isDashboardDisabled )
      return this.setState({isDashboardDisabled: true});

    if (this.props.match && this.props.match.params.filter) {
      this.setState({
        filter: this.props.match.params.filter,
        searchIsActive: true
      });
      this.props.dispatch(push(constants.SCREEN_SEARCH));
      this.props.search.requestFetchSearchCommunity(
        this.props.match.params.filter
      );
    }
  }

  private onSearchClick = () => {
    const filterValue = this.filterTextField.value;
    saveSearchEvent(filterValue);

    if (filterValue.length === 0 || filterValue.length > 256) {
      this.setState({
        searchIsActive: false,
        showWarning: true
      });

      return;
    }

    this.setState({
      filter: filterValue,
      searchIsActive: true,
      showWarning: false
    });

    this.props.search.clearSearch();
    switch (this.state.selectedKey) {
      case CONTENT_TYPE_COMMUNITY:
        this.props.search.requestFetchSearchCommunity(this.filterTextField.value);
        break;
      case CONTENT_TYPE_MEMBERS:
        this.props.search.requestFetchSearchMembers(this.filterTextField.value, '', true, '');
        break;
      case CONTENT_TYPE_DOCUMENTS:
        this.props.search.requestFetchSearchDocuments(this.filterTextField.value, 0, true);
        break;
      default:
        break;
    }
  };

  private onSearchChange = (event, newValue) => {
    this.setState({
      filter: newValue,
    })
  };

  private onKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.onSearchClick();
    }
  };

  private onPivotLinkClick = (item) => {
    this.setState({
      selectedKey: item.props.itemKey
    });

    switch (item.props.itemKey) {
      case CONTENT_TYPE_COMMUNITY:
        if (!this.props.searchCommunities) {
          this.props.search.requestFetchSearchCommunity(
            this.filterTextField.value
          );
        }
        break;
      case CONTENT_TYPE_MEMBERS:
        if (!this.props.searchMembers) {
          this.props.search.requestFetchSearchMembers(
            this.filterTextField.value,
            '',
            true,
            ''
          );
        }
        break;
      case CONTENT_TYPE_DOCUMENTS:
        if (!this.props.searchDocuments) {
          this.props.search.requestFetchSearchDocuments(
            this.filterTextField.value,
            '',
            true
          );
        }
        break;
      default:
        break;
    }
  };

  private onReturn = (event) => {
    this.props.search.clearSearch();
    this.props.dispatch(push(constants.SCREEN_DASHBOARD));
  };

  private onDismissWarning = (event) => {
    this.setState({
      showWarning: false
    });
  };

  private renderResults = (type) => {
    switch (type) {
      case CONTENT_TYPE_COMMUNITY:
        return <SearchCommunities />;
      case CONTENT_TYPE_MEMBERS:
        return <SearchMembers />;
      case CONTENT_TYPE_DOCUMENTS:
        return <SearchDocuments />;
      default:
        break;
    }

    return;
  };
}

const mapStateToProps = (state) => ({
  isGuest: state.profile.isGuest,
  profile: state.profile,
});

const mapDispatchToProps = (dispatch) => ({
  communityActionCreators: bindActionCreators(communityActionCreators, dispatch),
  search: bindActionCreators(searchActionCreators, dispatch),
  dispatch
});

export const Search = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchComponent);

export default Search;
