import { progressActionCreators } from '../../../../components/Progress';

import * as commonConstants from '../../../../common/Constants';
import actions from './QuickLinks.actions';
import {getAuthFromState} from '../../../../auth/authHandler';
import {httpServiceSecureGet} from '../../../../common/Http';

export const quickLinksActionCreators = {
  /**
   * Quick links
   */
  requestFetchQuickLinks: () => async (dispatch, getState) => {
    if (getState().quickLinks.isLoading) {
      return;
    }

    dispatch({
      type: actions.QUICKLINKS_FETCH_REQUEST
    });

    /**
     * Clear all pages in the search results
     */

    try {
      /**
       * Search all when there are no search terms but this is currently now allowed in the custom API
       */
      const { gtToken } = await getAuthFromState(getState, dispatch);
      const data: any = await httpServiceSecureGet('/Quicklinks', gtToken);
      const quickLinks: any[] = [];

      JSON.parse(data).d.results.forEach((link) => {
        const linkData: any = {
          id: link.ID,
          title: link.Title,
          description: link.Reference_x0020_Link.Description,
          url: link.Reference_x0020_Link.Url
        };

        quickLinks.push(linkData);
      });

      dispatch({
        type: actions.QUICKLINKS_FETCH_RESPONSE,
        error:null,
        quickLinks
      });

      dispatch(progressActionCreators.endShowProgress());
    } catch (error) {
      
      dispatch({
        type: actions.QUICKLINKS_FETCH_RESPONSE,
        error:commonConstants.ERROR_QUICKLINKS_FETCH
      });
      dispatch(progressActionCreators.endShowProgress());
    }
  }
};

export default quickLinksActionCreators;
