import * as React from 'react';
import { connect } from 'react-redux';
import { BRANDNAME } from '../../common/Tooltips';

const Error_GroupMailbox = props => (
  <div>
    <h2 className="heading-error">You are unable to register for {BRANDNAME} using a group mailbox</h2>
    <p>
      Unfortunately, you are attempting to register for {BRANDNAME} using a group mailbox. All {BRANDNAME} usage must be traceable to a single user for auditing purposes, so it is a requirement that you use your individual email address when creating your {BRANDNAME} account.
    </p>
    <p>
      You will need to contact the person who invited the group mailbox to instead invite you, and any other person who needs to register for {BRANDNAME}, using their individual email address.
    </p>
   
  </div>
);

export default connect()(Error_GroupMailbox);
