/**
 * InviteMembersModal item displayed in the InviteMembersModal panel.
 */

/**
 * React Redux
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { inviteMembersActionCreators } from '../../controllers/InviteMembersController';

import InviteMembersBulkForm from './InviteMembersBulkForm';
import InviteMembersPickerForm from './InviteMembersPickerForm';

/**
 * Fabric UI
 */
import { Dialog } from '@fluentui/react/lib/Dialog';

export class InviteMembersModalComponent extends React.Component<any, any> {
  public render() {
    return (
      <React.Fragment>
        {!this.props.isLoading && (
          <Dialog
            hidden={!this.props.isModalOpen}
            onDismiss={this.onDismiss}
            modalProps={{
              isBlocking: true,
              isDarkOverlay: true,
            }}
            minWidth={800}
          >
            {!this.props.isBulkInvite ? (
              <InviteMembersPickerForm />
            ) : (
              <InviteMembersBulkForm />
            )}
          </Dialog>
        )}
      </React.Fragment>
    );
  }

  private onDismiss = (): void => {
    this.props.inviteMembersActionCreators.endEditInviteMembers();
  };
}

const mapStateToProps = (state: any) => ({
  isModalOpen: state.inviteMembers.isModalOpen,
  isBulkInvite: state.inviteMembers.isBulkInvite,
  isLoading: state.community.isLoading
});

const mapDispatchToProps = (dispatch: any) => ({
  inviteMembersActionCreators: bindActionCreators(
    inviteMembersActionCreators,
    dispatch
  )
});

export const InviteMembersModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteMembersModalComponent);

export default InviteMembersModal;
