import * as React from 'react';
import { ActionButton } from '@fluentui/react/lib/Button';
import { Icon } from '@fluentui/react/lib/Icon';

import CommunityGridSection from './CommunityGridSection';
import { MemberType } from '../../../../controllers/CommunityController';

const CommunityGridOpenCommunities = (props) => {
  const [expandRow, setExpandRow] = React.useState<boolean>(false);
  const {
    memberType,
    openCommunities,
    isLoading,
  } = props;

  return (
    <div className={'communityDashboard-topOpenCommunities'}>
      {(memberType === MemberType.FAM ||
        memberType === MemberType.OrganisationAdmin ||
        memberType === MemberType.StateLicensedAccount ||
        memberType === MemberType.Partner
      ) && (
        <div className='communityDashboard-section'>
          <div className='communityDashboard-sectionHeader'>
            <h3>
              Join Open Communities{' '}
              {openCommunities && (
                <span className='heading-meta'>
                    ({openCommunities.length})
                  </span>
              )}
            </h3>
            <div className='communityDashboard-sectionTools'>
              {openCommunities?.length > 0 && (
                <ActionButton
                  className='communityAction'
                  onClick={(ev) => {
                    setExpandRow(prev => !prev);
                  }}
                >
                  {!expandRow ? 'See more' : 'See less'}
                  <Icon
                    iconName={!expandRow ? 'ChevronDown' : 'ChevronUp'}
                  />
                </ActionButton>
              )}
            </div>
          </div>
          <p style={{lineHeight:'22px',fontWeight:'500'}}><i>You can join any Open Community in GovTEAMS. Click the Join Community icon to become a member!</i></p>
          <div
            className={`community-ListGrid${expandRow ? ' expand' : ''}`}
          >
            <CommunityGridSection
              items={openCommunities}
              isLoading={isLoading}
              isTopOpenCommunity={true}
            />
          </div>
          <ActionButton
            className='communityAction'
            onClick={(ev) => {
              setExpandRow(prev => !prev);
            }}
            style={{
              display: expandRow ? 'block' : 'none'
            }}
          >
            See less <Icon iconName='ChevronUp' />
          </ActionButton>
        </div>
      )}
    </div>
  )
};

export default CommunityGridOpenCommunities;