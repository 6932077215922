/**
 * Panel accessed from help button in app header
 *
 */

/**
 * React
 */
import React, { useState, useEffect } from 'react';

/**
 * Redux
 */
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { organisationActionCreators } from '../../controllers/OrganisationController';
import { organisationSummaryActionCreators } from './../../screens/OrganisationSummary';
/**
 * Fabric UI
 */
import { IconButton } from '@fluentui/react/lib/Button';
import { Link } from '@fluentui/react/lib/Link';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';

/**
 * Constants and utilities
 */
import * as constants from '../../common/Constants';
import { IOrganisation } from '../../controllers/OrganisationController';
import { saveDashboardInteractionEvent } from '../../common/EventTracking';

export const OrganisationPanelComponent = (props) => {
  const { organisations, organisationActionCreators, organisationSummaryActionCreators } = props;
  const [ isVisible, setIsVisible ] = useState(false);

  const showPanel = (): void => {
    setIsVisible(true);
    saveDashboardInteractionEvent('manage_organisation_clicked',  'manage organisations');
  };

  const panelDismiss = (): void => {
    setIsVisible(false);
  };

  useEffect( () => {
    setTimeout(organisationActionCreators.requestFetchAllOrganisations, 2500);
  }, [organisationActionCreators.requestFetchAllOrganisations]);

  return (
      <React.Fragment>
        {organisations && organisations.length > 0 && (
          <div className='govTeams-help'>
            <IconButton
                iconProps={{ iconName: 'Org' }}
                title='View and manage organisations'
                onClick={showPanel}
            />
            <Panel
                className='govTeams-panel'
                isOpen={isVisible}
                onDismiss={panelDismiss}
                isBlocking={true}
                isLightDismiss={true}
                customWidth='450px'
                type={PanelType.custom}
            >
              <div className='org-panel'>
                <div className='panel-content'>
                  <h2>Manage organisation</h2>
                  <ul className='commandBar-tools'>
                    {organisations &&
                        organisations.map(
                            (org: IOrganisation, index: number) => {
                              return (
                                  <li key={index}>
                                    <Link
                                        onClick={(event: any) => {
                                          panelDismiss();
                                          organisationSummaryActionCreators.startOrganisationManage(
                                              event.currentTarget.getAttribute(
                                                  'data-orgid'
                                              )
                                          );
                                          props.dispatch(
                                              replace(
                                                  `${
                                                      constants.SCREEN_ORGANISATION
                                                  }/${event.currentTarget.getAttribute(
                                                      'data-orgid'
                                                  )}`
                                              )
                                          );
                                          event.preventDefault();
                                          event.stopPropagation();
                                        }}
                                        data-orgid={org.id}
                                    >
                                      {org.name}
                                    </Link>
                                  </li>
                              );
                            }
                        )}
                  </ul>
                </div>
              </div>
            </Panel>
          </div>
        )}
      </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  organisations: state.organisation.organisations
});

const mapDispatchToProps = (dispatch) => ({
  organisationSummaryActionCreators: bindActionCreators(
    organisationSummaryActionCreators,
    dispatch
  ),
  organisationActionCreators: bindActionCreators(
    organisationActionCreators,
    dispatch
  ),
  dispatch
});

export const OrganisationPanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganisationPanelComponent);

export default OrganisationPanel;
