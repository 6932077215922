import { BRANDNAME } from './Tooltips';


export const APP_CONFIG = {
    o365: 'https://www.office.com',
    sharepoint: 'https://' + process.env.REACT_APP_SHAREPOINTTENANT + '.sharepoint.com'
};

/**
 * Screen paths
 */
export const SCREEN_LOGOUTREDIRECT = 'https://www.govteams.gov.au';
export const SCREEN_DASHBOARD = '/';
export const SCREEN_COMMUNITY = '/Community';
export const SCREEN_COMMUNITYMANAGE = '/Community/Manage';
export const SCREEN_COMMUNITYMEMBERS = '/Community/Members';
export const SCREEN_COMMUNITYINVITEMEMBERS = '/Community/InviteMembers';
export const SCREEN_PROFILE = '/Profile';
export const SCREEN_LOGGING_OUT = '/LoggingOut';
export const SCREEN_LOGOUT = '/Logout';
export const SCREEN_GOODBYE = '/Goodbye';
export const SCREEN_CONFIRMATIONSUCCESS = '/ConfirmationSuccess';
export const SCREEN_CONFIRMATIONERROR = '/ConfirmationError';
export const SCREEN_CONFIRMATIONALREADYACTIVE = '/AlreadyActive';
export const SCREEN_CONFIRMATIONVERIFICATIONSUCCESS = '/VerificationSuccess';
export const SCREEN_CONFIRMATIONEMAILCHANGED = '/EmailAlreadyChanged';
export const SCREEN_THANKYOU = '/ThankyouForRegistering';
export const SCREEN_PUBLIC = '/';
export const SCREEN_SEARCH = '/Search';
export const SCREEN_SEARCHMEMBERS = '/SearchMembers';
export const SCREEN_SEARCHDOCUMENTS = '/SearchDocuments';
export const SCREEN_BOTTERMSCONDITIONS = '/TermsAndConditions';
export const SCREEN_ERROR = '/Error/';
export const SCREEN_ADMIN = '/Admin';
export const SCREEN_ADMIN_COMMUNITYACTIVITIES = '/Admin/CommunityActivities';
export const SCREEN_ADMIN_ALLMEMBERS = '/Admin/AllMembers';
export const SCREEN_ORGANISATION = '/Organisation';
export const SCREEN_REACTIVATE = '/Reactivate';
export const SCREEN_VERIFY = '/Verify';
export const SCREEN_VERIFIED = '/Verified';
export const SCREEN_VERIFIED_EMAIL_CHANGE = '/VerifiedEmailChange';
export const SCREEN_PENDING = '/LicencePending';
export const SCREEN_BLOCKED = '/LicenceBlocked';
export const SCREEN_APPROVAL_PENDING = '/ApprovalPending';
export const SCREEN_UNTRUSTED_NETWORK = '/UntrustedNetwork';
export const SCREEN_EXTERNAL_MEMBERS = '/ExternalMembersList';

/**
 * Header paths
 */
export const isProtected = process.env.REACT_APP_ISPROTECTED === 'true';

export const WAFFLE_GOVTEAMSAPP = 'https://' + process.env.REACT_APP_SHAREPOINTTENANT + '.sharepoint.com/sites/GovTEAMSApp';
export const WAFFLE_TEAMS = 'https://teams.microsoft.com/?tenant=' + process.env.REACT_APP_TENANTID;
export const WAFFLE_O365 = 'https://www.office.com/';
export const WAFFLE_DELVE = 'https://aus.delve.office.com/';
export const WAFFLE_SHAREPOINT = 'https://' + process.env.REACT_APP_SHAREPOINTTENANT + '.sharepoint.com/_layouts/15/sharepoint.aspx';
export const WAFFLE_PLANNER ='https://tasks.office.com/' + process.env.REACT_APP_TENANTDOMAIN + '/en-US/Home/Planner/';
export const WAFFLE_OUTLOOK = 'https://outlook.office365.com/';
export const WAFFLE_CALENDAR ='https://outlook.office.com/owa/?realm=' + process.env.REACT_APP_TENANTDOMAIN + '&exsvurl=1&ll-cc=1033&modurl=1';
export const WAFFLE_ONEDRIVE =
  'https://' + process.env.REACT_APP_SHAREPOINTTENANT + '-my.sharepoint.com/_layouts/15/MySite.aspx?MySiteRedirect=AllDocuments';
export const WAFFLE_ALLAPPS = 'https://www.office.com/apps?home=1&auth=2';

export const PROFILE_LINKPASSWORD =
  'https://account.activedirectory.windowsazure.com/ChangePassword.aspx?BrandContextID=O365&ruO365=';
export const PROFILE_LINKMFA =
  'https://mysignins.microsoft.com/security-info?tenant=' + process.env.REACT_APP_TENANTID;
export const PROFILE_VIRTUALDESKTOP = 'https://rdweb.wvd.microsoft.com/webclient/index.html';
export const PROFILE_PROTECTED = isProtected;

/**
 * Text
 */
export const LINK_BACKTODASHBOARD = 'Back to Dashboard';
export const LINK_BACKTOORGMANAGEMENT = 'Back to Organisation Summary';
export const LINK_BACKTOEXTERNALMEMBERSVIEW = 'Back to External Members List';

export const MESSAGE_MEMBERS_DELETE =
  "We've received your request to remove a user from your community. It's going to take about an hour to remove the users access from all systems.";
export const MESSAGE_MEMBERS_LEAVE =
  "We've received your request to leave this community. It might take a few minutes for the community to disappear from dashboard.";
export const MESSAGE_MEMBERS_PROMOTE =
  "We've received your request to promote a member to become an owner of your community. It's going to take about an hour to promote the user to an owner of the community across all systems.";
export const MESSAGE_MEMBERS_DEMOTE =
  "We've received your request to demote an owner to a member of your community. It's going to take about an hour to remove the users owner level access from all systems.";
export const MESSAGE_PROFILE_SAVE = 'Profile has been saved successfully.';
export const MESSAGE_PROFILE_SAVE_ERROR = 'Profile has not been saved.';
export const MESSAGE_USER_NOT_IN_TEAM =
  'Your access to Teams is not ready yet! It can take a while to process access to a Team. Please try again later.';
export const MESSAGE_PROFILE_VERIFY_SUCCESS =
  'You have successfully verified your email address.';
export const MESSAGE_PROFILE_EMAIL_CHANGE_SUCCESS =
  'You have successfully changed your email address.';
export const MESSAGE_PROFILE_VERIFY_FAIL =
    'We were unable to verify your account as your verification link is no longer valid. To generate a new verification link, go the \'Verify Account\' page and click the \'Resend link\' button. Click the link in the new email to verify your account. If you continue to have issue, please raise a support request with the GovTEAMS Helpdesk.';
export const MESSAGE_PROFILE_VERIFY_RESEND =
  'Verification email has been re-sent successfully.';
export const MESSAGE_PROFILE_DEACTIVATED =
  'Your account has been deactivated successfully.';
export const MESSAGE_PROFILE_EMAIL_SAVE = 'Email has been saved successfully.';
export const MESSAGE_PROFILE_EMAIL_RESEND =
  'Email has been re-sent successfully.';
export const MESSAGE_PROFILE_PENDING_EMAIL =
  "Pending request to update your email address to <strong>&lt;%EMAILADDRESS%&gt;</strong>. We've sent an email to your new address to confirm the change.";
export const MESSAGE_PROFILE_EMAIL_CHANGE_HAS_BEEN_PROCESSED =
    'The pending email address change has already been processed.';

export const ERROR_COMMUNITY_FETCH = 'Invalid response on fetching community';
export const ERROR_OWNERCOMMUNITIES_FETCH =
  'Invalid response on fetching owner communities';
export const ERROR_MEMBERCOMMUNITIES_FETCH =
  'Invalid response on fetching member communities';
export const ERROR_FAVOURITECOMMUNITIES_FETCH =
  'Invalid response on fetching your favourite communities';
export const ERROR_SUGGESTEDCOMMUNITIES_FETCH =
  'Invalid response on fetching suggested communities';
export const ERROR_OPENCOMMUNITIES_FETCH =
  'Invalid response on fetching open communities';
export const ERROR_SEARCHCOMMUNITIES_FETCH =
  'Invalid response on fetching search communities';
export const ERROR_REQUESTS_FETCH = 'Invalid response on fetch requests';
export const ERROR_REQUEST_CHANGE = 'Invalid response on change request';
export const ERROR_REQUEST_ADD = 'Invalid response on add request access';
export const ERROR_DOCUMENTS_FETCH = 'Invalid response on fetch documents';
export const ERROR_MEMBERS_FETCH = 'Invalid response on fetch members';
export const ERROR_INVITEMEMBERS_SAVE = 'Invalid response on invite members';
export const ERROR_INVITES_FETCH = 'Invalid response for fetching invites';
export const ERROR_INVITES_DELETE = 'Invalid response for deleting invite';
export const ERROR_INVITES_RESEND = 'Invalid response for resending invite';
export const ERROR_PROFILE_FETCH = 'Invalid response on fetch profile';
export const ERROR_PROFILE_SAVE = 'Invalid response on saving profile';
export const ERROR_PROFILE_EMAIL_SAVE = 'Invalid response on saving email';
export const ERROR_PROFILE_DEACTIVATION =
  'Invalid response on deactivating account';
export const ERROR_PROFILE_VERIFY = 'Invalid response on verify profile';
export const ERROR_PROFILE_EMPTYFIELD =
  "You haven't filled in this part of your profile";
export const ERROR_PROFILE_EMPTYFIELD_ABOUT =
  'Tell your story to help others get to know you';
export const ERROR_PROFILE_EMPTYFIELD_PROJECTS = 'Add a project title';
export const ERROR_PROFILE_EMPTYFIELD_SKILLS = 'Add skills';
export const ERROR_PROFILE_EMPTYFIELD_TOPICS = 'Add a topic';
export const ERROR_PROFILE_EMPTYFIELD_SCHOOLS = 'Add a school name';
export const ERROR_PROFILE_EMPTYFIELD_INTERESTS = 'Add an interest or hobby';
export const ERROR_SEARCH_WARNING =
  'Your query cannot be empty or more than 256 characters long';
export const ERROR_USER_IN_TEAM =
  'Invalid response on fetching team membership';
export const ERROR_QUICKLINKS_FETCH = 'Invalid response on fetch quicklinks';
export const ERROR_NEWS_FETCH = 'Invalid response on fetch News';
export const ERROR_FEED_FETCH = 'Invalid response on feed News';
export const ERROR_STATISTICSTOTAL_FETCH = 'Invalid response on fetch statistics total.';

export const PROGRESS_MEMBERS_FETCH = 'Fetching community members...';
export const PROGRESS_MEMBERS_DELETE = 'Deleting community members...';
export const PROGRESS_MEMBERS_LEAVE = 'Leaving community...';
export const PROGRESS_MEMBERS_PROMOTE = 'Promoting community members...';
export const PROGRESS_MEMBERS_DEMOTE = 'Demoting community members...';
export const PROGRESS_CHECKING_TEAM_MEMBERSHIP = 'Checking Team membership...';
export const PROGRESS_COMMUNITIES_OWNER =
  'Fetching communities you are an owner of...';
export const PROGRESS_COMMUNITIES_MEMBER =
  'Fetching communities you are a member of...';
export const PROGRESS_COMMUNITIES_FAVOURITE =
  'Fetching your favourite communities....';
export const PROGRESS_COMMUNITIES_SUGGESTED =
  'Fetching suggested communities..';



export const TEXT_INVITEMEMBERS = `<p>Invite members and guests to your community by entering their email address in the box below. To send multiple invites, separate each email address with a semicolon or a new line e.g: <b>govemployee@govdomain.gov.au; guest@domain.com</b></p><p>You're using the bulk invitation process so it's going to take some time to work through the list. We will send you an email once we've finished inviting these members and guests to your community. This email will tell you the status of each person invited.</p>`;

export const TEXT_INVITEMEMBERS_BULK_GTO = `<p>Invite members to your community by entering their email address in the box below. To send multiple invites, separate each email address with a semicolon or a new line e.g: <strong>govemployee@govdomain.gov.au; partneremployee@domain.com</strong></p><P>You're using the bulk invitation process so it's going to take some time to work through the list. We will send you an email once we've finished inviting these members to your community. This email will tell you the status of each person invited.</p>`

export const TEXT_INVITEMEMBERS_BULK_GTP = `<p>Invite members to your community by entering their email address in the box below. To send multiple invites, separate each email address with a semicolon or a new line e.g: <strong>govemployee@govdomain.gov.au; partneremployee@domain.com</strong></p><P>You're using the bulk invitation process so it's going to take some time to work through the list. We will send you an email once we've finished inviting these members to your community. This email will tell you the status of each person invited.</p><p><strong>Participants must have</strong> (at minimum) an active baseline clearance to access the system (or an agreement/ arrangement in line with the PSPF and acceptable to the Department of Finance).</p><p><strong>External members</strong> can only invite existing users to communities they own.</p>`

export const TEXT_INVITEMEMBERS_BULK = isProtected ? TEXT_INVITEMEMBERS_BULK_GTP : TEXT_INVITEMEMBERS_BULK_GTO;

export const TEXT_INVITEMEMBERS_INFO_GTP =
  `<h2>Inviting external members?</h2><p>Commonwealth Government members can invite members from partner organisations (external to Commonwealth Government organisations) to participate in their community as external members.</p ><p><strong>Note:</strong> Your organisation will be charged a licence fee for any new external members you invited to a community.</p>`;

export const TEXT_INVITEMEMBERS_INFO_GTO =
  `<p><b>Existing ${BRANDNAME} users</b> will receive an email to notify them that they have been added to your community.</p><p><b>New members who do not have an existing ${BRANDNAME} account</b> will receive an invite with information about how to register and access your community. The community name and description will be included in the invite email.</p >`;

export const TEXT_INVITEMEMBERS_INFO = isProtected ? TEXT_INVITEMEMBERS_INFO_GTP : TEXT_INVITEMEMBERS_INFO_GTO;


export const TEXT_INVITEMEMBERS_ALERTTITLE =
  'Are you sure you want to leave this page?';
export const TEXT_INVITEMEMBERS_ALERTTEXT =
  'You have not sent your invitations. Your changes may be lost.';
export const TEXT_INVITEMEMBERS_SUCCESSTITLE =
  "Success! We're sending your invitations.";
export const TEXT_INVITEMEMBERS_SUCCESSTEXT =
  `<p>We've received your request to invite community members. Existing ${BRANDNAME} users will receive an email notification advising they have been added to your community and can begin collaborating right away. New members who do not have an existing ${BRANDNAME} account will receive an invite with information about how to register and access your community.</p>`;
export const TEXT_INVITEMEMBERSBULK_SUCCESSTEXT =
  "<p>We've received your request to invite members to your community. You've used the bulk invitation process so it's going to take some time to work through the list. We will send you an email once we've finished inviting these members and guests to your community. This email will tell you the status of each person invited.</p><p><i>If you're inviting more than 50 people, we will batch up and process your invitation 50 at a time.</i></p>";
export const TEXT_COMMUNITYTOOLS_PROCESSING =
  "We are in the process of creating your community now and it's going to take about an hour. We'll send you an email when it's ready to use.";
export const TEXT_REACTIVATE_EMAILCHANGE =
  'If you’ve moved organisations and no longer have access to the email address listed above, you can update it below. You’ll be sent an email to the new address to confirm the change.';
export const TEXT_REACTIVATE_REMOVE =
  'Your account is currently inactive. Your community memberships are still in place, however you can choose to remove yourself from these communities by clicking the button below.';
export const TEXT_COMMUNITYGRID_NOSUGGESTEDCOMMUNITIES =
  'Fill in your profile to help us find communities that may be of interest';

export const RECENT_DOCUMENTS_MY = 'My documents';
export const RECENT_DOCUMENTS_POPULAR = 'Popular documents';

export const POPUP_COMMUNITY_CLOSE =
  "Are you sure you want to delete the community '%DISPLAYNAME%'? All channels, chats, files and the Office 365 Group for this community will be deleted. You should backup/export all content before you delete the community.";

export const LINKEDACCOUNT_PROFILEMESSAGE =
  'This is your linked account. To modify your profile, please use your primary account.';
