import { getAuthFromState } from '../../auth';
import { httpServiceSecureGet } from '../../common/Http';

const actions = {
  REFERENCE_FETCH_REQUEST: 'REFERENCE_FETCH_REQUEST',
  REFERENCE_FETCH_RESPONSE: 'REFERENCE_FETCH_RESPONSE'
};

const initialState = {
  departments: null,
  isLoading: false,
  error: null
};

export const referencesActionCreators = {
  requestFetchReference: (referenceName) => async (dispatch, getState) => {
    if (getState().references[referenceName] != null) {
      return;
    }

    if (getState().references.isLoading) {
      return;
    }

    dispatch({ type: actions.REFERENCE_FETCH_REQUEST });

    try {
      const { gtToken } = await getAuthFromState(getState, dispatch);
      const json: any = await httpServiceSecureGet(`/references/${referenceName}`, gtToken);
      const references: any[] = [];

      json.forEach((reference) => {
        const ref = {
          key: reference.id,
          text: reference.name
        };
        references.push(ref);
      });

      dispatch({
        type: actions.REFERENCE_FETCH_RESPONSE,
        referenceName,
        references
      });
    } catch (error) {
      alert('Invalid response on fetch reference ' + referenceName);
      dispatch({
        type: actions.REFERENCE_FETCH_RESPONSE,
        error
      });
    }
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  //UI
  if (action.type === actions.REFERENCE_FETCH_REQUEST) {
    return {
      ...state,
      isLoading: true
    };
  }

  if (action.type === actions.REFERENCE_FETCH_RESPONSE) {
    const newState = {
      ...state,
      isLoading: false,
      error: action.error
    };

    if (action.referenceName != null) {
      newState[action.referenceName] = action.references;
    }

    return newState;
  }

  return state;
};

export default reducer;
