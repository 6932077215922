import React from 'react';
import {Route} from 'react-router';
import {AuthenticatedTemplate, UnauthenticatedTemplate} from '@azure/msal-react';
import {useLocation} from 'react-router-dom';

import AuthLayout from '../../screens/AuthLayout';
import AppLoginRedirect from './AppLoginRedirect';
import { savePageViewEvent } from '../../common/EventTracking';

const AppSecureRoute = props => {
  const {component: Component, layout: Layout, authActionCreators: any, ...rest} = props;
  const location = useLocation();

  React.useEffect(() => {
    savePageViewEvent(location);
  }, [location]);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <AuthenticatedTemplate>
            <AuthLayout>
              <Layout>
                <Component
                  {...props}
                />
              </Layout>
            </AuthLayout>
          </AuthenticatedTemplate>

          <UnauthenticatedTemplate>
            <AppLoginRedirect/>
          </UnauthenticatedTemplate>
        </>
      )}
    />
  );
};

export default AppSecureRoute;
