/**
 * Onboarding cards for first logins
 *
 */

/**
 * React Redux
 */
import * as React from 'react';
import { connect } from 'react-redux';

/**
 * Fabric UI
 */
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { DialogFooter } from '@fluentui/react/lib/Dialog';
import { Icon } from '@fluentui/react/lib/Icon';
import { Label } from '@fluentui/react/lib/Label';
import { IPivotItemProps, Pivot, PivotItem } from '@fluentui/react/lib/Pivot';

import Welcome from './../../images/Welcome.png';
import YouAreHere from './../../images/YouAreHere.png';
import Communities from './../../images/Communities.png';
import TeamsAndSharePoint from './../../images/TeamsAndSharePoint.png';
import HelpfulInfo from './../../images/HelpfulInfo.png';
import { BRANDNAME } from '../../../../common/Tooltips';

class OnboardingFAMContent extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      selectedKey: '1',
      show: true
    };
  }

  public render() {
    return (
      <>
<div>
  {this.state.selectedKey === '1' && (
            <div>
              <img src={Welcome} style={{ width: '100%' }} alt={''} />
                <div className='dialogTitle'>Welcome to<br/>{BRANDNAME}</div>
              <div className='dialogText'>
                Let's take a look around and get you started
              </div>
            </div>
          )}

  {this.state.selectedKey === '2' && (
            <div className='dialogSection'>
              <div className='dialogSection-text'>
                <h2>{BRANDNAME} Dashboard</h2>
                <p>
                  You've reached the {BRANDNAME} Dashboard – from here you can
                  update your account information, create communities and search
                  for ones you’d like to join.
                </p>
                <p>
                  You can also search for {BRANDNAME} members and access Microsoft
                  Tools.
                </p>
              </div>
              <div className='dialogSection-image'>
                <img src={YouAreHere} style={{ width: '100%' }} alt={''} />
              </div>
            </div>
          )}

  {this.state.selectedKey === '3' && (
            <div className='dialogSection'>
              <div className='dialogSection-text'>
                <h2>Communities</h2>
                <p>A community is a group of users collaborating together in {BRANDNAME}. Each community has access to Microsoft Office 365 software.</p>
                <p>Search and join existing communities – create your own and invite people to collaborate.</p>
                <p>Use Microsoft Teams and SharePoint to work together in your community.</p>
              </div>
              <div className='dialogSection-image'>
                <img src={Communities} style={{ width: '100%' }} alt={''} />
              </div>
            </div>
          )}

  {this.state.selectedKey === '4' && (
            <div className='dialogSection'>
              <div className='dialogSection-text'>
                <h2>Working together</h2>
                <p>Use Microsoft Teams to connect, share and work together – meet online with video or audio, instantly connect through chat and collaborate on documents at the same time.</p>
                <p>Broadcast, share and structure your community information with SharePoint.</p>
                <p>To start connecting, click the Teams or SharePoint icon from the Dashboard.</p>
              </div>
              <div className='dialogSection-image'>
                <img src={TeamsAndSharePoint} style={{ width: '100%' }} alt={''} />
              </div>
            </div>
          )}
  {this.state.selectedKey === '5' && (
            <div className='dialogSection'>
              <div className='dialogSection-text'>
                <h2>We’re here to help!</h2>
                <p>For common queries, visit our help centre on the GovTEAMS website.</p>
              </div>
              <div className='dialogSection-image'>
                <img src={HelpfulInfo} style={{ width: '100%' }} alt={''} />
              </div>
            </div>
          )}
</div>
<DialogFooter>
  <div className='dialogFooter-top'>
    <div className='nextButton-label'>
      {this.getDialogFooterLabel(this.state.selectedKey)}
    </div>
    <PrimaryButton
      onClick={this.onNextClick}
      styles={{ root: { height: 42 } }}
    >
      {parseInt(this.state.selectedKey, 10) < 5 ? 'Next' : 'Go'}
    </PrimaryButton>
  </div>
  <Pivot
    onLinkClick={this.onLinkClick}
    headersOnly={true}
    selectedKey={this.state.selectedKey}
  >
    <PivotItem itemKey='1' onRenderItemLink={this.onRenderItemLink}>
      <Label>Welcome to {BRANDNAME}</Label>
    </PivotItem>
    <PivotItem itemKey='2' onRenderItemLink={this.onRenderItemLink}>
      <Label>Personalise your experience</Label>
    </PivotItem>
    <PivotItem itemKey='3' onRenderItemLink={this.onRenderItemLink}>
      <Label>Create communities</Label>
    </PivotItem>
    <PivotItem itemKey='4' onRenderItemLink={this.onRenderItemLink}>
      <Label>Interact with members</Label>
    </PivotItem>
    <PivotItem itemKey='5' onRenderItemLink={this.onRenderItemLink}>
      <Label>Interact with members</Label>
    </PivotItem>
  </Pivot>
</DialogFooter>
</>
    );
  }

  private getDialogFooterLabel(index: string) {
    switch (index) {
      case '1':
        return '';
      case '2':
        return `Let's explore communities.`;
      case '3':
        return `Let's explore how to work together in your community.`;
      case '4':
        return `Let's find out how to get more out of ${BRANDNAME}.`;
      case '5':
        return `Working together has never been easier, let's get started!`;
      default:
        return '';
    }
  }

  private onRenderItemLink = (item: IPivotItemProps) => {
    return <Icon iconName='CircleFill' />;
  };

  private onNextClick = (event) => {
    const nextKey = parseInt(this.state.selectedKey, 10) + 1;
    // console.log(nextKey);

    if (nextKey < 6) {
      this.setState({
        selectedKey: nextKey.toString()
      });
    } else {
      if(this.props.onComplete) {
        this.props.onComplete();
      }
    }
  };

  private onLinkClick = (item: PivotItem) => {
    this.setState({
      selectedKey: item.props.itemKey
    });
  };
}

const mapStateToProps = (state: any) => ({
  profile: state.profile
});

export default connect(mapStateToProps, {})(OnboardingFAMContent);
