import actions from './Search.actions';

const initialState = {
  filter: '',
  searchCommunities: null,
  searchDocuments: null,
  searchMembers: null,
  recentDocuments: null,
  popularDocuments: null,
  totalDocuments: 0,
  skipToken: '',
  isLoading: false,
  error: null,
  currentPage: 0
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === actions.SEARCH_CLEAR) {
    return {
      ...state,
      searchCommunities: null,
      searchDocuments: null,
      searchMembers: null,
      skipToken: '',
      totalDocuments: 0,
      isLoading: false
    };
  }

  if (action.type === actions.SEARCHCOMMUNITY_FETCH_REQUEST) {
    return {
      ...state,
      isLoading: true
    };
  }

  if (action.type === actions.SEARCHCOMMUNITY_FETCH_RESPONSE) {
    return {
      ...state,
      filter: action.filter,
      searchCommunities: action.searchCommunities,
      isLoading: false,
      error: action.error
    };
  }

  if (action.type === actions.SEARCHDOCUMENTS_FETCH_REQUEST) {
    return {
      ...state,
      isLoading: true,
      currentPage: action.currentPage
    };
  }

  if (action.type === actions.SEARCHDOCUMENTS_FETCH_RESPONSE) {
    return {
      ...state,
      filter: action.filter,
      searchDocuments: action.searchDocuments,
      totalDocuments: action.totalDocuments,
      isLoading: false,
      error: action.error
    };
  }

  if (action.type === actions.RECENTDOCUMENTS_FETCH_REQUEST) {
    return {
      ...state,
      isLoading: true
    };
  }

  if (action.type === actions.RECENTDOCUMENTS_FETCH_RESPONSE) {
    return {
      ...state,
      recentDocuments: action.recentDocuments,
      isLoading: false,
      error: action.error
    };
  }

  if (action.type === actions.SEARCHMEMBERS_FETCH_REQUEST) {
    return {
      ...state,
      isLoading: true,
      currentPage: action.currentPage
    };
  }

  if (action.type === actions.SEARCHMEMBERS_FETCH_RESPONSE) {
    return {
      ...state,
      filter: action.filter,
      searchMembers: action.searchMembers,
      skipToken: action.skipToken,
      isLoading: false,
      error: action.error
    };
  }

  if (action.type === actions.SET_CURRENT_PAGE) {
    return {
      ...state,
      currentPage: action.currentPage
    };
  }

  return state;
};

export default reducer;
