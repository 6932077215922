/**
 * React
 */
import * as React from 'react';

/**
 * Redux
 */
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { replace } from 'react-router-redux';
import { bindActionCreators } from 'redux';

import {
  organisationActionCreators,
  IOrganisationState,
  IOrganisationProps
} from '../../../controllers/OrganisationController';

/**
 * React form
 */
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { TooltipLabel } from './../../../components/ReduxFormFields/FormFieldUtils';

/**
 * Fabric UI
 */
import {
  ActionButton,
  PrimaryButton,
  DefaultButton
} from '@fluentui/react/lib/Button';

/**
 * React form field
 */
import TextField from '../../../components/ReduxFormFields/TextField';
import Toggle from '../../../components/ReduxFormFields/Toggle';

/**
 * Components
 */
import * as constants from '../../../common/Constants';
import { BRANDNAME } from '../../../common/Tooltips';

type IMergedProps = IOrganisationState &
  IOrganisationProps &
  RouteComponentProps &
  InjectedFormProps;

export class OrganisationEditComponent extends React.Component<
  IMergedProps,
  any
> {
  constructor(props) {
    super(props);

    this.state = {
      enableMessaging: false
    };
  }

  public render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <div>
        <ActionButton
          className="govTeams-actionButton"
          onClick={(event: any) => {
            this.props.organisationActionCreators.resetEditForm();
            this.props.dispatch(
              replace(
                `${constants.SCREEN_ORGANISATION}/${
                  this.props.match.params['organisationId']
                }`
              )
            );
          }}
          iconProps={{ iconName: 'ChevronLeft' }}>
          Back to organisation
        </ActionButton>

        <div className="formGrid formBasic">
          <div className="formGrid-row">
            <div className="formBody">
              <React.Fragment>
                <h1>Organisation terms of use</h1>
                <hr/>
                <p>
                  You can choose whether to display custom terms and conditions to any user registering for {BRANDNAME} from your organisation.
                </p>
                <p>{`For a richer experience, the message field can contain the following HTML tags: <p>, </p>, <br> and &nbsp;`}</p>
                <form onSubmit={handleSubmit(this.onSubmit)}>
                  <div className="formFields">
                    <h2 className="h3">Display custom message?</h2>
                    <div className="formField">
                      <Field
                        name="enableMessaging"
                        checked={this.state.enableMessaging}
                        onChange={(event, checked) => {
                          this.setState({
                            enableMessaging: !this.state.enableMessaging
                          });
                        }}
                        component={Toggle}
                        onText="Yes"
                        offText="No"
                        parse={(value)=>(value==='true'||value===true)}
                      />
                    </div>
                    <hr/>
                    <div
                      className={`formField ${
                        this.getFieldError('messageHeading').length > 0
                          ? 'formField-error'
                          : ''
                      }`}>
                      <Field
                        aria-labelledby="Terms of use heading"
                        name="messageHeading"
                        label="Heading"
                        component={TextField}
                        required={this.state.enableMessaging}/>
                      {this.getFieldError('messageHeading').length > 0 && (
                        <p className="formField-errorMessage">
                          {
                            this.getFieldError('messageHeading')[0]
                              .errorMessage
                          }
                        </p>
                      )}
                    </div>
                    <div className="formField">

                      <div
                        className={`formField ${
                          this.getFieldError('messageText').length > 0
                            ? 'formField-error'
                            : ''
                        }`}>
                        <Field
                          aria-labelledby="Terms of use message"
                          name="messageText"
                          label="Message"
                          component={TextField}
                          required={this.state.enableMessaging}
                          multiline={true}
                          rows={10}/>
                        {this.getFieldError('messageText').length > 0 && (
                          <p className="formField-errorMessage">
                            {
                              this.getFieldError('messageText')[0]
                                .errorMessage
                            }
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="formField">
                      <TooltipLabel
                        ariaLabel="Provide a link for users in your organisation to find additional information such as acceptable use policies or standard processes."
                        required={false}
                        label="Provide a link for users in your organisation to find additional information such as acceptable use policies or standard processes."/>
                      <div
                        className={`formField ${
                          this.getFieldError('moreInfoUrl').length > 0
                            ? 'formField-error'
                            : ''
                        }`}>
                        <Field
                          aria-labelledby="Find out more hyperlink"
                          name="moreInfoUrl"
                          component={TextField}/>
                        {this.getFieldError('moreInfoUrl').length > 0 && (
                          <p className="formField-errorMessage">
                            {
                              this.getFieldError('moreInfoUrl')[0]
                                .errorMessage
                            }
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="formFooter">
                    <DefaultButton text="Reset" onClick={this.onReset}/>
                    <PrimaryButton
                      text="Save"
                      type="submit"
                      disabled={submitting}
                      className="buttonSpacing"/>
                  </div>
                </form>
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
      );
  }

public componentDidMount() {
    const organisationId =
      this.props.organisation && this.props.organisation.id !== undefined
        ? this.props.organisationId
        : this.props.match.params['organisationId'];

    this.init(organisationId);
  }

public componentDidUpdate(prevProps) {
    if (prevProps.organisationId !== this.props.organisationId) {
      this.init(this.props.organisationId);
    }

    if (!prevProps.message && this.props.message) {
      this.setState({
        enableMessaging: this.props.message.enableMessaging
      });
    }
  }

private async init(organisationId: string) {
    await this.props.organisationActionCreators.requestFetchOrganisationMessage(
      organisationId
    );

    this.props.initialize(this.props.message);
  }

private onSubmit = event => {
    this.props.organisationActionCreators.requestSaveOrganisation(
      this.props.match.params['organisationId']
    );
  };

private onReset = event => {
    this.props.reset();
  };

private getFieldError(field) {
    const errors = this.props.organisationError
      ? this.props.organisationError
    : [];
  if (Array.isArray(errors))
    {
    const found = errors.filter(error => error.propName === field);
    return found;
  }
  return errors;
  }
}

const mapStateToProps = state => ({
organisation: state.organisation.organisation,
organisationError: state.organisation.organisationError,
message: state.organisation.message,
isFormSubmitted: state.organisation.isFormSubmitted
});

const mapDispatchToProps = dispatch => ({
organisationActionCreators: bindActionCreators(
organisationActionCreators,
dispatch
),
dispatch
});

export const OrganisationEditForm = reduxForm({
  form: 'OrganisationEditForm'
})(OrganisationEditComponent);

export const OrganisationEdit = connect(
mapStateToProps,
mapDispatchToProps
)(OrganisationEditForm);

export default OrganisationEdit;