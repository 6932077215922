/**
 * React
 */
import React from 'react';
import {Icon} from '@fluentui/react/lib/Icon';
import {ActionButton} from '@fluentui/react/lib/Button';
import {Spinner, SpinnerSize} from '@fluentui/react/lib/Spinner';

import {getSharepointUrl} from '../../../../common/Utils';
import {saveDashboardInteractionEvent} from '../../../../common/EventTracking';

function InsiderFeedComponent({isLoading, feed, seeMoreLink}) {

  /**
   * Log event in GA
   * @param name
   * @param uri
   */
  const _trackEvent = (name: string, uri: string) => {
    saveDashboardInteractionEvent( 'insider_feed_clicked', name);
  };

  const _renderFeedItem = fi => {
    return (
      <div className='card' key={fi.id}>
        {fi?.imageContentType !== 'text/plain' && fi?.imageContentType !== 'application/xml' && (
          <div className='image'>
            <a
              href={getSharepointUrl(fi.link)}
              target='_blank'
              rel='noreferrer'
              onClick={() => _trackEvent(fi.title, fi.link)}
            >
              <img src={`data:${fi.imageContentType};base64, ${fi.imageContent}`} title={fi.title} alt={fi.title}/>
            </a>
          </div>
        )}
        <div className='title'>
          <a
            href={getSharepointUrl(fi.link)}
            target='_blank'
            rel='noreferrer'
            onClick={() => _trackEvent(fi.title, fi.link)}
          >
            <h3>{fi.title}</h3>
          </a>
          <p>{fi.description}</p>
        </div>
      </div>
    );
  };

  /**
   * Get valid data array
   * @param arr
   */
  const _getValidData = (arr: any) =>
    arr.filter((item: any) =>
      item &&
      item.title &&
      item.imageContentType &&
      item.imageContent &&
      item.imageContentType !== 'text/plain' &&
      item.imageContentType !== 'application/xml'
    );

  return (
    <>
      <div className='govTeams-insider-feed'>
        {isLoading && (
          <Spinner
            size={SpinnerSize.small}
            label={'Loading insider feed'}
            ariaLive='assertive'
          />
        )}
        {feed && feed.length > 0 && (
          <>
            <h2>The GovTEAMS Insider</h2>
            {_getValidData(feed).map(feedItem => _renderFeedItem(feedItem))}
          </>
        )}
      </div>
      {seeMoreLink != null && (
        <div className={'govTeams-insider-feed-see-more'}>
          <ActionButton className='profile-toggle' href={seeMoreLink} target='_blank' rel='noreferrer'>
            See more
            <Icon iconName={'ChevronRight'}/>
          </ActionButton>
        </div>
      )}
    </>

  );
}

export default InsiderFeedComponent;
