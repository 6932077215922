import {BrowserAuthError, InteractionRequiredAuthError} from '@azure/msal-browser';

import { graphConfig, loginRequest } from './authConfig';
import { IAuthToken } from '../controllers/AuthController/Auth.types';
import { fetchHandleErrors } from '../common/Http';

const _msGraphFunc = (uri: string, token: IAuthToken, method: string) => {
    const headers = new Headers();
    const bearer = `Bearer ${token.accessToken}`;

    headers.append('Authorization', bearer);

    const options = {
        method: method,
        headers: headers
    };

    const url = `${graphConfig.graphEndpoint}${uri}`;
    return fetch(url, options);
};

/**
 * Attaches a given access token to MS Graph API call. Returns information about the user
 * @param uri
 * @param token
 * @param msalInstance
 */
export const msGraphSecureGet = (uri: string, token: IAuthToken, msalInstance: any) => {
    if ( !token || !token.accessToken ) {
        console.error('Access token empty. Cannot call API');
        return Promise.reject(new Error('Cannot call API. Please login'));
    }

    return _msGraphFunc(uri, token, 'GET')
        .then(fetchHandleErrors)
        .then(response => response.json())
        .catch(error => {
            const e = error.error || error;
            console.log('Graph API error', e);
            if ( error instanceof InteractionRequiredAuthError ||
                error instanceof BrowserAuthError ||
                e === "invalid_grant" ||
                e?.code === 'InvalidAuthenticationToken' ||
                e?.code?.search(/unauthorized/i) >= 0 ||
                e?.code?.search(/authentication/i) >= 0
            ) {
                // goToLoginScreen(msalInstance).then(null);
                console.log('Graph API call failed. Redirecting to get new token');
                msalInstance.acquireTokenRedirect(loginRequest);
            }
            throw error;
        });
};

export const msGraphSecureGetRaw = (uri: string, token: IAuthToken) => {
    if ( !token || !token.accessToken ) {
        console.error('Access token empty. Cannot call API');
        return Promise.reject(new Error('Cannot call API'));
    }

    return _msGraphFunc(uri, token, 'GET')
        .then(fetchHandleErrors)
        .then(res => res.blob())
        .catch(error => {
            console.log('Graph API error', error);
            throw error;
        });
};