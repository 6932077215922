/**
 * Quick links dashboard component
 *
 */

/**
 * React
 */
import * as React from 'react';

/**
 * Redux
 */
import { connect } from 'react-redux';

/**
 * Office Fabric UI components
 */
import { Link } from '@fluentui/react/lib/Link';

/**
 * Utilities
 */
import { isEmpty } from 'lodash';

export class QuickLinksComponent extends React.Component<any, any> {
  public render() {
    return (

      <div className='govTeams-quickLinks'>
            <h2>Key information</h2>
          {!isEmpty(this.props.links) && isEmpty(this.props.error) ? (
            <ul>
            {this.props.links.map((link: any) => {
              return (
                <li key={'quick-link-' + link.ID + '-' + link.title}>
                  <Link href={link.url} target='_blank'>
                    {link.title}
                  </Link>
                  {!isEmpty(link.description) && <div>{link.description}</div>}
                </li>
              );
            })}
          </ul>
          ) : (
            <>
            {!isEmpty(this.props.error) ? (
            <p className="quicklinksError">We weren't able to retrieve this information. Please refresh your browser to try again</p>
                
            ) : (
              <></>
            // <p className="quicklinksError">Loading...</p>
          )}
          </>
          )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  isGuest: state.profile.isGuest,
  links: state.quickLinks.quickLinks,
  error: state.quickLinks.error
});

const mapDispatchToProps = (dispatch: any) => ({});

export const QuickLinks = connect(
  mapStateToProps,
  mapDispatchToProps
)(QuickLinksComponent);

export default QuickLinks;
