// import actions from './.actions';
import { notificationActionCreators } from '../../../../components/Notification';
import { progressActionCreators } from '../../../../components/Progress';

import actions from './Invites.actions';
import * as constants from '../../../../common/Constants';
import {getAuthFromState} from '../../../../auth/authHandler';
import {httpServiceSecureGet, httpServiceSecurePost, httpServiceSecureDelete} from '../../../../common/Http';

export const invitesActionCreators = {
  requestFetchInvites: (communityId) => async (dispatch, getState) => {
    if (getState().invites.isLoading) {
      return;
    }

    dispatch({ type: actions.INVITES_FETCH_REQUEST });
    dispatch(
        progressActionCreators.startShowProgress(
            'Fetching community invites...'
        )
    );

    try {
      const { gtToken } = await getAuthFromState(getState, dispatch);
      const values = await httpServiceSecureGet(`/invites/${communityId}`, gtToken);

      const pendingInvitations: any[] = [];
      const expiredInvitations: any[] = [];

      values.forEach((invite: any) => {
        if (!invite.expired) {
          pendingInvitations.push(invite);
        } else {
          expiredInvitations.push(invite);
        }
      });

      dispatch({
        type: actions.INVITES_FETCH_RESPONSE,
        values,
        pendingInvitations,
        expiredInvitations
      });
      dispatch(progressActionCreators.endShowProgress());
    } catch (error) {
      dispatch(
          notificationActionCreators.startShowNotification(
              constants.ERROR_INVITES_FETCH
          )
      );
      dispatch({ type: actions.INVITES_FETCH_RESPONSE, error });
      dispatch(progressActionCreators.endShowProgress());
    }
  },

  requestDeleteInvite: (inviteId: string) => async (dispatch, getState) => {
    if (getState().invites.isLoading) {
      return;
    }

    dispatch({ type: actions.INVITES_DELETE_REQUEST });
    dispatch(
        progressActionCreators.startShowProgress(
            'Deleting community invite...'
        )
    );

    try {
      const { gtToken } = await getAuthFromState(getState, dispatch);
      await httpServiceSecureDelete(`/invites/${getState().community.communityId}/${inviteId}`, gtToken);

      /**
       * Note: Usage of filter in TypeScript requires that the two items being compared are of the same type
       */
      const inviteEmailAddress = getState().invites.values.filter(
          (invite: any) => parseInt(invite.id, 10) === parseInt(inviteId, 10)
      )[0].emailAddress;

      const invites = getState().invites.values.filter(
          (i: any) => parseInt(i.id, 10) !== parseInt(inviteId, 10)
      );

      const pendingInvitations: any[] = [];
      const expiredInvitations: any[] = [];

      invites.forEach((invite: any) => {
        if (!invite.expired) {
          pendingInvitations.push(invite);
        } else {
          expiredInvitations.push(invite);
        }
      });

      dispatch({
        type: actions.INVITES_DELETE_RESPONSE,
        pendingInvitations,
        expiredInvitations
      });
      dispatch(
          notificationActionCreators.startShowNotification(
              'You have successfully removed the invitation for the user ' +
              inviteEmailAddress
          )
      );
      dispatch(
          invitesActionCreators.requestFetchInvites(
              getState().community.communityId
          )
      );
      dispatch(progressActionCreators.endShowProgress());
    } catch (error) {
      dispatch(
          notificationActionCreators.startShowNotification(
              constants.ERROR_INVITES_DELETE
          )
      );
      dispatch({ type: actions.INVITES_DELETE_RESPONSE, error });
      dispatch(progressActionCreators.endShowProgress());
    }
  },

  requestResendInvite: (inviteId: string) => async (dispatch, getState) => {
    if (getState().invites.isLoading) {
      return;
    }

    dispatch({ type: actions.INVITES_RESEND_REQUEST });
    dispatch(
        progressActionCreators.startShowProgress(
            'Resending community invite...'
        )
    );

    try {
      const { gtToken } = await getAuthFromState(getState, dispatch);
      await httpServiceSecurePost(
          `/invites/${getState().community.communityId}/${inviteId}`,
          gtToken,
          JSON.stringify({ resendDate: new Date() })
      );

      const inviteEmailAddress = getState().invites.values.filter(
          (invite: any) => parseInt(invite.id, 10) === parseInt(inviteId, 10)
      )[0].emailAddress;

      dispatch({ type: actions.INVITES_RESEND_RESPONSE });
      dispatch(
          notificationActionCreators.startShowNotification(
              'You have successfully resent the invitation for the user: ' +
              inviteEmailAddress
          )
      );
      dispatch(
          invitesActionCreators.requestFetchInvites(
              getState().community.communityId
          )
      );
      dispatch(progressActionCreators.endShowProgress());
    } catch (error) {
      console.dir(error);
      dispatch(
          notificationActionCreators.startShowNotification(
              constants.ERROR_INVITES_RESEND
          )
      );
      dispatch({ type: actions.INVITES_RESEND_RESPONSE, error });
      dispatch(progressActionCreators.endShowProgress());
    }
  }
};

export default invitesActionCreators;
