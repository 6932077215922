import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reportsActionCreators } from './Reports.operations';
import { CSVLink } from 'react-csv';

import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  IColumn
} from '@fluentui/react/lib/DetailsList';

export interface IReportRecord {
  [MemberId: string]: any;
  FullName: string;
  EmailAddress: string;
  Department: string;
  Occupation: string;
  PositionLevel: string;
  Status: string;
  AzureUPN: string;
  VerificationCheckDate: Date;
}

export interface IAllMembersProps {
  allMembers: any;
  reportActions: any;
}

export interface IAllMembersState {
  columns: IColumn[];
  items: IReportRecord[];
}

export class AllMembers extends React.Component<
  IAllMembersProps,
  IAllMembersState
> {
  constructor(props: any) {
    super(props);

    const columns: IColumn[] = [
      {
        key: 'FullName',
        name: 'Name',
        fieldName: 'FullName',
        minWidth: 200,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this.onColumnClick,
        data: 'string',
        isPadded: true
      },
      {
        key: 'EmailAddress',
        name: 'Email Address',
        fieldName: 'EmailAddress',
        minWidth: 300,
        maxWidth: 500,
        isResizable: true,
        onColumnClick: this.onColumnClick,
        data: 'string',
        isPadded: true
      },
      {
        key: 'EmailDomain',
        name: 'Email Domain',
        fieldName: 'EmailDomain',
        minWidth: 200,
        maxWidth: 300,
        isResizable: true,
        onColumnClick: this.onColumnClick,
        data: 'string',
        isPadded: true
      },
      {
        key: 'Department',
        name: 'Department',
        fieldName: 'Department',
        minWidth: 300,
        maxWidth: 400,
        isResizable: true,
        onColumnClick: this.onColumnClick,
        data: 'string',
        isPadded: true
      },
      {
        key: 'Occupation',
        name: 'Occupation',
        fieldName: 'Occupation',
        minWidth: 200,
        maxWidth: 400,
        isResizable: true,
        onColumnClick: this.onColumnClick,
        data: 'string',
        isPadded: true
      },
      {
        key: 'PositionLevel',
        name: 'Level/Classification',
        fieldName: 'PositionLevel',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this.onColumnClick,
        data: 'string',
        isPadded: true
      },
      {
        key: 'Status',
        name: 'Status',
        fieldName: 'Status',
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onColumnClick: this.onColumnClick,
        data: 'string',
        isPadded: true
      },
      {
        key: 'AzureUPN',
        name: 'AzureUPN',
        fieldName: 'AzureUPN',
        minWidth: 400,
        maxWidth: 500,
        isResizable: true,
        onColumnClick: this.onColumnClick,
        data: 'string',
        isPadded: true
      },
      {
        key: 'VerificationCheckDate',
        name: 'Verification Check Date',
        fieldName: 'VerificationCheckDate',
        minWidth: 150,
        maxWidth: 300,
        isResizable: true,
        data: 'date',
        onColumnClick: this.onColumnClick
      }
    ];

    this.state = {
      items: [],
      columns
    };
  }

  public render() {
    const { columns, items } = this.state;
    const headers = [
      { label: 'Name', key: 'FullName' },
      { label: 'Email Address', key: 'EmailAddress' },
      { label: 'Email Domain', key: 'EmailDomain' },
      { label: 'Department', key: 'Department' },
      { label: 'Occupation', key: 'Occupation' },
      { label: 'Level/Classification', key: 'PositionLevel' },
      { label: 'Status', key: 'Status' },
      { label: 'AzureUPN', key: 'AzureUPN' },
      { label: 'Verification Check Date', key: 'VerificationCheckDate' }
    ];
    return (
      <React.Fragment>
        {items && items.length > 0 ? (
          <CSVLink
            data={items}
            headers={headers}
            filename={'Reports_AllMembers.csv'}
          >
            Download all members report csv file
          </CSVLink>
        ) : null}
        <DetailsList
          items={items}
          compact={true}
          columns={columns}
          selectionMode={SelectionMode.none}
          setKey='set'
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
          enterModalSelectionOnTouch={true}
          ariaLabelForSelectionColumn='Toggle selection'
          ariaLabelForSelectAllCheckbox='Toggle selection for all items'
        />
      </React.Fragment>
    );
  }

  public componentDidMount() {
    this.props.reportActions.requestReportAllMember();
  }

  public componentDidUpdate(
    previousProps: any,
    previousState: IAllMembersState
  ) {
    if (
      previousProps.allMembers !== this.props.allMembers &&
      this.props.allMembers
    ) {
      this.setState({
        items: this.props.allMembers
      });
    }
  }

  private onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { columns, items } = this.state;
    let newItems: IReportRecord[] = items.slice();
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol: IColumn, idx: number) => {
        return column.key === currCol.key;
      }
    )[0];

    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    newItems = this._sortItems(
      newItems,
      currColumn.fieldName || '',
      currColumn.isSortedDescending
    );

    this.setState({
      columns: newColumns,
      items: newItems
    });
  };

  private _sortItems = (
    items: IReportRecord[],
    sortBy: string,
    descending = false
  ): IReportRecord[] => {
    if (descending) {
      return items.sort((a: IReportRecord, b: IReportRecord) => {
        if (a[sortBy] < b[sortBy]) {
          return 1;
        }
        if (a[sortBy] > b[sortBy]) {
          return -1;
        }
        return 0;
      });
    } else {
      return items.sort((a: IReportRecord, b: IReportRecord) => {
        if (a[sortBy] < b[sortBy]) {
          return -1;
        }
        if (a[sortBy] > b[sortBy]) {
          return 1;
        }
        return 0;
      });
    }
  };
}

const mapStateToProps = (state) => ({
  allMembers: state.reports.allMembers
});

const mapDispatchToProps = (dispatch) => ({
  reportActions: bindActionCreators(reportsActionCreators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AllMembers);
