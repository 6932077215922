/**
 * List pagination
 *
 */

/**
 * React
 */
import * as React from 'react';

/**
 * Fabric UI
 */
import { Icon } from '@fluentui/react/lib/Icon';
import { Link } from '@fluentui/react/lib/Link';

interface IListPagerProps {
  currentPage: number;
  pageSize: number;
  maxPages: number;
  onPage: (event) => void;
  onNext: (event) => void;
  onPrevious: (event) => void;
}

export class ListPager extends React.Component<IListPagerProps, any> {
  public render() {
    return (
      <React.Fragment>
        <div className="govTeams-searchPager">
          {this.props.currentPage > 1 && (
            <Link
              onClick={this.props.onPage}
              data-page={1}
              className="govTeams-searchPager--prev"
            >
              <Icon iconName="DoubleChevronLeft" /> First
            </Link>
          )}
          {this.props.maxPages > 1 && this.props.currentPage > 1 && (
            <Link
              onClick={this.props.onPrevious}
              className="govTeams-searchPager--prev"
            >
              <Icon iconName="ChevronLeft" /> Previous
            </Link>
          )}

          <span className="govTeams-searchPager--numbers pagerInfo">
            {this.props.currentPage} of {this.props.maxPages}
          </span>
          <span className="govTeams-searchPager--numbers pagerLinks">
            {this.getPageArray(
              this.props.maxPages,
              5,
              this.props.currentPage
            ).map((page: number, index: number) => {
              return (
                <React.Fragment key={index}>
                  <Link
                    onClick={this.props.onPage}
                    data-page={page}
                    className={`govTeams-searchPager--number ${
                      page === this.props.currentPage ? 'selected' : ''
                    }`}
                  >
                    {page}
                  </Link>
                </React.Fragment>
              );
            })}
          </span>

          {this.props.currentPage < this.props.maxPages && (
            <Link
              onClick={this.props.onNext}
              className="govTeams-searchPager--next"
            >
              Next <Icon iconName="Chevronright" />
            </Link>
          )}
          {this.props.currentPage < this.props.maxPages && (
            <Link
              onClick={this.props.onPage}
              data-page={this.props.maxPages}
              className="govTeams-searchPager--next"
            >
              Last <Icon iconName="DoubleChevronRight" />
            </Link>
          )}
        </div>
      </React.Fragment>
    );
  }

  private getPageArray(
    totalPages: number,
    range: number,
    current: number
  ): number[] {
    let nextCount = current + 1;
    let prevCount = current - range;
    const pageArray: number[] = [];

    while (prevCount < current) {
      if (prevCount > 0) {
        pageArray.push(prevCount);
      }
      prevCount++;
    }
    pageArray.push(current);

    while (nextCount <= current + range && nextCount <= totalPages) {
      pageArray.push(nextCount);
      nextCount++;
    }

    return pageArray;
  }
}

export default ListPager;
