import actions from './Carousel.actions';
import { ICarouselState } from './Carousel.types';

const initialState: ICarouselState = {
  slides: [],
  isLoading: false
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_CAROUSEL_SLIDES_REQUEST:
      return {
        ...state,
        slides: [],
        isLoading: true,
      };
    case actions.FETCH_CAROUSEL_SLIDES_RESPONSE:
      return {
        ...state,
        slides: action.slides,
        isLoading: false,
      };
    default:
      return { ...state };
  }
};

export default reducer;
