import { IGovTEAMSProfileInfo } from './Profile.types';
import { MemberType, MemberStatus } from '../CommunityController';
/**
 * Actions
 */
export const PROFILE_EDIT_START = 'PROFILE_EDIT_START';
export const PROFILE_EDIT_END = 'PROFILE_EDIT_END';
export const PROFILE_DEACTIVATION_START = 'PROFILE_DEACTIVATION_START';
export const PROFILE_DEACTIVATION_END = 'PROFILE_DEACTIVATION_END';
export const PROFILE_DEACTIVATED = 'PROFILE_DEACTIVATED';
export const PROFILE_LOGOUT = 'PROFILE_LOGOUT';
export const PROFILE_SAVE = 'PROFILE_SAVE';
export const PROFILE_EMAIL_SAVE = 'PROFILE_EMAIL_SAVE';
export const PROFILE_EMAIL_RESEND = 'PROFILE_EMAIL_RESEND';
export const PROFILE_AUTHENTICATE = 'PROFILE_AUTHENTICATE';
export const PROFILE_LOAD = 'PROFILE_LOAD';
export const PROFILE_GET_AZURE = 'PROFILE_GET_AZURE';
export const PROFILE_GET_GOVTEAMS_PROFILE = 'PROFILE_GET_GOVTEAMS_PROFILE';
export const PROFILE_GET_GOVTEAMS_PROFILE_ERROR = 'PROFILE_GET_GOVTEAMS_PROFILE_ERROR';
export const PROFILE_GET_DELVE = 'PROFILE_GET_DELVE';
export const PROFILE_GET_IMAGE = 'PROFILE_GET_IMAGE';
export const PROFILE_VERIFY = 'PROFILE_VERIFY';
export const PROFILE_VERIFY_RESEND = 'PROFILE_VERIFY_RESEND';
export const PROFILE_RESTORE_LICENCE = 'PROFILE_RESTORE_LICENCE';

export interface ProfileEditStart {
  type: typeof PROFILE_EDIT_START;
  govTeamsProfileInfo: IGovTEAMSProfileInfo;
}

export interface ProfileEditEnd {
  type: typeof PROFILE_EDIT_END;
}

export interface ProfileDeactivationStart {
  type: typeof PROFILE_DEACTIVATION_START;
  isDeactivating: boolean;
}

export interface ProfileDeactivationEnd {
  type: typeof PROFILE_DEACTIVATION_END;
  isDeactivating: boolean;
}

export interface ProfileDeactivated {
  type: typeof PROFILE_DEACTIVATED;
  isLoading: boolean;
  error: any;
}

export interface ProfileLogout {
  type: typeof PROFILE_LOGOUT;
  id: string;
}

export interface ProfileSave {
  type: typeof PROFILE_SAVE;
  isSubmitted: boolean;
  isLoading: boolean;
  error: any;
}

export interface ProfileEmailSave {
  type: typeof PROFILE_EMAIL_SAVE;
  isLoading: boolean;
  error: any;
}

export interface ProfileEmailResend {
  type: typeof PROFILE_EMAIL_RESEND;
  isLoading: boolean;
  error: any;
}

export interface ProfileAuthenticate {
  type: typeof PROFILE_AUTHENTICATE;
  isAuthenticated: boolean;
  isLoading: boolean;
  error: any;
}

export interface ProfileLoad {
  type: typeof PROFILE_LOAD;
  isLoading: boolean;
}

export interface ProfileGetAzure {
  type: typeof PROFILE_GET_AZURE;
  id: string;
  azureMail: string;
  businessPhones: string;
  mobilePhone: string;
  displayName: string;
  givenName: string;
  department: string;
  jobTitle: string;
  isGuest: boolean;
  userPrincipalName: string;
  isLoading: boolean;
  error: any;
}

export interface ProfileGetGovTeamsProfile {
  type: typeof PROFILE_GET_GOVTEAMS_PROFILE;
  isLoading: false;
  govTeamsProfileInfo: IGovTEAMSProfileInfo;
  mail: string;
  linkedAccountAzureId: string;
  memberStatus: string;
  daysLeftToVerify: string;
  memberTypeId: number;
  memberType: MemberType;
  organisationContact: string;
  isAuthorised: boolean;
}

export interface ProfileGetGovTeamsProfileError {
  type: typeof PROFILE_GET_GOVTEAMS_PROFILE_ERROR;
  isLoading: false;
  error: any;
}

export interface ProfileGetDelve {
  type: typeof PROFILE_GET_DELVE;
  aboutMe: string;
  skills: string;
  schools: string;
  interests: string;
  topics: string;
  projects: string;
  isLoading: boolean;
  error: any;
}

export interface ProfileGetImage {
  type: typeof PROFILE_GET_IMAGE;
  imageUrl: string;
  error: any;
}

export interface ProfileVerify {
  type: typeof PROFILE_VERIFY;
  isLoading: false;
  memberStatus: MemberStatus;
  error: any;
  verificationError: any;
}

export interface ProfileVerifyResend {
  type: typeof PROFILE_VERIFY_RESEND;
  isLoading: boolean;
  error: any;
}

export interface ProfileRestoreLicence {
  type: typeof PROFILE_RESTORE_LICENCE;
  error: any;
}

export type ProfileActionTypes =
  | ProfileEditStart
  | ProfileEditEnd
  | ProfileDeactivationStart
  | ProfileDeactivationEnd
  | ProfileDeactivated
  | ProfileLogout
  | ProfileSave
  | ProfileEmailSave
  | ProfileEmailResend
  | ProfileAuthenticate
  | ProfileLoad
  | ProfileGetAzure
  | ProfileGetGovTeamsProfile
  | ProfileGetGovTeamsProfileError
  | ProfileGetDelve
  | ProfileGetImage
  | ProfileVerify
  | ProfileVerifyResend
  | ProfileRestoreLicence;
