import React from 'react';
import { Route } from 'react-router';
import { useLocation } from 'react-router-dom';
import { savePageViewEvent } from '../../common/EventTracking';

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const location = useLocation();

  React.useEffect(() => {
    savePageViewEvent(location);
  }, [location]);

    return (
        <Route
            {...rest}
            render={(props) => (
                <Layout>
                    <Component {...props} />
                </Layout>
            )}
        />
    );
};

export default AppRoute;