/**
 * Profile section on dashboard
 *
 */

/**
 * React
 */
import * as React from 'react';
import renderHTML from 'react-render-html';

/**
 * Redux
 */
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { profileActionCreators } from '../../../controllers/ProfileController';

/**
 * UI Fabric
 */
import {
  ActionButton,
  IconButton,
  PrimaryButton
} from '@fluentui/react/lib/Button';
import { Callout, DirectionalHint } from '@fluentui/react/lib/Callout';
import { Icon } from '@fluentui/react/lib/Icon';
import { Link } from '@fluentui/react/lib/Link';
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';

/**
 * Constants
 */
import * as constants from '../../../common/Constants';
import { BRANDNAME } from '../../../common/Tooltips';
import { isUserDashboardDisabled } from '../../../auth/';

export interface IDashboardProfileProps {
  profile: any;
  dispatch: any;
  profileActions: any;
}

export interface IDashboardProfileState {
  expand: boolean;
  showCallout: boolean;
  showStatus: boolean;
}

export class DashboardProfile extends React.Component<
  IDashboardProfileProps,
  IDashboardProfileState
> {
  private settingsButtonElement: HTMLElement | null;

  constructor(props: IDashboardProfileProps) {
    super(props);

    this.state = {
      expand: false,
      showCallout: false,
      showStatus: false
    };
  }

  public render() {
    const isDashboardDisabled = isUserDashboardDisabled(this.props.profile);

    return (
      <>
        {isDashboardDisabled && (
            <Redirect to={constants.SCREEN_REACTIVATE} />
        )}

        {!isDashboardDisabled && (
          <div className='dashboard-profile'>
            <div className='dashboard-profileSection'>
              <div
                  className='dashboard-profileSettings'
                  ref={(settingsButtonElement) => {
                    this.settingsButtonElement = settingsButtonElement;
                  }}
              >
                <IconButton
                    iconProps={{ iconName: 'Settings' }}
                    onClick={this.toggleCallout}
                />
              </div>

              <Callout
                  calloutWidth={310}
                  directionalHint={DirectionalHint.bottomCenter}
                  onDismiss={this.onCalloutDismiss}
                  hidden={!this.state.showCallout}
                  target={this.settingsButtonElement}
                  preventDismissOnScroll={false}
              >
                <div className='dashboard-profileSettings-menu'>
                  <ul>
                    <li>
                      <Link onClick={this.showProfile}>My {BRANDNAME} account</Link>
                    </li>
                    {!this.props.profile.isGuest && (
                        <li>
                          <Link
                              href={
                                  'https://aus.delve.office.com/?u=' +
                                  this.props.profile.id +
                                  '&v=work'
                              }
                              rel='noopener noreferrer'
                              target='_blank'
                          >
                            My Delve Profile
                          </Link>
                        </li>
                    )}
                    <li>
                      <Link href={constants.PROFILE_LINKPASSWORD} target='_blank'>
                        Change my password
                      </Link>
                    </li>
                    <li>
                      <Link href={constants.PROFILE_LINKMFA} target='_blank'>
                        Change Multi-Factor Authentication option
                      </Link>
                    </li>
                  </ul>
                  <hr />
                  <Link onClick={this.signout}>Sign out</Link>
                </div>
              </Callout>

              {this.props.profile.id && (
                  <Persona
                      className='dashboard-persona'
                      size={PersonaSize.size100}
                      text={this.props.profile.displayName}
                      secondaryText={this.props.profile.jobTitle}
                      tertiaryText={this.props.profile.department}
                      imageUrl={this.props.profile.imageUrl}
                      coinSize={120}
                  />
              )}
              <hr />
              <h3>
                Contact
                <ActionButton
                    onClick={this.showProfile}
                    iconProps={{ iconName: 'Edit' }}
                    rel='noopener noreferrer'
                    target='_blank'
                />
              </h3>
              <table className='profile-contactTable'>
                <tbody>
                {this.props.profile.mail && (
                    <tr>
                      <td>
                        <Icon iconName='Mail' />
                      </td>
                      <td>{this.props.profile.mail}</td>
                    </tr>
                )}
                {!this.props.profile.isGuest &&
                    this.props.profile.businessPhones &&
                    this.props.profile.businessPhones.length > 0 && (
                        <tr>
                          <td>
                            <Icon iconName='Phone' />
                          </td>
                          <td>{this.props.profile.businessPhones[0]}</td>
                        </tr>
                    )}
                {!this.props.profile.isGuest && this.props.profile.mobilePhone && (
                    <tr>
                      <td>
                        <Icon iconName='CellPhone' />
                      </td>
                      <td>{this.props.profile.mobilePhone}</td>
                    </tr>
                )}
                </tbody>
              </table>
            </div>
            {!this.props.profile.isGuest && (
                <div className='dashboard-profileSection'>
                  {this.state.expand && (
                      <div className='profile-secondary'>
                        {this.renderSection(
                            'About me',
                            this.props.profile.aboutMe,
                            constants.ERROR_PROFILE_EMPTYFIELD_ABOUT,
                            true,
                            false
                        )}
                        {this.renderSection(
                            'Projects',
                            this.props.profile.projects,
                            constants.ERROR_PROFILE_EMPTYFIELD_PROJECTS,
                            false
                        )}
                        {this.renderSection(
                            'Skills',
                            this.props.profile.skills,
                            constants.ERROR_PROFILE_EMPTYFIELD_SKILLS,
                            false
                        )}
                        {this.renderSection(
                            'Expertise',
                            this.props.profile.topics,
                            constants.ERROR_PROFILE_EMPTYFIELD_TOPICS,
                            false
                        )}

                        {this.renderSection(
                            'Schools and Education',
                            this.props.profile.schools,
                            constants.ERROR_PROFILE_EMPTYFIELD_SCHOOLS,
                            false
                        )}
                        {this.renderSection(
                            'Interests and hobbies',
                            this.props.profile.interests,
                            constants.ERROR_PROFILE_EMPTYFIELD_INTERESTS,
                            false
                        )}
                        <div className='profile-edit'>
                          <PrimaryButton
                              className='govTeams-delveButton'
                              href={
                                  'https://aus.delve.office.com/?u=' +
                                  this.props.profile.id +
                                  '&v=work'
                              }
                              iconProps={{ iconName: 'Edit' }}
                              rel='noopener noreferrer'
                              target='_blank'
                              text='Update Delve profile'
                          />
                        </div>
                      </div>
                  )}
                </div>
            )}
            {!this.props.profile.isGuest && (
                <div className='dashboard-profileFooter'>
                  <div className='dashboard-profileFooter-bottom'>
                    <ActionButton className='profile-toggle' onClick={this.toggle}>
                      {!this.state.expand ? 'Show more' : 'Show less'}
                      <Icon
                          iconName={this.state.expand ? 'ChevronUp' : 'ChevronDown'}
                      />
                    </ActionButton>
                  </div>
                </div>
            )}
          </div>
        )}
      </>
    );
  }

  public componentDidUpdate(prevProps: any) {
    if (prevProps.profile !== this.props.profile) {
      if (
        (this.props.profile && !sessionStorage.getItem('showStatus')) ||
        sessionStorage.getItem('showStatus') === 'true'
      ) {
        if (
          !this.props.profile.imageUrl ||
          !this.props.profile.aboutMe ||
          !this.props.profile.skills ||
          !this.props.profile.topics
        ) {
          sessionStorage.setItem('showStatus', 'true');

          if (sessionStorage.getItem('showStatus') === 'true') {
            this.setState({
              showStatus: true
            });
          }
        } else {
          sessionStorage.setItem('showStatus', 'false');

          if (sessionStorage.getItem('showStatus') === 'false') {
            this.setState({
              showStatus: false
            });
          }
        }
      }
    }
  }

  private renderSection(
    title: string,
    field: string,
    error: string,
    html: boolean,
    edit: boolean = false
  ): React.ReactElement<any> {
    return (
      <div>
        <h3>
          {title}
          {edit && (
            <ActionButton
              href={
                'https://aus.delve.office.com/?u=' +
                this.props.profile.id +
                '&v=editprofile'
              }
              iconProps={{ iconName: 'Edit' }}
              rel='noopener noreferrer'
              target='_blank'
            />
          )}
        </h3>
        {html ? (
          <p>
            {field !== undefined && field !== '' ? renderHTML(field) : error}
          </p>
        ) : (
          <p>{field !== undefined && field !== '' ? field : error}</p>
        )}
      </div>
    );
  }

  private showProfile = () => {
    this.props.dispatch(push(constants.SCREEN_PROFILE));
  };

  private signout = () => {
    sessionStorage.clear();
    localStorage.clear();
    //this.props.dispatch(this.props.profileActions.startLogoutProfile());

    window.location.href =
      'https://login.windows.net/common/oauth2/logout?post_logout_redirect_uri=' +
      constants.SCREEN_LOGOUTREDIRECT;
  };

  private onCalloutDismiss = () => {
    this.setState({
      showCallout: false
    });
  };

  private toggleCallout = () => {
    this.setState({
      showCallout: !this.state.showCallout
    });
  };

  private toggle = () => {
    this.setState({
      expand: !this.state.expand
    });
  };
}

const mapStateToProps = (state: any) => ({
  profile: state.profile
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatch,
  profileActions: bindActionCreators(profileActionCreators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardProfile);
