import * as React from 'react';
import { Link } from '@fluentui/react/lib/Link';

import { isProtected } from '../../common/Constants';
import { BRANDNAME } from '../../common/Tooltips';

interface IProps {
  prefix?: string | React.ReactNode
}
const SupportButton = (props: IProps) => {
  const { prefix = '' } = props;

  return (
    <>
      {prefix}
      <Link href={`https://www.govteams.gov.au/${isProtected ? 'protected':'official'}/support`} target='_blank'>
        {BRANDNAME} support
      </Link>
    </>
  );
}

export default SupportButton;