/**
 * Community create button
 *
 * Used on the Dashboard screen to link to the community creation form
 */

/**
 * React Redux
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { communityActionCreators } from '../../../../controllers/CommunityController';

/**
 * UI Fabric
 */
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { saveCommunityManageEvent } from '../../../../common/EventTracking';

export interface ICommunityCreateButtonProps {
  isGuest: any;
  communityActionCreators: any;
}

export class CommunityCreateButton extends React.Component<
  ICommunityCreateButtonProps,
  any
> {
  public render() {
    return (
      <React.Fragment>
        {(!this.props.isGuest == null || !this.props.isGuest) && (
          <PrimaryButton
            className='govTeams-actionButtonAlt'
            text='Create community'
            iconProps={{ iconName: 'ChevronRightSmall' }}
            onClick={(ev) => {
              saveCommunityManageEvent('open_create',  'create');
              this.props.communityActionCreators.startEditCommunity();
            }}
            styles={{
              root: {
                borderRadius: 6
              }
            }}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  isGuest: state.profile.isGuest
});

const mapDispatchToProps = (dispatch: any) => ({
  communityActionCreators: bindActionCreators(communityActionCreators, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunityCreateButton);
