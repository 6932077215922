import { progressActionCreators } from '../../components/Progress';

import actions from './Reports.actions';
import { getAuthFromState } from '../../auth';
import { httpServiceSecureGet } from '../../common/Http';

export const reportsActionCreators = {

  requestReportAllMember: () => async (dispatch, getState) => {
    if (getState().reports.isLoading) {
      return;
    }

    dispatch({ type: actions.ALLMEMBERS_REPORT_REQUEST });
    try {
      const { gtToken } = await await getAuthFromState(getState, dispatch);
      const odata: any = await httpServiceSecureGet(`/AllMembersReport`, gtToken);

      dispatch({
        type: actions.ALLMEMBERS_REPORT_RESPONSE,
        allMembers: odata.value
      });
    } catch (error) {
      dispatch({
        type: actions.ALLMEMBERS_REPORT_RESPONSE,
        error
      });
    }
    finally {
      dispatch(progressActionCreators.endShowProgress());
    }
  }
};

export default reportsActionCreators;
