/**
 * React Redux
 */

import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { profileActionCreators } from '../../controllers/ProfileController';

/**
 * Fabric UI
 */
import { ActionButton } from '@fluentui/react/lib/Button';
import { Dialog, DialogFooter } from '@fluentui/react/lib/Dialog';

/**
 * GovTEAMS components
 */
import UserAccessContent from './UserAccessContent';

/**
 * Constants and utilities
 */
import * as constants from '../../common/Constants';

export interface IUserAccessDialogProps {
  memberStatus: string;
  externalEmailAddress: string;
  daysLeftToVerify: number;
  profile: any;
  dispatch: any;
}

export interface IUserAccessDialogState {
  isOpen: boolean;
  verificationCode: string;
  errorMessage: string | undefined;
}

export class UserAccessDialog extends React.Component<
  IUserAccessDialogProps,
  IUserAccessDialogState
> {
  constructor(props: IUserAccessDialogProps) {
    super(props);
    this.state = {
      errorMessage: undefined,
      isOpen: false,
      verificationCode: ''
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.memberStatus === 'Inactive') {
      this.props.dispatch(push(constants.SCREEN_REACTIVATE));
    } else if (!this.state.isOpen && nextProps.memberStatus === 'UnderReview') {
      this.setState({ isOpen: true });
    } else {
      this.setState({ isOpen: false });
    }
  }

  public render() {
    return (
      <Dialog
        hidden={!this.state.isOpen}
        onDismiss={this.onDismiss}
        dialogContentProps={{
          showCloseButton: true,
          title: 'We just need to check'
        }}
        modalProps={{
          isBlocking: true,
          isDarkOverlay: true,
          className: 'govTeams-dialog govTeams-UserAccess-modal'
        }}
      >
        <div>
          <UserAccessContent
            daysLeftToVerify={this.props.daysLeftToVerify}
            externalEmailAddress={this.props.externalEmailAddress}
          />

          {/* <div className="govTeams-fieldVerify">
            <MaskedTextField
              placeholder="Enter 6-digit verification code"
              onKeyPress={this.onKeyPress}
              onChange={this.onChange}
              errorMessage={this.state.errorMessage}
              mask="999999"
            />
            <PrimaryButton onClick={this.onVerify} text="Verify" />
          </div> */}
        </div>
        <DialogFooter>
          <ActionButton
            className='govTeams-actionButton'
            onClick={this.onResend}
          >
            Resend link
          </ActionButton>
          <ActionButton
            className='govTeams-actionButton'
            onClick={this.onUpdateEmail}
          >
            Update email address
          </ActionButton>
        </DialogFooter>
      </Dialog>
    );
  }

  private onDismiss = (event) => {
    this.setState({ isOpen: false });
  };

  // private onVerify = event => {
  //   this.verify();
  // };

  private onResend = (event) => {
    this.props.profile.requestResendVerify();
  };

  private onUpdateEmail = (event) => {
    this.setState({ isOpen: false });
    this.props.dispatch(push(constants.SCREEN_PROFILE));
  };

  // private onChange = (event, newValue) => {
  //   this.setState({ verificationCode: newValue });
  // };

  // private onKeyPress = event => {
  //   if (event.key === 'Enter') {
  //     event.preventDefault();
  //     this.verify();
  //   }
  // };

  // private verify() {
  //   const code = parseInt(this.state.verificationCode, 10);
  //   if (isNaN(code)) {
  //     this.setState({ errorMessage: 'please enter verification code.' });
  //   } else if ((this.state.verificationCode).indexOf('_') !== -1) {
  //     this.setState({
  //       errorMessage: 'please enter correct 6-digit verification code.'
  //     });
  //   } else {
  //     this.setState({
  //       errorMessage: undefined
  //     });
  //     this.props.profile.requestVerifyProfile(code);
  //   }
  // }
}

const mapStateToProps = (state: any) => ({
  daysLeftToVerify: state.profile.daysLeftToVerify,
  externalEmailAddress: state.profile.mail,
  memberStatus: state.profile.memberStatus
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatch,
  profile: bindActionCreators(profileActionCreators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(UserAccessDialog);
