/**
 * Community members screen
 *
 * Display a sortable list of all members in a community
 *
 */

/**
 * React Redux
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { inviteMembersActionCreators } from '../../../../controllers/InviteMembersController';
import { communityActionCreators } from '../../../../controllers/CommunityController';
import { membersActionCreators } from '../../../../controllers/MembersController';

/**
 * Fabric UI
 */
import { isProtected } from '../../../../common/Utils';
import { CommunityMemberPersona } from '../CommunityMemberPersona';

/**
 * GovTEAMS components
 */
import CommunityMembersComponent from '@govteams/library/components/Community/CommunityMembersComponent/';

const CommunityMembers = (props) => {
  const {
    match,
    allMembers,
    initialValues,
    profile,
    results,
    inviteMembersActionCreators,
    communityActionCreators,
    membersActionCreators,
  } = props;

  return (
    <CommunityMembersComponent
      isProtected={isProtected()}
      allMembers={allMembers}
      communityAzureId={initialValues?.communityAzureId}
      community={initialValues}
      match={match}
      profile={profile}
      results={results}
      inviteMembersActionCreators={inviteMembersActionCreators}
      communityActionCreators={communityActionCreators}
      membersActionCreators={membersActionCreators}
      CommunityMemberPersona={CommunityMemberPersona}
    />
  );
}

const mapStateToProps = (state) => ({
  allMembers: state.members.allMembers,
  initialValues: state.community.initialValues,
  profile: state.profile,
  results: state.inviteMembers.results
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  inviteMembersActionCreators: bindActionCreators(
    inviteMembersActionCreators,
    dispatch
  ),
  communityActionCreators: bindActionCreators(
    communityActionCreators,
    dispatch
  ),
  membersActionCreators: bindActionCreators(membersActionCreators, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CommunityMembers));
