import * as React from 'react';
import { connect } from 'react-redux';
import { BRANDNAME } from '../../common/Tooltips';

const Error_Generic = props => (
  <div>
    <h2 className="heading-error">Something has gone wrong!</h2>
    <p>
      It looks like something has gone wrong. It's most likely due to a {BRANDNAME} or Microsoft system outage.
    </p>
    <p>
      Please contact the <a href={props.mailTo}>{BRANDNAME} support</a> helpdesk
      and quote the following error ID: <strong> {props.correlationId} </strong>
    </p>
  </div>
);

export default connect()(Error_Generic);
