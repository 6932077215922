import { push } from 'react-router-redux';

import { getApiLoginToken, getAuthFromState, getMsLoginToken, logoutRedirect, msGraphSecureGet, msGraphSecureGetRaw, BLOCKED_CONDITIONAL_ACCESS } from '../../auth';

import { notificationActionCreators } from '../../components/Notification';
import { progressActionCreators } from '../../components/Progress';
import { MemberStatus } from '../CommunityController';
import { departmentActionCreators } from '../DepartmentController';

import * as constants from '../../common/Constants';

import { IGovTEAMSProfileInfo } from './Profile.types';

import {
  PROFILE_EDIT_START,
  PROFILE_EDIT_END,
  PROFILE_DEACTIVATION_START,
  PROFILE_DEACTIVATION_END,
  PROFILE_DEACTIVATED,
  PROFILE_LOGOUT,
  PROFILE_SAVE,
  PROFILE_EMAIL_SAVE,
  PROFILE_EMAIL_RESEND,
  PROFILE_AUTHENTICATE,
  PROFILE_LOAD,
  PROFILE_GET_AZURE,
  PROFILE_GET_GOVTEAMS_PROFILE,
  PROFILE_GET_DELVE,
  PROFILE_GET_IMAGE,
  PROFILE_VERIFY,
  PROFILE_VERIFY_RESEND,
  PROFILE_RESTORE_LICENCE,
  PROFILE_GET_GOVTEAMS_PROFILE_ERROR,
} from './Profile.actions';
import AUTH_ACTIONS from '../AuthController/Auth.actions';
import { isProtected } from '../../common/Utils';
import {
  httpServiceSecureDelete,
  httpServiceSecureGet,
  httpServiceSecurePut,
  httpServiceSecurePost,
  httpServiceSecurePostRaw
} from '../../common/Http';

/**
 * Find if URL is of organisation or profile page
 */
const _isOrganisationOrProfilePage = (): boolean => {
  const paths = window.location.pathname.split('/');
  return ( `/${paths[1]}` === constants.SCREEN_ORGANISATION || `/${paths[1]}` === constants.SCREEN_PROFILE )
}

/**
 * This function loads profile from GovTeams API
 * @param dispatch
 * @param getState
 * @param gtToken
 * @param msalInstance
 * @param msalAccounts
 */
const _getGTProfile = async ({
                      dispatch,
                      getState,
                      gtToken,
                      msalInstance,
                      msalAccounts,
                             }) => {
  try {
    let isAuthorised = false;

    // Store does not have tokens, get them from API
    if ( !gtToken ) {
      gtToken = await getApiLoginToken(msalInstance, msalAccounts);

      /**
       * Save token in store
       */
      dispatch({
        type: AUTH_ACTIONS.SAVE_TOKEN_GT,
        gtToken: gtToken,
      });
    }

    const govTeamsProfileInfo: IGovTEAMSProfileInfo = await httpServiceSecureGet('/profiles', gtToken);

    /**
     * If you got some record from govTeams, mark user as authenticated because token is working fine
     */
    if ( govTeamsProfileInfo?.emailAddress && govTeamsProfileInfo?.memberType ) {
      dispatch({ type: PROFILE_AUTHENTICATE });
    }

    /**
     * Check status and set flag based on that
     */
    if (
        govTeamsProfileInfo.memberStatus !== MemberStatus.ApprovalPending &&
        govTeamsProfileInfo.memberStatus !== MemberStatus.Blocked &&
        govTeamsProfileInfo.memberStatus !== MemberStatus.PendingLicence
    ) {
      isAuthorised = true;
    }

    /**
     * Save profile
     */
    dispatch({
      type: PROFILE_GET_GOVTEAMS_PROFILE,
      govTeamsProfileInfo,
      isAuthorised
    });

    dispatch(progressActionCreators.endShowProgress());

    // Account is active or Under Review, we are good to go forward
    if (govTeamsProfileInfo.memberStatus === MemberStatus.Active || govTeamsProfileInfo.memberStatus === MemberStatus.UnderReview) {
      return gtToken;
    }

    // If not organisation & profile screen and account is inactive or pending license. Redirect
    if (!_isOrganisationOrProfilePage()) {
      if ( govTeamsProfileInfo.memberStatus === MemberStatus.Inactive )
        dispatch(push(constants.SCREEN_REACTIVATE));
      else if ( govTeamsProfileInfo.memberStatus === MemberStatus.PendingLicence )
        dispatch(push(constants.SCREEN_PENDING));
    }

    // Irrespective of the screen, if account is blocked or pending approval. Redirect
    if (govTeamsProfileInfo.memberStatus === MemberStatus.Blocked)
      dispatch(push(constants.SCREEN_BLOCKED));
    else if (govTeamsProfileInfo.memberStatus === MemberStatus.ApprovalPending)
      dispatch(push(constants.SCREEN_APPROVAL_PENDING));

    return null;

  } catch (error) {
    //Don't show a notification for the error.
    dispatch(progressActionCreators.endShowProgress());
    dispatch({
      type: PROFILE_GET_GOVTEAMS_PROFILE_ERROR,
      error
    });
    console.log('Error fetching profile information', error);
    return null;
  }
}

/**
 * This function loads profile from MS Graph
 * @param dispatch
 * @param getState
 * @param msToken
 * @param msalInstance
 * @param msalAccounts
 */
const _getMSProfile = async ({
                      dispatch,
                      getState,
                      msToken,
                      msalInstance,
                      msalAccounts,
                             }) => {

  try {
    // If Store does not have MS Token
    if ( !msToken ) {
      msToken = await getMsLoginToken(msalInstance, msalAccounts);

      // Save token in store
      dispatch({
        type: AUTH_ACTIONS.SAVE_TOKEN_MS,
        msToken: msToken,
      });
    }

    /**
     * Protected only
     */
    if (isProtected() && msToken.accessToken === BLOCKED_CONDITIONAL_ACCESS) {
      dispatch(push(constants.SCREEN_UNTRUSTED_NETWORK));
      return;
    }

    const azureAD = await msGraphSecureGet('/me?$select=id,mail,displayName,userPrincipalName,jobTitle,employer,department,businessPhones,mobilePhone,givenName', msToken, msalInstance);

    /**
     * All guest users have #EXT# in their usernames
     */
    const isGuest = azureAD.userPrincipalName.indexOf('#EXT#') > 0;

    dispatch({
      type: PROFILE_GET_AZURE,
      id: azureAD.id,
      azureMail: azureAD.mail,
      businessPhones: azureAD.businessPhones,
      mobilePhone: azureAD.mobilePhone,
      displayName: azureAD.displayName,
      givenName: azureAD.givenName,
      department: azureAD.department,
      jobTitle: azureAD.jobTitle,
      userPrincipalName: azureAD.userPrincipalName,
      isGuest
    });
    return { isGuest, msToken };

  } catch (error) {
    //Don't render anything for an error message.
    dispatch({
      type: PROFILE_GET_GOVTEAMS_PROFILE,
      error
    });
    console.error('Failed to get user information from Microsoft');
    return null;
  }
};

/**
 * This function loads about us and profile photo
 * @param dispatch
 * @param msToken
 * @param msalInstance
 */
const _getMSAboutUsAndIcon = async ({
                        dispatch,
                        msToken,
                        msalInstance,
                      }) => {
  try {
    const azureADProfile = await msGraphSecureGet('/me?$select=aboutMe,skills,schools,interests,responsibilities,pastProjects', msToken, msalInstance);
    dispatch({
      type: PROFILE_GET_DELVE,
      aboutMe: azureADProfile.aboutMe
          ? azureADProfile.aboutMe
              .replace('&lt;', '<')
              .replace('&gt;', '>')
          : '',
      skills: azureADProfile.skills.join(', '),
      schools: azureADProfile.schools.join(', '),
      interests: azureADProfile.interests.join(', '),
      topics: azureADProfile.responsibilities.join(', '),
      projects: azureADProfile.pastProjects.join(', ')
    });
  }
  catch (error) {
    //Don't show a notification for the error.
    dispatch({
      type: PROFILE_GET_GOVTEAMS_PROFILE,
      error
    });
    console.log('Error fetching profile information from graph', error);
    return false;
  }

  try {
    const azureADImage = await msGraphSecureGetRaw('/me/photos/120x120/$value', msToken);
    const url = window.URL || window.webkitURL;
    const blobUrl = url.createObjectURL(azureADImage);
    dispatch({
      type: PROFILE_GET_IMAGE,
      imageUrl: blobUrl
    });
  }
  catch (error) {
    // Ignore profile pic errors
  }
  return true;
};

export const profileActionCreators = {

  startEditProfile: () => async (dispatch, getState) => {
    dispatch(
      progressActionCreators.startShowProgress('Fetching profile data...')
    );

    await dispatch(
        departmentActionCreators.requestFetchAllMemberDepartments(getState().profile.memberTypeId,getState().profile.organisationDepartmentId)
    );

    const { instance: msalInstance, gtToken, msToken } = await getAuthFromState(getState, dispatch);

    try {
      const govTeamsProfileInfo: IGovTEAMSProfileInfo = await httpServiceSecureGet(`/profiles`, gtToken);

      dispatch({
        type: PROFILE_EDIT_START,
        govTeamsProfileInfo
      });
      dispatch(progressActionCreators.endShowProgress());

      if (govTeamsProfileInfo.memberStatus === MemberStatus.Inactive) {
        dispatch(push(constants.SCREEN_REACTIVATE));
      } else {
        dispatch(push(constants.SCREEN_PROFILE));
      }
    } catch (error) {
      dispatch(
        notificationActionCreators.startShowNotification(
          `${constants.ERROR_PROFILE_FETCH} error1: ${error}`
        )
      );
      dispatch({
        type: PROFILE_EDIT_START,
        error
      });
      dispatch(progressActionCreators.endShowProgress());
    }

    if (getState().profile.isGuest != null && !getState().profile.isGuest) {
      try {
        const azureAD = await msGraphSecureGet('/me?$select=aboutMe,skills,schools,interests,responsibilities,pastProjects', msToken, msalInstance);
        dispatch({
          type: PROFILE_GET_DELVE,
          aboutMe: azureAD.aboutMe
              ? azureAD.aboutMe.replace('&lt;', '<').replace('&gt;', '>')
              : '',
          skills: azureAD.skills.join(', '),
          schools: azureAD.schools.join(', '),
          interests: azureAD.interests.join(', '),
          topics: azureAD.responsibilities.join(', '),
          projects: azureAD.pastProjects.join(', ')
        });
      }
      catch (error) { }
    }
  },

  endEditProfile: (communityId: string) => async (dispatch, getState) => {
    dispatch(push(constants.SCREEN_DASHBOARD));
    dispatch({
      type: PROFILE_EDIT_END
    });
  },

  startDeactivationProfile: () => (dispatch, getState) => {
    dispatch({
      type: PROFILE_DEACTIVATION_START
    });
  },

  endDeactivationProfile: (shouldDeactivate, removeFromCommunities) => async (
    dispatch,
    getState
  ) => {
    dispatch({
      type: PROFILE_DEACTIVATION_END
    });

    if (shouldDeactivate) {
      dispatch(
        profileActionCreators.requestDeactivationProfile(removeFromCommunities)
      );
    }
  },

  startLogoutProfile: (msalInstance: any, msalAccounts: any) => async (dispatch, getState) => {
    // MSAL v2 does that
    // sessionStorage.clear();
    // localStorage.clear();

    // Reset redirect loop flag
    localStorage.setItem('msal_attemptCount', '0');
    localStorage.setItem('msal_attemptTime',  '0');

    logoutRedirect(msalInstance, msalAccounts);

    dispatch({
      type: PROFILE_LOGOUT
    });
    dispatch({
      type: AUTH_ACTIONS.DELETE_AUTH
    });
  },

  requestSaveProfile: () => async (dispatch, getState) => {
    if (getState().profile.isLoading) {
      return;
    }

    dispatch({
      type: PROFILE_LOAD
    });

    const profileForm = getState().form.ProfileForm.values;

    /**
     * Process department input value to match what's saved in the DB
     */
    if (Array.isArray(profileForm.department)) {
      profileForm.department = {
        id: profileForm.department[0].id,
        name: profileForm.department[0].name
      };
    }

    dispatch(progressActionCreators.startShowProgress('Saving profile...'));

    try {
      const { gtToken } = await getAuthFromState(getState, dispatch);
      const json = await httpServiceSecurePut('/profiles', gtToken, profileForm);

      dispatch(notificationActionCreators.startShowNotification(json.result));
      dispatch(push(constants.SCREEN_DASHBOARD));
      dispatch({
        type: PROFILE_SAVE,
        isSubmitted: true,
        error: ''
      });
      setTimeout(() => {
        dispatch(profileActionCreators.requestProfile(true));
      }, 5000);

    } catch (error) {
      const syncErrors = {};
      let errorMessage = ' ';

      if (error.errors) {
        error.errors.forEach((error) => {
          syncErrors[error.propName] = error.errorMessage;
          errorMessage += error.errorMessage + ' ';
        });
      } else {
        errorMessage = error;
      }

      dispatch({
        type: '@@redux-form/UPDATE_SYNC_ERRORS',
        meta: {
          form: 'ProfileForm'
        },
        payload: {
          syncErrors
        }
      });
      dispatch(
        notificationActionCreators.startShowNotification(
          `${constants.ERROR_PROFILE_SAVE} Error: ${errorMessage}`
        )
      );
      dispatch({
        type: PROFILE_SAVE,
        isSubmitted: false,
        error: errorMessage
      });
    }
    finally {
      dispatch(progressActionCreators.endShowProgress());
    }
  },

  requestSaveEmail: () => async (dispatch, getState) => {
    if (getState().profile.isLoading) {
      return;
    }

    dispatch({
      type: PROFILE_LOAD
    });

    const params = {
      emailAddress: getState().form.EmailForm.values.emailAddress
    };

    dispatch(progressActionCreators.startShowProgress('Saving email...'));

    try {
      const { gtToken } = await getAuthFromState(getState, dispatch);
      const json = await httpServiceSecurePost(`/profiles`, gtToken, params);

      if (!json.errors.length) {
        dispatch(
          notificationActionCreators.startShowNotification(
            constants.MESSAGE_PROFILE_EMAIL_SAVE
          )
        );
        dispatch(push(constants.SCREEN_DASHBOARD));
        dispatch({
          type: PROFILE_EMAIL_SAVE,
          error: ''
        });
      } else {
        json.errors.forEach((error) => {
          if (error.errorMessage) {
            dispatch(
              notificationActionCreators.startShowNotification(
                error.errorMessage
              )
            );

            dispatch({
              type: PROFILE_EMAIL_SAVE,
              error: error.errorMessage
            });
          }
        });
      }

      setTimeout(() => {
        dispatch(profileActionCreators.requestProfile(true));
      }, 5000);

    } catch (error) {
      const syncErrors = {};
      let errorMessage = ' ';

      if (error.errors) {
        error.errors.forEach((error) => {
          syncErrors[error.propName] = error.errorMessage;
          errorMessage += error.errorMessage + ' ';
        });
      } else {
        errorMessage = error;
      }

      dispatch({
        type: '@@redux-form/UPDATE_SYNC_ERRORS',
        meta: {
          form: 'EmailForm'
        },
        payload: {
          syncErrors
        }
      });
      dispatch(
        notificationActionCreators.startShowNotification(
          `${constants.ERROR_PROFILE_EMAIL_SAVE} Error: '${errorMessage}`
        )
      );
      dispatch({
        type: PROFILE_EMAIL_SAVE,
        isSubmitted: false,
        error: errorMessage
      });
    }
    finally {
      dispatch(progressActionCreators.endShowProgress());
    }
  },

  requestResendEmail: () => async (dispatch, getState) => {
    if (getState().profile.isLoading) {
      return;
    }

    dispatch({
      type: PROFILE_LOAD
    });

    dispatch(
        progressActionCreators.startShowProgress('Re-sending email...')
    );

    try {

      const { gtToken } = await getAuthFromState(getState, dispatch);
      const response = await httpServiceSecurePostRaw(`/profiles/ResendChangeEmail`, gtToken);

      //Response.ok and status code is 204 = The pending email address change has already been processed
      //NOTE: response.ok is always true when the status codes is in the 200 range.
      //Throw the notification message
      if (response.status === 204) {
        dispatch(notificationActionCreators.startShowNotification(
                constants.MESSAGE_PROFILE_EMAIL_CHANGE_HAS_BEEN_PROCESSED
            )
        );
      }
      else {
        dispatch(
            notificationActionCreators.startShowNotification(
                constants.MESSAGE_PROFILE_EMAIL_RESEND
            )
        );
      }

      dispatch(push(constants.SCREEN_DASHBOARD));
      dispatch({
        type: PROFILE_EMAIL_RESEND,
        error: ''
      });
      setTimeout(() => {
        dispatch(profileActionCreators.requestProfile(true));
      }, 5000);

    } catch (error) {
      let errorMessage = ' ';

      if (error.errors) {
        error.errors.forEach((error) => {
          errorMessage += error.errorMessage + ' ';
        });
      } else {
        errorMessage = error;
      }

      dispatch(
          notificationActionCreators.startShowNotification(
              `${constants.ERROR_PROFILE_EMAIL_SAVE} Error: ${errorMessage}`
          )
      );
      dispatch({
        type: PROFILE_EMAIL_RESEND,
        isSubmitted: false,
        errorMessage
      });
    }
    finally {
      dispatch(progressActionCreators.endShowProgress());
    }
  },

  requestProfile: (needRefresh: boolean) => async (dispatch, getState) => {
    let { instance: msalInstance, accounts: msalAccounts, msToken, gtToken } = await getAuthFromState(getState, dispatch);
    let isGuest = true;

    // Do not proceed if needRefresh is false and profile is already set OR if profile is loading
    if (
      (!needRefresh && getState().profile.id != null) ||
      getState().profile.isLoading
    ) {
      return;
    }

    dispatch({
      type: PROFILE_LOAD
    });
    dispatch(progressActionCreators.startShowProgress());

    // For testing when fear of loop
    //debugger;

    /**
     * Retrieve GovTEAMS account information
     */
    const gtRet = await _getGTProfile({dispatch, getState, msalInstance, msalAccounts, gtToken});
    if ( gtRet === null ) return;

    /**
     * Retrieve Azure AD information
     */
    const msRet = await _getMSProfile({dispatch, getState, msalInstance, msalAccounts, msToken});
    if ( msRet === null ) return;
    isGuest = !!msRet.isGuest;
    msToken = msRet.msToken;


    /**
     * Retrieve additional user information for Government accounts
     */
    if ( !isGuest ) {
      await _getMSAboutUsAndIcon({dispatch, msToken, msalInstance});
    }
  },

  requestAuthentication: () => async (dispatch, getState) => {
  },

  requestVerifyProfile: (verificationCode: string) => async (
    dispatch,
    getState
  ) => {
    if (getState().profile.isLoading) {
      return;
    }

    dispatch({
      type: PROFILE_LOAD
    });

    dispatch(
        progressActionCreators.startShowProgress('Verifying profile...')
    );

    try {
      const { gtToken } = await getAuthFromState(getState, dispatch);
      const responseText = await httpServiceSecurePost(`/profiles/${verificationCode}`, gtToken);

      let memberStatus = getState().profile.memberStatus;

      /**
       * TODO check if json or text is being returned
       */
      if (responseText.message === 'Account successfully verified.') {
        memberStatus = MemberStatus.Active;
        dispatch(
            notificationActionCreators.startShowNotification(
                constants.MESSAGE_PROFILE_VERIFY_SUCCESS
            )
        );
      } else {
        dispatch(
            notificationActionCreators.startShowNotification(
                constants.MESSAGE_PROFILE_VERIFY_FAIL
            )
        );
      }

      dispatch({
        type: PROFILE_VERIFY,
        memberStatus
      });
      dispatch(progressActionCreators.endShowProgress());

      //TODO: try and update the profile sooner
      setTimeout(() => {
        dispatch(profileActionCreators.requestProfile(true));
      }, 1000);
      dispatch(push(constants.SCREEN_DASHBOARD));
    } catch (error) {
      dispatch({
        type: PROFILE_VERIFY,
        error,
        verificationError: error
      });
      dispatch(progressActionCreators.endShowProgress());
    }
  },

  notifyVerifiedEmail: () => async (dispatch, getState) => {
    try {
      const path = window.location.pathname;
      const message = (path === '/VerifiedEmailChange'
        ? constants.MESSAGE_PROFILE_EMAIL_CHANGE_SUCCESS
        : constants.MESSAGE_PROFILE_VERIFY_SUCCESS);
      dispatch(
        notificationActionCreators.startShowNotification(
          message
        )
      );
    } catch (error) {
      let errorMessage = ' ';

      if (error.errors) {
        error.errors.forEach((error) => {
          errorMessage += error.errorMessage + ' ';
        });
      } else {
        errorMessage = error;
      }

      dispatch(
        notificationActionCreators.startShowNotification(
          constants.ERROR_PROFILE_EMAIL_SAVE + ' Error:' + errorMessage
        )
      );
      dispatch(progressActionCreators.endShowProgress());
    }
  },

  notifyVerifiedEmailFail: () => async (dispatch, getState) => {
    try {
      dispatch(
        notificationActionCreators.startShowNotification(
          constants.MESSAGE_PROFILE_VERIFY_FAIL
        )
      );
    } catch (error) {
      let errorMessage = ' ';

      if (error.errors) {
        error.errors.forEach((error) => {
          errorMessage += error.errorMessage + ' ';
        });
      } else {
        errorMessage = error;
      }

      dispatch(
        notificationActionCreators.startShowNotification(
          constants.ERROR_PROFILE_EMAIL_SAVE + ' Error:' + errorMessage
        )
      );
      dispatch(progressActionCreators.endShowProgress());
    }
  },

  requestResendVerify: () => async (dispatch, getState) => {
    if (getState().profile.isLoading) {
      return;
    }

    dispatch({
      type: PROFILE_LOAD
    });

    dispatch(
        progressActionCreators.startShowProgress(
            'Re-sending verification email...'
        )
    );

    try {
      const { gtToken } = await getAuthFromState(getState, dispatch);
      await httpServiceSecurePost(`/profiles/ResendVerificationEmail`, gtToken);

      dispatch(
          notificationActionCreators.startShowNotification(
              constants.MESSAGE_PROFILE_VERIFY_RESEND
          )
      );
      dispatch({
        type: PROFILE_VERIFY_RESEND,
        error: ''
      });

      setTimeout(() => {
        dispatch(profileActionCreators.requestProfile(true));
      }, 5000);

    } catch (error) {
      let errorMessage = ' ';

      if (error.errors) {
        error.errors.forEach((error) => {
          errorMessage += error.errorMessage + ' ';
        });
      } else {
        errorMessage = error;
      }

      dispatch(
          notificationActionCreators.startShowNotification(
              `${constants.ERROR_PROFILE_EMAIL_SAVE} Error: ${errorMessage}`
          )
      );
      dispatch({
        type: PROFILE_VERIFY_RESEND,
        isSubmitted: false,
        error: errorMessage
      });
    }
    finally {
      dispatch(progressActionCreators.endShowProgress());
    }
  },

  requestDeactivationProfile: (removeFromCommunities) => async (
    dispatch,
    getState
  ) => {
    if (getState().profile.isLoading) {
      return;
    }

    dispatch({
      type: PROFILE_LOAD
    });

    dispatch(
        progressActionCreators.startShowProgress(
            'Deactivating your account...'
        )
    );

    // Get member type
    const memberType = getState().profile.memberType;

    try {
      const { instance, accounts, gtToken } = await getAuthFromState(getState, dispatch);
      await httpServiceSecureDelete(`/profiles/${removeFromCommunities}`, gtToken);

      dispatch(
          notificationActionCreators.startShowNotification(
              constants.MESSAGE_PROFILE_DEACTIVATED
          )
      );
      dispatch(push(`${constants.SCREEN_GOODBYE}#${ window.btoa(memberType) }`));
      dispatch({
        type: PROFILE_DEACTIVATED
      });
      dispatch(progressActionCreators.endShowProgress());
      dispatch(profileActionCreators.startLogoutProfile(instance, accounts,));

    } catch (error) {
      dispatch(
          notificationActionCreators.startShowNotification(
              constants.ERROR_PROFILE_DEACTIVATION
          )
      );
      dispatch({
        type: PROFILE_DEACTIVATED,
        error
      });
      dispatch(progressActionCreators.endShowProgress());
    }
  },

  requestProfileRestoreLicence: () => async (dispatch, getState) => {
    if (getState().profile.isLoading) {
      return;
    }

    dispatch({
      type: PROFILE_LOAD
    });

    try {
      const { gtToken } = await getAuthFromState(getState, dispatch);
      await httpServiceSecurePost(`/profiles/RestoreLicence`, gtToken);

      setTimeout(() => {
        dispatch(profileActionCreators.requestProfile(true));
      }, 5000);

    } catch (error) {
      let errorMessage = ' ';

      if (error.errors) {
        error.errors.forEach((error) => {
          errorMessage += error.errorMessage + ' ';
        });
      } else {
        errorMessage = error;
      }
      dispatch({
        type: PROFILE_RESTORE_LICENCE,
        error: errorMessage
      });
    }
  }
};

export default profileActionCreators;
