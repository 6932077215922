import React from 'react';
import { PrimaryButton } from '@fluentui/react/lib/Button';

const Logout = () => {
    return (
        <div className='govTeams-errorContent'>
            <br/>
            <h1>Logged out</h1>
            <hr/>

            <PrimaryButton
                text='Go to GovTeams'
                href='https://www.govteams.gov.au/'
            />
        </div>
    );

}

export default Logout;
