import { isProtected } from './Utils';

export const COMMNUNITYDASHBOARD_SUGGESTED = "These communities are suggested based on your profile information, system usage, and community popularity";
export const BRANDNAME = isProtected() ? "GovTEAMS PROTECTED" : "GovTEAMS OFFICIAL";

export const COMMUNITYTOOLS_TEAMS = "Launch community in Teams";
export const COMMUNITYTOOLS_TEAMS_DISABLED = "Your access to Teams is not ready yet!";
export const COMMUNITYTOOLS_SHAREPOINT = "Launch community in SharePoint";
export const COMMUNITYTOOLS_LEAVE = "Leave community";
export const COMMUNITYTOOLS_INVITE = "Invite members to community";
export const COMMUNITYTOOLS_EDIT = "Edit community";
export const COMMUNITYTOOLS_CLOSE = "Close community";
export const COMMUNITYTOOLS_JOIN = "Join community";
export const COMMUNITYTOOLS_REQUEST = "Request access";
export const COMMUNITYTOOLS_VIEWMANAGE = "View community details and membership";

export const MEMBERTOOLS_EMAIL = "Email member";
export const MEMBERTOOLS_DEMOTE = "Demote owner to member";
export const MEMBERTOOLS_PROMOTE = "Promote member to owner";
export const MEMBERTOOLS_REMOVE = "Remove member from community";

export const INVITETOOLS_RESEND = "Resend invitation";
export const INVITETOOLS_CANCEL = "Cancel invitation";

export const REQUESTTOOLS_APPROVE = "Approve request";
export const REQUESTTOOLS_CANCEL = "Cancel request";

export const PROFILE_USERNAME = `This is your ${BRANDNAME} username`;
export const PROFILE_EMAIL = `${BRANDNAME} correspondence is forwarded to this address. If you change this, you'll be sent an email to confirm. Must be an active and accessible Commonwealth Government email address.`;
export const PROFILE_DELVE = "Filling in your profile with your skills and interests will allow the system to suggest communities that may interest you and help connect you with like-minded people.";
export const PROFILE_DELVE_UPDATE = "Click here to edit the extended profile fields.";
export const PROFILE_PASSWORD =`Change ${BRANDNAME} account password`;
export const PROFILE_DEACTIVATION = "Deactivate your account";
export const PROFILE_MOBILE = `At least one phone number is required. The mobile number you list here will be displayed to other members of ${BRANDNAME}.`;
export const PROFILE_PHONE = `At least one phone number is required. The phone number you list here will be displayed to other members of ${BRANDNAME}.`;

export const SPONSORED_BADGE =
         'A sponsored community supports collaboration for whole of government initiatives. For an additional fee, community owners can invite employees from self-excluded Commonwealth Government organisations to access the community as a restricted user.';