import React, {useEffect, useState} from 'react';
import { useMsal } from '@azure/msal-react';
import { useLocation } from 'react-router-dom';

import { goToLoginScreen, goToLoggingOutScreen } from '../../auth';

const AppLoginRedirect = () => {
    const [message, setMessage] = useState(<p>Redirecting to login screen...</p>)
    const { instance } = useMsal();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search || '');
    const loginHint = searchParams.get('login_hint');

    useEffect(() => {

        // Get attempt count to avoid flooding
        let attempt = 0;
        let attemptStr = localStorage.getItem('msal_attemptCount');
        if ( attemptStr )
            attempt = parseInt(attemptStr, 10);

        // Get last attempt time
        const now = (new Date()).getTime();
        const lastAttemptTime = localStorage.getItem('msal_attemptTime');

        // If 2 min passed, reset attempt count
        if ( (now - parseInt(lastAttemptTime, 10)) > (120 * 1000)) {
            attempt = 0;
            setMessage(<p>Redirecting to login screen...</p>);
        }


        if ( instance ) {
            if ( attempt > 10 ) {
                setMessage(<p>Stopping login attempt to avoid redirect loop.<br />Please wait while we redirect you to logout screen.</p>);
                setTimeout(goToLoggingOutScreen, 1000);
                return;
            }
            localStorage.setItem('msal_attemptCount', String(attempt+1));
            localStorage.setItem('msal_attemptTime', '' + now);

            // @ts-ignore
            goToLoginScreen(instance, loginHint)
                .catch(e => {
                    console.error('Error occurred while trying to redirect to login screen', e);
                });
            return;
        }
        setMessage(<p>Waiting to load login screen</p>);

    }, [instance]);

    return (
        <div className='ms-fontColor-gray100 mx-2'>{message}</div>
    );
};

export default AppLoginRedirect;
