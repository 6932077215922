import actions from './QuickLinks.actions';

const initialState = {
  quickLinks: null,
  isLoading: false,
  error: null
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === actions.QUICKLINKS_FETCH_REQUEST) {
    return {
      ...state,
      isLoading: true
    };
  }

  if (action.type === actions.QUICKLINKS_FETCH_RESPONSE) {
    return {
      ...state,
      quickLinks: action.quickLinks,
      isLoading: false,
      error: action.error
    };
  }

  return state;
};

export default reducer;
