/**
 * Community member persona
 *
 * Displays user information with hover card
 */

/**
 * React Redux
 */
import * as React from 'react';
import { connect } from 'react-redux';

/**
 * Fabric UI
 */
import { ActionButton } from '@fluentui/react/lib/Button';
import {
  HoverCard,
  HoverCardType,
} from '@fluentui/react/lib/HoverCard';
import { DirectionalHint } from '@fluentui/react/lib/Callout';
import { Icon } from '@fluentui/react/lib/Icon';
import { Link } from '@fluentui/react/lib/Link';
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';
import { BRANDNAME } from '../../../common/Tooltips';

import {
  MemberStatus,
  MemberType
} from '../../../controllers/CommunityController';

export class CommunityMemberPersona extends React.Component<any, any> {
  public render() {
    const { item, community } = this.props;

    const data: any = {
      community,
      displayChat: this.props.azureId === item.azureId ? false : true,
      isGuest: this.props.isGuest,
      item
    };

    let cardHeight = 0;

    if (
      community.isMember &&
      !this.props.isGuest &&
      this.props.azureId !== item.azureId &&
      item.inactiveDays < 0
    ) {
      cardHeight = 190;

      if (item.isGuest) {
        cardHeight = 170;
      }
    } else {
      cardHeight = 135;

      if (item.isGuest) {
        cardHeight = 110;
      }
    }

    const cardProps = {
      directionalHint: this.props.directionalHint
        ? this.props.directionalHint
        : DirectionalHint.rightCenter,
      gapSpace: this.props.gapSpace ? this.props.gapSpace : 0,
      isGuest: this.props.isGuest,
      onRenderPlainCard: this.renderCard,
      plainCardHeight: cardHeight,
      renderData: data,
      styles: {}
    };

    return (
      <HoverCard plainCardProps={cardProps} type={HoverCardType.plain}>
        <Persona
          text={item.fullName}
          onRenderPrimaryText={this.onRenderPrimaryText}
          secondaryText={item.occupation + ' | ' + item.department}
          tertiaryText={item.emailAddress}
          onRenderTertiaryText={this.onRenderTertiaryText}
          size={this.props.size ? this.props.size : PersonaSize.size72}
          data-status={item.inactiveDays}
          data-membertype={item.memberType}
          data-memberstatus={item.memberStatus}
          coinSize={this.props.coinSize}
        />
      </HoverCard>
    );
  }

  private onRenderPrimaryText = (props) => {
    const inactive =
      props['data-memberstatus'] === MemberStatus.PendingLicence ||
      props['data-status'] > -1 ? (
        <span className='member-status'>({props['data-memberstatus']})</span>
      ) : (
        ''
      );
    const sponsored =
      props['data-membertype'] === MemberType.Sponsored ? (
        <span className='sponsoredBadge'>{MemberType.Sponsored}</span>
      ) : (
        ''
      );

    return (
      <div>
        {props.text} {inactive} {sponsored}
      </div>
    );
  };

  private onRenderTertiaryText = (props) => {
    const status = (
      <div>
        {props['data-status'] > 6
          ? 'Inactive for ' + props['data-status'] + ' days'
          : ''}
      </div>
    );

    return (
      <div>
        <div>{props.tertiaryText}</div>
        {status}
      </div>
    );
  };

  private renderCard = (data: any): React.ReactElement<any> => {
    const { community, displayChat, isGuest, item } = data;

    return (
      <div className='memberPersona-callout'>
        <div className='memberPersona-delve'>
          <Persona
            text={item.fullName}
            onRenderPrimaryText={this.onRenderPrimaryText}
            secondaryText={item.occupation + ' | ' + item.department}
            tertiaryText={item.emailAddress}
            onRenderTertiaryText={this.onRenderTertiaryText}
            size={PersonaSize.size72}
            data-status={item.inactiveDays}
            data-membertype={item.memberType}
            data-memberstatus={item.memberStatus}
          />
          {!isGuest && !item.isGuest && item.accountType !== 'Linked Account' && (
            <Link
              className='memberPersona-delveLink'
              href={
                'https://aus.delve.office.com/?u=' + item.azureId + '&v=work'
              }
              rel='noopener noreferrer'
              target='_blank'
            >
              View full profile <Icon iconName='Forward' />
            </Link>
          )}
          {!item.isGuest && item.accountType === 'Linked Account' && (
            <div>
              <br />
              <p>
                This State Government user has paid a fee to own communities in
                {BRANDNAME}.{' '}
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://www.govteams.gov.au/state-government-owner'
                >
                  Find out more.
                </a>
              </p>
            </div>
          )}
        </div>
        {community !== undefined &&
          community.isMember &&
          this.props.govTeamsProfileInfo &&
          this.props.govTeamsProfileInfo.memberType !== MemberType.Sponsored &&
          displayChat &&
          item.memberStatus !== MemberStatus.Inactive &&
          item.memberStatus.indexOf('Pending') === -1 &&
          item.memberType !== MemberType.Sponsored && (
            <div>
              <hr />
              <div className='memberPersona-contacts'>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <ActionButton
                          text='Send direct message'
                          iconProps={{ iconName: 'Message' }}
                          href={`https://teams.microsoft.com/_#/conversations/8:orgid:${
                            item.accountType === 'Linked Account' &&
                            item.linkedAzureId
                              ? item.linkedAzureId
                              : item.azureId
                          }?ctx=chat`}
                          target='_blank'
                          styles={{
                            icon: {
                              fontSize: 24,
                              height: 24,
                              width: 24,
                              lineHeight: 24,
                              backgroundColor: '#eaeaea',
                              boxSizing: 'content-box',
                              marginLeft: 0,
                              padding: 8,
                              borderRadius: 8
                            }
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
      </div>
    );
  };
}

const mapStateToProps = (state: any) => ({
  govTeamsProfileInfo: state.profile.govTeamsProfileInfo,
  azureId: state.profile.id,
  isGuest: state.profile.isGuest
});

export default connect(mapStateToProps)(CommunityMemberPersona);
