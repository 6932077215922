import * as React from 'react';
import { connect } from 'react-redux';
import { BRANDNAME } from '../common/Tooltips';

const ThankyouForRegistering = props => (
  <div>
    <h2 className="heading-success">Thank you for registering with {BRANDNAME}</h2>
    <p>
      A confirmation email will be sent to you in order to activate your
      account.
    </p>
    <p>
      Please ensure that you check your Junk/Spam folders if you don't see this
      email in your inbox.
    </p>
  </div>
);

export default connect()(ThankyouForRegistering);
