import actions from './Members.actions';

import { IMemberState } from './Members.types';
export const initialState: IMemberState = {
  communityId: null,
  adminMembers: [],
  govMembers: [],
  guestMembers: [],
  externalMembers: [],
  allMembers: [],
  isLoading: false,
  error: null
};

export const reducer = (state, action) => {
  state = state || initialState;

  //API
  if (action.type === actions.MEMBERS_FETCH_REQUEST) {
    return {
      ...state,
      adminMembers: null,
      govMembers: null,
      guestMembers: null,
      externalMembers: null,
      isLoading: true
    };
  }

  if (action.type === actions.MEMBERS_FETCH_RESPONSE) {
    return {
      ...state,
      allMembers: action.allMembers,
      adminMembers: action.adminMembers,
      govMembers: action.govMembers,
      guestMembers: action.guestMembers,
      externalMembers: action.externalMembers,
      isLoading: false,
      error: action.error
    };
  }

  if (action.type === actions.MEMBERS_VIEW_REQUEST) {
    return {
      ...state,
      allMembers: null,
      isLoading: true
    };
  }

  if (action.type === actions.MEMBERS_VIEW_RESPONSE) {
    return {
      ...state,
      allMembers: action.allMembers,
      isLoading: false,
      error: action.error
    };
  }

  if (action.type === actions.MEMBERS_FETCH_BY_ROLE_REQUEST) {
    return {
      ...state,
      allMembers: null,
      isLoading: true
    };
  }

  if (action.type === actions.MEMBERS_FETCH_BY_ROLE_RESPONSE) {
    return {
      ...state,
      allMembers: action.allMembers,
      isLoading: false,
      error: action.error
    };
  }

  if (action.type === actions.MEMBERS_DELETE_REQUEST) {
    return {
      ...state,
      isLoading: true
    };
  }

  if (action.type === actions.MEMBERS_DELETE_RESPONSE) {
    return {
      ...state,
      isLoading: false,
      error: action.error
    };
  }

  if (action.type === actions.MEMBERS_CHANGE_REQUEST) {
    return {
      ...state,
      isLoading: true
    };
  }

  if (action.type === actions.MEMBERS_CHANGE_RESPONSE) {
    return {
      ...state,
      isLoading: false,
      error: action.error
    };
  }

  return state;
};

export default reducer;
