import ReactGA from 'react-ga4';

// Cant include this function from Util then get errors becz of circular dependency
export const consoleLog = (...args) => {
  if ( process.env.REACT_APP_ENV === "development" )
    console.log(...args);
};

export const saveGAPageView = (url: any) => {
  if ( saveGAPageView === undefined ) {
    consoleLog('GA Page view not logged. URL NA: ', url);
    return;
  }
  ReactGA.send({ hitType: 'pageview', page:  url });
};

export const setGAUserId = (userId: string, user: any) => {
  if ( !userId ) {
    consoleLog('GA user not set: ', userId, user);
    return;
  }

  ReactGA.set({
    user_id: userId,
    user_member_type: user.memberType,
    user_member_status: user.memberStatus,
  });
  ReactGA.event('login', {
    event_category: 'login',
    event_action: user.memberType?.toLowerCase().replaceAll(' ', '_'),
    event_label: userId,
    event_platform: 'Portal',
    traffic_type: process.env.REACT_APP_ENV === 'development' ? 'internal':null,
  });
};

export const saveGAEvent = (eventName: string, params: any = {}) => {
  if ( !eventName ) {
    consoleLog('GA event not sent. Event name is empty', eventName);
    return;
  }
  consoleLog('GA event logged', eventName, params);
  ReactGA.event(eventName, {
    ...params,
    event_platform: 'Portal',
    traffic_type: process.env.REACT_APP_ENV === 'development' ? 'internal':null,
  });
};

export const saveGACustomEvent = (eventName: string, actionName: string, category: string, label: string, params: any = {}) => {
  if ( !eventName ) {
    consoleLog('GA event not sent. Event name is empty', eventName);
    return;
  }
  saveGAEvent(eventName, {
    ...params,
    event_action: actionName,
    event_category: category,
    event_label: label,
  });
};

