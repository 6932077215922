/**
 * List of organisation administrators
 *
 * Provides functionality to message admins
 *
 * Used on organisation summary screen
 *
 */

/**
 * React
 */
import * as React from 'react';
import { connect } from 'react-redux';

/**
 * Fabric UI
 */
import { List } from '@fluentui/react/lib/List';
import { IconButton } from '@fluentui/react/lib/Button';
import {
  TooltipHost
  // DirectionalHint
} from '@fluentui/react/lib/Tooltip';
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';

/**
 * Constants
 */
import { IOrganisationMember } from '../../../controllers/OrganisationController';

export class OrganisationAdministratorsComponent extends React.Component<
  any,
  any
> {
  public render() {
    const orgAdmins: IOrganisationMember[] = this.props.orgAdmins;

    return (
      <React.Fragment>
        {orgAdmins && orgAdmins.length > 0 && (
          <React.Fragment>
            <div className="communityPage-cellHeader">
              <h2>Administrators</h2>
            </div>
            <List items={orgAdmins} onRenderCell={this.onRenderCell} />
            <hr />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  private onRenderCell = (item: IOrganisationMember) => {
    const status = item.memberStatus;

    return (
      <div className="memberItem" data-is-focusable={true}>
        <div className="memberPersona">
          <Persona
            text={item.fullName}
            secondaryText={item.occupation + ' | ' + item.department}
            tertiaryText={item.emailAddress}
            size={PersonaSize.size72}
            coinSize={40}
            data-status={item.inactiveDays}
          />
        </div>
        <div className="memberTools">
          {this.props.id !== item.azureId &&
            item.azureId !== this.props.linkedAccountid &&
            status !== 'Inactive' &&
            status.indexOf('Pending') === -1 && (
              <TooltipHost content="Message administrator">
                <IconButton
                            id={item.azureId}
                  iconProps={{ iconName: 'Message' }}
                  href={`https://teams.microsoft.com/_#/conversations/8:orgid:${
                      item.azureId
                  }?ctx=chat`}
                  target="_blank"
                />
              </TooltipHost>
            )}
        </div>
      </div>
    );
  };
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export const OrganisationAdministrators = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganisationAdministratorsComponent);

export default OrganisationAdministrators;
