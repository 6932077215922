import actions from './Auth.actions';

export const authActionCreators = {
  saveAuthInstance: (instance, accounts) => async (dispatch, getState) => {
    dispatch({
      type: actions.SAVE_AUTH,
      instance: instance,
      accounts: accounts,
    });
  },
  saveMsToken: (msToken) => async (dispatch, getState) => {
    dispatch({
      type: actions.SAVE_TOKEN_MS,
      msToken: msToken,
    });
  },
  saveGtToken: (gtToken) => async (dispatch, getState) => {
    dispatch({
      type: actions.SAVE_TOKEN_GT,
      gtToken: gtToken,
    });
  },
  deleteAuth: () => async (dispatch, getState) => {
    dispatch({
      type: actions.DELETE_AUTH,
    });
  },
};

export default authActionCreators;
