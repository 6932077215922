import * as React from 'react';
import { connect } from 'react-redux';
import { BRANDNAME } from '../../common/Tooltips';
import * as qs from 'qs';

class ConfirmationVerificationSuccess extends React.Component<any, any> {
    public render() {
        const loginHint = this.getLoginHint();

        return (
            <div className='my-2'>
                <h1 className="heading-success">Congratulations!</h1>
                <p>
                    Your account has been successfully verified!
                </p>
                <p>
                    Go to the <a href={'verified?login_hint='+loginHint}>{BRANDNAME} dashboard</a> to continue
                </p>
            </div>);
    }

    private getLoginHint() {
        const params = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true
        });
        if (params.LoginHint) {
            return params.LoginHint;
        } else {
            return '';
        }
    }
}

export default connect()(ConfirmationVerificationSuccess);