/**
 * Reactivation screen
 *
 * Allows member to:
 * -Reactivate account
 * -Change email address
 * -TO DO: Remove membership from all communities
 *
 */
import React from 'react';

/**
 * Redux
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { profileActionCreators } from '../../controllers/ProfileController';

/**
 * UI Fabric
 */
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { Icon } from '@fluentui/react/lib/Icon';
import { MessageBar } from '@fluentui/react/lib/MessageBar';

/**
 * GovTEAMS components
 */
import EmailForm from './EmailForm';
import Deactivation from './Deactivation';
import { MemberType } from '../../controllers/CommunityController';

/**
 * UI Fabric customized
 */
import * as constants from '../../common/Constants';
import UserAccess from './UserAccess';
import { getMemberEmailDomain } from '../../common/Utils';
import { BRANDNAME } from '../../common/Tooltips';

export class Reactivate extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      showSection1: false,
      showSection2: false,
      showDeactivate: false
    };
  }

  public render() {
    return (
      <div className='govTeams-account govTeams-reactivate'>
        <Deactivation />
        <div className='govTeams-account-row'>
          <div className='govTeams-account-col col-wide'>
            {this.accountUnderReview() && !this.accountInactive() && (
              <div className='govTeams-userAccess'>
                <h1>Let's verify your email address</h1>
                <hr />
                <p>
                  To check you still have access to your email, we’ve
                  sent a verification link to{' '}
                  <strong>{this.props.profile.mail}</strong>. Click this link to
                  verify your account. You have{' '}
                  {this.props.profile.daysLeftToVerify} days to do this before
                  your account is on hold.{' '}
                </p>
                <UserAccess noContent={true} />
                <p>
                  <a
                    href='https://www.govteams.gov.au/reconfirm-your-email/'
                    rel='noreferrer'
                    target='_blank'
                  >
                    Why we ask you to verify your email address
                  </a>
                  .
                </p>
                <MessageBar className='govTeams-messageBar'>
                  <strong>What happens if my account is deactivated?</strong>
                  <div>
                    Don’t worry, you can pick up right where you left off! Even
                    if your account is now on hold, your profile information and
                    community memberships will remain in the system.
                  </div>
                </MessageBar>
              </div>
            )}

            {!this.accountUnderReview() && this.accountInactive() && (
              <div className='govTeams-userAccess'>
                <h1>Let’s reactivate your account!</h1>
                <hr />
                <p>
                  Your account is on hold until you verify that you still have access
                  to your email. Don’t worry, you can pick up right
                  where you left off! Even though your account is on hold, your
                  profile information and community memberships are still in the
                  system. To reactivate your account, click the verification
                  link we've sent to <strong>{this.props.profile.mail}</strong>.
                </p>
                <UserAccess noContent={true} />
                <p>
                  <a
                    href='https://www.govteams.gov.au/reconfirm-your-email/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Why we ask you to verify your email address
                  </a>
                  .
                </p>
              </div>
            )}

            {this.props.profile.memberType !== MemberType.LAM && this.props.profile.memberType !== MemberType.Partner &&
              <div className='govTeams-collapsibleSection'>
                <div className='collapsibleHeader' onClick={this.onClickSection1}>
                  <Icon
                    iconName={
                      !this.state.showSection1 ? 'ChevronRight' : 'ChevronDown'
                    }
                  />
                  <h2>Has your email address changed?</h2>
                </div>
                {this.state.showSection1 && (
                  <div className='collapsibleContent'>
                    <p>{constants.TEXT_REACTIVATE_EMAILCHANGE}</p>
                    <EmailForm
                      showCancel={false}
                    />
                  </div>
                )}
              </div>
            }

            <div className='govTeams-collapsibleSection'>
              <div className='collapsibleHeader' onClick={this.onClickSection2}>
                <Icon
                  iconName={
                    !this.state.showSection2 ? 'ChevronRight' : 'ChevronDown'
                  }
                />
                <h2>Don’t want to use {BRANDNAME} anymore?</h2>
              </div>
              {this.state.showSection2 && (
                <div className='collapsibleContent'>
                  {this.accountUnderReview() && !this.accountInactive() && (
                    <div>
                      <p>
                        You can deactivate your {BRANDNAME} account at any time.
                        Your community memberships will remain in the system,
                        however you have the option to be removed from these
                        communities.
                      </p>
                      <p>
                        <PrimaryButton onClick={this.onDeactivate}>
                          Deactivate my account
                        </PrimaryButton>
                      </p>
                    </div>
                  )}

                  {this.accountInactive() && !this.accountUnderReview() && (
                    <div>
                      <p>{constants.TEXT_REACTIVATE_REMOVE}</p>
                      <p>
                        <PrimaryButton onClick={this.onRemoveCommunities}>
                          Remove me from communities
                        </PrimaryButton>
                      </p>
                    </div>
                  )}

                  <MessageBar className='govTeams-messageBar'>
                    You can reactivate your account at any time by logging in
                    with your {BRANDNAME} username (ending in{' '}
                    <strong>{getMemberEmailDomain(this.props.profile.memberType)}</strong>) and current password.
                  </MessageBar>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  private onClickSection1 = (event) => {
    this.setState({
      showSection1: !this.state.showSection1
    });
  };

  private onClickSection2 = (event) => {
    this.setState({
      showSection2: !this.state.showSection2
    });
  };

  private onDeactivate = (event) => {
    this.props.profileActions.startDeactivationProfile();
  };

  private onRemoveCommunities = (event) => {
    this.props.profileActions.endDeactivationProfile(true, true);
  };

  private accountUnderReview = () => {
    return this.props.profile.memberStatus === 'Under Review';
  };

  private accountInactive = () => {
    return this.props.profile.memberStatus === 'Inactive';
  };
}

const mapStateToProps = (state) => ({
  profile: state.profile,
  initialValues: state.profile.initialValues
});

const mapDispatchToProps = (dispatch) => ({
  profileActions: bindActionCreators(profileActionCreators, dispatch),
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Reactivate);
