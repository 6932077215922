/**
 * List of members organised by owners, FAM members and guests.
 *
 * Provides functionality to promote, demote, message and remove members
 *
 * Used on community management screen
 *
 */

/**
 * React
 */
import * as React from 'react';

import MembersComponent from '@govteams/library/components/ManageCommunity/MembersComponent';
import CommunityMemberPersona from './CommunityMemberPersona';

/**
 * Constants
 */
import * as tooltips from '../../../common/Tooltips';

const Members = (props) => {
  const {
    communityId,
    adminMembers,
    initialValues,
    memberType,
    azureId,
    linkedAccountAzureId,
    membersActionCreators,
  } = props;

  return (
    <MembersComponent
      communityId={communityId}
      tooltips={tooltips}
      adminMembers={adminMembers}
      initialValues={initialValues}
      memberType={memberType}
      azureId={azureId}
      linkedAccountAzureId={linkedAccountAzureId}
      CommunityMemberPersona={CommunityMemberPersona}

      requestChangeMember={membersActionCreators.requestChangeMember}
      requestDeleteMember={membersActionCreators.requestDeleteMember}
      viewCommunityMembers={membersActionCreators.viewCommunityMembers}
    />
  );
}

export default Members;
