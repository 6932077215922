/**
 * Simple media query functionality to control view and visibility of React components
 *  
 */

export const mediaQuery = {
  minWidth(width, minWidth): boolean {

    if (width >= minWidth) {
      return true;
    }
      
    return false;
  },

  maxWidth(width, maxWidth): boolean {
    if (width <= maxWidth) {
      return true;
    }

    return false;
  },
}

export const screenSize = {
  SCREEN_LG: 768,
  SCREEN_MD: 640,
  SCREEN_SM: 480,
  SCREEN_XL: 1024,
  SCREEN_XXL: 1366
}