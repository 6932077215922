/**
 * organisation management screen
 *
 */

/**
 * React
 */
import * as React from 'react';

/*
 * Redux
 */
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { RouteComponentProps } from 'react-router';
import { bindActionCreators } from 'redux';

/**
 * Fabric UI
 */
import { ActionButton } from '@fluentui/react/lib/Button';

/**
 * Components
 */
import ExternalMemberListComponent from '@govteams/library/components/ManageOrganisation/ExternalMemberListComponent/';

/**
 * GovTEAMS
 */
import {
  IOrganisationProps,
  IOrganisationState,
  organisationActionCreators
} from '../../controllers/OrganisationController';
import { IExternalMembersState } from './ExternalMembers.types';
import {
  selectionActions,
  constants as componentConstants,
  tooltips
} from '../OrganisationMembers/OrganisationMembers.constants';

/**
 * Constants and utilities
 */
import * as constants from '../../common/Constants';


type IMergedProps = IOrganisationState &
  IOrganisationProps &
  RouteComponentProps;

export class ExternalMembersList extends React.Component<
  IMergedProps,
  IExternalMembersState
  > {

  public render() {
    return (
      <div className='orgMembersManagement-page'>
        <ActionButton
          className='govTeams-actionButton'
          onClick={(event: any) => {
            this.props.dispatch(
              replace(
                `${constants.SCREEN_ORGANISATION}/${this.props.match.params['organisationId']}`
              )
            );
          }}
          iconProps={{ iconName: 'ChevronLeft' }}
        >
          {constants.LINK_BACKTOORGMANAGEMENT}
        </ActionButton>

        <ExternalMemberListComponent
          organisation={this.props.organisation}
          error={this.props.error}
          componentConstants={componentConstants}
          urlMatch={this.props.match}
          tooltips={tooltips}
          selectionActions={selectionActions}
          callbackOnLoad={this.props.organisation}
          membersActionResult={this.props.membersActionResult}
          membersRemoveActionResult={this.props.membersRemoveActionResult}
          membersResult={this.props.membersResult}

          requestFetchExternalOrganisationMembers={this.props.organisationActionCreators.requestFetchExternalOrganisationMembers}
          requestFetchOrganisationMembers={this.props.organisationActionCreators.requestFetchOrganisationMembers}
          removeExternalMember={this.props.organisationActionCreators.removeExternalMember}
          startExternalMemberReview={this.props.organisationActionCreators.startExternalMemberReview}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  organisation: state.organisation.organisation,
  membersResult: state.organisation.membersResult,
  membersActionResult: state.organisation.membersActionResult,
  domains: state.organisation.domains,
  licence: state.organisation.licence,
  membersCsv: state.organisation.membersCsv,
  error: state.organisation.error
});

const mapDispatchToProps = (dispatch) => ({
  organisationActionCreators: bindActionCreators(
    organisationActionCreators,
    dispatch
  ),
  dispatch
});

export const ExternalMembers = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExternalMembersList);

export default ExternalMembers;