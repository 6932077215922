import * as React from 'react';
import { connect } from 'react-redux';
import { BRANDNAME } from '../../common/Tooltips';

const ConfirmationAlreadyActive = props => (
  <div>
    <h1 className="heading-success">You're good to go!</h1>
    <p>
        Your account has been verified and you don't need
        to do anything more at this time. 
    </p>
    <p>
      Go to the <a href="dashboard">{BRANDNAME} dashboard</a> to continue
    </p>
  </div>
);

export default connect()(ConfirmationAlreadyActive);
