import actions from './Auth.actions';
import { IAuthState } from './Auth.types';

const initialState: IAuthState = {
  instance: null,
  accounts: [],
  msToken: null,
  gtToken: null,
  error: null
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === actions.SAVE_AUTH) {
    return {
      ...state,
      instance: action.instance,
      accounts: action.accounts,
    };
  }

  if (action.type === actions.SAVE_TOKEN_MS) {
    return {
      ...state,
      msToken: action.msToken,
    };
  }

  if (action.type === actions.SAVE_TOKEN_GT) {
    return {
      ...state,
      gtToken: action.gtToken,
    };
  }

  if (action.type === actions.DELETE_AUTH) {
    return {
      ...state,
      gtToken: null,
      msToken: null,
      // instance: null,
      // accounts: null,
    };
  }
  return state;
};

export default reducer;
