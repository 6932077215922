import * as React from 'react';
import { connect } from 'react-redux';
import { BRANDNAME } from '../../common/Tooltips';

const Error_VerifyAccountBlocked = props => (
    <div>
        <h2 className="heading-alert">Your account could not be verified</h2>
        <p>
            Your account is currently blocked or suspended. Please contact the{' '}
            <a href={props.mailTo}>{BRANDNAME} Helpdesk </a>if you require further information.
        </p>
    </div>
);

export default connect()(Error_VerifyAccountBlocked);
