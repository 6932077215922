/**
 * organisation management screen
 *
 */

/**
 * React
 */
import * as React from 'react';

/*
 * Redux
 */
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { bindActionCreators } from 'redux';

/**
 * Fabric UI
 */
import { ActionButton } from '@fluentui/react/lib/Button';

/**
 * Components
 */
import DefaultCommunityComponent from '@govteams/library/components/ManageOrganisation/DefaultCommunityComponent/';

/**
 * GovTEAMS
 */
import {
    constants as componentConstants,
    tooltips
} from '../OrganisationMembers/OrganisationMembers.constants';

/**
 * Constants and utilities
 */
import * as constants from '../../common/Constants';
import organisationActionCreators from '../../controllers/OrganisationController/Organisation.operations';

const OrganisationDefaultCommunitiesListComponent = (props) => {
    const { 
        dispatch, 
        match, 
        organisation, 
        error, 
        OrganisationDefaultCommunitiesResult, 
        OrganisationDefaultCommunitiesMessage, 
        OrganisationDefaultCommunitiesError, 
        organisationActionCreators,
    } = props;

    return (
        <div className='orgMembersManagement-page'>
            <ActionButton
                className='govTeams-actionButton'
                onClick={(event: any) => {
                    dispatch(
                        replace(
                            `${constants.SCREEN_ORGANISATION}/${match.params['organisationId']}`
                        )
                    );
                }}
                iconProps={{ iconName: 'ChevronLeft' }}
            >
                {constants.LINK_BACKTOORGMANAGEMENT}
            </ActionButton>

            <DefaultCommunityComponent
                title={'Default communities'}
                organisation={organisation}
                error={error || OrganisationDefaultCommunitiesError}
                componentConstants={componentConstants}
                urlMatch={match}
                tooltips={tooltips}
                callbackOnLoad={organisation}
                itemResult={OrganisationDefaultCommunitiesResult}
                apiMessage={OrganisationDefaultCommunitiesMessage}

                requestFetch={organisationActionCreators.requestFetchOrganisationDefaultCommunities}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    organisation: state.organisation.organisation,
    OrganisationDefaultCommunitiesResult: state.organisation.orgDefaultCommunities,
    OrganisationDefaultCommunitiesError: state.organisation.organisationError,
    OrganisationDefaultCommunitiesMessage: state.organisation.message,
    error: state.organisation.error
});

const mapDispatchToProps = (dispatch) => ({
    organisationActionCreators: bindActionCreators(
        organisationActionCreators,
        dispatch
    ),
    dispatch
});

export const OrganisationDefaultCommunitiesList = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrganisationDefaultCommunitiesListComponent);

export default OrganisationDefaultCommunitiesList; 