/**
 * Persona component used for member search results
 *
 */

/**
 * React Redux
 */
import * as React from 'react';
import { connect } from 'react-redux';

/**
 * Fabric UI
 */
import { ActionButton } from '@fluentui/react/lib/Button';
import { DirectionalHint } from '@fluentui/react/lib/Callout';
import {
  HoverCard,
  HoverCardType,
} from '@fluentui/react/lib/HoverCard';
import { Icon } from '@fluentui/react/lib/Icon';
import { Link } from '@fluentui/react/lib/Link';
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';

export class MemberPersona extends React.Component<any, any> {
  public render() {
    const item = this.props.item;
    const cardProps = {
      directionalHint: DirectionalHint.rightCenter,
      onRenderPlainCard: this.renderCard,
      plainCardHeight: !this.props.isGuest ? 208 : 110,
      renderData: item
    };

    return (
      <HoverCard plainCardProps={cardProps} type={HoverCardType.plain}>
        <Persona text={item.displayName} size={PersonaSize.size32} />
      </HoverCard>
    );
  }

  private renderCard = (item) => {
    return (
      <div className='memberPersona-callout'>
        <div className='memberPersona-delve'>
          <Persona
            text={item.displayName}
            secondaryText={item.jobTitle + ' | ' + item.department}
            size={PersonaSize.size72}
          />
          {!this.props.isGuest && (
            <Link
              className='memberPersona-delveLink'
              href={'https://aus.delve.office.com/?u=' + item.id + '&v=work'}
              rel='noopener noreferrer'
              target='_blank'
            >
              View full profile <Icon iconName='Forward' />
            </Link>
          )}
        </div>
        {!this.props.isGuest && this.props.memberType !== 'Sponsored' && (
          <div>
            <hr />
            <div className='memberPersona-contacts'>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <ActionButton
                        text='Send direct message'
                        iconProps={{ iconName: 'Message' }}
                        href={
                          'https://teams.microsoft.com/_#/conversations/8:orgid:' +
                          item.id +
                          '?ctx=chat'
                        }
                        target='_blank'
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  isGuest: state.profile.isGuest,
  memberType: state.profile.memberType
});

export default connect(mapStateToProps)(MemberPersona);
