/**
 * Community view of search
 *
 */

/**
 * React
 */
import * as React from 'react';

/**
 * Redux
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { communityActionCreators } from '../../../controllers/CommunityController';
import { searchActionCreators } from '../Search.operations';

/**
 * Office Fabric UI components
 */
import { Icon } from '@fluentui/react/lib/Icon';
import { Link } from '@fluentui/react/lib/Link';
import { ActionButton } from '@fluentui/react/lib/Button';
import {
  ConstrainMode,
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode
} from '@fluentui/react/lib/DetailsList';
import {
  MessageBar,
  MessageBarType
} from '@fluentui/react/lib/MessageBar';

/**
 * GovTEAMS dependencies
 */
import CommunityPanel from '../../../components/CommunityPanel/CommunityPanel';
import Tag from '../../../components/ReduxFormFields/Tag';

/**
 * Utilities
 */
import { sortItems, sortItemsByInt } from '../../../common/Utils';
import { isEqual } from 'lodash';

export class SearchCommunities extends React.Component<any, any> {
  constructor(props) {
    super(props);

    const columns = [
      {
        key: 'displayName',
        name: 'Community Name',
        fieldName: 'displayName',
        minWidth: 250,
        maxWidth: 300,
        isMultiline: true,
        isResizable: true,
        ariaLabel: 'Operations for community name',
        onColumnClick: this.onColumnClick
      },
      {
        key: 'description',
        name: 'Description',
        fieldName: 'description',
        minWidth: 300,
        maxWidth: 400,
        isMultiline: true,
        isResizable: true,
        onColumnClick: this.onColumnClick
      },
      {
        key: 'communityType',
        name: 'Community type',
        fieldName: 'communityType',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        ariaLabel: 'Operations for community type',
        onColumnClick: this.onColumnClick
      },
      {
        key: 'tags',
        name: 'Tags',
        fieldName: 'tags',
        isMultiline: true,
        minWidth: 200,
        maxWidth: 300,
        isResizable: true,
        onColumnClick: this.onColumnClick
      },
      {
        key: 'members',
        name: 'Members',
        fieldName: 'members',
        maxWidth: 50,
        isResizable: true,
        onColumnClick: this.onColumnClick
      }
    ];

    this.state = {
      columns,
      currPage: 0,
      filter: '',
      items: [],
      pageItems: [],
      page: []
    };
  }

  public render() {
    const { columns } = this.state;

    return (
      <React.Fragment>
        <CommunityPanel />
        {this.state.items && this.state.items.length > 0 && (
          <React.Fragment>
            <h2>
              Community search results: {this.props.filter}{' '}
              {this.props.searchCommunities &&
              this.props.searchCommunities.length ? (
                <span>({this.props.searchCommunities.length})</span>
              ) : (
                ''
              )}
            </h2>
            <DetailsList
              columns={columns}
              constrainMode={ConstrainMode.horizontalConstrained}
              items={this.state.page}
              layoutMode={DetailsListLayoutMode.justified}
              onRenderItemColumn={this.onRenderItem}
              selectionMode={SelectionMode.none}
            />
          </React.Fragment>
        )}
        {this.props.searchCommunities &&
          this.props.searchCommunities.length === 0 && (
            <MessageBar messageBarType={MessageBarType.info} isMultiline={true}>
              There are no results for {this.props.filter} in communities
            </MessageBar>
          )}
        {this.props.searchCommunities &&
          this.props.searchCommunities.length > 0 && (
            <div className='govTeams-searchPager'>
              {this.state.currPage > 0 && this.state.pageItems.length > 1 && (
                <ActionButton
                  className='govTeams-searchPager--prev'
                  onClick={this.onPrevious}
                >
                  <Icon iconName='ChevronLeft' />
                  Previous
                </ActionButton>
              )}
              {this.state.pageItems.length - 1 > this.state.currPage && (
                <ActionButton
                  className='govTeams-searchPager--next'
                  onClick={this.onNext}
                >
                  Next
                  <Icon iconName='ChevronRight' />
                </ActionButton>
              )}
            </div>
          )}
      </React.Fragment>
    );
  }
  public componentDidMount() {
    if (this.props.searchCommunities) {
      let items = this.props.searchCommunities;

      if (this.props.memberTeams) {
        items = this.setItemTeamMembership(items, this.props.memberTeams);
      }

      const pageItems = this.setPages(items);

      this.setState({
        items,
        pageItems,
        page: pageItems[0],
        currPage: 0
      });
    }
  }

  public componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps.searchCommunities, this.props.searchCommunities)) {
      if (this.props.searchCommunities) {
        let items = this.props.searchCommunities;

        if (this.props.memberTeams) {
          items = this.setItemTeamMembership(items, this.props.memberTeams);
        }

        const pageItems = this.setPages(items);

        this.setState({
          items,
          pageItems,
          page: pageItems[0],
          currPage: 0
        });
      }
    }
  }

  private setItemTeamMembership(communities, teams) {
    const items: any[] = [];

    if (communities) {
      communities.forEach((community: any) => {
        teams.forEach((team: any) => {
          if (
            team.id.toLowerCase() === community.communityAzureId.toLowerCase()
          ) {
            community.isTeamMember = true;
          }
        });
        items.push(community);
      });
    }

    return items;
  }

  private setPages(items) {
    const pageItems: any[] = [];
    let count = 0;
    let page = 0;

    items.forEach((item) => {
      if (!pageItems[page]) {
        pageItems[page] = [];
      }

      if (count < 12) {
        pageItems[page].push(item);
        count++;
      }

      if (count === 12) {
        page++;
        count = 0;
      }
    });

    return pageItems;
  }

  private onRenderItem = (item, index, column) => {
    const fieldContent = item[column.fieldName];
    const key = column.key;

    switch (key) {
      case 'displayName':
        return this.renderDisplayname(item);
      case 'tags':
        return this.renderTags(item);
      case 'members':
        return this.renderMembers(item);
      case 'communityType':
        return this.renderCommunityType(item);
      default:
        return fieldContent;
    }
  };

  private renderDisplayname = (item) => {
    return (
      <strong>
        <Link
          key={item}
          onClick={(ev) => {
            if (item.communityId < 0) {
              window.open(item.sharePointUrl, 'blank');
            } else {
              this.props.communityActionCreators.viewCommunityPanel(item, true);
            }

            ev.preventDefault();
            ev.stopPropagation();
          }}
        >
          {item.displayName}
        </Link>
      </strong>
    );
  };

  private renderTags = (item) => {
    const tags = item.tags;

    return (
      <div className='tags'>
        {tags.map((tag, idx) => {
          return <Tag tag={tag} key={idx} />;
        })}
      </div>
    );
  };

  private renderCommunityType = (item) => {
    return (
      <div>
        <span>
          <Icon iconName={this.getCommunityTypeIcon(item)} />{' '}
          {item.communityType}
        </span>{' '}
        {item.sponsored && <span className='sponsoredBadge'>Sponsored</span>}
      </div>
    );
  };

  private getCommunityTypeIcon(item) {
    switch (item.communityType) {
      case 'Open':
        return 'Unlock';
      case 'Private':
        return 'Lock';
      case 'Hidden':
        return 'Hide';
      default:
        return '';
    }
  }

  private renderMembers = (item) => {
    return <span>{item.totalMembers}</span>;
  };

  private onColumnClick = (ev, column) => {
    const { columns, page } = this.state;
    let newPage = page.slice();
    const newColumns = columns.slice();
    const currColumn = newColumns.filter((currCol, idx) => {
      return column.key === currCol.key;
    })[0];
    if (currColumn.fieldName === 'tags') {
      // This column cannot be sorted
      return;
    }

    newColumns.forEach((newCol) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = false;
      }
    });

    if (currColumn.fieldName !== 'members') {
      newPage = sortItems(
        newPage,
        currColumn.fieldName,
        currColumn.isSortedDescending
      );
    } else {
      newPage = sortItemsByInt(
        newPage,
        'totalMembers',
        currColumn.isSortedDescending
      );
    }

    this.setState({
      columns: newColumns,
      page: newPage
    });
  };

  private onNext = (event) => {
    const nextPage = this.state.currPage + 1;

    this.setState({
      currPage: nextPage,
      page: this.state.pageItems[nextPage]
    });
  };

  private onPrevious = (event) => {
    const prevPage = this.state.currPage - 1;

    this.setState({
      currPage: prevPage,
      page: this.state.pageItems[prevPage]
    });
  };
}

const mapStateToProps = (state) => ({
  filter: state.search.filter,
  searchCommunities: state.search.searchCommunities,
  memberTeams: state.community.memberTeams
});

const mapDispatchToProps = (dispatch) => ({
  communityActionCreators: bindActionCreators(communityActionCreators, dispatch),
  search: bindActionCreators(searchActionCreators, dispatch),
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchCommunities);
