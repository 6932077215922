/**
 * Displays a message to inactive user telling them to reactivate their account
 *
 */

/**
 * React Redux
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

/**
 * Fabric UI
 */
import { PrimaryButton } from '@fluentui/react/lib/Button';
import {
  MessageBar,
  MessageBarType
} from '@fluentui/react/lib/MessageBar';

/**
 * Constants and utilities
 */
import * as constants from '../../common/Constants';

export class VerificationBanner extends React.Component<any, any> {
  public render() {
    return (
      <React.Fragment>
        {this.props.profile.daysLeftToVerify > 0 && (
            <MessageBar
              messageBarType={MessageBarType.warning}
              isMultiline={false}
              actions={
                <PrimaryButton
                  onClick={this.onReactivate}
                  primary={true}
                  className='govTeams-actionButtonAlt'
                  text='Verify account'
                  iconProps={{ iconName: 'ChevronRightSmall' }}
                />
              }
            >
              You have {this.props.profile.daysLeftToVerify} day{this.props.profile.daysLeftToVerify>1?`s`:``} to verify you
              still have access to <strong>{this.props.profile.mail}</strong>
            </MessageBar>
          )}
      </React.Fragment>
    );
  }

  private onReactivate = (event) => {
    this.props.dispatch(push(constants.SCREEN_VERIFY));
  };
}

const mapStateToProps = (state: any) => ({
  profile: state.profile
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(VerificationBanner);
