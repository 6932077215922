/**
 * Displays app announcements
 */

/**
 * React Redux
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { announcementsActionCreators } from './Announcements.operations';

/**
 * Fabric UI
 */
import {
  MessageBar,
  MessageBarType
} from '@fluentui/react/lib/MessageBar';

/**
 * Fabric UI
 */
import { List } from '@fluentui/react/lib/List';

export class AnnouncementsComponent extends React.Component<any, any> {
  public static getDerivedStateFromProps(props, state) {
    let announcementToHide = {};
    let announcementList = [];

    announcementList = props.announcementList;
    announcementToHide = props.announcementToHide;

    return {
      items: announcementList,
      itemsToHide: announcementToHide
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      itemsToHide: {}
    };
  }

  public render() {
    return <List items={this.state.items} onRenderCell={this.onRenderCell} />;
  }

  public componentDidMount() {
    this.props.announcements.requestAnnouncements();
  }

  private getMessageType(item) {
    switch (item.Priority) {
      case '1 - Alert':
        return MessageBarType.error;
      case '2 - Warning':
        return MessageBarType.severeWarning;
      default:
        return MessageBarType.info;
    }
  }

  private onRenderCell = (item, index) => {
    return (
      <React.Fragment>
        {!this.state.itemsToHide[item.ID] && (
          <MessageBar
            messageBarType={this.getMessageType(item)}
            onDismiss={() => this.props.announcements.hideAnnouncement(item.ID)}
            dismissButtonAriaLabel='Close'
            isMultiline={true}
            overflowButtonAriaLabel='Overflow'
            className={`govTeams-announcements-${this.getMessageType(item)} govTeams-announcement-padding`}
          >
            <div dangerouslySetInnerHTML={{ __html: item.Announcement }} />
          </MessageBar>
        )}
      </React.Fragment>
    );
  };
}
const mapStateToProps = (state) => ({
  announcementToHide: state.announcements.announcementToHide,
  announcementList: state.announcements.announcementList
});

const mapDispatchToProps = (dispatch) => ({
  announcements: bindActionCreators(announcementsActionCreators, dispatch)
});

export const Announcements = connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnouncementsComponent);

export default Announcements;
