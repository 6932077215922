import {
  IMemberPreferencesState,
  FETCH_MEMBERPREFERENCES_REQUEST,
  FETCH_MEMBERPREFERENCES_RESPONSE,
  SHOW_MEMBERPREFERENCES,
  HIDE_MEMBERPREFERENCES,
  UPDATE_MEMBERPREFERENCES_REQUEST,
  UPDATE_MEMBERPREFERENCES_RESPONSE
} from './MemberPreferences.types';
//import { CommunityGridSortOrder } from './../../screens/Dashboard/components/Communities/CommunityGridSorter';

export const initialState: IMemberPreferencesState = {
  showPreferences: false,
  preferences: null,
  isMemberPreferencesLoading: false,
  error: null
};

export const reducer = (state: IMemberPreferencesState = initialState, action) => {
  switch (action.type) {
    case FETCH_MEMBERPREFERENCES_REQUEST:
      return {
        ...state,
        isMemberPreferencesLoading: action.isMemberPreferencesLoading
      };

    case FETCH_MEMBERPREFERENCES_RESPONSE:
      return {
        ...state,
        isMemberPreferencesLoading: action.isMemberPreferencesLoading,
        preferences: action.preferences
      };

    case UPDATE_MEMBERPREFERENCES_REQUEST:
      return {
        ...state,
        isMemberPreferencesLoading: action.isMemberPreferencesLoading
      };

    case UPDATE_MEMBERPREFERENCES_RESPONSE:
      return {
        ...state,
        isMemberPreferencesLoading: action.isMemberPreferencesLoading,
        preferences: action.preferences
      };

    case SHOW_MEMBERPREFERENCES:
      return {
        ...state,
        showPreferences: action.showPreferences
      };

    case HIDE_MEMBERPREFERENCES:
      return {
        ...state,
        showPreferences: action.showPreferences
      };
    default:
      return {
        ...state
      };
  }
};

export default reducer;
