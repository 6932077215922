/**
 * Community card
 *
 * Displays community information and provides the ability to
 * -Add to favourite
 * -Launch community in Teams
 * -Launch community in SharePoint
 * -Invite members to community
 * -View community management page
 * -View community panel
 */

/**
 * React
 */
import * as React from 'react';

/**
 * Redux
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { inviteMembersActionCreators } from '../../../../controllers/InviteMembersController';
import { requestsActionCreators } from '../../../../controllers/RequestsController';
import { communityActionCreators } from '../../../../controllers/CommunityController';

/**
 * UI Fabric
 */
import {
  DocumentCard,
  DocumentCardActions,
  DocumentCardTitle,
  DocumentCardType
} from '@fluentui/react/lib/DocumentCard';
import { DefaultButton, IconButton } from '@fluentui/react/lib/Button';
import { Callout } from '@fluentui/react/lib/Callout';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';

/**
 * Constants
 */
import * as tooltips from '../../../../common/Tooltips';
import {saveCommunityManageEvent, saveInteractionWithCommunityEvent} from '../../../../common/EventTracking';

class CommunityCard extends React.Component<any, any> {
  private sponsoredBadge: HTMLElement | null;

  constructor(props) {
    super(props);

    this.state = {
      hideSponsoredCallout: true
    };
  }

  public render() {
    return (
      <DocumentCard
        type={DocumentCardType.normal}
        onClick={(ev: React.MouseEvent<any>) => {
          ev.preventDefault();
          ev.stopPropagation();

          const actionName = this.props.isTopOpenCommunity === true ? 'suggested_open_detail':'open_detail';
          if ( this.props.isOwner )
            saveCommunityManageEvent( actionName, this.props.displayName);
          else
            saveInteractionWithCommunityEvent( actionName, this.props.displayName);

          if (this.props.communityId < 0) {
            window.open(this.props.sharePointUrl, '_blank');
          } else {
            this.props.communityActionCreators.startEditManage(this.props);
          }
        }}
      >
        <div
          className={`ms-DocumentCard-details ${this.getColorClass(
            this.props.communityType
          )}`}
        >
          {this.props.communityId && (
            <div className={'communityCard-header'}>
              <div className='card-header-left'>
                {this.props.communityId > 0 && (
                  <div className='members'>
                    <TooltipHost
                      content={
                        'This community has ' +
                        this.props.totalMembers +
                        ' member(s)'
                      }
                    >
                      <DefaultButton
                        iconProps={{ iconName: 'Contact' }}
                        text={this.props.totalMembers}
                      />
                    </TooltipHost>
                  </div>
                )}
              </div>
              <div
                className='communityTypeBadge'
                style={{ marginRight: this.props.communityId > 0 ? 22 : 0 }}
              >
                {this.props.communityType}
              </div>
              <div className='card-header-right'>
                {this.props.sponsored && (
                  <React.Fragment>
                    <span
                      className='sponsored'
                      ref={(sponsoredBadge) =>
                        (this.sponsoredBadge = sponsoredBadge)
                      }
                      onMouseOver={() => {
                        this.setState({
                          hideSponsoredCallout: false
                        });
                      }}
                      onMouseOut={() => {
                        this.setState({
                          hideSponsoredCallout: true
                        });
                      }}
                    >
                      S
                    </span>
                    <Callout
                      hidden={this.state.hideSponsoredCallout}
                      target={this.sponsoredBadge}
                      calloutMaxWidth={300}
                    >
                      <div className='govTeams-Callout-body'>
                        <h3>Sponsored Communities</h3>
                        <p>{tooltips.SPONSORED_BADGE}</p>
                      </div>
                    </Callout>
                  </React.Fragment>
                )}

                {this.props.isMember && this.props.communityId > 0 && (
                  <div className='following icon-favorite'>
                    <TooltipHost
                      content={
                        this.props.isFavourite
                          ? 'Remove favourite'
                          : 'Favourite community'
                      }
                      calloutProps={{ gapSpace: 0 }}
                    >
                      <div>
                        <IconButton
                            iconProps={{
                              iconName: this.props.isFavourite
                                  ? 'FavoriteStarFill'
                                  : 'FavoriteStar'
                            }}
                            onClick={(ev) => {
                              saveInteractionWithCommunityEvent('toggle_favorite', this.props.displayName);
                              this.toggleFavourite();
                              ev.preventDefault();
                              ev.stopPropagation();
                            }}
                        />
                      </div>
                    </TooltipHost>
                  </div>
                )}
              </div>
            </div>
          )}

          <DocumentCardTitle title={this.props.displayName} />
          {this.props.description && (
            <div className='communityCard-description'>
              {this.getDescription(this.props)}
            </div>
          )}
        </div>
        {this.props.communityId > 0 && (
          <div className='ms-clearfix'>
            <div className='card-action-icons'>
              <DocumentCardActions
                actions={[
                  {
                    ariaLabel: tooltips.COMMUNITYTOOLS_TEAMS,
                    disabled:
                      this.props.hideButtons ||
                      !this.props.url ||
                      !this.props.isCreated ||
                      !this.props.isMember ||
                      this.props.iconPreferenceId === 3,
                    href: this.props.url,
                    className: 'teams-link',
                    onClick: (ev) => {
                      saveInteractionWithCommunityEvent('teams', this.props.displayName);
                      window.open(this.props.url, '_blank');
                      ev.preventDefault();
                      ev.stopPropagation();
                    },
                    title: tooltips.COMMUNITYTOOLS_TEAMS
                  },
                  {
                    ariaLabel: 'Open in SharePoint',
                    disabled:
                      this.props.hideButtons ||
                      !this.props.sharePointUrl ||
                      !this.props.isCreated ||
                      !this.props.isMember ||
                      this.props.iconPreferenceId === 2,
                    className: 'sharepoint-link',
                    onClick: (ev) => {
                      saveInteractionWithCommunityEvent('sharepoint', this.props.displayName);
                      window.open(this.props.sharePointUrl, '_blank');
                      ev.preventDefault();
                      ev.stopPropagation();
                    },
                    title: 'Open in SharePoint'
                  },
                  {
                    ariaLabel: 'Invite members',
                    disabled:
                      this.props.hideButtons ||
                      !this.props.isCreated ||
                      !this.props.isOwner,
                    iconProps: { iconName: 'PeopleAdd' },
                    onClick: (ev) => {
                      saveCommunityManageEvent('invite_members', this.props.displayName);
                      this.props.inviteMembersActionCreators.startEditInviteMembers(
                        this.props.communityId
                      );
                      ev.preventDefault();
                      ev.stopPropagation();
                    },
                    title: 'Invite members'
                  },
                  {
                    ariaLabel: 'Add me action',
                    disabled:
                      this.props.hideButtons ||
                      !this.props.isCreated ||
                      this.props.isMember,
                    iconProps: { iconName: 'AddFriend' },
                    onClick: (ev) => {
                      this.props.requestsActionCreators.startJoinMember(
                        this.props.communityId,
                        this.props.communityType,
                        false,
                        this.props.displayName,
                      );
                      ev.preventDefault();
                      ev.stopPropagation();
                    },
                    title:
                      this.props.communityType !== 'Private'
                        ? tooltips.COMMUNITYTOOLS_JOIN
                        : tooltips.COMMUNITYTOOLS_REQUEST
                  },
                  {
                    ariaLabel: 'Manage this community',
                    disabled:
                      this.props.hideButtons || !(this.props.communityId > 0),
                    iconProps: { iconName: 'Info' },
                    onClick: (ev) => {
                      if ( this.props.isOwner )
                        saveCommunityManageEvent( 'open_detail', this.props.displayName);
                      else
                        saveInteractionWithCommunityEvent( 'open_detail', this.props.displayName);

                      /**
                       * If the communityId is lees than 0, it is a link to the GovTeams page in Sharepoint
                       */
                      if (this.props.communityId < 0) {
                        window.open(this.props.sharePointUrl, '_blank');
                      } else {
                        this.props.communityActionCreators.viewCommunityPanel(this.props);
                      }
                      ev.preventDefault();
                      ev.stopPropagation();
                    },
                    title: this.props.isOwner
                      ? 'Manage this community'
                      : 'View community information'
                  },
                  {
                    ariaLabel: 'Pending requests',
                    disabled: !(
                      this.props.isOwner && this.props.pendingRequests > 0
                    ),
                    iconProps: { iconName: 'Ringer' },
                    onClick: (ev) => {
                      /**
                       * If the communityId is lees than 0, it is a link to the GovTeams page in Sharepoint
                       */
                      if (this.props.communityId < 0) {
                        window.open(this.props.sharePointUrl, '_blank');
                      } else {
                        this.props.communityActionCreators.startEditManage(this.props);
                      }
                      ev.preventDefault();
                      ev.stopPropagation();
                    },
                    title: `Pending requests: ${this.props.pendingRequests.toString()}`,
                    styles: {
                      root: {
                        backgroundColor: '#fdbc6a !important'
                      }
                    }
                  }
                ]}
              />
            </div>
          </div>
        )}
      </DocumentCard>
    );
  }

  /**
   * Get truncated community description
   */
  private getDescription = (community, maxLen = 80) => {
    let description = community.description;

    if (description && description.length > maxLen) {
      description = community.description.substring(0, maxLen) + '...';
    }

    return description;
  };

  /**
   * Get card header colour based on community type
   */
  private getColorClass = (communityType) => {
    switch (communityType) {
      case 'Open':
        return 'community-open';
      case 'Private':
        return 'community-closed';
      case 'Hidden':
        return 'community-hidden';
      default:
        return 'govTeams-blue';
    }
  };

  private toggleFavourite = () => {
    this.props.communityActionCreators.toggleFavourite(this.props);
  };
}

const mapStateToProps = (state) => ({
  azureId: state.community.communityAzureId,
  isUserInTeam: state.community.isUserInTeam
});

const mapDispatchToProps = (dispatch) => ({
  communityActionCreators: bindActionCreators(communityActionCreators, dispatch),
  inviteMembersActionCreators: bindActionCreators(inviteMembersActionCreators, dispatch),
  requestsActionCreators: bindActionCreators(requestsActionCreators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CommunityCard);
