import {
  FETCH_MEMBERPREFERENCES_REQUEST,
  FETCH_MEMBERPREFERENCES_RESPONSE,
  SHOW_MEMBERPREFERENCES,
  HIDE_MEMBERPREFERENCES,
  UPDATE_MEMBERPREFERENCES_REQUEST,
  UPDATE_MEMBERPREFERENCES_RESPONSE,
  MemberPreferences
} from './MemberPreferences.types';
import { progressActionCreators } from '../../components/Progress';
import { CommunityGridSortOrder } from '../../screens/Dashboard/components/Communities/CommunityGridSorter';
import { getAuthFromState } from '../../auth/authHandler';
import { httpServiceSecureGet, httpServiceSecurePost } from '../../common/Http';

export const memberPreferencesActionCreators = {
  fetchMemberPreferences: () => async (dispatch, getState) => {
    if (getState().memberPreferences.isMemberPreferencesLoading) {
      return;
    }

    dispatch({
      type: FETCH_MEMBERPREFERENCES_REQUEST,
      isMemberPreferencesLoading: true
    });

    const { gtToken } = await getAuthFromState(getState, dispatch);

    let preferences: MemberPreferences = {
      dashboardIsListMode: false,
      gridSortOrder: CommunityGridSortOrder.AlphabeticalAsc
    };

    try {
      const json = await httpServiceSecureGet('/memberPreferences', gtToken);
      preferences = JSON.parse(json);

      dispatch({
        type: FETCH_MEMBERPREFERENCES_RESPONSE,
        isMemberPreferencesLoading: false,
        preferences
      });

    } catch (error) {
      dispatch({
        type: UPDATE_MEMBERPREFERENCES_RESPONSE,
        isMemberPreferencesLoading: false,
        preferences,
        error
      });
    }
  },

  updateMemberPreferences: () => async (dispatch, getState) => {
    if (getState().memberPreferences.isMemberPreferencesLoading) {
      return;
    }

    dispatch({
      type: UPDATE_MEMBERPREFERENCES_REQUEST,
      isMemberPreferencesLoading: true
    });
    dispatch(progressActionCreators.startShowProgress('Saving preferences'));

    let preferences = getState().memberPreferences.preferences;
    const memberPreferencesForm = getState().form.MemberPreferencesForm;

    try {
      const { gtToken } = await getAuthFromState(getState, dispatch);
      const response = await httpServiceSecurePost('/memberPreferences', gtToken, memberPreferencesForm.values);

      if ( response ) {
        const json = await httpServiceSecureGet('/memberPreferences', gtToken);
        preferences = JSON.parse(json);
      }
    } catch (error) {
      dispatch({
        type: UPDATE_MEMBERPREFERENCES_RESPONSE,
        isMemberPreferencesLoading: false,
        preferences,
        error
      });
    }
    finally {
      dispatch(progressActionCreators.endShowProgress());
    }

    dispatch({
      type: UPDATE_MEMBERPREFERENCES_RESPONSE,
      isMemberPreferencesLoading: false,
      preferences,
      error: null
    });
    dispatch({
      type: HIDE_MEMBERPREFERENCES,
      showPreferences: false
    });
  },

  updateMemberPreferencesField: (field, newValue) => async (dispatch, getState) => {
    if (getState().memberPreferences.isMemberPreferencesLoading) {
      return;
    }

    dispatch({
      type: UPDATE_MEMBERPREFERENCES_REQUEST,
      isMemberPreferencesLoading: true
    });

    // dispatch(progressActionCreators.startShowProgress('Saving preferences'));

    let preferences = getState()?.memberPreferences?.preferences;
    if ( typeof preferences === 'string' )
      preferences = JSON.parse(preferences);

    preferences[field] = newValue;

    try {

      const { gtToken } = await getAuthFromState(getState, dispatch);
      const response = await httpServiceSecurePost('/memberPreferences', gtToken, preferences);

      if (response) {
        const json = await httpServiceSecureGet('/memberPreferences', gtToken);
        preferences = JSON.parse(json);
      }
    } catch (error) {
      dispatch({
        type: UPDATE_MEMBERPREFERENCES_RESPONSE,
        isMemberPreferencesLoading: false,
        preferences,
        error
      });
    }

    // dispatch(progressActionCreators.endShowProgress());
    dispatch({
      type: UPDATE_MEMBERPREFERENCES_RESPONSE,
      isMemberPreferencesLoading: false,
      preferences,
      error: null
    });
    dispatch({
      type: HIDE_MEMBERPREFERENCES,
      showPreferences: false
    });
  },

  showMemberPreferences: () => (dispatch, getState) => {
    dispatch({
      type: SHOW_MEMBERPREFERENCES,
      showPreferences: true
    });
  },

  hideMemberPreferences: () => (dispatch, getState) => {
    dispatch({
      type: HIDE_MEMBERPREFERENCES,
      showPreferences: false
    });
  }
};
