import * as React from 'react';
import { connect } from 'react-redux';
import { BRANDNAME } from '../../common/Tooltips';

const ConfirmationSuccess = props => (
  <div>
    <h1 className="heading-success">Registration</h1>
    <p>
      Your email address has been verified and the system is creating your
      account.
    </p>
    <p>
      An email will be sent shortly with your UserName and Password to access
      Users Area of {BRANDNAME}
    </p>
    <p>
      Please ensure that you check your Junk/Spam folders if you don't receive
      this email.
    </p>
  </div>
);

export default connect()(ConfirmationSuccess);
