/**
 * React
 */
import * as React from 'react';
import renderHTML from 'react-render-html';

/**
 * Redux
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { profileActionCreators } from '../../controllers/ProfileController';

/**
 * Redux-form
 */
import { Field, reduxForm } from 'redux-form';

/**
 * UI Fabric
 */
import {
  PrimaryButton,
  DefaultButton
} from '@fluentui/react/lib/Button';
import { Icon } from '@fluentui/react/lib/Icon';
import { Label } from '@fluentui/react/lib/Label';
import { Link } from '@fluentui/react/lib/Link';
import {
  MessageBar,
  MessageBarType
} from '@fluentui/react/lib/MessageBar';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { DirectionalHint } from '@fluentui/react/lib/Callout';

/**
 * Typings
 */
import { MemberType } from '../../controllers/CommunityController';

/**
 * UI Fabric customized
 */
import TextField from '../../components/ReduxFormFields/TextField';
import * as constants from '../../common/Constants';
import * as tooltips from '../../common/Tooltips';
import { isEqual } from 'lodash';

const validate = (values) => {
  const errors: any = {};

  const regexEmail = /^(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?)/;

  if (values.emailAddress === '') {
    errors.emailAddress = 'Email address is required.';
  } else if (
    values.emailAddress !== undefined &&
    values.emailAddress &&
    values.emailAddress.length > 256
  ) {
    errors.emailAddress = 'Email cannot be more than 256 characters.';
  }

  if (!regexEmail.test(values.emailAddress)) {
    errors.emailAddress = 'This does not appear to be a email address';
  }

  return errors;
};

export class EmailFormComponent extends React.Component<any, any> {
  constructor(props) {
      super(props);
      this.onResend.bind(this);
  }

  public render() {
    const { handleSubmit, pristine, submitting, invalid, showCancel } = this.props;

    return (
      <div>
        {this.props.profile.memberType !== MemberType.StateLicensedAccount &&
          this.props.profile.memberType !== MemberType.LinkedGuestUser && (
            <form onSubmit={handleSubmit} onKeyPress={this.onKeyPress}>
              <div className='formFields' style={{marginTop:20}}>
                <div className='formField'>
                  <hr className="my-2" />
                  {!this.props.profile.isGuest && this.props.profile.memberType !== MemberType.LAM ? (
                    <TooltipHost
                      content={tooltips.PROFILE_EMAIL}
                      directionalHint={DirectionalHint.rightTopEdge}
                    >
                      <Label className='formLabel h2' required={true}>
                        Email Address
                      </Label>
                      <Icon iconName='Info' />
                    </TooltipHost>
                  ) : (
                    <Label className='formLabel h2' required={true}>
                      Email Address
                    </Label>
                  )}
                  {this.props.profile.govTeamsProfileInfo &&
                    this.props.profile.govTeamsProfileInfo
                      .pendingEmailAddress && (
                      <MessageBar
                        className='govTeams-messageBar'
                        messageBarType={MessageBarType.warning}
                      >
                        <div>
                          {renderHTML(
                            constants.MESSAGE_PROFILE_PENDING_EMAIL.replace(
                              '%EMAILADDRESS%',
                              this.props.profile.govTeamsProfileInfo
                                .pendingEmailAddress
                            )
                          )}{' '}
                          <Link onClick={this.onResend}>
                            Re-send confirmation email
                          </Link>
                        </div>
                      </MessageBar>
                    )}

                  <div className={this.props.error && 'formField-error'}>
                    <Field
                      name='emailAddress'
                      component={TextField}
                      placeholder='Enter email address'
                      maxLength={256}
                      disabled={
                        this.props.profile.isGuest ||
                        this.props.profile.memberType === MemberType.LAM ||
                        this.props.profile.memberType === MemberType.Partner
                      }
                      required={true}
                  />
                  </div>
                </div>
              </div>
              {!this.props.profile.isGuest &&
                this.props.profile.memberType !== MemberType.LAM &&
                this.props.profile.memberType !== MemberType.Partner && (
                  <div className='formFooter'>
                    <div className='formFooter-left'>
                    {showCancel &&
                      <DefaultButton text='Cancel' onClick={this.onClose} />
                    }
                    <PrimaryButton
                        text='Update'
                        type='submit'
                        disabled={pristine || submitting || invalid}
                        onClick={handleSubmit(this.onSubmit)}
                      />
                    </div>
                  </div>
                )}
            </form>
          )}
      </div>
    );
  }

  public componentDidMount() {
    this.props.initialize(this.props.govTeamsProfileInfo);
  }

  public componentDidUpdate(prevProps) {
    if (
      !isEqual(prevProps.govTeamsProfileInfo, this.props.govTeamsProfileInfo)
    ) {
      this.props.initialize(this.props.govTeamsProfileInfo);
    }
  }

  private onKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  private onClose = (event) => {
    this.props.profileActions.endEditProfile();
    event.preventDefault();
    event.stopPropagation();
  };

  private onSubmit = (form) => {
    this.props.profileActions.requestSaveEmail();
  };

    private onResend = async () => {

        if (this.props.profile.govTeamsProfileInfo.pendingEmailAddress !== undefined || this.props.profile.govTeamsProfileInfo.pendingEmailAddress !== "") {
            this.props.profileActions.requestResendEmail();
        }
        else {
            window.location.reload();
        }

    };
}

const mapStateToProps = (state) => ({
  profile: state.profile,
  govTeamsProfileInfo: state.profile.govTeamsProfileInfo,
  error: state.profile.error
});

const mapDispatchToProps = (dispatch) => ({
  profileActions: bindActionCreators(profileActionCreators, dispatch)
});

const EmailForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailFormComponent);

export default reduxForm({
  form: 'EmailForm',
  validate
})(EmailForm);
