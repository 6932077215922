export const actions = {
  INVITES_FETCH_REQUEST: 'INVITES_FETCH_REQUEST',
  INVITES_FETCH_RESPONSE: 'INVITES_FETCH_RESPONSE',
  INVITES_DELETE_REQUEST: 'INVITES_DELETE_REQUEST',
  INVITES_DELETE_RESPONSE: 'INVITES_DELETE_RESPONSE',
  INVITES_RESEND_REQUEST: 'INVITES_RESEND_REQUEST',
  INVITES_RESEND_RESPONSE: 'INVITES_RESEND_RESPONSE'
};

export default actions;
