import { applyMiddleware, compose, legacy_createStore as createStore } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';

// Create browser history to use in the Redux store
import createRootReducer from './reducers';

const baseUrl: any = document.getElementsByTagName('base')[0]
    ? document.getElementsByTagName('base')[0].getAttribute('href')
    : '';

export const history = createBrowserHistory({ basename: baseUrl });

// In development, use the browser's Redux dev tools extension if installed
const enhancers = [];
const isDevelopment = process.env.NODE_ENV === 'development';

const composeEnhancer: typeof compose =
                            (isDevelopment &&
                              typeof window !== 'undefined' &&
                              (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
                            compose;

// Get the application-wide store instance, pre-populating with state from the server where available.

export const store = createStore(
    createRootReducer(history), // root reducer with router state
    {},
    composeEnhancer(
        applyMiddleware(
            routerMiddleware(history), // for dispatching history actions
            thunk,
            // ... other middlewares
        ),
        ...enhancers
    ),
)