/**
 * Profile screen
 *
 * -Manage GovTEAMS account
 * -Change email account
 * -TO DO ? Deactivate account
 * -View Delve information
 */

/**
 * React
 */
import * as React from 'react';

/**
 * Screen components
 */
import Delve from './Delve';
import Profile from './Profile';
import Email from './EmailForm';
import Deactivation from './Deactivation';

export class UserLayout extends React.Component<any, any> {
  public render() {
    return (
      <div className='govTeams-account'>
        <div className='govTeams-account-row'>
          <div className='govTeams-account-col'>
            <Profile />
            <Email showCancel={true} />
            <Delve />
            <Deactivation />
          </div>
        </div>
      </div>
    );
  }
}

export default UserLayout;
