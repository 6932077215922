import actions from './InviteMembers.actions';
import { IInviteMembersState } from './InviteMembers.types';

const initialState: IInviteMembersState = {
  communityId: null,
  isSubmitted: false,
  isLimitOverdue: false,
  results: [],
  isLoading: false,
  isModalOpen: false,
  isBulkInvite: false,
  error: null
};

export const reducer = (state, action) => {
  state = state || initialState;

  //UI
  if (action.type === actions.INVITE_MODAL_OPEN) {
    return {
      ...state,
      communityId: action.communityId,
      isModalOpen: true
    };
  }

  if (action.type === actions.INVITE_MODAL_CLOSE) {
    return {
      ...state,
      isSubmitted: false,
      isModalOpen: false,
      isBulkInvite: false
    };
  }

  if (action.type === actions.INVITE_TOGGLE_BULK_INVITE) {
    return {
      ...state,
      isBulkInvite: action.isBulkInvite
    };
  }

  //API
  if (action.type === actions.INVITE_MEMBERS_SAVE_REQUEST) {
    return {
      ...state,
      isSubmitted: false,
      isLoading: true
    };
  }

  if (action.type === actions.INVITE_MEMBERS_SAVE_RESPONSE) {
    return {
      ...state,
      isSubmitted: action.isSubmitted,
      results: action.results,
      isLimitOverdue: action.isLimitOverdue,
      isLoading: false,
      error: action.error
    };
  }

  return state;
};

export default reducer;
