/**
 * Notification panel accessible from app header.
 * Displays a list of app notifactions with function to clear all.
 */

/**
 * React Redux
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { notificationActionCreators } from './Notification.operations';

/**
 * Fabric UI
 */
import { Icon } from '@fluentui/react/lib/Icon';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { saveDashboardInteractionEvent } from '../../common/EventTracking';

export interface INotificationPanelProps {
  notification: any;
  notifications: any[];
  newCount: number;
}

export class NotificationPanel extends React.Component<
  INotificationPanelProps,
  any
> {
  constructor(props: any) {
    super(props);
    this.state = {
      isPanelVisible: false
    };
  }

  public render(): React.ReactElement<any> {
    return (
      <div className='govTeams-notifications'>
        <DefaultButton
          iconProps={{ iconName: 'Ringer' }}
          title='View notifications in your session'
          ariaLabel='View notifications in your session'
          onClick={this.showPanel}
          className='notification-button'
          text={this.props.newCount > 0 ? this.props.newCount.toString() : ''}
        />
        <Panel
          className='govTeams-panel govTeams-notification-panel'
          headerText='Notifications'
          isOpen={this.state.isPanelVisible}
          isBlocking={true}
          isLightDismiss={true}
          onDismiss={this.onPanelDismiss}
          onRenderHeader={this.onRenderHeader}
          customWidth='450px'
          type={PanelType.custom}
        >
          <div className='panel-content govTeams-panelNotifications'>
            <div className='notifications'>
              {this.props.notifications.length === 0 && (
                <div>You have no notifications</div>
              )}
              {this.props.notifications.map((notification, key) => {
                return (
                  <div className='notification-item' key={key}>
                    <div className={notification.isNew ? 'new' : ''}>
                      <Icon iconName='Info' />{' '}
                      <span className='timestamp'>
                        {notification.timestamp}
                      </span>
                    </div>
                    <p>{notification.message}</p>
                    <hr />
                  </div>
                );
              })}
            </div>
          </div>
        </Panel>
      </div>
    );
  }

  // private storageAvailable(type) {
  //   let storage = [];

  //   try {
  //     storage = window[type];
  //     const  x = '__storage_test__';
  //     storage.setItem(x, x);
  //     storage.removeItem(x);
  //     return true;
  //   }
  //   catch (e) {
  //     return e instanceof DOMException && (
  //       // everything except Firefox
  //       e.code === 22 ||
  //       // Firefox
  //       e.code === 1014 ||
  //       // test name field too, because code might not be present
  //       // everything except Firefox
  //       e.name === 'QuotaExceededError' ||
  //       // Firefox
  //       e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
  //       // acknowledge QuotaExceededError only if there's something already stored
  //       storage.length !== 0;
  //   }
  // }

  private onRenderHeader = (props) => {
    return (
      <div className='govTeams-panelHeader'>
        <div>
          <p>
            Clear:{' '}
            <button className='link-Button' onClick={this.clearAllNotifications} title={'Clear All Notifications'}>
              All
            </button>
          </p>
        </div>
        <div className={'one-line-left-right'}>
          <h2>Notifications{' '}</h2>
          <span className='notification-count'>
            {this.props.newCount} new updates
          </span>
        </div>
      </div>
    );
  };

  private showPanel = (): void => {
    saveDashboardInteractionEvent('notification_panel_clicked', 'notification');

    this.setState({
      isPanelVisible: !this.state.isPanelVisible
    });
  };

  private onPanelDismiss = (): void => {
    this.props.notification.dismissNotifications();

    this.setState({
      isPanelVisible: false
    });
  };
  private clearAllNotifications = (): void => {
    this.props.notification.deleteAllNotifications();
  };
}

const mapStateToProps = (state: any) => ({
  newCount: state.notification.newCount,
  notifications: state.notification.notifications
});

const mapDispatchToProps = (dispatch: any) => ({
  notification: bindActionCreators(notificationActionCreators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPanel);
