/**
 * Commmunity grid view
 *
 * Displays a four instances of the community grid for
 * -favourites
 * -owner
 * -member
 * -suggested
 */

/**
 * React Redux
 */
import * as React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { communityActionCreators, MemberType } from '../../../../controllers/CommunityController';
import { requestsActionCreators } from '../../../../controllers/RequestsController';

/**
 * UI Fabric
 */
import { ActionButton } from '@fluentui/react/lib/Button';
import { Icon } from '@fluentui/react/lib/Icon';

/**
 * GovTeams dependencies
 */
import CommunityGridSection from './CommunityGridSection';

import { isEmpty } from 'lodash';

interface IPropsToggleSeeMoreButton {
  className: string;
  isExpanded: boolean;
  callbackOnAction(flag: boolean): void;
}
const ToggleSeeMoreButton = (props: IPropsToggleSeeMoreButton) => {
  const { className, isExpanded, callbackOnAction } = props;

  const _onClick = () => callbackOnAction?.(!isExpanded);

  return (
    <ActionButton
      className={className}
      onClick={_onClick}
    >
      {!isExpanded ? 'See more' : 'See less'}
      <Icon
        iconName={
          !isExpanded ? 'ChevronDown' : 'ChevronUp'
        }
      />
    </ActionButton>
  );
};

const CommunityGrid = (props) => {
  const {
    isLoading,
    isGuest,
    favouriteCommunities,
    memberCommunities,
    ownerCommunities,
    memberType,
  } = props;

  const [expandFavouriteRow, setExpandFavouriteRow] = useState<boolean>(false);
  const [expandMemberRow, setExpandMemberRow] = useState<boolean>(false);
  const [expandOwnerRow, setExpandOwnerRow] = useState<boolean>(false);
  // const [expandSuggestedRow, setExpandSuggestedRow] = useState<boolean>(false);


  return (
    <React.Fragment>
      {(favouriteCommunities?.length > 0 || ownerCommunities?.length > 0 || memberCommunities?.length > 0) && (
        <div className='communityGrid'>
          {favouriteCommunities?.length > 0 && (
            <div className='communityDashboard-section'>
              <div className='communityDashboard-sectionHeader'>
                <h3>
                  Favourites{' '}
                  <span className='heading-meta'>({favouriteCommunities.length})</span>
                </h3>
                <div className='communityDashboard-sectionTools'>
                  <ToggleSeeMoreButton
                    className={'communityAction'}
                    isExpanded={expandFavouriteRow}
                    callbackOnAction={setExpandFavouriteRow}
                  />
                </div>
              </div>
              <div
                className={`community-ListGrid${
                  expandFavouriteRow ? ' expand' : ''
                }${!isEmpty(favouriteCommunities) ? '' : ' noCommunities'}`}
              >
                {!isLoading && isEmpty(favouriteCommunities) && (
                  <div className='communityDashboard-section-favourites'>
                    <div className='sectionText'>
                      Favourite communities by clicking the{' '}
                      <Icon iconName='FavoriteStar' /> icon.
                    </div>
                  </div>
                )}
                <CommunityGridSection
                  items={favouriteCommunities}
                  isLoading={isLoading}
                />
              </div>
              <ToggleSeeMoreButton
                className={'communityAction'}
                isExpanded={expandFavouriteRow}
                callbackOnAction={setExpandFavouriteRow}
              />
              <hr />
            </div>
          )}

          {(isGuest == null || !isGuest) &&
            memberType !== MemberType.Sponsored &&
            ownerCommunities &&
            ownerCommunities.length > 0 && (
              <div className='communityDashboard-section'>
                <div className='communityDashboard-sectionHeader'>
                  <h3>
                    Owner{' '}
                    <span className='heading-meta'>{ownerCommunities.length}</span>
                  </h3>
                  <div className='communityDashboard-sectionTools'>
                    <ToggleSeeMoreButton
                      className={'communityAction'}
                      isExpanded={expandOwnerRow}
                      callbackOnAction={setExpandOwnerRow}
                    />
                  </div>
                </div>
                <div
                  className={`community-ListGrid${expandOwnerRow ? ' expand' : ''}`}
                >
                  <CommunityGridSection
                    items={ownerCommunities}
                    isLoading={isLoading}
                  />
                </div>
                <ToggleSeeMoreButton
                  className={'communityAction'}
                  isExpanded={expandOwnerRow}
                  callbackOnAction={setExpandOwnerRow}
                />
                <hr />
              </div>
            )}

          {memberCommunities?.length > 0 && (
            <div className='communityDashboard-section'>
              <div className='communityDashboard-sectionHeader'>
                <h3>
                  Member{' '}
                  <span className='heading-meta'>({memberCommunities.length})</span>
                </h3>
                <div className='communityDashboard-sectionTools'>
                  <ToggleSeeMoreButton
                    className={'communityAction'}
                    isExpanded={expandMemberRow}
                    callbackOnAction={setExpandMemberRow}
                  />
                </div>
              </div>
              <div
                className={`community-ListGrid${expandMemberRow ? ' expand' : ''}`}
              >
                <CommunityGridSection
                  items={memberCommunities}
                  isLoading={isLoading}
                />
              </div>
              <ToggleSeeMoreButton
                className={'communityAction'}
                isExpanded={expandMemberRow}
                callbackOnAction={setExpandMemberRow}
              />
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.community.isLoading,
  isDirty: state.community.isDirty,
  isGuest: state.profile.isGuest,
  memberType: state.profile.memberType,
  favouriteCommunities: state.community.favouriteCommunities,
  memberCommunities: state.community.memberCommunities,
  memberTeams: state.community.memberTeams,
  ownerCommunities: state.community.ownerCommunities,
});

const mapDispatchToProps = (dispatch) => ({
  communityActionCreators: bindActionCreators(communityActionCreators, dispatch),
  requests: bindActionCreators(requestsActionCreators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CommunityGrid);
