/**
 * Recent documents dashboard component
 *
 */

/**
 * React
 */
import * as React from 'react';

/**
 * Redux
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { searchActionCreators } from '../../Search';

/**
 * Office Fabric UI components
 */
import { getFileTypeIconProps } from '@uifabric/file-type-icons';
import {
  ConstrainMode,
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode
} from '@fluentui/react/lib/DetailsList';
import { Icon } from '@fluentui/react/lib/Icon';
import { Link } from '@fluentui/react/lib/Link';
import {
  MessageBar,
  MessageBarType
} from '@fluentui/react/lib/MessageBar';
import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { DirectionalHint } from '@fluentui/react/lib/Callout';

/**
 * GovTeams dependencies
 */
import * as constants from '../../../common/Constants';

const RecentDocuments = (props) => {
  const { search, recentDocuments } = props;

  const myDocumentColumns = [
    {
      fieldName: 'fileType',
      isResizable: true,
      key: 'fileType',
      maxWidth: 60,
      minWidth: 60,
      name: 'File type'
    },
    {
      fieldName: 'title',
      isMultiline: true,
      isResizable: true,
      key: 'title',
      minWidth: 300,
      name: 'Title'
    },
    {
      fieldName: 'lastAccessed',
      isResizable: true,
      key: 'lastAccessed',
      maxWidth: 170,
      minWidth: 170,
      name: 'Date accessed'
    },
    {
      fieldName: 'lastModified',
      isResizable: true,
      key: 'lastModified',
      maxWidth: 170,
      minWidth: 170,
      name: 'Date modified'
    },
    {
      fieldName: 'community',
      isMultiline: true,
      isResizable: true,
      key: 'community',
      minWidth: 170,
      name: 'Commmunity'
    }
  ];

  const popularDocumentsColumns = [
    {
      fieldName: 'fileType',
      isResizable: true,
      key: 'fileType',
      maxWidth: 60,
      minWidth: 60,
      name: 'File type'
    },
    {
      fieldName: 'title',
      isMultiline: true,
      isResizable: true,
      key: 'title',
      name: 'Title'
    },
    {
      fieldName: 'community',
      isMultiline: true,
      isResizable: true,
      key: 'community',
      minWidth: 170,
      name: 'Commmunity'
    }
  ];

  const [columns, setColumns] = React.useState<any[]>(myDocumentColumns);
  const [selectedKey, setSelectedKey] = React.useState<string>(constants.RECENT_DOCUMENTS_MY);

  const onRenderItemLink = (el: any) => {
    return (
      <TooltipHost
        content={el.ariaLabel}
        directionalHint={DirectionalHint.bottomCenter}
      >
        {el.headerText}
      </TooltipHost>
    );
  };

  const onRenderItem = (item: any, index: number, column: any) => {
    const fieldContent = item[column.fieldName];
    const key = column.key;

    switch (key) {
      case 'title':
        return onRenderTitle(item);
      case 'fileType':
        return onRenderFileType(item.type);
      case 'dateModified':
        const date = new Date(fieldContent);
        return date.toLocaleString('en-US');
      default:
        return fieldContent;
    }
  };

  const removeRepeatedSentences = (input) => {
    // Regular expression to split sentences based on punctuation marks
    let sentences = input.split('\r\n');

    if (!sentences) {
      return input; // No valid sentences found, return original input
    }

    // Use a Set to store unique sentences
    let uniqueSentences: Set<string> = new Set(sentences);

    // Convert Set back to an array and join into a single string
    let result = [...Array.from(uniqueSentences)].join('\r\n');

    return result;
  }

  const onRenderTitle = (item: any) => {
    const previewText = item?.previewText;
    let optimizedText = removeRepeatedSentences(previewText);

    if (optimizedText?.length > 90) {
      optimizedText = optimizedText.substring(0, 90) + '...';
    }

    return (
      <div>
        <strong>
          <Link href={item.url} target='_blank'>
            {item.title}
          </Link>
        </strong>
        <div>{optimizedText}</div>
      </div>
    );
  }

  const onRenderFileType = (fileType: string) => {
    let extension = '';

    switch (fileType) {
      case 'Word':
        extension = 'docx';
        break;
      case 'OneNote':
        extension = 'one';
        break;
      case 'OneNotePage':
        extension = 'one';
        break;
      case 'Web':
        extension = 'website';
        break;
      case 'Excel':
        extension = 'xlsx';
        break;
      case 'Csv':
        extension = 'csv';
        break;
      case 'PowerPoint':
        extension = 'pptx';
        break;
      case 'Pdf':
        extension = 'pdf';
        break;
      case 'Text':
        extension = 'txt';
        break;
      case 'Visio':
        extension = 'vsdx';
        break;
      case 'spsite':
        extension = 'website';
        break;
      default:
        extension = '';
        break;
    }

    return <Icon {...getFileTypeIconProps({ extension, size: 32 })} />;
  };

  const onPivotLinkClick = (item: any) => {
    setSelectedKey(item.props.itemKey)

    switch (item.props.itemKey) {
      case constants.RECENT_DOCUMENTS_MY:
        setColumns(myDocumentColumns);
        search.requestFetchRecentDocuments(
          constants.RECENT_DOCUMENTS_MY
        );
        break;

      case constants.RECENT_DOCUMENTS_POPULAR:
        setColumns(popularDocumentsColumns);
        search.requestFetchRecentDocuments(
          constants.RECENT_DOCUMENTS_POPULAR
        );
        break;
      default:
        break;
    }
  };

  return (
    <div className='govTeams-recentDocuments'>
      <h2>Recent documents</h2>
      <div className='pivotLinks'>
        <Pivot
          selectedKey={selectedKey}
          onLinkClick={onPivotLinkClick}
        >
          <PivotItem
            ariaLabel={'Documents you have recently contributed to.'}
            headerText={constants.RECENT_DOCUMENTS_MY}
            itemKey={constants.RECENT_DOCUMENTS_MY}
            onRenderItemLink={onRenderItemLink}
          />
          <PivotItem
            ariaLabel={'You only see documents you have access to.'}
            headerText={constants.RECENT_DOCUMENTS_POPULAR}
            itemKey={constants.RECENT_DOCUMENTS_POPULAR}
            onRenderItemLink={onRenderItemLink}
          />
        </Pivot>
      </div>
      <div>
        {recentDocuments &&
          recentDocuments.length > 0 && (
            <DetailsList
              columns={columns}
              constrainMode={ConstrainMode.horizontalConstrained}
              items={recentDocuments}
              layoutMode={DetailsListLayoutMode.justified}
              onRenderItemColumn={onRenderItem}
              selectionMode={SelectionMode.none}
            />
          )}
        {recentDocuments &&
          recentDocuments.length === 0 && (
            <MessageBar
              messageBarType={MessageBarType.info}
              isMultiline={true}
            >
              There are no results for{' '}
              {selectedKey === constants.RECENT_DOCUMENTS_MY
                ? constants.RECENT_DOCUMENTS_MY
                : constants.RECENT_DOCUMENTS_POPULAR}
            </MessageBar>
          )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  recentDocuments: state.search.recentDocuments
});

const mapDispatchToProps = (dispatch: any) => ({
  search: bindActionCreators(searchActionCreators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(RecentDocuments);
