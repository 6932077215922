import * as React from 'react';
import { IAccordionProps, IAccordionState } from './index';
import { DefaultButton, IIconProps } from '@fluentui/react';

/**
 * Icon styles. Feel free to change them
 */
const collapsedIcon: IIconProps = { iconName: 'ChevronRight', className: 'accordionChevron' };
const expandedIcon: IIconProps = { iconName: 'ChevronDown', className: 'accordionChevron' };

export class Accordion extends React.Component<IAccordionProps, IAccordionState> {
  private _drawerDiv: HTMLDivElement | null = null;
  constructor(props: IAccordionProps) {
    super(props);

    this.state = {
      expanded: props.defaultCollapsed == null ? false : !props.defaultCollapsed
    };
  }

  public render(): React.ReactElement<IAccordionProps> {
    return (
      <div className={'accordion ' + this.props.className}>
        <DefaultButton
          toggle={true}
          checked={this.state.expanded}
          text={this.props.title}
          iconProps={this.state.expanded ? expandedIcon : collapsedIcon}
          onClick={() => {
            this.setState({
              expanded: !this.state.expanded
            });
          }}
          aria-expanded={this.state.expanded}
          aria-controls={(this._drawerDiv === null) ? undefined : this._drawerDiv.id}
        />
        {this.state.expanded &&
          <div className={'drawer'} ref={(el) => { this._drawerDiv = el; }}>
            {this.props.children}
          </div>
        }
      </div>
    );
  }
}
