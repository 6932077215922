import {
  ProfileActionTypes,
  PROFILE_EDIT_START,
  PROFILE_EDIT_END,
  PROFILE_DEACTIVATION_START,
  PROFILE_DEACTIVATION_END,
  PROFILE_DEACTIVATED,
  PROFILE_LOGOUT,
  PROFILE_SAVE,
  PROFILE_EMAIL_SAVE,
  PROFILE_EMAIL_RESEND,
  PROFILE_AUTHENTICATE,
  PROFILE_LOAD,
  PROFILE_GET_AZURE,
  PROFILE_GET_GOVTEAMS_PROFILE,
  PROFILE_GET_GOVTEAMS_PROFILE_ERROR,
  PROFILE_GET_DELVE,
  PROFILE_GET_IMAGE,
  PROFILE_VERIFY,
  PROFILE_VERIFY_RESEND,
  PROFILE_RESTORE_LICENCE
} from './Profile.actions';

import { IProfileState } from './Profile.types';

export const initialState: IProfileState = {
  id: null,
  azureMail: null,
  linkedAccountAzureId: null,
  userPrincipalName: null,
  mail: null,
  businessPhones: null,
  mobilePhone: null,
  displayName: null,
  givenName: null,
  department: null,
  govTeamsProfileInfo: null,
  imageUrl: null,
  isAuthenticated: false,
  isAuthorised: false,
  isGuest: false,
  isSubmitted: false,
  jobTitle: null,
  aboutMe: '',
  skills: null,
  schools: null,
  interests: null,
  topics: null,
  projects: null,
  isLoading: false,
  memberStatus: null,
  memberTypeId: -1,
  memberType: null,
  daysLeftToVerify: -1,
  isDeactivating: false,
  error: null,
  verificationError: '',
  organisationContact: null,
  licenceTempRemovedDisplayModal: false
};

export const reducer = (
  state: IProfileState = initialState,
  action: ProfileActionTypes
) => {
  //UI
  if (action.type === PROFILE_LOAD) {
    return {
      ...state,
      isLoading: true
    };
  }
  if (action.type === PROFILE_EDIT_START) {
    return {
      ...state,
      govTeamsProfileInfo: action.govTeamsProfileInfo
    };
  }

  if (action.type === PROFILE_EDIT_END) {
    return {
      ...state
    };
  }

  if (action.type === PROFILE_DEACTIVATION_START) {
    return {
      ...state,
      isDeactivating: true
    };
  }

  if (action.type === PROFILE_DEACTIVATION_END) {
    return {
      ...state,
      isDeactivating: false
    };
  }

  if (action.type === PROFILE_LOGOUT) {
    return {
      ...state,
      id: null
    };
  }

  //API
  if (action.type === PROFILE_SAVE) {
    return {
      ...state,
      isSubmitted: action.isSubmitted,
      isLoading: false,
      error: action.error
    };
  }

  if (action.type === PROFILE_EMAIL_SAVE) {
    return {
      ...state,
      isLoading: false,
      error: action.error
    };
  }

  if (action.type === PROFILE_EMAIL_RESEND) {
    return {
      ...state,
      isLoading: false,
      error: action.error
    };
  }

  if (action.type === PROFILE_AUTHENTICATE) {
    return {
      ...state,
      isAuthenticated: true,
      isLoading: false,
      error: action.error
    };
  }

  if (action.type === PROFILE_GET_AZURE) {
    return {
      ...state,
      id: action.id,
      azureMail: action.azureMail,
      businessPhones: action.businessPhones,
      mobilePhone: action.mobilePhone,
      displayName: action.displayName,
      givenName: action.givenName,
      department: action.department,
      jobTitle: action.jobTitle,
      isGuest: action.isGuest,
      userPrincipalName: action.userPrincipalName,
      isLoading: false,
      error: action.error
    };
  }

  if (action.type === PROFILE_GET_DELVE) {
    return {
      ...state,
      aboutMe: action.aboutMe,
      skills: action.skills,
      schools: action.schools,
      interests: action.interests,
      topics: action.topics,
      projects: action.projects,
      isLoading: false,
      error: action.error
    };
  }

  if (action.type === PROFILE_GET_IMAGE) {
    return {
      ...state,
      imageUrl: action.imageUrl,
      error: action.error
    };
  }

  if (action.type === PROFILE_GET_GOVTEAMS_PROFILE) {
    return {
      ...state,
      isLoading: false,
      govTeamsProfileInfo: action.govTeamsProfileInfo,
      mail: action.govTeamsProfileInfo?.emailAddress,
      linkedAccountAzureId: action.govTeamsProfileInfo?.linkedAccountAzureId,
      memberStatus: action.govTeamsProfileInfo?.memberStatus,
      daysLeftToVerify: action.govTeamsProfileInfo?.daysLeftToVerify,
      memberTypeId: action.govTeamsProfileInfo?.memberTypeId,
      memberType: action.govTeamsProfileInfo?.memberType,
      organisationContact: action.govTeamsProfileInfo?.organisationContact,
      isAuthorised: action.isAuthorised,
      licenceTempRemovedDisplayModal: action.govTeamsProfileInfo?.licenceTempRemovedDisplayModal
    };
  }

  if (action.type === PROFILE_GET_GOVTEAMS_PROFILE_ERROR) {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  }

  if (action.type === PROFILE_VERIFY) {
    return {
      ...state,
      isLoading: false,
      memberStatus:
        action.memberStatus !== undefined
          ? action.memberStatus
          : state.memberStatus,
      error: action.error,
      verificationError: action.verificationError
    };
  }

  if (action.type === PROFILE_VERIFY_RESEND) {
    return {
      ...state,
      isLoading: false,
      error: action.error
    };
  }

  if (action.type === PROFILE_DEACTIVATED) {
    return {
      ...state,
      isLoading: false,
      error: action.error
    };
  }

  if (action.type === PROFILE_RESTORE_LICENCE) {
    return {
      ...state,
      error: action.error
    };
  }

  return state;
};

export default reducer;
