/**
 * Community management screen
 *
 */

/**
 * React
 */
import * as React from 'react';
import { isEqual } from 'lodash';

/**
 * Redux
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { membersActionCreators } from '../../controllers/MembersController';
import { invitesActionCreators } from './components/Invites';
import { requestsActionCreators } from '../../controllers/RequestsController';
import { communityActionCreators } from '../../controllers/CommunityController';

/**
 * Fabric UI
 */
import { ActionButton } from '@fluentui/react/lib/Button';

/**
 * Child components
 */
import CommunityActions from './components/CommunityActions';
import Members from './components/Members';

import GovTeamsLogo from '../../screens/Dashboard/images/LogoGTO.png';
import DashboardComponent from '@govteams/library/components/ManageCommunity/DashboardComponent/';

/**
 * Constants and utilities
 */
import * as constants from '../../common/Constants';
import * as tooltips from '../../common/Tooltips';
import { ICommunity } from '../../controllers/CommunityController';
import { isProtected } from '../../common/Utils';
import CommunityEditModal from './../../components/CommunityEditModal';

export class CommunityManagementComponent extends React.Component<any, any> {
  private sponsoredBadge: HTMLElement | null;

  constructor(props) {
    super(props);

    this.state = {
      screenWidth: window.innerWidth,
      hideSponsoredCallout: true
    };
  }

  public render() {
    const community: ICommunity = this.props.initialValues;
    const { profile } = this.props;

    return (
      <div className='communityManagement-layout'>
        <ActionButton
          className='govTeams-actionButton'
          onClick={this.onClose}
          iconProps={{ iconName: 'ChevronLeft' }}
        >
          {constants.LINK_BACKTODASHBOARD}
        </ActionButton>

        <CommunityEditModal />
        <DashboardComponent
          community={community}
          constants={constants}
          tooltips={tooltips}
          profile={profile}
          govTeamsLogo={GovTeamsLogo}
          isProtected={isProtected()}
          screenWidth={this.state.screenWidth}
          toggleFavourite={this.toggleFavourite}

          requestsProps={{
            values: this.props.requestsValues,
            requestChangeRequest: this.props.requestsActionCreators?.requestChangeRequest,
          }}
          pendingInvitesProps={{
            invitations: this.props.pendingInvitations,
            inviteType: 'pending',
            requestResendInvite: this.props.invitesActionCreators?.requestResendInvite,
            requestDeleteInvite: this.props.invitesActionCreators?.requestDeleteInvite,
          }}
          expiredInvitesProps={{
            invitations: this.props.expiredInvitations,
            inviteType: 'expired',
            requestResendInvite: this.props.invitesActionCreators?.requestResendInvite,
            requestDeleteInvite: this.props.invitesActionCreators?.requestDeleteInvite,
          }}

          CommunityActionsComponent={<CommunityActions />}
          MembersComponent={<Members {...this.props} />}
        />
      </div>
    );
  }

  public async componentDidMount() {
    // const communityId = this.props.match.params.communityId;
    const communityAzureId = this.props.match.params.communityAzureId;

    if (!this.props.initialValues) {
      await this.props.communityActionCreators.requestFetchCommunity(
        communityAzureId
      );
    }

    if (this.props.initialValues) {
      if (this.props.initialValues.isOwner) {
        this.props.requestsActionCreators.requestFetchRequests(
          this.props.initialValues.communityId
        );
        this.props.invitesActionCreators.requestFetchInvites(
          this.props.initialValues.communityId
        );
      }

      this.props.membersActionCreators.requestFetchMembers(
        this.props.initialValues.communityId
      );
    }

    window.addEventListener('resize', this.updateDimensions);
  }

  public componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps.invitationResults, this.props.invitationResults)) {
      this.props.communityActionCreators.requestFetchCommunity(
        this.props.initialValues.communityAzureId
      );
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  private updateDimensions = (event: UIEvent) => {
    this.setState({ screenWidth: window.innerWidth });
  };

  private onClose = (event: React.MouseEvent<any>) => {
    this.props.communityActionCreators.endEditManage();
  };

  private toggleFavourite = async (event) => {
    const community: ICommunity = this.props.initialValues;

    if (!this.props.favouriteCommunities) {
      await this.props.communityActionCreators.requestFetchCommunities();
    }

    await this.props.communityActionCreators.toggleFavourite(community);
  };
}

const mapStateToProps = (state) => ({
  azureId: state.profile.id,
  linkedAccountAzureId: state.profile.linkedAccountAzureId,
  memberType: state.profile.memberType,
  communityId: state.community.communityId,
  initialValues: state.community.initialValues,
  pendingInvitations: state.invites.pendingInvitations,
  expiredInvitations: state.invites.expiredInvitations,
  requestsValues: state.requests.values,
  adminMembers: state.members.adminMembers,
  govMembers: state.members.govMembers,
  guestMembers: state.members.guestMembers,
  externalMembers: state.members.externalMembers,
  profile: state.profile,
  invitationResults: state.inviteMembers.results
});

const mapDispatchToProps = (dispatch) => ({
  communityActionCreators: bindActionCreators(
    communityActionCreators,
    dispatch
  ),
  membersActionCreators: bindActionCreators(membersActionCreators, dispatch),
  invitesActionCreators: bindActionCreators(invitesActionCreators, dispatch),
  requestsActionCreators: bindActionCreators(requestsActionCreators, dispatch)
});

export const CommunityManagement = connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunityManagementComponent);

export default CommunityManagement;
